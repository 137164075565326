import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { IPolicy } from '@prcins/utils';
import { CURRENT_POLICY, CURRENT_POLICY_LOB } from '@prcins/constants';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'prcins-mobile-selector',
  templateUrl: './mobile-selector.component.html',
  styleUrls: ['./mobile-selector.component.css']
})
export class MobileSelectorComponent implements OnChanges {
  @Input() registeredPolicies: IPolicy[];
  @Input() isHome: boolean;
  @Input() noSelector: boolean;
  @Output() policySelected = new EventEmitter<IPolicy>();
  currentSelectedPolicy: IPolicy;
  type2Selector = false;

  showPolicies = false;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnChanges(): void {
    this.type2Selector = !this.isHome;
    this.showPolicies = false;
    if (this.registeredPolicies) {
      this.currentSelectedPolicy = {
        policyNumber: sessionStorage.getItem(CURRENT_POLICY),
        policyInfo: {
          lob: sessionStorage.getItem(CURRENT_POLICY_LOB)
        }
      };
    }
  }

  togglePolicyDropdown(type) {
    this.showPolicies = !this.showPolicies;
    this.type2Selector = type === 'type2';
  }

  onPolicySelected(policy: IPolicy) {
    if (!sessionStorage.getItem('guid')) {
      this.currentSelectedPolicy = policy;
      this.policySelected.next(policy);
      this.showPolicies = false;
    } else {
      return;
    }

  }

  linkPolicy() {
    this.showPolicies = false;
    this.router.navigate(['../home/link-a-policy'], { relativeTo: this.route });
  }
}
