/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./welcome-banner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../notification/notification.component.ngfactory";
import * as i3 from "../notification/notification.component";
import * as i4 from "@angular/router";
import * as i5 from "./desktop-banner/desktop-banner.component.ngfactory";
import * as i6 from "./desktop-banner/desktop-banner.component";
import * as i7 from "./mobile-banner/mobile-banner.component.ngfactory";
import * as i8 from "./mobile-banner/mobile-banner.component";
import * as i9 from "./welcome-banner.component";
import * as i10 from "../services/login.service";
var styles_WelcomeBannerComponent = [i0.styles];
var RenderType_WelcomeBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WelcomeBannerComponent, data: {} });
export { RenderType_WelcomeBannerComponent as RenderType_WelcomeBannerComponent };
export function View_WelcomeBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "w-full flex justify-center desktop-banner mt-4 p-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "prcins-notification", [["class", "sm:w-3/4 max-w-2xl"]], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(2, 114688, null, 0, i3.NotificationComponent, [i4.Router], { notification: [0, "notification"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "prcins-desktop-banner", [], null, null, null, i5.View_DesktopBannerComponent_0, i5.RenderType_DesktopBannerComponent)), i1.ɵdid(4, 49152, null, 0, i6.DesktopBannerComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "prcins-mobile-banner", [["class", "w-full"]], null, null, null, i7.View_MobileBannerComponent_0, i7.RenderType_MobileBannerComponent)), i1.ɵdid(6, 49152, null, 0, i8.MobileBannerComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notification; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WelcomeBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-welcome-banner", [], null, null, null, View_WelcomeBannerComponent_0, RenderType_WelcomeBannerComponent)), i1.ɵdid(1, 114688, null, 0, i9.WelcomeBannerComponent, [i10.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WelcomeBannerComponentNgFactory = i1.ɵccf("prcins-welcome-banner", i9.WelcomeBannerComponent, View_WelcomeBannerComponent_Host_0, {}, {}, []);
export { WelcomeBannerComponentNgFactory as WelcomeBannerComponentNgFactory };
