/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./mobile-header.component";
import * as i5 from "../../../../../../libs/utils/src/lib/services/navigation-provider.service";
var styles_MobileHeaderComponent = [i0.styles];
var RenderType_MobileHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileHeaderComponent, data: {} });
export { RenderType_MobileHeaderComponent as RenderType_MobileHeaderComponent };
function View_MobileHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "assets/svgs/hamburger.svg"]], null, null, null, null, null))], null, null); }
function View_MobileHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "assets/svgs/close-hamburger.svg"]], null, null, null, null, null))], null, null); }
function View_MobileHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/svgs/plus-icon.svg"]], null, null, null, null, null))], null, null); }
function View_MobileHeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/svgs/minus-icon.svg"]], null, null, null, null, null))], null, null); }
function View_MobileHeaderComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "small-loader"], ["src", "assets/png/loading.gif"]], null, null, null, null, null))], null, null); }
function View_MobileHeaderComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "ml-8 p-2"]], [[2, "disable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onMenuClick(_v.parent.parent.parent.context.$implicit.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_10)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.route; _ck(_v, 1, 0, currVal_3); var currVal_5 = _v.parent.context.$implicit.waitConditionally; _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.disable; var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_4); }); }
function View_MobileHeaderComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "ml-8 p-2"], ["target", "_blank"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMenuClick(_v.parent.parent.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.link, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_1); }); }
function View_MobileHeaderComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "ml-8 p-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.onMenuClick(_v.parent.parent.parent.context.$implicit.id);
        var pd_0 = (_co.menuNavigationHandler(_v.parent.context.$implicit.click) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_MobileHeaderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_9)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_11)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_12)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLink(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.link; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.click; _ck(_v, 6, 0, currVal_2); }, null); }
function View_MobileHeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flex flex-col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_8)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.children; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MobileHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [["class", "es-mobile-header-menu cursor-pointer"]], [[2, "active_menu", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showSubMenu(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "mr-2"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_5)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["paymentsSub", 2]], null, 0, null, View_MobileHeaderComponent_6)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_7)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.navMenu[_v.context.$implicit.id]; var currVal_4 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _co.navMenu[_v.context.$implicit.id]; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.id === _co.selectedHeader); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.icon, ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_2); }); }
function View_MobileHeaderComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "es-mobile-header-menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSoftLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "flex items-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Admin Link"]))], null, null); }
function View_MobileHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "bg-es-blue text-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "es-mobile-header-menu"]], [[2, "active_menu", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onMenuClick("home") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["class", "mr-2"], ["src", "assets/svgs/home-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Home"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_4)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_13)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "a", [["class", "es-mobile-header-menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["alt", ""], ["class", "mr-2"], ["src", "assets/svgs/logout.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Log Out"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 3, 0, "../home"); _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.menus; _ck(_v, 8, 0, currVal_4); var currVal_5 = (_co.isAdmin && (_co.menus.length > 0)); _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("home" === _co.selectedHeader); var currVal_1 = i1.ɵnov(_v, 2).target; var currVal_2 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_MobileHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "lg:hidden xl:hidden w-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "header", [["class", "sm:flex md:flex p-4 bg-es-blue flex justify-between items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleNavMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.showNavigationPanel; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.showNavigationPanel; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.showNavigationPanel; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "assets/svgs/brands/", _co.brand, "/mobile-logo.svg"); _ck(_v, 3, 0, currVal_0); }); }
export function View_MobileHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-mobile-header", [], null, null, null, View_MobileHeaderComponent_0, RenderType_MobileHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.MobileHeaderComponent, [i5.NavigationProviderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileHeaderComponentNgFactory = i1.ɵccf("prcins-mobile-header", i4.MobileHeaderComponent, View_MobileHeaderComponent_Host_0, { menus: "menus", activeMenus: "activeMenus", selectedHeader: "selectedHeader" }, { logOut: "logOut", softLogOut: "softLogOut" }, []);
export { MobileHeaderComponentNgFactory as MobileHeaderComponentNgFactory };
