<div class="desktop-only-container">
  <main class="px-8 pb-8 pt-4">
    <h1 class="es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0">Reset Password</h1>
    <prcins-danger *ngIf="registrationError">
      <span>
        {{errorMessage}}
      </span>
    </prcins-danger>
    <prcins-danger *ngIf="accountLockError">Oops! Your account has been temporarily locked. Please wait
      30 minutes and try again or let us help.
      <a class="underline font-bold text-es-blue-light" target="_blank"
        href="https://www.plymouthrock.com/contact-us">Give us a call! </a>
    </prcins-danger>
    <prcins-password-form [passwordForm]="resetPasswordForm"></prcins-password-form>

    <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
  </main>
</div>

<div class="flex px-8 lg:px-0 mb-4">
  <label class="flex items-center">
    <strong class="terms"> I have read and agree to the <a class="text-es-blue hover:underline"
        href="https://www.plymouthrock.com/utility/internet-policy-popup" target="_blank"
        rel="noopener noreferrer">Terms and
        Conditions</a> and I <a href="https://www.plymouthrock.com/internet-policy/consent-to-conduct" target="_blank"
        class="text-blue-600">Consent to Conduct Business Electronically</a>.</strong>
  </label>
</div>

<div class="flex justify-end flex-wrap px-8 lg:px-0">
  <button class="w-full lg:w-2/5 es-btn-primary" (click)="onContinue()" type="button">Continue</button>
</div>

<prcins-es-popup *ngIf="showGuardPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Confirmation</span>
  </div>
  <div body>
    <p class="text-center text-lg">Are you sure you want to cancel reset password?</p>
    <div class="flex flex-wrap-reverse justify-between">
      <a class="es-link-secondary  w-half-2 mt-8  cursor-pointer" (click)="chooseDeactivate(false)">No</a>
      <button class="es-btn-primary w-half-2 mt-8 cursor-pointer" type="button"
        (click)="cancelTransaction()">Yes</button>
    </div>
  </div>
</prcins-es-popup>
