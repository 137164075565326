import { Component } from '@angular/core';
import { BRAND, Brand } from '@prcins/constants';
import { environment } from '@prcins/environments';

@Component({
  selector: 'prcins-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.css']
})
export class MobileHeaderComponent {
  brand = localStorage.getItem(BRAND);
  contactUs = Brand[this.brand].urlContactUs;
  mainPage = Brand[this.brand].urlEnterpriseWeb;
  showNavigationPanel = false;
  efnolURL = environment.EFNOL_URL;

  toggleNavMenu () {
    this.showNavigationPanel = !this.showNavigationPanel;
  }
}