import { UtilityProviderService } from './utility-provider.service';
import * as i0 from "@angular/core";
import * as i1 from "./utility-provider.service";
export class NavigationProviderService {
    constructor(utilityProvider) {
        this.utilityProvider = utilityProvider;
    }
    gotoReportClaim() {
        this.utilityProvider.getClaimsToken().subscribe(data => {
            window.open(data, '_blank');
        });
    }
}
NavigationProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationProviderService_Factory() { return new NavigationProviderService(i0.ɵɵinject(i1.UtilityProviderService)); }, token: NavigationProviderService, providedIn: "root" });
