<header class="hidden lg:flex xl:flex p-4 bg-es-blue items-center border-t-4 border-es-orange">
  <a href="{{mainPage}}" target="_blank">
    <img src="assets/svgs/brands/{{brand}}/header-logo.svg">
  </a>
  <div class="text-white ml-16 flex items-center">
    <a class="es-desktop-header-menu menu" [routerLink]="['/eservice/login']"><img class="mr-2" src="assets/svgs/logout.svg"
        alt="">Login</a>
    <a class="es-desktop-header-menu menu" [routerLink]="['/qpay/login']"><img class="mr-2"
        src="assets/svgs/payment-icon.svg" alt="">Quick Pay</a>
    <a class="es-desktop-header-menu menu" href="{{efnolURL}}" target="_blank"><img class="mr-2"
        src="assets/svgs/claims-icon.svg" alt="">Report a Claim</a>
    <a class="es-desktop-header-menu menu" href="{{contactUs}}" target="_blank"><img
        class="mr-2" src="assets/svgs/mailing-icon.svg" alt="">Contact Us</a>
  </div>
</header>