import { AfterViewInit, Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'prcins-danger',
  templateUrl: './danger.component.html',
  styleUrls: ['./danger.component.css']
})
export class DangerComponent implements AfterViewInit {

  @ViewChild('danger', { static: false }) element: ElementRef;

  ngAfterViewInit() {
    let {
      offsetLeft,
      offsetTop,
      offsetParent: { clientWidth, clientHeight }
    } = this.element.nativeElement;

    window.scroll(offsetLeft, offsetTop)
  }
}
