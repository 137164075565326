import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ACCOUNTS_URL } from '@prcins/constants';
import { HttpClient } from '@angular/common/http';
import { INotification } from '@prcins/utils';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notify$: Subject<INotification> = new Subject();

  constructor(private httpClient: HttpClient) { }

  sendEmail(request): Observable<any> {
    return this.httpClient.post(`${ACCOUNTS_URL.EmailSupport}`, request);
  }
}
