<header class="hidden lg:flex xl:flex bg-es-blue border-t-4 border-es-orange" (mouseleave)="onHideSubMenu()">
  <div class="flex ml-4 mr-16 items-center">
    <a>
      <img src="assets/svgs/brands/{{brand}}/header-logo.svg">
    </a>
  </div>
  <div class="flex justify-between w-full">
    <div class="text-white flex">
      <a class="es-desktop-header-menu menu" [routerLink]="['../home']">
        <span class="flex items-center" [class.header_active]="'home' === selectedHeader">Home</span></a>

      <a class="es-desktop-header-menu menu cursor-pointer relative" *ngFor="let menu of menus"
        [class.menu_hovered]="navMenu[menu.id]" (mouseenter)="onShowSubMenu(menu.id)" (mouseleave)="onHideSubMenu()">
        <span class="flex items-center">{{menu.label}}
          <img class="ml-2" [ngClass]="{ item_visible: !navMenu[menu.id], item_hidden: navMenu[menu.id]}"
            src="assets/svgs/down-icon.svg" alt="">
          <img class="ml-2" [ngClass]="{ item_visible: navMenu[menu.id], item_hidden: !navMenu[menu.id]}"
            src="assets/svgs/down-icon-blue.svg" alt="">
        </span>
        <div *ngIf="navMenu[menu.id]"
          class="sub-menu flex flex-col absolute border border-t-0 border-gray-400 rounded-b">
          <ng-container *ngFor="let subMenu of menu.children">
            <a *ngIf="showLink(subMenu)" class="py-2" (click)="onSubMenuClicked(menu.id)" [routerLink]="subMenu.route"
              [class.disable]="subMenu.disable">{{subMenu.label}}
              <img *ngIf="subMenu.waitConditionally" src="assets/png/loading.gif" alt="" class="small-loader">
            </a>
            <a class="py-2" (click)="onSubMenuClicked(menu.id)" *ngIf="subMenu.link" href="{{subMenu.link}}"
              target="_blank">{{subMenu.label}}</a>
            <a class="py-2" (click)="onSubMenuClicked(menu.id)" *ngIf="subMenu.click"
              (click)="menuNavigationHandler(subMenu.click)">{{subMenu.label}}</a>
          </ng-container>
        </div>
      </a>
      <a class="es-desktop-header-menu menu cursor-pointer" (click)="onSoftLogout()" *ngIf="isAdmin && menus?.length>0">
        <span class="flex items-center">Admin Link</span></a>

    </div>
    <div class="text-white flex items-center">
      <a class="es-desktop-header-menu mr-4 cursor-pointer" (click)="onLogout()"><img class="mr-2"
          src="assets/svgs/logout.svg" alt="">Log
        Out</a>
    </div>
  </div>
</header>
