/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./policy-type-resolver.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./policy-type-resolver.component";
var styles_PolicyTypeResolverComponent = [i0.styles];
var RenderType_PolicyTypeResolverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PolicyTypeResolverComponent, data: {} });
export { RenderType_PolicyTypeResolverComponent as RenderType_PolicyTypeResolverComponent };
function View_PolicyTypeResolverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Personal Automobile"]))], null, null); }
function View_PolicyTypeResolverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Homeowner/Dwelling"]))], null, null); }
function View_PolicyTypeResolverComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Personal Umbrella "]))], null, null); }
function View_PolicyTypeResolverComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Business Automobile "]))], null, null); }
function View_PolicyTypeResolverComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicyTypeResolverComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["businessAutoGroup", 2]], null, 0, null, View_PolicyTypeResolverComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.productCode === "ALN_UMB") || (_co.productCode === "ALN_UMB")); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_PolicyTypeResolverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicyTypeResolverComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicyTypeResolverComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicyTypeResolverComponent_3)), i1.ɵdid(7, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lob; _ck(_v, 1, 0, currVal_0); var currVal_1 = "PA"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "HO"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "CA"; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_PolicyTypeResolverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-policy-type-resolver", [], null, null, null, View_PolicyTypeResolverComponent_0, RenderType_PolicyTypeResolverComponent)), i1.ɵdid(1, 49152, null, 0, i3.PolicyTypeResolverComponent, [], null, null)], null, null); }
var PolicyTypeResolverComponentNgFactory = i1.ɵccf("prcins-policy-type-resolver", i3.PolicyTypeResolverComponent, View_PolicyTypeResolverComponent_Host_0, { lob: "lob", productCode: "productCode" }, {}, []);
export { PolicyTypeResolverComponentNgFactory as PolicyTypeResolverComponentNgFactory };
