/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./skeleton.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../notification/notification.component.ngfactory";
import * as i4 from "../notification/notification.component";
import * as i5 from "@angular/router";
import * as i6 from "./skeleton.component";
var styles_SkeletonComponent = [i0.styles];
var RenderType_SkeletonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SkeletonComponent, data: {} });
export { RenderType_SkeletonComponent as RenderType_SkeletonComponent };
function View_SkeletonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "line"]], null, null, null, null, null))], null, null); }
function View_SkeletonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "skelton"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SkeletonComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.looper; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SkeletonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "prcins-notification", [], null, null, null, i3.View_NotificationComponent_0, i3.RenderType_NotificationComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotificationComponent, [i5.Router], { notification: [0, "notification"], pinned: [1, "pinned"] }, null), (_l()(), i1.ɵted(-1, 0, [" Oops! We seem to be having a problem. Please try again later or contact us for help. "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notification; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SkeletonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SkeletonComponent_4)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideTimeoutMessage; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SkeletonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SkeletonComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["timedOut", 2]], null, 0, null, View_SkeletonComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isTimedOut; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SkeletonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-skeleton", [], null, null, null, View_SkeletonComponent_0, RenderType_SkeletonComponent)), i1.ɵdid(1, 245760, null, 0, i6.SkeletonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SkeletonComponentNgFactory = i1.ɵccf("prcins-skeleton", i6.SkeletonComponent, View_SkeletonComponent_Host_0, { numOfRows: "numOfRows", timeout: "timeout", hideTimeoutMessage: "hideTimeoutMessage" }, { reloadEvent: "reload", timeoutEvent: "onTimeout" }, []);
export { SkeletonComponentNgFactory as SkeletonComponentNgFactory };
