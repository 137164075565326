<ng-container *ngIf="!isModeSelected && userPolicyDetailsResp">
  <prcins-verification-options (submitted)="onFormSubmitted($event)" [verificationMode]="verificationMode"
    [userPolicyDetailsResp]="userPolicyDetailsResp">
  </prcins-verification-options>
</ng-container>

<ng-container *ngIf="isModeSelected">
  <prcins-code-verification [verificationOptionsForm]="verificationOptionsForm" (backClicked)="onBackClicked($event)"
    [userPolicyDetailsResp]="userPolicyDetailsResp">
  </prcins-code-verification>
</ng-container>
