import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, ActivatedRoute, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VerificationAuthorizeGuard implements CanActivate, CanActivateChild {
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.canActivate(childRoute, state);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const verificationId = sessionStorage.getItem('verificationId');
        if (verificationId) return true;
        this.notAllowEndorse();
        return false;
    }

    notAllowEndorse() {
        this.router.navigate(['/eservice/login'], { relativeTo: this.route }); // once we have all the endorsements change it to home
    }

    constructor(private router: Router, private route: ActivatedRoute) { }
}
