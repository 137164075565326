import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DiscardComponent, BankFormProviderService, FormValidatorService } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { USER_ID } from '@prcins/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { Bank } from '../model/wallet';

@Component({
  selector: 'prcins-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.css']
})
export class AddBankComponent extends DiscardComponent implements OnInit {
  eftForm: FormGroup;
  policyNumber: string;
  showSpinner = false;
  showError = false;
  channel: string;
  generalPhone: string;
  agentInfo = {};

  constructor(private bankFormProvider: BankFormProviderService,
    private managePaymentsProviderService: ManagePaymentsProviderService,
    private router: Router,
    private route: ActivatedRoute,
    private formValidator: FormValidatorService) {
    super();
  }

  ngOnInit() {
    this.eftForm = this.bankFormProvider.getForm();
    this.policyNumber = this.route.snapshot.queryParamMap.get('policyNumber');
    this.setAgentInfo();
  }

  doAddBank() {
    this.formValidator.validateAllFormFields(this.eftForm);

    if (this.eftForm.valid) {
      this.showSpinner = true;
      var newBank = new Bank();
      const accountNumber: string = this.eftForm.get('accountNumber').value;
      newBank.debitAccount = accountNumber;
      newBank.debitRouting = this.eftForm.get('routingNumber').value;
      newBank.creditAccount = this.policyNumber;
      newBank.accountEmail = sessionStorage.getItem(USER_ID);
      newBank.paymentType = "ACH";
      const accountType = this.eftForm.get('accountType').value;
      if (accountType === 'C') {
        newBank.transType = "CHECKING";
      } else     if (accountType === 'S') {
        newBank.transType = "SAVINGS";
      }
      newBank.accountNickName = new Date().getTime() + " ***** " + accountNumber.substring(accountNumber.length - 4); // TODO - remove after ACI Speedpay fix
  
      this.managePaymentsProviderService.createWallet(newBank).subscribe((response) => {
        if (response == null || response.status === 'success' && response.error === "") {
          this.navigateToManagePayments('true');
        } else {
          alert(response.status + ", " + response.errorMessage);
          // TODO - error
        }
      }, (error: HttpErrorResponse) => {
        this.httpErrorResponse();
      });
    }
  }

  navigateToManagePayments(isSuccess: string) {
    sessionStorage.setItem('editWalletSuccess', isSuccess);
    this.router.navigate(['../manage'], {
      relativeTo: this.route
    });
  }

  cancelTransaction() {
    this.navigateToManagePayments('false');
  }

  httpErrorResponse() {
    this.showSpinner = false;
    this.showError = true;
  }

  setAgentInfo() {
    let agentDetails = sessionStorage.agentInfo;
    if (agentDetails) {
      this.agentInfo = JSON.parse(agentDetails);
    }
    this.generalPhone = sessionStorage.getItem('general-phone');
    this.channel = sessionStorage.channel;
  }
}
