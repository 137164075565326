import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule, DiscardChangesGuard } from '@prcins/utils';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';

import { PaymentComponent } from './payment.component';
import { PaymentEntryFormComponent } from './payment-entry-form/payment-entry-form.component';
import { PaymentReviewComponent } from './payment-review/payment-review.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { MobileRegistrationComponent } from './mobile-registration/mobile-registration.component';
import { ManagePaymentsComponent } from './manage-payments/manage-payments.component';
import { AddCardComponent } from './add-card/add-card.component';
import { AddBankComponent } from './add-bank/add-bank.component';
import { EditCardComponent } from './edit-card/edit-card.component';
import { EditBankComponent } from './edit-bank/edit-bank.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { PendingPaymentsComponent } from './pending-payments/pending-payments.component';
import { LoginModule } from '@prcins/login';
import { QuickPayRegistrationHeaderComponent } from './quick-pay-registration-header/quick-pay-registration-header.component';
import { IntermediateRegistrationCompleteComponent } from './intermediate-registration-complete/intermediate-registration-complete.component';


const routes = [
  {
    path: '',
    component: PaymentComponent,
    children: [
      {
        path: 'entry',
        component: PaymentEntryFormComponent,
        canDeactivate: [DiscardChangesGuard],
        // children: [{
        //   path: 'form',
        //   component: PaymentEntryFormComponent,
        //   canDeactivate: [DiscardChangesGuard]
        // }],
        data: { 'tealium': { 'subSection': 'Payment Entry' } }
      },
      {
        path: 'review',
        component: PaymentReviewComponent,
        canDeactivate: [DiscardChangesGuard],
        data: { 'tealium': { 'subSection': 'Payment Review' } }
      },
      {
        path: 'confirm',
        component: PaymentConfirmationComponent,
        data: { 'tealium': { 'subSection': 'Payment Successful' } }
      },
      {
        path: 'manage',
        component: ManagePaymentsComponent,
        data: { 'tealium': { 'subSection': 'Manage Payments' } }
      },
      {
        path: 'add-card',
        component: AddCardComponent,
        data: { 'tealium': { 'subSection': 'Add Card' } }
      },
      {
        path: 'add-bank',
        component: AddBankComponent,
        data: { 'tealium': { 'subSection': 'Add Bank Account' } }
      },
      {
        path: 'edit-card',
        component: EditCardComponent,
        data: { 'tealium': { 'subSection': 'Edit Card' } }
      },
      {
        path: 'edit-bank',
        component: EditBankComponent,
        data: { 'tealium': { 'subSection': 'Edit Card' } }
      },
      {
        path: 'intermediateRegistrationComplete',
        component: IntermediateRegistrationCompleteComponent
      }

    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UtilsModule,
    CurrencyMaskModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    NgSelectModule,
    FormsModule,
    LoginModule
  ],
  declarations: [
    PaymentComponent,
    PaymentEntryFormComponent,
    PaymentReviewComponent,
    PaymentConfirmationComponent,
    MobileRegistrationComponent,
    ManagePaymentsComponent,
    AddCardComponent,
    AddBankComponent,
    EditCardComponent,
    EditBankComponent,
  	PendingPaymentsComponent,
  	QuickPayRegistrationHeaderComponent,
    IntermediateRegistrationCompleteComponent
  ],
  exports: [PendingPaymentsComponent]
})
export class PaymentModule { }
