import { Input, ComponentRef } from '@angular/core';

export class GuardNotificationBase {

    ref: ComponentRef<GuardNotificationBase>;
    display: boolean = false;
    
    showPopup(componentRef) {
        this.ref = componentRef;
        this.display = true;
    }

    hidePopup() {
        this.display = false;
        this.ref.destroy();
        return false;
    }
}