import { Component, OnInit } from '@angular/core';
import { LOGIN_URL, Message } from '@prcins/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { FormValidatorService, UtilityProviderService, RegistrationService, LoginService } from '@prcins/utils';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'prcins-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerError = false;
  errorMessage: string;
  showSpinner = false;
  alreadyRegisterError = false;
  showDeleteAccountPopup = false;
  registrationForm = this.utilityProvider.getRegistrationUserForm();

  constructor(
    private loginProvider: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private formValidator: FormValidatorService,
    private utilityProvider: UtilityProviderService,
    private registrationProvider: RegistrationService,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.registrationProvider.invalidateEndorsementSession();
    this.registrationProvider.userRegistrationFormObservable.subscribe((resp) => {
      if (_.size(resp) > 0) {
        if (resp.dob) {
          resp.dob = moment(resp.dob).format('MM/DD/YYYY');
        }
        this.registrationForm.patchValue(resp);
      }
    });

    if (history.state.edocRedirectStatus === "SUCCESS") {
      this.loginService.statusNotification$.next({ type: 'INFO', message: 'Congratulations! You have successfully enrolled in eDocuments! Next up? Get 24/7 access to your documents and policy details by creating an online account.' })
    }
  }

  onRegister() {
    this.registerError = false;
    if (this.registrationForm.valid) {
      this.showSpinner = true;
      const userPolicy = this.registrationForm.value;
      if (userPolicy.dob) {
        userPolicy.dob = moment(userPolicy.dob).format('YYYY-MM-DD');
      }
      this.registrationProvider
        .userDetails(userPolicy)
        .subscribe((resp: any) => {
          if (resp.messageCode === 'USER_NOT_FOUND') {
            this.registerError = true;
            this.errorMessage = "Oops! We can't seem to find you. Please try that again.";
          } else if (resp.messageCode === 'POLICY_NOT_ELIGIBLE') {
            this.registerError = true;
            this.errorMessage = "This policy is not eligible for enrollment.";
          } else if (resp.messageCode === 'POLICY_ALREADY_REGISTERED') {
            this.registerError = true;
            this.alreadyRegisterError = true;
          } else {
            this.registrationProvider.userRegistrationForm$.next(userPolicy);
            this.registrationProvider.verifyUserRegisteredOrNot$.next(resp);
            sessionStorage.setItem('verificationId', resp.id);
            let passwordActionType = this.registrationProvider.getPasswordActionTypeInfo();
            if (!passwordActionType) {
              sessionStorage.setItem('passwordActionType', 'Create');
            }
            if (resp.mfaVerificationRequired) {
              this.router.navigate(['../../verification'], {
                relativeTo: this.route
              });
            } else {
              this.router.navigate(['../../create-password'], {
                relativeTo: this.route
              });
            }
          }
          this.showSpinner = false;
        }, (error: HttpErrorResponse) => {
          this.registerError = true;
          this.errorMessage = "Oops! We can't seem to find you. Please try that again.";
          this.showSpinner = false;
        });
    } else {
      this.formValidator.validateAllFormFields(this.registrationForm);
    }
  }

  handleRegistration(registration) {
    const handledMessage = this.loginProvider.handleRegistration(registration);
    if (!handledMessage) {
      this.registerError = true;
      if (registration.messageCode == 'POLICY_ALREADY_REGISTERED') {
        this.alreadyRegisterError = true;
      } else {
        this.errorMessage = Message[registration.messageCode] || "Registration failed";
      }

    } else {
      this.router.navigate([handledMessage], { relativeTo: this.route });
    }
  }

  hideDisablePopup() {
    this.showDeleteAccountPopup = false;
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });
  }
}
