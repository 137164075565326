import { Component, OnInit, Input } from '@angular/core';
import { GuardNotificationBase } from '../guard-notification-base/guard-notification-base';

@Component({
  selector: 'prcins-id-cards-notification',
  templateUrl: './id-cards-notification.component.html',
  styleUrls: ['./id-cards-notification.component.css']
})
export class IdCardsNotificationComponent extends GuardNotificationBase {

  @Input() csPhone: string;

}
