import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';

@Component({
  selector: 'prcins-message-banner',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.css']
 
})
export class MessageBannerComponent implements OnInit {
   text:any;
  constructor(private apollo: Apollo,public sanitizer: DomSanitizer) { }
  
  ngOnInit() {
    this.getBannerMessage("").subscribe((data) =>{
      if(data && data.uiMessages) {
        if(data.uiMessages.length > 0) {
          this.text = data.uiMessages;
        }
      }
    })
  }


  getBannerMessage(username?: string): Observable<any> {
    return this.apollo
      .watchQuery({
        query: BannerMessageQuery,
        variables: {
          username
        }
      })
      .valueChanges.pipe(map(({ data }: any) => data));
  }
}


export const BannerMessageQuery = gql`
  query Uimessages($username: String!) {
    uiMessages(username: $username) {
      richText
    }
  }
`;