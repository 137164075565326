<div class="lg:hidden xl:hidden w-full">
  <header class="sm:flex md:flex p-4 bg-es-blue flex justify-between items-center">
    <a >
      <img src="assets/svgs/brands/{{brand}}/mobile-logo.svg">
    </a>
    <a (click)="toggleNavMenu()">
      <img *ngIf="!showNavigationPanel" src="assets/svgs/hamburger.svg">
      <img *ngIf="showNavigationPanel" src="assets/svgs/close-hamburger.svg">
    </a>
  </header>
  <div *ngIf="showNavigationPanel" class="bg-es-blue text-white">
    <a class="es-mobile-header-menu" [class.active_menu]="'home' === selectedHeader" (click)="onMenuClick('home')"
      [routerLink]="['../home']">
      <span class="flex"><img class="mr-2" src="assets/svgs/home-icon.svg" alt="">Home</span>
    </a>

    <ng-container *ngFor="let menu of menus">
      <a class="es-mobile-header-menu cursor-pointer" [class.active_menu]="menu.id === selectedHeader"
        (click)="showSubMenu(menu.id)">
        <span class="flex"><img class="mr-2" src="{{menu.icon}}" alt="">{{menu.label}}</span>
        <img *ngIf="!navMenu[menu.id]; else paymentsSub" src="assets/svgs/plus-icon.svg" alt="">
        <ng-template #paymentsSub><img src="assets/svgs/minus-icon.svg" alt=""></ng-template>
      </a>
      <div class="flex flex-col" *ngIf="navMenu[menu.id]">
        <ng-container *ngFor="let subMenu of menu.children">
          <a *ngIf="showLink(subMenu)" class="ml-8 p-2" (click)="onMenuClick(menu.id)" [routerLink]="subMenu.route"
            [class.disable]="subMenu.disable">{{subMenu.label}}
            <img *ngIf="subMenu.waitConditionally" src="assets/png/loading.gif" alt="" class="small-loader">
          </a>
          <a class="ml-8 p-2" *ngIf="subMenu.link" (click)="onMenuClick(menu.id)" href="{{subMenu.link}}"
            target="_blank">{{subMenu.label}}
          </a>
          <a class="ml-8 p-2" *ngIf="subMenu.click"
            (click)="onMenuClick(menu.id); menuNavigationHandler(subMenu.click)">{{subMenu.label}}</a>
        </ng-container>
      </div>
    </ng-container>

    <a class="es-mobile-header-menu" (click)="onSoftLogout()" *ngIf="isAdmin && menus.length>0">
      <span class="flex items-center">Admin Link</span></a>

    <a class="es-mobile-header-menu" (click)="onLogout()">
      <span class="flex"><img class="mr-2" src="assets/svgs/logout.svg" alt="">Log Out</span>
    </a>
  </div>
</div>
