import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'prcins-register-user-form',
  templateUrl: './register-user-form.component.html',
  styleUrls: ['./register-user-form.component.css']
})
export class RegisterUserFormComponent implements OnInit {
  @Input() userForm;

  constructor() { }

  ngOnInit() {
    this.onPolicyTypeSelected();
  }

  onPolicyTypeSelected() {
    if (this.userForm.get('type').value === 'INDIVIDUAL') {
      this.userForm.get('policyNumber').disable();
      this.userForm.get('companyName').disable();
    } else {
      this.userForm.get('policyNumber').enable();
      this.userForm.get('companyName').enable();
    }

    if (this.userForm.get('type').value === 'COMMERCIAL') {
      this.userForm.get('firstName').disable();
      this.userForm.get('lastName').disable();
      this.userForm.get('dob').disable();
    } else {
      this.userForm.get('firstName').enable();
      this.userForm.get('lastName').enable();
      this.userForm.get('dob').enable();
    }
  }

}
