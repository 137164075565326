import { Apollo } from 'apollo-angular';
import { EdocsQuery } from '../graphql/edocs-schema';
import { RightRailsEdocsQuery } from '../graphql/rightrails-schema';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
export class EdocsBroadcasterService {
    constructor(apollo) {
        this.apollo = apollo;
        this.notify$ = new Subject();
    }
    getEdocs(username) {
        try {
            return this.apollo.getClient().readQuery({
                query: EdocsQuery,
                variables: {
                    username
                }
            });
        }
        catch (e) {
            return null;
        }
    }
    updateEdocs(username, edocs) {
        return this.apollo.getClient().writeQuery({
            query: EdocsQuery,
            variables: {
                username
            },
            data: edocs
        });
    }
    updateShowEdocs(policyNumber, showEdocs) {
        try {
            const rightRails = this.apollo.getClient().readQuery({
                query: RightRailsEdocsQuery,
                variables: {
                    policyNumber
                }
            });
            rightRails.rightRail.showEdocs = showEdocs;
            if (rightRails) {
                this.apollo.getClient().writeQuery({
                    query: RightRailsEdocsQuery,
                    variables: {
                        policyNumber
                    },
                    data: rightRails
                });
            }
        }
        catch (e) { }
    }
}
EdocsBroadcasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EdocsBroadcasterService_Factory() { return new EdocsBroadcasterService(i0.ɵɵinject(i1.Apollo)); }, token: EdocsBroadcasterService, providedIn: "root" });
