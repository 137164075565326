import { DiscardChangesGuard } from '@prcins/utils';
import { PaymentComponent } from './payment.component';
import { PaymentEntryFormComponent } from './payment-entry-form/payment-entry-form.component';
import { PaymentReviewComponent } from './payment-review/payment-review.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { ManagePaymentsComponent } from './manage-payments/manage-payments.component';
import { AddCardComponent } from './add-card/add-card.component';
import { AddBankComponent } from './add-bank/add-bank.component';
import { EditCardComponent } from './edit-card/edit-card.component';
import { EditBankComponent } from './edit-bank/edit-bank.component';
import { IntermediateRegistrationCompleteComponent } from './intermediate-registration-complete/intermediate-registration-complete.component';
const ɵ0 = { 'tealium': { 'subSection': 'Payment Entry' } }, ɵ1 = { 'tealium': { 'subSection': 'Payment Review' } }, ɵ2 = { 'tealium': { 'subSection': 'Payment Successful' } }, ɵ3 = { 'tealium': { 'subSection': 'Manage Payments' } }, ɵ4 = { 'tealium': { 'subSection': 'Add Card' } }, ɵ5 = { 'tealium': { 'subSection': 'Add Bank Account' } }, ɵ6 = { 'tealium': { 'subSection': 'Edit Card' } }, ɵ7 = { 'tealium': { 'subSection': 'Edit Card' } };
const routes = [
    {
        path: '',
        component: PaymentComponent,
        children: [
            {
                path: 'entry',
                component: PaymentEntryFormComponent,
                canDeactivate: [DiscardChangesGuard],
                // children: [{
                //   path: 'form',
                //   component: PaymentEntryFormComponent,
                //   canDeactivate: [DiscardChangesGuard]
                // }],
                data: ɵ0
            },
            {
                path: 'review',
                component: PaymentReviewComponent,
                canDeactivate: [DiscardChangesGuard],
                data: ɵ1
            },
            {
                path: 'confirm',
                component: PaymentConfirmationComponent,
                data: ɵ2
            },
            {
                path: 'manage',
                component: ManagePaymentsComponent,
                data: ɵ3
            },
            {
                path: 'add-card',
                component: AddCardComponent,
                data: ɵ4
            },
            {
                path: 'add-bank',
                component: AddBankComponent,
                data: ɵ5
            },
            {
                path: 'edit-card',
                component: EditCardComponent,
                data: ɵ6
            },
            {
                path: 'edit-bank',
                component: EditBankComponent,
                data: ɵ7
            },
            {
                path: 'intermediateRegistrationComplete',
                component: IntermediateRegistrationCompleteComponent
            }
        ]
    }
];
export class PaymentModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
