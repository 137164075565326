/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mrb-notification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../es-popup/es-popup.component.ngfactory";
import * as i3 from "../es-popup/es-popup.component";
import * as i4 from "../pipes/telephone.pipe";
import * as i5 from "@angular/common";
import * as i6 from "./mrb-notification.component";
var styles_MrbNotificationComponent = [i0.styles];
var RenderType_MrbNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MrbNotificationComponent, data: {} });
export { RenderType_MrbNotificationComponent as RenderType_MrbNotificationComponent };
function View_MrbNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "prcins-es-popup", [], null, null, null, i2.View_EsPopupComponent_0, i2.RenderType_EsPopupComponent)), i1.ɵdid(1, 49152, null, 0, i3.EsPopupComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 2, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "es-h1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops, it\u2019s us, not you!"])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "mt-4 text-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "span", [["class", "justify-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We're unable to process your policy change right now. Please call "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["class", "text-es-blue"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 1), (_l()(), i1.ɵted(-1, null, [" and someone will be happy to assist you."])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "flex justify-between mt-4 flex-wrap-reverse items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "mt-4 es-btn-primary w-full lg:w-40 text-center"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hidePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("tel:" + _co.csPhone); _ck(_v, 9, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), _co.csPhone)); _ck(_v, 10, 0, currVal_1); }); }
export function View_MrbNotificationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.TelephonePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MrbNotificationComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.display; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MrbNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-mrb-notification", [], null, null, null, View_MrbNotificationComponent_0, RenderType_MrbNotificationComponent)), i1.ɵdid(1, 49152, null, 0, i6.MrbNotificationComponent, [], null, null)], null, null); }
var MrbNotificationComponentNgFactory = i1.ɵccf("prcins-mrb-notification", i6.MrbNotificationComponent, View_MrbNotificationComponent_Host_0, { csPhone: "csPhone" }, {}, []);
export { MrbNotificationComponentNgFactory as MrbNotificationComponentNgFactory };
