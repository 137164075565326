import { Router, ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class VerificationAuthorizeGuard {
    constructor(router, route) {
        this.router = router;
        this.route = route;
    }
    canActivateChild(childRoute, state) {
        return this.canActivate(childRoute, state);
    }
    canActivate(route, state) {
        const verificationId = sessionStorage.getItem('verificationId');
        if (verificationId)
            return true;
        this.notAllowEndorse();
        return false;
    }
    notAllowEndorse() {
        this.router.navigate(['/eservice/login'], { relativeTo: this.route }); // once we have all the endorsements change it to home
    }
}
VerificationAuthorizeGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VerificationAuthorizeGuard_Factory() { return new VerificationAuthorizeGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute)); }, token: VerificationAuthorizeGuard, providedIn: "root" });
