import { Component, OnInit } from '@angular/core';
import { CURRENT_POLICY, USER_ID, WALLET_APP_NAME, CURRENT_POLICY_PAY_PLAN, CURRENT_POLICY_STATUS } from '@prcins/constants';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { DiscardComponent, RightRailService } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { Bank, Card, Wallet } from '../model/wallet';
import { HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'prcins-manage-payments',
  templateUrl: './manage-payments.component.html',
  styleUrls: ['./manage-payments.component.css']
})
export class ManagePaymentsComponent extends DiscardComponent implements OnInit {

  storedCreditCards: Card[];
  storedBankAccounts: Bank[];
  isLoading = false;
  policyNumber: string;
  removeCardPopup: boolean;
  cardToRemove: any;
  bankToRemove: any;
  removeBankPopup: boolean;
  showSpinner = false;
  editWalletSuccess: boolean;
  payPlan: string;
  payPlanPopup: boolean;
  continueFunction: Function;
  showChangePayplanLink = false;

  constructor(private managePaymentsProviderService: ManagePaymentsProviderService, 
    private rightRailService: RightRailService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.showSpinner = true;
    this.storedCreditCards = [];
    this.storedBankAccounts = [];
    this.policyNumber = sessionStorage.getItem(CURRENT_POLICY);
    this.payPlan = this.parsePayPlan(sessionStorage.getItem(CURRENT_POLICY_PAY_PLAN));
    const currentPolicyStatus = sessionStorage.getItem(CURRENT_POLICY_STATUS);
    const editWalletSuccessParam = sessionStorage.getItem('editWalletSuccess');
    this.editWalletSuccess = editWalletSuccessParam === 'true';
    sessionStorage.removeItem('editWalletSuccess');
    this.managePaymentsProviderService.getPolicyPaymentDetails(
      sessionStorage.getItem(USER_ID), this.policyNumber, WALLET_APP_NAME).subscribe(wallets => {
        this.isLoading = true;
        if (wallets) {
          wallets.forEach((wallet: any) => {
            if (wallet.paymentType === "CC" || wallet.paymentType === "ATM") {
              this.storedCreditCards.push(wallet);
            }
            else if (wallet.paymentType === "ACH") {
              this.storedBankAccounts.push(wallet);
            }
          });
        }
        this.showSpinner = false;
      });

    this.rightRailService.getQuickLinks(this.policyNumber)
    .pipe(map(rightRails => {
      if (currentPolicyStatus !== 'Incomplete') {
        const isMrbActive = (rightRails && rightRails.mrbActive === false) ? false : true;

        this.showChangePayplanLink = isMrbActive || rightRails.showPayPlanChange;
      }
    })).subscribe();
  }

  parsePayPlan(payPlan: string): string {
    return payPlan == null ? "NONE" : payPlan.toUpperCase().includes("EFT") ? "EFT" : payPlan.toUpperCase().includes("REC") ? "REC" : "NONE";
  }

  /* CREDIT CARD PAYMENTS */
  addNewCard(policyNum: string) {
    this.router.navigate(['../add-card'], {
      queryParams: { policyNumber: policyNum },
      relativeTo: this.route
    });
  }

  onEditCreditCard(card: Card) {
    this.managePaymentsProviderService.data = card;
    if (this.payPlan === "REC") {
      this.payPlanPopup = true;
      this.continueFunction = this.editCreditCard;
    } else {
      this.editCreditCard();
    }
  }

  editCreditCard() {
    this.router.navigate(['../edit-card'], {
      queryParams: { policyNumber: this.policyNumber },
      relativeTo: this.route
    });
  }

  toggleDefault(wallet: Wallet) {
    // Call API
    this.managePaymentsProviderService.changeDefaultWallet(sessionStorage.getItem(USER_ID), this.policyNumber, wallet).subscribe(
      (response) => {
        if (response == null || response.status === 'success' && response.error === "") {
          // Reload
          this.ngOnInit();
        } else {
          alert(response.status + ", " + response.errorMessage);
          // TODO - error
        }
      }
    );
  }

  onDeleteCreditCard(card: Card) {
    this.cardToRemove = card;
    if (this.payPlan === "REC") {
      this.payPlanPopup = true;
      this.continueFunction = this.deleteCreditCard;
    } else {
      this.deleteCreditCard();
    }
  }

  deleteCreditCard() {
    this.removeCardPopup = true;
  }

  doDeleteCard(card: Card) {
    this.deleteWallet(card);
    this.removeCardPopup = false;
  }

  private deleteWallet(wallet: Wallet) {
    // Call API
    this.managePaymentsProviderService.deleteWallet(sessionStorage.getItem(USER_ID), this.policyNumber, wallet).subscribe(
      (response) => {
        if (response == null || response.status === 'success' && response.error === "") {
          sessionStorage.setItem('editWalletSuccess', 'true');
          // Reload
          this.ngOnInit();
        } else {
          alert(response.status + ", " + response.errorMessage);
          // TODO - error
        }
      }, (error: HttpErrorResponse) => {
        // TODO - show error?
      }
    );
  }

  goToPayPlan() {
    this.router.navigate(['../../billing/change-plan'], {
      relativeTo: this.route
    });
  }


  continueAction() {
    this.payPlanPopup = false;
    this.continueFunction();
  }

  /* BANK PAYMENTS */
  addNewBank(policyNum: string) {
    this.router.navigate(['../add-bank'], {
      queryParams: { policyNumber: policyNum },
      relativeTo: this.route
    });
  }

  onEditBank(bank: Bank) {
    this.managePaymentsProviderService.data = bank;
    if (this.payPlan === "EFT") {
      this.payPlanPopup = true;
      this.continueFunction = this.editBank;
    } else {
      this.editBank();
    }
  }

  editBank() {
    this.router.navigate(['../edit-bank'], {
      queryParams: { policyNumber: this.policyNumber },
      relativeTo: this.route
    });
  }

  onDeleteBank(bank: Bank) {
    this.bankToRemove = bank;
    if (this.payPlan === "EFT") {
      this.payPlanPopup = true;
      this.continueFunction = this.deleteBank;
    } else {
      this.deleteBank();
    }
  }

  deleteBank() {
    this.removeBankPopup = true;
  }

  doDeleteBank(bank: Bank) {
    this.deleteWallet(bank);
    this.removeBankPopup = false;
  }
}
