class Environment {
    constructor() {
        this.production = false;
        this.AUTH_URL = "";
        this.BASE_URL = "";
        this.GQL_URL = "";
        this.EFNOL_URL = "";
        this.RR_URL = "";
        this.NY_RR_URL = "";
        this.ESERVICE_URL = "";
        this.QPAY_URL = "";
        this.QPAY_BASE_URL = "";
        this.IP_ADDRESS = "";
        this.LOGGER_URL = "";
        this.ENDORSE_DRIVER_JS = "";
        this.ENDORSE_DRIVER_CSS = "";
        this.ENDORSE_MORTGAGE_JS = "";
        this.ENDORSE_MORTGAGE_CSS = "";
        this.ENDORSE_EDIT_COVERAGE_JS = "";
        this.ENDORSE_EDIT_COVERAGE_CSS = "";
        this.PDF_URL = "";
        this.INVISIBLE_CAPTCHA_KEY = "";
        this.INTERACTIVE_CAPTCHA_KEY = "";
        this.SCRIPTS = [];
        this.API_KEY = "";
        this.API_KEY_VALUE = "";
        this.CROSS_SELL_URL = "";
        this.ENABLE_CAPTCHA = false;
        this.ENABLE_CAPTCHA_IOS = false;
    }
}
const ɵ0 = () => {
    const env = new Environment();
    const request = new XMLHttpRequest();
    request.open('GET', '/config/app.json', false);
    request.send(null);
    if (request.status === 200) {
        const response = JSON.parse(request.responseText);
        for (const key in response) {
            if (env.hasOwnProperty(key)) {
                env[key] = response[key];
            }
        }
    }
    return env;
};
export const environment = (ɵ0)();
export { ɵ0 };
/* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
