import { Component, OnInit } from '@angular/core';
import { BRAND, Brand } from '@prcins/constants';

@Component({
  selector: 'prcins-questionnaire-renewal-driver-error',
  templateUrl: './questionnaire-renewal-driver-error.component.html',
  styleUrls: ['./questionnaire-renewal-driver-error.component.css']
})
export class QuestionnaireRenewalDriverErrorComponent implements OnInit {

  brand = localStorage.getItem(BRAND);
  mainPage = Brand[this.brand].urlEnterpriseWeb;

  constructor() { }

  ngOnInit() {
  }

}
