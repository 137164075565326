import { Injectable } from '@angular/core';
import { TealiumUtagService } from './utag.service';
import { environment } from '@prcins/environments';

@Injectable({
  providedIn: 'root'
})
export class TealiumTrackerService {

  pageContext: any;

  constructor(private uTagService: TealiumUtagService) {
    this.uTagService.setConfig({
      account: 'plymouth',
      profile: 'eservice',
      environment: environment.production? 'prod':'qa'
    });
  }

  track(tealium_event: string, data?: any) {
    this.uTagService.track(tealium_event, data);
  }

  view(data?: any) {
    this.uTagService.track('view', data);
  }

  link(data?: any) {
    this.uTagService.track('link', data);
  }

}
