import { enableProdMode } from '@angular/core';
import { environment } from '@prcins/environments';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
    if (window) {
        window.console.log = window.console.warn = window.console.info = window.console.error = function () { };
    }
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(err => console.error(err));
