import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import * as moment from "moment";
export class DateTimeValidators {
  static ValidDate(dateFormat: any = "MM/DD/YYYY", message: String = "Invalid date"): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let controlValue = control.value;
      if (!controlValue) {
        return null;
      }
      if (typeof controlValue === "string" && controlValue.length === 8) {
        const [m0, m1, d0, d1, y0, y1, y2, y3] = controlValue.split('');
        controlValue = `${m0}${m1}/${d0}${d1}/${y0}${y1}${y2}${y3}`;
      }
      const valueAsMoment = dateFormat ? moment(controlValue, dateFormat, true) : moment(controlValue);
      const isValidDate = valueAsMoment.isValid();
      return isValidDate ? null : { invalidDate: { value: controlValue } };
    }
  }

  static noFutureDate(dateFormat: any = "MM/DD/YYYY"): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let controlValue = control.value;
      if (!controlValue) {
        return null;
      }
      if (typeof controlValue === "string" && controlValue.length === 8) {
        const [m0, m1, d0, d1, y0, y1, y2, y3] = controlValue.split('');
        controlValue = `${m0}${m1}/${d0}${d1}/${y0}${y1}${y2}${y3}`;
      }
      const valueAsMoment = moment(controlValue, dateFormat, true);
      const isValidDate = valueAsMoment.isValid();
      if (!isValidDate) return null;
      const formatedDate = moment(moment(controlValue).format(dateFormat));
      return formatedDate.isSameOrBefore(moment(new Date()).format(dateFormat)) ? null : { futureDate: { value: controlValue } };
    }
  }

  static minDate(min, dateSections: string, referenceDate = new Date(), dateFormat: any = "MM/DD/YYYY"): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!min) return null;
      if (!dateSections) return null;
      let controlValue = control.value;
      if (!controlValue) {
        return null;
      }
      if (typeof controlValue === "string" && controlValue.length === 8) {
        const [m0, m1, d0, d1, y0, y1, y2, y3] = controlValue.split('');
        controlValue = `${m0}${m1}/${d0}${d1}/${y0}${y1}${y2}${y3}`;
      }
      const valueAsMoment = moment(controlValue, dateFormat, true);
      const isValidDate = valueAsMoment.isValid();
      if (!isValidDate) return null;
      const formatedDate = moment(moment(controlValue).format(dateFormat));
      return formatedDate.isSameOrAfter(moment(referenceDate).subtract(min, dateSections).format(dateFormat)) ? null : { minDate: { value: controlValue } }
    }
  }
  static validateAge(dateFormat: any = "MM/DD/YYYY"): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let controlValue = control.value;
      if (!controlValue) {
        return null;
      }
      if (typeof controlValue === "string" && controlValue.length === 8) {
        const [m0, m1, d0, d1, y0, y1, y2, y3] = controlValue.split('');
        controlValue = `${m0}${m1}/${d0}${d1}/${y0}${y1}${y2}${y3}`;
      }
      const valueAsMoment = moment(controlValue, dateFormat, true);
      const isValidDate = valueAsMoment.isValid();
      if (!isValidDate) return null;
      const formatedDate = moment(moment(controlValue).format(dateFormat));
      let age = moment().diff(formatedDate, 'years');

      if (age > 99 || age < 15) {
        return { validDob: { value: controlValue } }
      } else {
        return null;
      }

    }
  }

  static validateModelYear(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let year = control.value;
      if (!year) {
        return null;
      }
      if (year) {
        if (year > new Date().getFullYear() + 1 || year < 1900) {
          return { inValidModelYear: { value: year } }
        } else {
          return null;
        }
      } else {
        return null;
      }


    }
  }


}
