import { CardBaseForm } from '../card-base-form/card-base';
export class CardFormComponent extends CardBaseForm {
    constructor() {
        super(...arguments);
        this.helps = {
            cardNumber: false,
            securityCode: false,
            expirationDate: false
        };
        this.helpFlags = { ...this.helps };
        this.cvvLengthError = false;
    }
    handleMaskOnBlur(maskedControlName, actualControlName, cvv) {
        const maskedControl = this.cardForm.get(maskedControlName);
        const actualControl = this.cardForm.get(actualControlName);
        const actualValue = maskedControl.value;
        actualControl.markAsTouched();
        if (cvv) {
            let mask = '';
            for (let i = 0; i < actualValue.length; i++) {
                mask += '*';
            }
            maskedControl.setValue(mask);
            this.cvvLengthError = false;
        }
        else {
            if (actualValue.length >= 4) {
                maskedControl.setValue('************' + actualValue.slice(-4));
            }
        }
    }
    handleMaskOnFocus(maskedControlName, actualControlName) {
        const maskedControl = this.cardForm.get(maskedControlName);
        const actualControl = this.cardForm.get(actualControlName);
        maskedControl.setValue(actualControl.value);
    }
    showHelpText(key) {
        this.helpFlags = { ...this.helps };
        this.helpFlags[key] = true;
    }
    hideHelpText() {
        this.helpFlags = { ...this.helps };
    }
}
