/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manage-alert-preferences.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../utils/src/lib/danger/danger.component.ngfactory";
import * as i3 from "../../../../utils/src/lib/danger/danger.component";
import * as i4 from "../../../../utils/src/lib/card/card.component.ngfactory";
import * as i5 from "../../../../utils/src/lib/card/card.component";
import * as i6 from "../../../../utils/src/lib/round-toggle-switch/round-toggle-switch.component.ngfactory";
import * as i7 from "../../../../utils/src/lib/round-toggle-switch/round-toggle-switch.component";
import * as i8 from "@angular/common";
import * as i9 from "../../../../utils/src/lib/skeleton/skeleton.component.ngfactory";
import * as i10 from "../../../../utils/src/lib/skeleton/skeleton.component";
import * as i11 from "./manage-alert-preferences.component";
import * as i12 from "../services/accounts-provider.service";
import * as i13 from "@angular/router";
var styles_ManageAlertPreferencesComponent = [i0.styles];
var RenderType_ManageAlertPreferencesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManageAlertPreferencesComponent, data: {} });
export { RenderType_ManageAlertPreferencesComponent as RenderType_ManageAlertPreferencesComponent };
function View_ManageAlertPreferencesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "prcins-danger", [], null, null, null, i2.View_DangerComponent_0, i2.RenderType_DangerComponent)), i1.ɵdid(1, 4243456, null, 0, i3.DangerComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Oops! We seem to be having a problem. Please try again later or contact us for help."]))], null, null); }
function View_ManageAlertPreferencesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "prcins-danger", [], null, null, null, i2.View_DangerComponent_0, i2.RenderType_DangerComponent)), i1.ɵdid(1, 4243456, null, 0, i3.DangerComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Oops! We seem to be having a problem. Please try again later or contact us for help."]))], null, null); }
function View_ManageAlertPreferencesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "flex items-center mb-4 p-4 border rounded border-gray-300 bg-es-sky-blue"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/png/check-icon.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Alert preferences have been successfully updated."]))], null, null); }
function View_ManageAlertPreferencesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flex flex-wrap-reverse justify-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "es-btn-primary w-full mt-4 sm:w-40"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSaveAlertPreference() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save Preferences"]))], null, null); }
function View_ManageAlertPreferencesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, "prcins-card", [["pt", "pt-0"]], null, null, null, i4.View_CardComponent_0, i4.RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i5.CardComponent, [], { pt: [0, "pt"] }, null), (_l()(), i1.ɵeld(2, 0, null, 2, 29, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "flex justify-between items-center mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["class", "font-bold"], ["style", "margin-top: -1.5rem;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Policy, Billing and Claims "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "font-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Updates and reminders to make sure you always have the latest information about your policy"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "prcins-round-toggle-switch", [], null, [[null, "sliderChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sliderChanged" === en)) {
        var pd_0 = (_co.alertPreferenceChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_RoundToggleSwitchComponent_0, i6.RenderType_RoundToggleSwitchComponent)), i1.ɵdid(11, 114688, null, 0, i7.RoundToggleSwitchComponent, [], { sliderKey: [0, "sliderKey"], sliderValue: [1, "sliderValue"] }, { sliderChanged: "sliderChanged" }), (_l()(), i1.ɵeld(12, 0, null, null, 8, "div", [["class", "flex justify-between items-center mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "span", [["class", "font-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Weather and Safety"])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "font-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Alerts, tips and updates to help keep you and your family safe."])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "prcins-round-toggle-switch", [], null, [[null, "sliderChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sliderChanged" === en)) {
        var pd_0 = (_co.alertPreferenceChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_RoundToggleSwitchComponent_0, i6.RenderType_RoundToggleSwitchComponent)), i1.ɵdid(20, 114688, null, 0, i7.RoundToggleSwitchComponent, [], { sliderKey: [0, "sliderKey"], sliderValue: [1, "sliderValue"] }, { sliderChanged: "sliderChanged" }), (_l()(), i1.ɵeld(21, 0, null, null, 8, "div", [["class", "flex justify-between items-center mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 4, "span", [["class", "font-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Newsletters and Promotions"])), (_l()(), i1.ɵeld(24, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "span", [["class", "font-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Occasional Plymouth Rock updates and special offers/promotions."])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "prcins-round-toggle-switch", [], null, [[null, "sliderChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sliderChanged" === en)) {
        var pd_0 = (_co.alertPreferenceChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_RoundToggleSwitchComponent_0, i6.RenderType_RoundToggleSwitchComponent)), i1.ɵdid(29, 114688, null, 0, i7.RoundToggleSwitchComponent, [], { sliderKey: [0, "sliderKey"], sliderValue: [1, "sliderValue"] }, { sliderChanged: "sliderChanged" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManageAlertPreferencesComponent_6)), i1.ɵdid(31, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pt-0"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "billingAlertOptionKey"; var currVal_2 = _co.billingAlertOption; _ck(_v, 11, 0, currVal_1, currVal_2); var currVal_3 = "weatherAlertOptionKey"; var currVal_4 = _co.weatherSafetyOption; _ck(_v, 20, 0, currVal_3, currVal_4); var currVal_5 = "newsletterAlertOptionKey"; var currVal_6 = _co.newsLetterOption; _ck(_v, 29, 0, currVal_5, currVal_6); var currVal_7 = _co.showTermsAndConditions; _ck(_v, 31, 0, currVal_7); }, null); }
function View_ManageAlertPreferencesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "p", [["class", "mt-4 text-sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please allow up to 5 business days for any email preference changes to take effect. "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your selections will not affect receipt of service-related, transactional or legal email communications related to your policy. Changes to Policy and Billing Alerts do not change your electronic documents and electronic billing subscriptions. Sign up for and manage electronic documents and electronic billing by going to "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "text-blue-600"], ["href", "https://www.plymouthrock.com/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage Policy"])), (_l()(), i1.ɵted(-1, null, [" from the home page. Learn about our "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "text-blue-600"], ["href", "https://www.plymouthrock.com/privacy-policy"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["privacy policy"])), (_l()(), i1.ɵted(-1, null, [". "]))], null, null); }
function View_ManageAlertPreferencesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "es-h1 mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage your email preferences"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Get reminders for payments, policy renewals and other important account updates."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManageAlertPreferencesComponent_2)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManageAlertPreferencesComponent_3)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManageAlertPreferencesComponent_4)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManageAlertPreferencesComponent_5)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManageAlertPreferencesComponent_7)), i1.ɵdid(14, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.submitError; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.fetchPolicyError; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.submitSuccess; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.showAlertSection; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.showTermsAndConditions; _ck(_v, 14, 0, currVal_4); }, null); }
function View_ManageAlertPreferencesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "prcins-card", [["pt", "pt-0"]], null, null, null, i4.View_CardComponent_0, i4.RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i5.CardComponent, [], { pt: [0, "pt"] }, null), (_l()(), i1.ɵeld(2, 0, null, 2, 2, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "prcins-skeleton", [["numOfRows", "10"]], null, null, null, i9.View_SkeletonComponent_0, i9.RenderType_SkeletonComponent)), i1.ɵdid(4, 245760, null, 0, i10.SkeletonComponent, [], { numOfRows: [0, "numOfRows"] }, null)], function (_ck, _v) { var currVal_0 = "pt-0"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "10"; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ManageAlertPreferencesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "header", [["class", "hidden lg:flex xl:flex p-4 bg-es-blue justify-between items-center border-t-4 border-es-orange"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "main", [["class", "w-full px-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManageAlertPreferencesComponent_1)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_ManageAlertPreferencesComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isLoading; var currVal_3 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.mainPage, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "assets/svgs/brands/", _co.brand, "/header-logo.svg"); _ck(_v, 2, 0, currVal_1); }); }
export function View_ManageAlertPreferencesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-alert-preferences", [], null, null, null, View_ManageAlertPreferencesComponent_0, RenderType_ManageAlertPreferencesComponent)), i1.ɵdid(1, 114688, null, 0, i11.ManageAlertPreferencesComponent, [i12.AccountsProviderService, i13.ActivatedRoute, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ManageAlertPreferencesComponentNgFactory = i1.ɵccf("prcins-alert-preferences", i11.ManageAlertPreferencesComponent, View_ManageAlertPreferencesComponent_Host_0, {}, {}, []);
export { ManageAlertPreferencesComponentNgFactory as ManageAlertPreferencesComponentNgFactory };
