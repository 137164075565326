<div class="flex flex-col items-center w-full es-app-container">
    <div class="w-full">
        <div class="px-8">
            <h1 class="es-h1 mt-4">
                Registration Complete!
            </h1>
            <p>You successfully registered for an account. You can now access your policy online or in the mobile app to view your information and make quicker payments.</p>
        
            <div class="flex justify-center pb-8">
            <button class="es-btn-primary w-full mt-4 eservice-register cursor-pointer" (click)="onContinueToEserviceRoute()">{{continueButtonLabel}}</button>        
            </div>
        </div>
    </div>
</div>