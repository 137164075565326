import { Component, OnInit, Input, ComponentRef } from '@angular/core';
import { GuardNotificationBase } from '../guard-notification-base/guard-notification-base';

@Component({
  selector: 'prcins-mrb-notification',
  templateUrl: './mrb-notification.component.html',
  styleUrls: ['./mrb-notification.component.css']
})
export class MrbNotificationComponent extends GuardNotificationBase {
  @Input() csPhone: string;

}

