<div class="min-h-screen relative">
    <header class=" lg:flex xl:flex p-4 bg-es-blue justify-between items-center border-t-4 border-es-orange">
        <a href="{{mainPage}}" target="_blank">
            <img src="assets/svgs/brands/{{brand}}/header-logo.svg">
        </a>
    </header>
    <main class="flex justify-center w-full ">
        <div class=" w-full flex justify-between flex-wrap px-8 pb-24 lg:px-40">
            <section class=" w-full">
                <router-outlet></router-outlet>

                <h1 class="es-h1 mt-4">Something went wrong</h1>
                <div>
                    <p>Your driver information couldn't be submitted due to an error, but your completed Renewal
                        Questionnaire has been successfully sent. <br>
                        Our agent will be in contact with you soon to validate your new drivers information.</p>
                </div>

                <div class="mt-4">

                    Want to speak With someone sooner?

                    <div class="lg:hidden xl:hidden w-full">
                        <a href="tel:(877) 749-7722"> <button class="es-btn-primary lg:w-6/12 my-8" type="submit">Call
                                an Agent</button></a>
                    </div>
                    <div class="hidden lg:flex xl:flex">
                        <div class="border rounded border-gray-300 mt-2 w-1/3">
                            <div body>
                                <div class="flex flex-row w-full p-4">

                                    <div class="basis-1/3 "><img src="assets/svgs/agent-icon.svg"></div>
                                    <div class="basis-2/3 ml-4">
                                        <div class="text-es-orange">Contact Us</div>
                                        <div class="mt-2">
                                            <a href="tel:(877) 749-7722"><img class="inline"
                                                    src="assets/svgs/phone.svg"><span
                                                    class="ml-1 text-es-blue-light">(877)
                                                    749-7722</span></a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>



                </div>



            </section>



        </div>
    </main>
    <prcins-footer></prcins-footer>
</div>

<!-- <prcins-footer></prcins-footer> -->