import gql from 'graphql-tag';

export const MobileRegistrationQuery = gql`
  query MobileRegistration($policyNumber: String) {
    billingDetails(policyNumber: $policyNumber) {
      payplan
    }
    policySearchInfo(policyNumber: $policyNumber) {
      statusCode
      state
      companyCode
      productCode
    }
  }
`;
