import { Component } from '@angular/core';
import { BRAND, Brand } from '@prcins/constants';

@Component({
  selector: 'prcins-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent {
  brand = localStorage.getItem(BRAND);
  terms = Brand[this.brand].urlTermsConditions;
}
