import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'prcins-edoc-promotion-banner',
  templateUrl: './edoc-promotion-banner.component.html',
  styleUrls: ['./edoc-promotion-banner.component.css']
})
export class EdocPromotionBannerComponent implements OnInit {
  productCode = sessionStorage.getItem("productCode");
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  goToEdocs() {
    this.router.navigate(['eservice/home/documents/manage-edocs']);
  };

}
