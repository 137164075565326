import { NGXLogger } from 'ngx-logger';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as i0 from "@angular/core";
import * as i1 from "ngx-logger";
import * as i2 from "ngx-device-detector";
/**
 * Facade for the underlying logger implementation
 */
export class Logger {
    constructor(logger, deviceService) {
        this.logger = logger;
        this.deviceService = deviceService;
    }
    debug(message, ...additional) {
        this.logger.debug(message, additional);
    }
    info(message, ...additional) {
        this.logger.info(message, additional);
    }
    warn(message, ...additional) {
        this.logger.warn(message, additional);
    }
    error(message, ...additional) {
        message = this.getDeviceInfo() + ' ' + message;
        this.logger.error(message, additional);
    }
    getDeviceInfo() {
        let message = '';
        const deviceInfo = this.deviceService.getDeviceInfo();
        if (deviceInfo) {
            message = 'Device: ' + deviceInfo.device + ', OS: ' + deviceInfo.os + ', OS_Version: ' + deviceInfo.os_version + ', Browser: ' + deviceInfo.browser + ', Browser_version: ' + deviceInfo.browser_version;
        }
        return message;
    }
}
Logger.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Logger_Factory() { return new Logger(i0.ɵɵinject(i1.NGXLogger), i0.ɵɵinject(i2.DeviceDetectorService)); }, token: Logger, providedIn: "root" });
