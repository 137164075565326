import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'prcins-bank-form',
  templateUrl: './bank-form.component.html',
  styleUrls: ['./bank-form.component.css']
})
export class BankFormComponent implements OnInit {
  @Input() eftForm: FormGroup;
  isChangePayPlan = false;
  maskedControls = {
    1: '************',
    2: '**'
  };

  helps = {
    accountNumber: false,
    routingNumber: false
  }

  helpFlags = { ... this.helps };

  checkingSelectedCls = '';
  savingsSelectedCls = '';

  accountMismatchError = false;
  routingError = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    const routePath = this.route.snapshot.routeConfig.path;
    if (routePath === 'change-plan') {
      this.isChangePayPlan = true;
      this.eftForm.addControl('plan_terms', new FormControl(false, [Validators.requiredTrue]));
    }

    let reAccountValue;
    if (this.eftForm.get('accountType').value === 'C') {
      this.checkingSelected();
    } else if (this.eftForm.get('accountType').value === 'S') {
      this.savingSelected();
    }
    this.eftForm.get("maskedAccountNumber").valueChanges.subscribe(val => {
      if (val && !val.includes('*')) {
        this.eftForm.get('accountNumber').setValue(val);
        reAccountValue = this.eftForm.get('reAccountNumber').value;
        if (reAccountValue && val !== reAccountValue) {
          this.accountMismatchError = true;
        } else {
          this.accountMismatchError = false;
        }
      }
    });
    this.eftForm.get("maskedReAccountNumber").valueChanges.subscribe(val => {
      if (val && !val.includes('*')) {
        this.eftForm.get('reAccountNumber').setValue(val);
        if (val !== this.eftForm.get('accountNumber').value) {
          this.accountMismatchError = true;
        } else {
          this.accountMismatchError = false;
        }
      }
    });

    this.eftForm.get("maskedRoutingNumber").valueChanges.subscribe(val => {
      if (val && !val.includes('*')) {
        this.eftForm.get('routingNumber').setValue(val);
        this.eftForm.get('routingNumber').markAsTouched();
        setTimeout(() => {
          if (!this.eftForm.get('routingNumber').valid) {
            this.routingError = true;
          } else {
            this.routingError = false;
          }
        }, 0);
      }
    });
  }

  checkingSelected() {
    this.savingsSelectedCls = 'bg-white text-black';
    this.checkingSelectedCls = 'bg-es-blue text-white';
    this.eftForm.patchValue({ accountType: 'C' });
  }

  savingSelected() {
    this.checkingSelectedCls = 'bg-white text-black';
    this.savingsSelectedCls = 'bg-es-blue text-white';
    this.eftForm.patchValue({ accountType: 'S' });
  }

  handleMaskOnBlur(maskedControlName, actualControlName, maskType = 1) {
    const maskedControl = this.eftForm.get(maskedControlName);
    const actualControl = this.eftForm.get(actualControlName);
    const actualValue = maskedControl.value;
    actualControl.markAsTouched();
    if (actualValue.length >= 4) {
      maskedControl.setValue(
        this.maskedControls[maskType] + actualValue.slice(-4)
      );
    }
  }

  handleMaskOnFocus(maskedControlName, actualControlName) {
    const maskedControl = this.eftForm.get(maskedControlName);
    const actualControl = this.eftForm.get(actualControlName);
    maskedControl.setValue(actualControl.value);
    delete history.state.error;
    this.eftForm.updateValueAndValidity();
  }

  showHelpText(key) {
    this.helpFlags = { ... this.helps };
    this.helpFlags[key] = true;
  }

  hideHelpText() {
    this.helpFlags = { ... this.helps };
  }

  onTermsChanged(item) {
    this.eftForm.get('plan_terms').setValue(item.value);
  }
}
