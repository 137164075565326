import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@prcins/environments";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EsignService {
    constructor(handler, http) {
        this.handler = handler;
        this.http = http;
        this.httpClient = new HttpClient(handler);
    }
    getEligibleForms(policies) {
        const body = { policies };
        return this.http.post(environment.BASE_URL + "/esign/eligible/forms", body);
    }
    viewDoc(policyNumber, eSignId, formId, token) {
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`
        });
        const apiUrl = environment.BASE_URL + `/esign/forms/pdf/${policyNumber}/${eSignId}/${formId}`;
        return this.httpClient.get(apiUrl, { headers: headers, responseType: 'text' });
    }
    submitESign(currentForm) {
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${currentForm.token}`
        });
        const apiUrl = environment.BASE_URL + `/esign/forms`;
        const body = {
            "policyNumber": currentForm.policyNumber,
            "firstName": currentForm.firstName,
            "lastName": currentForm.lastName,
            "prefix": null,
            "initial": null,
            "sob": "AI",
            "esignId": currentForm.eSignId,
            "fid": null,
            "esignDate": null,
            "appName": "ESERVICE"
        };
        return this.httpClient.post(apiUrl, body, { headers: headers });
    }
}
EsignService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EsignService_Factory() { return new EsignService(i0.ɵɵinject(i1.HttpBackend), i0.ɵɵinject(i1.HttpClient)); }, token: EsignService, providedIn: "root" });
