import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { UtilityProviderService } from '../services/utility-provider.service';

@Component({
  selector: 'prcins-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {
  @Input() userForm;

  constructor(
    private policyProviderService: UtilityProviderService
  ) {}

  ngOnInit(): void {
    this.userForm.get('businessName').disable();
    this.userForm.get('companyName').disable();
  }

  onPolicyTypeSelected() {
    if (this.userForm.get('lob').value === 'CA') {
      this.userForm.get('businessName').enable();
      this.userForm.get('companyName').enable();
    } else {
      this.userForm.get('businessName').disable();
      this.userForm.get('companyName').disable();
      
      this.userForm.get('firstName').enable();
      this.userForm.get('lastName').enable();
    }
  }

  onBusinessTypeSelected() {
    if (this.userForm.get('businessName').value === 'I') {
      this.userForm.get('firstName').enable();
      this.userForm.get('lastName').enable();
      this.userForm.get('companyName').disable();
    } else if (this.userForm.get('businessName').value === 'C') {
      this.userForm.get('firstName').disable();
      this.userForm.get('lastName').disable();
      this.userForm.get('companyName').enable();
    }
  }

  validatePolicyLob(control: AbstractControl) {
    return this.policyProviderService
      .getPolicyGreetingDetails(control.value)
      .pipe(
        map(res => {
          let match = false;
          const selectedLob = this.userForm.get('lob').value;
          if (selectedLob === 'PCAT' || selectedLob === 'ALN_UMB') {
            match = res.productCode === selectedLob ? true : false;
          } else {
            match = res.lob === selectedLob ? true : false;
          }
          return match ? null : { lobMismatch: true };
        })
      );
  }
}
