import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { ACCOUNTS_URL, USER_ID, ADMIN_URL, REGISTRATION_URL, POLICY_URL } from '@prcins/constants';
import {
  SecurityQuestionListQuery,
  ManageAlertsQuery,
  ManageAlertsPolicyList,
  ManageRemainderList,
  UtilityProviderService,
  AllAssociatedPolicyQuery,
  AlertPreferences
} from '@prcins/utils';
import { map, delay, catchError } from 'rxjs/operators';
import { WatchQueryFetchPolicy } from 'apollo-client';

@Injectable({
  providedIn: 'root'
})
export class AccountsProviderService {
  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private utility: UtilityProviderService
  ) { }

  getSecurityQuestions(username) {
    return this.apollo
      .watchQuery({
        query: SecurityQuestionListQuery,
        variables: {
          username
        }
      })
      .valueChanges.pipe(map(({ data }: any) => data.account));
  }

  getAllAssociatedPolicies(
    username: string,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
  ): Observable<any> {
    return this.apollo
      .watchQuery({
        query: AllAssociatedPolicyQuery,
        variables: {
          username: username
        },
        fetchPolicy
      })
      .valueChanges.pipe(
        map(({ data }: any) => data.policyAssociations.registeredPolicies)
      );
  }

  getRegisteredPolicies(
    username: string,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
  ): Observable<any> {
    return this.apollo
      .watchQuery({
        query: ManageAlertsPolicyList,
        variables: {
          username: username
        },
        fetchPolicy
      })
      .valueChanges.pipe(
        map(({ data }: any) => data.account.registeredPolicies)
      );
  }

  getPolicyReminders(username?: string): Observable<any> {
    return this.apollo
      .watchQuery({
        query: ManageRemainderList,
        variables: {
          username: username
        }
      })
      .valueChanges.pipe(
        map(({ data }: any) => data.account.profile.registeredPolicies)
      );
  }

  getManageAlertsConfigs(policyNumber: string): Observable<any> {
    return this.apollo
      .watchQuery({
        query: ManageAlertsQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }: any) => data.manageAlerts));
  }

  updateEmail(newEmail, userId) {
    return this.http.post(
      `${ACCOUNTS_URL.ChangeEmail}/${userId}/idg_user_group`,
      { newEmail, userId }
    );
  }

  updatePassword(currentPassword, newPassword, userId) {
    return this.http.post(
      `${ACCOUNTS_URL.ChangePassword}/${userId}/idg_user_group`,
      { currentPassword, newPassword, userId }
    );
  }

  updateSecurityQuestions(questionsPayload, userId) {
    return this.http.post(
      `${ACCOUNTS_URL.ChangeSecurityQuestions}/${userId}/idg_user_group`,
      questionsPayload
    );
  }

  updateScheduledReminder(policyNumber, remindersFlag) {
    const userType = this.utility.getUserType();
    return this.http.put(
      `${ACCOUNTS_URL.ScheduledReminder}/${sessionStorage.getItem(
        USER_ID
      )}/${userType}/${policyNumber}/${remindersFlag}`,
      null
    );
  }

  deletePolicy(policyNumber) {
    const userType = this.utility.getUserType();
    return this.http.delete(
      `${ACCOUNTS_URL.DeletePolicy}/${sessionStorage.getItem(
        USER_ID
      )}/${userType}/${policyNumber}`
    );
  }

  disableAccount(policy) {
    return this.http.delete(
      `${ADMIN_URL.disableAccount}/${sessionStorage.getItem(
        USER_ID
      )}/idg_user_group/${policy.policyNumber}`
    );
  }

  getTextAlertDetails(userId) {
    return this.http.get(REGISTRATION_URL.TextAlertDetails + "/" + userId);
  }

  addUpdateTextAlertDetails(payload) {

    return this.http.post(REGISTRATION_URL.TextAlertAddUpdate, payload);
  }

  stepOneRegistration(payload) {
    return this.http.post(REGISTRATION_URL.TextAlertSendOtpVerification, payload);
  }

  stepTwoRegistration(payload) {
    return this.http.post(
      REGISTRATION_URL.TextAlertOTPVerify,
      payload
    );
  }

  getAlertPreferences(policyEmail): Observable<any> {
    return this.http.get(ACCOUNTS_URL.alertPreferences + "/" + policyEmail);
  }

  saveAlertPreference(request: AlertPreferences): Observable<any> {
    return this.http.post(ACCOUNTS_URL.alertPreferences, request);
  }
}
