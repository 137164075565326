import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prcins-lob',
  templateUrl: './lob.component.html',
  styleUrls: ['./lob.component.css']
})
export class LobComponent {
  @Input() account;
}

/***
 * <prcins-lob [account]="account">
      <p PA class="font-bold">Personal Auto Policy</p>
      <p HO class="font-bold">Home Owners Policy</p>
      <p CA class="font-bold">Business Auto Policy</p>
      <p PCAT class="font-bold">Umbrella Policy</p>
  </prcins-lob>
 */
