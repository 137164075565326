import { Subject, BehaviorSubject } from 'rxjs';


export class DiscardComponent {
  isSafeToNavigate: boolean = true;
  showGuardPopup: boolean = false;
  deactivateGuardSelection$: Subject<boolean> = new Subject<boolean>();


  updateSafeToNavigate(safeFlag: boolean) {
    this.isSafeToNavigate = safeFlag;
  }

  promptDeactivate(): any {
    this.showGuardPopup = true;
    return this.deactivateGuardSelection$;
  }

  chooseDeactivate(response: boolean) {
    this.showGuardPopup = false;
    this.deactivateGuardSelection$.next(response);
  }


}
