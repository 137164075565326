<div>
  <h1 class="es-h1 mt-4">Edit Card</h1>
  <div body>
    <prcins-danger *ngIf="showError">
      Oops! There is a problem with the card you entered.
    </prcins-danger>
    <div class="flex items-center">
      <span><img class="mr-4 cardType" width="50" height="32" alt="" src="{{card.imageUrl}}"></span>
      <span class="font-whitney-semibold">{{card.cardDisplayType}} *****{{card.debitAccount}}</span>
      <img (click)="cardNumberHelp=true" class="ml-2 cursor-pointer" src="assets/svgs/question-round-blue.svg"
        alt="">
    </div>
    <prcins-card-form [cardForm]="cardForm"></prcins-card-form>
    <div class="btns">
      <button class="es-btn-primary w-full mt-4" (click)="doEditCard()">Save</button>
      <a class="es-link-secondary w-full mt-4 cursor-pointer" (click)="showGuardPopup = true">Cancel</a>
    </div>
  </div>
</div>
<!-- <prcins-es-popup *ngIf="showError" headerClass="bg-es-blue text-white pop-up-width">
  <div header-left>
    <span>Edit Card</span>
  </div>
  <div body class="items-center justify-between">
    <div class="flex justify-center items-center"><b>Let us help you</b></div>
    <p *ngIf="channel === 'DIRECT'" class="mt-4">
      We would like to assist you with this change, please contact us for assistance.</p>
    <p *ngIf="channel != 'DIRECT'" class="mt-4">
      Your agent would like to assist you with this change. Please contact {{agentInfo.agencyName}} for
      assistance.
    </p>

    <div class="mt-4">
      <a *ngIf="channel === 'DIRECT'" href="tel:{{generalPhone}}">
        <button class="add-btn add-btn-primary">{{generalPhone}}</button>
      </a>
      <a *ngIf="channel != 'DIRECT' && agentInfo.agencyPhone" href="tel:{{agentInfo.agencyPhone}}">
        <button class="add-btn add-btn-primary">{{agentInfo.agencyPhone}}</button>
      </a>
      <a class="es-link-secondary w-full mt-4" (click)="showError = false">Close</a>
    </div>
  </div>
</prcins-es-popup> -->
<prcins-es-popup *ngIf="showGuardPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Confirmation</span>
  </div>
  <div body>
    <p class="text-center text-lg">Are you sure you want to cancel this transaction?</p>
    <div class="flex flex-wrap-reverse justify-between">
      <a class="es-link-secondary  w-half-2 mt-8  cursor-pointer" (click)="showGuardPopup = false">No</a>
      <button class="es-btn-primary w-half-2 mt-8 cursor-pointer" type="button"
        (click)="cancelTransaction()">Yes</button>
    </div>
  </div>
</prcins-es-popup>
<prcins-spinner *ngIf="showSpinner"></prcins-spinner>
<prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="cardNumberHelp">
  <div header-left>
    <span>Edit Card</span>
  </div>
  <div header-right>
    <img class="cursor-pointer" height='15px' width='15px' (click)="cardNumberHelp=false"
      src="assets/svgs/close-hamburger.svg">
  </div>
  <div body>
    <p>Only an expiration date can be changed, not the card number. To replace this card, you must first add a new card and then delete this one. If you are enrolled in an Automatic pay plan, we will continue to use this card for your scheduled payments.</p>
  </div>
</prcins-es-popup>
