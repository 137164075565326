import gql from 'graphql-tag';

export const PaymentDetailsQuery = gql`
  query PolicySearch($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      product {
        policyNumber
        companyCode
        productCode
        cancelPolicyStatus
        cancelEffectiveDate
        businessName
        lobCode
        effectiveDate
        channel
        cancelPending
      }
      primaryAddress {
        addressLine1
        city
        zipCode
        state
      }
    }
    policySearchInfo(policyNumber: $policyNumber) {
      underwriterCode
    }
    billingDetails(policyNumber: $policyNumber) {
      nextPaymentAmnt
      nextPaymentDueDt
      payInFullAmnt
      payInFullFlag
      balance
      expiryDate
      billedUnpaid
      insuredName
      expiryDate
      payplan
    }
  }
`;

export const PolicyReviewQuery = gql`
  query PolicyReview($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      product {        
        productCode
        lobCode
        effectiveDate
        companyCode
        channel
      }
      primaryAddress {
        addressLine1
        city
        zipCode
        state
      }
    }
    policySearchInfo(policyNumber: $policyNumber) {
        businessName
        middleName
        firstName
        lastName
    }
  }
`

export const GetPolicyWalletsQuery = gql`
  query GetPolicyWallets($userId:String, $policyNumber: String, $appName: String) {
    getWallets(userId: $userId, policyNumber: $policyNumber, appName: $appName) {
      walletId,
      paymentMethodId,
      debitAccount,
      debitRouting,
      paymentType,
      defaultInd,
      cardType,
      expirationMonth,
      expirationYear,
      transType,
      modifiedDate,
      accountNickName
    }
  }
`;
