import { Component,Input } from '@angular/core';
import { BRAND } from '@prcins/constants';

@Component({
  selector: 'prcins-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {
  brand = localStorage.getItem(BRAND);
  @Input() isAddressSpinner: boolean;
}
