import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  baseURL = sessionStorage.getItem('baseURL');
  apiUrlGetDrivers = this.baseURL + "/dropdown/driver/list";
  apiUrl = this.baseURL + "/dropdown/lists";

  constructor(private http: HttpClient) { }

  getDropdownEntries(request: IGetDropDownEntriesRequest): Observable<any> {
    return this.http.post(this.apiUrl, request);
  }

  getDriverDropdownEntries(request): Observable<any> {
    return this.http.post(this.apiUrlGetDrivers, request);
  }
}

export enum DropDownName {
  VEH_PRIMARY_USE = 'VEH_PRIMARY_USE',
  ANTI_THFT_DEV = 'ANTI_THFT_DEV',
  PRE_INSP_REQ = 'PRE_INSP_REQ',
  OWNER_STATUS = 'OWNER_STATUS',
  LOAN_OR_LEASE = 'LOAN_OR_LEASE',
  US_STATE = 'US_STATE'
}

export interface IGetDropDownEntriesRequest {
  UI_Component: string,
  State?: string,
  List_Name?: string

}

export interface IGetDropDownEntriesResponse {
  code: string,
  description: string,
  displayOrder: number
}
