export const SCHEDULED_PAY_PLANS = [
    '1PAYEFT',
    '1PAYREC',
    '2PAYEFT',
    '2PAYREC',
    '3PAYEFT',
    '3PAYREC',
    '4REC_6',
    '4REC_6_SX',
    '5REC',
    '5REC_NB',
    '5REC_RN',
    '6PAYEFT',
    '6PAYREC',
    '10REC',
    '10REC_RN',
    'EFT_1PAY',
    'EFT_1PAY',
    'EFT4P_6_SX',
    'EFT5P_6',
    'EFT_6PAY',
    'EFT6PAY',
    'EFT10PAY',
    'EFT10PREF',
    'EFT12PAY',
    'EFT12PREF',
    'EFT2PAY_6',
    'EFT2PAY12',
    'EFT4PAY_12',
    'EFT4PAY_6',
    'EFT4PAY12',
    'EFT6PREF',
    'CTHOEFT',
    'CTHOMORT',
    'EQ',
    'MAHOEFT',
    'MAHOMORT',
    'MORTGAGEE',
    'NHHOEFT',
    'NHHOMORT',
    'NJCAEFT',
    'NJHOEFT-11',
    'NJHOMORT',
    'NJUMBEFT'
];
