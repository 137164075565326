<div class="desktop-only-container">
  <main class="px-8 pb-8 pt-4">
    <h1 class="es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0">
      <span *ngIf="verificationOptionsForm.mode === 'TEXT'">Check Your Cell</span>
      <span *ngIf="verificationOptionsForm.mode === 'EMAIL'">Check Your Email</span>
    </h1>
    <prcins-danger *ngIf="verificationRetryMessage">
      <span>
        {{errorMessage}}
      </span>
    </prcins-danger>
    <p><span *ngIf="!resendCode">An authorization code has been sent to: </span>
      <span *ngIf="resendCode">We have resent a code to: </span>
      <span *ngIf="verificationOptionsForm.mode === 'TEXT'">{{userPolicyDetailsResp.phoneNumber}}</span>
      <span *ngIf="verificationOptionsForm.mode === 'EMAIL'">{{userPolicyDetailsResp.emailAddress}}</span>
    </p>
    <form [formGroup]="codeVerificationForm">
      <div class="flex items-center justify-between flex-wrap mt-4">
        <label class="mb-2 font-semibold" for="">Enter 6 digit code</label>
        <input type="text" trimInput maxlength="6" prcinsOnlyNumber class="w-full lg:w-3/5 es-textfield"
          formControlName="token">
      </div>
      <div class="flex items-center justify-between flex-wrap">
        <label class="mb-2 font-semibold" for=""></label>
        <prcins-error-label [control]="codeVerificationForm.get('token')" class="w-full lg:w-3/5"
          [controlValue]="codeVerificationForm.get('token').value" controlLabel="codeVerificationForm_digitCode"
          [controlTouched]="codeVerificationForm.get('token').touched">
        </prcins-error-label>
      </div>
    </form>
    <div class="flex flex-col">
      <span class="flex justify-end mt-4"><a class="text-es-blue-light cursor-pointer"
          (click)="resendVerificationCode()">Resend
          Code</a> <a class="ml-8 text-es-blue-light cursor-pointer" (click)="onBackClicked()">Choose a
          different validation method</a></span>
    </div>
    <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
  </main>
</div>
<div class="flex justify-between flex-wrap px-8 lg:px-0" *ngIf="!isMobileApp && !isMobileBrowser; else mobileAppBtns">
  <button class="w-full lg:w-2/5 es-btn-secondary mt-2" (click)="onBackClicked()" type="button">Back</button>
  <button class="w-full lg:w-2/5 es-btn-primary mt-2" (click)="onRegister()" type="button">Continue</button>
</div>

<ng-template #mobileAppBtns>
  <div class="flex justify-between flex-wrap px-8 lg:px-0">
    <button class="w-full lg:w-2/5 es-btn-primary mt-2" (click)="onRegister()" type="button">Continue</button>
    <button class="w-full lg:w-2/5 es-btn-secondary mt-2" (click)="onBackClicked()" type="button">Back</button>
  </div>
</ng-template>
