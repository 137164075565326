<ng-container [ngSwitch]="account.policyInfo.lob">
    <ng-content select="[PA]" *ngSwitchCase="'PA'"></ng-content>
    <ng-content select="[HO]" *ngSwitchCase="'HO'"></ng-content>
    <ng-content select="[MC]" *ngSwitchCase="'MC'"></ng-content>
    <ng-container *ngSwitchCase="'CA'">
        <ng-container *ngIf="account.policyInfo.productCode==='PCAT' || account.policyInfo.productCode==='ALN_UMB'; else businessAutoGroup">
            <ng-content select="[PCAT]"></ng-content>
        </ng-container>
        <ng-template #businessAutoGroup>
            <ng-content select="[CA]"></ng-content>
        </ng-template>
    </ng-container>
</ng-container>