import { Injectable } from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "@prcins/environments";
import { CURRENT_POLICY, TOKEN } from '@prcins/constants';

@Injectable({
  providedIn: 'root'
})
export class RenewalquestionnaireService {
  private httpClient: HttpClient;

  constructor(
    private handler: HttpBackend,
    private http: HttpClient
  ) {
    this.httpClient = new HttpClient(handler);
   }

   getDriverDetails(pol: any) {
    console.log("inside the renewalquestion service and calling a post")
    console.log(environment.BASE_URL);
    const headers = new HttpHeaders({
      'Authorization': 'Dearer '+sessionStorage.getItem(TOKEN), 
      'Content-Type': 'application/json', 
      'pn': sessionStorage.getItem(CURRENT_POLICY)
    });
    return this.http.post('/rq/rq-submit', pol, {headers});


  }
}
