/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./id-cards-notification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../es-popup/es-popup.component.ngfactory";
import * as i3 from "../es-popup/es-popup.component";
import * as i4 from "@angular/common";
import * as i5 from "./id-cards-notification.component";
var styles_IdCardsNotificationComponent = [i0.styles];
var RenderType_IdCardsNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IdCardsNotificationComponent, data: {} });
export { RenderType_IdCardsNotificationComponent as RenderType_IdCardsNotificationComponent };
function View_IdCardsNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "prcins-es-popup", [["headerClass", "bg-es-blue text-white"]], null, null, null, i2.View_EsPopupComponent_0, i2.RenderType_EsPopupComponent)), i1.ɵdid(1, 49152, null, 0, i3.EsPopupComponent, [], { headerClass: [0, "headerClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["header-left", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Evidence of Insurance"])), (_l()(), i1.ɵeld(5, 0, null, 1, 1, "div", [["header-right", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "cursor-pointer"], ["height", "15px"], ["src", "assets/svgs/close-hamburger.svg"], ["width", "15px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hidePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, 2, 2, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There are no documents available."]))], function (_ck, _v) { var currVal_0 = "bg-es-blue text-white"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IdCardsNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_IdCardsNotificationComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.display; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IdCardsNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-id-cards-notification", [], null, null, null, View_IdCardsNotificationComponent_0, RenderType_IdCardsNotificationComponent)), i1.ɵdid(1, 49152, null, 0, i5.IdCardsNotificationComponent, [], null, null)], null, null); }
var IdCardsNotificationComponentNgFactory = i1.ɵccf("prcins-id-cards-notification", i5.IdCardsNotificationComponent, View_IdCardsNotificationComponent_Host_0, { csPhone: "csPhone" }, {}, []);
export { IdCardsNotificationComponentNgFactory as IdCardsNotificationComponentNgFactory };
