import { Component, OnInit } from '@angular/core';
import { RegistrationService, TealiumUtagService } from '@prcins/utils';
import { PAYMENT_ID, PAYMENT_AMOUNT, CURRENT_POLICY, SAVED_WALLET, SAVE_WALLET_ERROR, PAYMENT_SOURCE, QPAY_REGISTRATION_EMAIL } from '@prcins/constants';
import { PaymentProviderService } from '../services/payment-provider.service';
import { PolicyProviderService } from 'libs/policy/src/lib/services/policy-provider.service';
import { USER_ID } from '@prcins/constants';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMapTo, tap } from 'rxjs/operators';

@Component({
  selector: 'prcins-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.css']
})
export class PaymentConfirmationComponent implements OnInit {
  paymentAmount: string;
  paymentId: string;
  helpPhone:string;
  policyNumber;
  isDisable = false;
  showMobileRegistration = true;
  savedWallet: string;
  saveWalletError: string;
  isEService: boolean;
  showHOCrossSellPromotion: boolean;
  showIntermediateRegisterationForm: boolean;
 
  registrationEmailAddress: string;

  constructor(private uTagService: TealiumUtagService, private paymentProvider: PaymentProviderService, private policyProvider:PolicyProviderService,
     private router: Router, private route: ActivatedRoute, private registerationService: RegistrationService
    ) {
  }
  ngOnInit() {
    this.paymentId = sessionStorage.getItem(PAYMENT_ID);
    this.policyNumber = sessionStorage.getItem(CURRENT_POLICY);
    this.uTagService.view({ "event_name": this.paymentId ? 'payment_successful:' + this.paymentId : null });    
    
    this.paymentAmount = sessionStorage.getItem(PAYMENT_AMOUNT);
    this.helpPhone = sessionStorage.getItem("general-phone");
    if(this.policyNumber.toUpperCase().startsWith("COV")) {
      this.helpPhone = "800-437-5556";
    }
    this.showHOCrossSellPromotion = false;

    this.policyProvider.getCrossSellPromotions(this.policyNumber).subscribe(data => {
      if (data && data.policyCrossSellPromotions && _.isArray(data.policyCrossSellPromotions) && data.policyCrossSellPromotions.length> 0) {
        data.policyCrossSellPromotions.forEach(element => {
          console.log("xsell data xSellType"+ element.crossSellType);           
          console.log("xsell data discountPercentage"+ element.discountPercentage);   
          if (element && element.crossSellType === "HO") {
            this.showHOCrossSellPromotion = true;
          }                  
        });
      } else {
        console.log("error getting xsell data element"+ data);
      }
    });
    let isGuestQpayLogin = sessionStorage.getItem(PAYMENT_SOURCE)? sessionStorage.getItem(PAYMENT_SOURCE) === 'qpay': false;
    if (isGuestQpayLogin) {
      this.registerationService.checkIfPolicyAlreadyRegisteredInEservice(this.policyNumber).subscribe((resp) => {
          if (resp) {
            this.showIntermediateRegisterationForm = false;
          } else {
            let qPayEserviceEnroll: string = sessionStorage.getItem("qPayEserviceEnroll");
            //TODO: check if already registered with esevice
            this.showIntermediateRegisterationForm = qPayEserviceEnroll? qPayEserviceEnroll === 'defer': false;
            this.registrationEmailAddress = this.showIntermediateRegisterationForm && sessionStorage.getItem(QPAY_REGISTRATION_EMAIL)? sessionStorage.getItem(QPAY_REGISTRATION_EMAIL): '';  
          }
        });
     
    } else {
      this.showIntermediateRegisterationForm = false;
    }

    this.paymentProvider
      .getPayloadForRegistration(this.policyNumber).subscribe( (resp: any) => {
        const {
          statusCode
        } = resp.policySearchInfo;
        const payplan = resp.billingDetails.payplan;
        const payplanToShow = !payplan.includes('PAYROLL') && !payplan.includes('PREMFIN') && !payplan.includes('MORTGAGEE');
        this.isDisable = statusCode === 'Expired' || statusCode === 'Cancelled';
        this.showMobileRegistration = this.showMobileRegistration && statusCode !== 'Cancelled' && payplanToShow;
    });
    this.paymentProvider
      .getMobileRegisteredPolicies(sessionStorage.getItem(USER_ID)).subscribe( (resp: any) => {
        const policy = resp.find( data => data.policyNumber === this.policyNumber);
        if (!policy.policyInfo.isPrime) {
          this.showMobileRegistration = false;
          return;
        }
        for (const veh of policy.inquiry.vehicles) {
          if (veh.entityCode === 'PPA' || veh.entityCode === 'MC') {
            return;
          }
        }
        this.showMobileRegistration = false;
    });
    this.savedWallet = sessionStorage.getItem(SAVED_WALLET);
    this.saveWalletError = sessionStorage.getItem(SAVE_WALLET_ERROR);
    this.isEService = !sessionStorage.getItem(PAYMENT_SOURCE) || sessionStorage.getItem(PAYMENT_SOURCE)  === 'eservice';
      
    
  }

  onContinueToPaymentForm() {
    this.showIntermediateRegisterationForm = false;
  }

  onRegistrationSubmitted(eventResponse) {
    if (eventResponse && eventResponse.responseCode === "REGISTRATION_SUCCESS") {
      console.log("REGISTRATION_SUCCESS");
      sessionStorage.removeItem("qPayEserviceEnroll");
      sessionStorage.removeItem(PAYMENT_SOURCE);
      this.router.navigate(['../intermediateRegistrationComplete'], {
        relativeTo: this.route
      });
     
    } else if (eventResponse && eventResponse.responseCode === 'EXISTING') {
      sessionStorage.removeItem("qPayEserviceEnroll");
      sessionStorage.removeItem(PAYMENT_SOURCE);
      this.router.navigate(['/eservice/login'], {
        relativeTo: this.route,
        state: {
          email: eventResponse.userName
        }
      });
    } else if (eventResponse && eventResponse.responseCode === 'NEW_POLICY') {
      sessionStorage.removeItem("qPayEserviceEnroll");
      sessionStorage.removeItem(PAYMENT_SOURCE);
      this.router.navigate(['/eservice/link-policies'], {
        relativeTo: this.route,
        state: {
          email: eventResponse.userName
        }
      });
    } else {
      console.log(`REGISTRATION_ERROR: ${eventResponse}`);
    }
  }


  closePopup(){
    this.isDisable = false;
  }

  printPage() {
    window.print();
  }


}
