import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'prcins-round-radio',
  templateUrl: './round-radio.component.html',
  styleUrls: ['./round-radio.component.css']
})
export class RoundRadioComponent {
  @Input() radioName;
  @Input() radioValue;
  @Input() radioChecked;
  @Input() radioDisabled;
  @Output() radioChanged = new EventEmitter<{ key: string; value: string }>();

  onRadioChange() {
    this.radioChanged.next({ key: this.radioName, value: this.radioValue });
  }
}

/***
 *  Usage
 * <prcins-round-radio radioName="isNewAddress" radioValue="new" (radioChanged)="addressSelectionChanged($event)"></prcins-round-radio> 
 * <prcins-round-radio radioName="isNewAddress" radioValue="existing" (radioChanged)="addressSelectionChanged($event)"></prcins-round-radio> 
***/
