import { FormBuilder, Validators } from '@angular/forms';
import { cardNumberValidatorFn, expiryDateValidatorFn } from '../validators/payment-form.validators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
export class CardFormProviderService {
    constructor(fb) {
        this.fb = fb;
    }
    getForm(isZipRequired = false) {
        let formControls = {
            cardNumber: [
                '',
                [Validators.required, Validators.minLength(15), cardNumberValidatorFn()]
            ],
            cardType: [''],
            maskedCardNumber: ['', [Validators.required, Validators.minLength(15)]],
            expirationMonth: ['%%', Validators.pattern(/^[0-9]*$/)],
            expirationYear: ['%%', Validators.pattern(/^[0-9]*$/)],
            expirationDate: ['', [Validators.required, expiryDateValidatorFn()]]
        };
        if (isZipRequired) {
            formControls = {
                ...formControls,
                cardZip: ['', [Validators.required, Validators.pattern(/\d{5}/)]]
            };
        }
        return this.fb.group(formControls);
    }
    getEditCardForm(isZipRequired = false) {
        let formControls = {
            cardType: [''],
            expirationMonth: ['%%', Validators.pattern(/^[0-9]*$/)],
            expirationYear: ['%%', Validators.pattern(/^[0-9]*$/)],
            expirationDate: ['', [Validators.required, expiryDateValidatorFn()]],
            securityCode: ['', [Validators.required, Validators.minLength(3)]],
            maskedSecurityCode: ['', [Validators.required, Validators.minLength(3)]]
        };
        if (isZipRequired) {
            formControls = {
                ...formControls,
                cardZip: ['', [Validators.required, Validators.pattern(/\d{5}/)]]
            };
        }
        return this.fb.group(formControls);
    }
}
CardFormProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CardFormProviderService_Factory() { return new CardFormProviderService(i0.ɵɵinject(i1.FormBuilder)); }, token: CardFormProviderService, providedIn: "root" });
