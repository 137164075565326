import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'prcins-welcome-banner',
  templateUrl: './welcome-banner.component.html',
  styleUrls: ['./welcome-banner.component.css']
})
export class WelcomeBannerComponent implements OnInit {

  notification = null;

  constructor(private loginService: LoginService) { }

  ngOnInit() {
    this.loginService.statusNotification$.subscribe(notification => {
      this.notification = notification;
    })
  }

}
