import { Component, OnInit, Input } from '@angular/core';
import { BRAND, Brand, CURRENT_POLICY, USER_ID } from '@prcins/constants';
import { RenewalquestionnaireService } from '../services/renewalquestionnaire.service'
// import { AccountsProviderService } from '../services/accounts-provider.service';
//import { AlertPreferences } from '@prcins/utils';
// import { take } from 'rxjs/operators';
// import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { dateWithinRange, licenseNumberValidator } from '../../../../../apps/endorsements/add-driver/src/app/validators';
import { FormControl, FormGroup, FormsModule, Validators, FormBuilder, FormArray } from '@angular/forms';
import { DropdownService, DropDownName, IGetDropDownEntriesResponse } from '../../../../../libs/features/personal-auto/src/lib/vehicle/services/dropdown.service'
import { Observable } from 'rxjs';


@Component({
  selector: 'prcins-additional-drivers-details',
  templateUrl: './additional-drivers-details.component.html',
  styleUrls: ['./additional-drivers-details.component.css']
})

export class AdditionalDriversDetailsComponent implements OnInit {
  driverForm: FormGroup;
  brand = localStorage.getItem(BRAND);
  mainPage = Brand[this.brand].urlEnterpriseWeb;
  MIN_INSURABLE_AGE = 15;
  MAX_INSURABLE_AGE = 99;
  _usStateOptions: IGetDropDownEntriesResponse[];
  isLoading = false;

  constructor(private route: ActivatedRoute, private router: Router, private dropdownService: DropdownService,
    private formBuilder: FormBuilder, private renewalQuestion: RenewalquestionnaireService) {

    this.driverForm = this.formBuilder.group({
      drivers: this.formBuilder.array([])
    });
    console.log('calling adddriver row')
    this.addDriverRow();
  }
  licenseStatusChange(e, cntrl) {
    // console.log('am i here ?', cntrl.controls.licenseStatus.value)
    const ctl = cntrl.controls;
    // console.log('printing ctl', ctl)
    if (cntrl.controls.licenseStatus.value !== 'active' && cntrl.controls.licenseStatus.value !== 'permit') {
      cntrl.controls.licenseState.patchValue({ 'licenseState': '' })
      cntrl.controls.licenseNumber.patchValue('');
      cntrl.controls.licenseState.disable()
      cntrl.controls.licenseNumber.disable()
    } else {
      cntrl.controls.licenseState.enable()
      cntrl.controls.licenseNumber.enable()
    }
  }
  addDriverRow() {
    console.log('Log inside addDriverRow method')
    const driverGroup = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern("([a-zA-Z -.,']+)")]],
      lastName: ['', [Validators.required, Validators.pattern("([a-zA-Z -.,']+)")]],
      dob: ['', [Validators.required, dateWithinRange(this.MIN_INSURABLE_AGE, this.MAX_INSURABLE_AGE)]],
      licenseStatus: ['', [Validators.required]],
      licenseState: ['', [Validators.required]],
      licenseNumber: ['', [Validators.required]]
    });
    console.log('Pushing the driver group')
    this.driverControls.push(driverGroup);
  }

  get driverControls() {
    console.log('Log inside getter method')
    return this.driverForm.get('drivers') as FormArray;
  }

  removeDriver(driverIndex) {
    if (this.driverControls.length > driverIndex) {
      this.driverControls.removeAt(driverIndex);
    }
  }


  onSubmit() {
    console.log("Log inside onSubmit method")
    console.log(this.driverForm)
    const driverData = this.driverControls.value;
    console.log(driverData);
    const data =
    {
      policyNumber: sessionStorage.getItem(CURRENT_POLICY),
      driverDetails: driverData
    };
    this.isLoading = true;
    this.renewalQuestion.getDriverDetails(data).subscribe(
      (response) => {
        console.log('POST request successful', response);
        this.router.navigate(["../confirmation"], {
          relativeTo: this.route,
          state: data
        });
        this.isLoading = false;

      },
      (error) => {
        console.error('POST request error', error);
        this.isLoading = false;
        this.router.navigate(["../error"], {
          relativeTo: this.route
        });
      }
    );
    // if (this.driverForm.valid) {
    //   console.log("Driver form is valid and redirecting")
    //   const driverData = this.driverControls.value;
    //   console.log(driverData);
    //   this.router.navigate(['../additionaldriversrspn'], { state: { driverData } });
    // }
    // this.router.navigate(['../additionaldriversrspn'], {
    //   relativeTo: this.route
    // });
  }
  ngOnInit() {
    this.dropdownService.getDropdownEntries({
      UI_Component: "eService",
      List_Name: DropDownName.US_STATE
    }).subscribe(resp => {
      console.log(resp);
      if (resp) {
        this._usStateOptions = resp.US_STATE;
      }
    });

  }

}
