import { Component, OnInit } from '@angular/core';
import { IPolicy, PolicyBroadcasterService, INotification, RightRailService, UtilityProviderService } from '@prcins/utils';
import { Observable, Subject, forkJoin, combineLatest, zip } from 'rxjs';
import { PolicyProviderService } from '../services/policy-provider.service';
import { NotificationService } from '../notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@prcins/environments';
import { CURRENT_POLICY_STATUS, CURRENT_POLICY } from '@prcins/constants';
import { first } from 'rxjs/operators';
import { SessionService } from '../services/session.service';
import * as _ from 'lodash';

@Component({
  selector: 'prcins-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  policySelected$: Observable<IPolicy>;

  eDocNotification$ = new Subject<INotification>();
  pastDueNotification$ = new Subject<INotification>();
  noicNotification$ = new Subject<INotification>();
  notificationModel;
  covidMessageData: any;
  showRoadRewards: boolean;
  validRRdisplay: boolean;
  validRRLetsGo: boolean = false;
  isPolicyIncomplete: boolean = false;
  isPolicyNotCancelledOrExpired: boolean = false;
  isHO3Policy = false;
  isAlligned = false;
  showAgentLetUsHelpYouPopUp = false;
  agentInfoLoaded$: Observable<boolean>;
  agentInfo;
  generalPhone;
  channel = sessionStorage.channel;
  mobileApp = false;

  showRedirectPopupFlag = false;
  redirectQuickLink;

  roadRewardsLoaded$: Observable<boolean>;
  private roadRewardsDriverLoaded = new Subject<boolean>();
  roadRewardsDriverLoaded$ = this.roadRewardsDriverLoaded.asObservable();

  showEdocsPopup = false;
  showEdocsNotification = false;
  showHOCrossSellPromotion = false;
  showEdocsPromotion = false;

  hoLandingQuickLinks = ['additionalProtection', 'floodInsurance', 'petInsurance']

  landingQuickLinksConfig = {
    additionalProtection: {
      link: "policy/additional-protection",
      icon: "assets/svgs/security_protection_icon.svg",
      label: "Additional Protection"
    },
    floodInsurance: {
      link: "https://neptuneflood.com/consumer-app/?source=Rc4trJmYEwC7W%2BrLTaOrJhsPKZuwXmq0Kpl4JsWj9ngw4%2BoxyS0Jyln4kpGog3WHGYqpZhsaGbqRXI4Gtp5g",
      icon: "assets/svgs/flood_insurance_icon.svg",
      label: "Flood Insurance"
    },
    petInsurance: {
      link: "https://www.fetchpet.com/partners/plymouthrock?utm_source=Plymouthrock&utm_medium=partnership&utm_campaign=Plymouthrock_vanityurl&c=Plymouthrock10&p=plymouthrock",
      icon: "assets/svgs/pet_insurance.svg",
      label: "Pet Insurance"
    }
  };
  constructor(private policyBroadcaster: PolicyBroadcasterService,
    private policyProvider: PolicyProviderService,
    private sessionService: SessionService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private rightRailService: RightRailService,
    private utilityProvider: UtilityProviderService) { }

  ngOnInit() {
    if (self != top) {
      this.mobileApp = true;
    }

    this.notificationService.notify$.subscribe(data => {
      this.notificationModel = data;
    });
    this.policySelected$ = this.policyBroadcaster.policySelected$;
    this.roadRewardsLoaded$ = this.utilityProvider.roadRewardsLoaded$;
    this.roadRewardsDriverLoaded.next(false);
    this.policySelected$.subscribe(policySelected => {
      this.showEdocsPromotion = false;
      this.isHO3Policy = false;
      this.isAlligned = false;
      this.isPolicyNotCancelledOrExpired = sessionStorage.getItem(CURRENT_POLICY_STATUS) !== 'Canceled' && sessionStorage.getItem(CURRENT_POLICY_STATUS) !== 'Expired';
      this.isPolicyIncomplete = sessionStorage.getItem(CURRENT_POLICY_STATUS) === 'Incomplete';
      if (this.isPolicyIncomplete) {
        return;
      }

      this.notificationModel = null;
      this.eDocNotification$.next(null);
      this.pastDueNotification$.next(null);
      this.noicNotification$.next(null);

      this.policyBroadcaster.broadcastPolicyLoaded(null);
      // this.utilityProvider.getCovidMsgDetails(policySelected.policyNumber).subscribe((resp: any) => {
      //   this.covidMessageData  = resp;
      //   console.log(this.covidMessageData)
      // });
      this.policyProvider.getPolicyInquiry(policySelected.policyNumber).subscribe(data => {
        if (data.policyDetails) {
          let policyChannel = data.policyDetails.product.channel;
          sessionStorage.setItem("channel", policyChannel);
          this.utilityProvider.broadcastInquiryLoaded(true);
          sessionStorage.setItem("policyInstance", data.policyDetails.policyInstance);
          this.rightRailService.showAgentInfo$.next((policyChannel.indexOf("DIRECT") == -1) ? true : false);
          if (policyChannel == 'IA' && sessionStorage.getItem("invalidRRDisplay") == "N" && sessionStorage.getItem('productCode') != "PAIP") {
            this.validRRdisplay = true;
          }
          this.agentInfoLoaded$ = this.rightRailService.agentInfoLoaded$;
          this.agentInfoLoaded$.subscribe(() => {
            this.setAgentInfo();
          });
          
          this.isHO3Policy = data.policyDetails.locations && data.policyDetails.locations.length !== 0 && data.policyDetails.locations[0].entityCode === "HO_3";
          this.isAlligned = data.policyDetails.product.productCode === 'ALN_HO';
          this.channel = policyChannel;

          sessionStorage.setItem('channel', data.policyDetails.product.channel);
          this.policyBroadcaster.broadcastPolicyLoaded(data);
        }
      });

      this.showHOCrossSellPromotion = false;

      this.policyProvider.getCrossSellPromotions(policySelected.policyNumber).subscribe(data => {
        if (data && data.policyCrossSellPromotions && _.isArray(data.policyCrossSellPromotions) && data.policyCrossSellPromotions.length > 0) {
          data.policyCrossSellPromotions.forEach(element => {
            console.log("xsell data xSellType" + element.crossSellType);
            console.log("xsell data discountPercentage" + element.discountPercentage);
            if (element && element.crossSellType === "HO") {
              this.showHOCrossSellPromotion = true;
            }
          });
        } else {
          console.log("error getting xsell data element" + data);
        }
      });

      if (sessionStorage.getItem("lob") === 'PA') {

        this.utilityProvider.inquiryLoaded$.subscribe(isInquiryLoaded => {
          if(isInquiryLoaded){
        this.policyProvider.getRRDriversDetails(policySelected.policyNumber).subscribe(
          val => {
            console.log(val);

            if (val["errorCode"] == "") {
              console.info(val["data"]);
              let data = val["data"];
              console.info(data["drivers"]);
              let drivers$ = data["drivers"]
              //let product = data["product"]
              var channel = sessionStorage.getItem("channel");
              if (drivers$ != null) {
                for (var driver of drivers$) {
                  if (channel == 'IA' &&
                    driver.activationCode != null &&
                    driver.registerDate == null) {
                    this.validRRLetsGo = true;
                  } else if (channel == 'IA' &&
                    driver.activationCode == null &&
                    driver.registerDate == null) {
                    this.validRRLetsGo = false;
                    this.validRRdisplay = true;
                  }
                }
              }
              if (this.validRRLetsGo == true) {
                this.validRRdisplay = false;
              }
              this.roadRewardsDriverLoaded.next(true);
            } else {
              sessionStorage.setItem('showRRSubMenu', "N");

            }
          }
        );
      }
        })

      }
      this.rightRailService.getEdocs(policySelected.policyNumber).subscribe(eDocs => {
        this.showEdocsPromotion = eDocs;// && sessionStorage.getItem(CURRENT_POLICY_STATUS) !== 'Canceled';
      });

      zip(this.policyBroadcaster.billingLoaded$, this.policyBroadcaster.policyLoaded$)
        .subscribe(response => {
          this.noicNotification$.next(null);
          let [billing, policy] = response;
          if (billing && policy && (policy.policyDetails.product.policyNumber === sessionStorage.getItem(CURRENT_POLICY))) {
            let {
              policyDetails: {
                product: {
                  edocPromise,
                  lobCode,
                  state,
                  cancelPendingDate
                }
              },
              rightRail: {
                quickLinks: {
                  isEFT,
                  isREC,
                  isNOIC,
                  isPayRollDeduct,
                  productDesciption
                }, showEdocs
              }
            } = policy;

            let amount = Number(billing.nextPaymentAmnt);
            if (!isNOIC && !isEFT && !isREC && billing.nextPaymentDueDt && amount) {
              let invoiceDate = new Date(billing.nextPaymentDueDt);
              invoiceDate.setDate(invoiceDate.getDate() + 1);
              let today = new Date();
              if (today > invoiceDate && amount > 0 && !isPayRollDeduct) {
                let pastDueNotification = {
                  data: {
                    productDesciption,
                    amount,
                    dueDate: new Date(billing.nextPaymentDueDt)
                  },
                  type: 'WARN'
                }
                this.pastDueNotification$.next(pastDueNotification);
              } else {
                this.pastDueNotification$.next(null);
              }
            }

            if (edocPromise === 'P' && showEdocs) {
              let edocsNotification = {
                data: {
                  discountType: lobCode === 'HO' && state === 'PA' ? 'savings' : 'discount',
                  productDesciption
                },
                type: 'WARN'
              }
              this.eDocNotification$.next(edocsNotification);
            } else {
              this.eDocNotification$.next(null);
            }

            if (isNOIC) {
              let noicNotification = {
                data: {
                  cancellationDate: sessionStorage.getItem("policyInstance") == "PPRO" ? new Date(billing.nextPaymentDueDt) : new Date(cancelPendingDate), //QC 111044
                  productDesciption,
                  amount,
                  dueDate: new Date(billing.nextPaymentDueDt)
                },
                type: 'WARN'
              }
              this.noicNotification$.next(noicNotification);
            } else {
              this.noicNotification$.next(null);
            }
          }
        });
    });
    setTimeout(() => {
      this.showRoadRewards = sessionStorage.getItem("showedRoadRewards") == "Y" ? false : true;
    }, 2000);


  }

  cancelRoadReawds() {
    this.showRoadRewards = false;
    sessionStorage.setItem("showedRoadRewards", "Y");
  }
  openRRPage() {

    this.showRoadRewards = false;
    sessionStorage.setItem("showedRoadRewards", "Y");
    this.router.navigate(['/eservice//home/policy/road-rewards'], { relativeTo: this.route });
  }
  openRoadRewads() {
    this.showRoadRewards = false;
    sessionStorage.setItem("showedRoadRewards", "Y");
    let zip = sessionStorage.getItem("RoadRewardsZip");
    let policyNumber = sessionStorage.getItem("currentPolicy").toUpperCase();
    let state = sessionStorage.getItem("state");

    if (state === "NY") {
      window.open(environment.NY_RR_URL + "/#/?utm_source=eservice&utm_medium=overlay&utm_campaign=rr_enrollment&policyNumber=" + policyNumber + "&zipCode=" + zip, "_blank");
    } else {
      window.open(environment.RR_URL + "/#/?utm_source=eservice&utm_medium=overlay&utm_campaign=rr_enrollment&policyNumber=" + policyNumber + "&zipCode=" + zip, "_blank");
    }
  }

  goToEdocs() {
    this.router.navigate(['documents/manage-edocs'], { relativeTo: this.route });
  };

  togglePopup(): void {
    this.showEdocsPopup = !this.showEdocsPopup;
  }

  setAgentInfo() {
    let agentDetails = sessionStorage.agentInfo;
    if (agentDetails) {
      this.agentInfo = JSON.parse(agentDetails);
      this.generalPhone = sessionStorage.getItem('general-phone');
    }
  }

  closeShowAgentLetUsHelpYouPopUp() {
    this.showAgentLetUsHelpYouPopUp = false;
  }
  showRedirectPopup(link) {
    this.showRedirectPopupFlag = true;
    this.redirectQuickLink = link;
  }

  closeRedirectPopup() {
    this.showRedirectPopupFlag = false;
  }

  redirectSession(path: string, init: string) {
    this.sessionService.transferSession(path, init).subscribe((response) => {
      const _URL = `/eservice/mobile/redirect?session=${response.data}&token=${sessionStorage.getItem('token')}&restoreSession=true`;
      const ua = navigator.userAgent.toLowerCase();
      if (this.mobileApp && /ipad|iphone|ipod/.test(ua)) {
        console.log("Inside iPad")
        window.location.href = encodeURI(_URL);
      } else {
        window.open(encodeURI(_URL), "_blank");
      }
    })
  }

  isRoadRewardsEligible(){
    return sessionStorage.getItem('RoadRewardsZip')!==null;
  }

}
