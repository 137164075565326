import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormValidatorService, RegistrationService, UtilityProviderService, AiEnrollmentService } from '@prcins/utils';
import { ClaimsBasicService } from "libs/claims/src/lib/claims-basic.service";
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { USER_ID, USER_LOGIN, TOKEN } from '@prcins/constants';
import * as moment from 'moment';

@Component({
  selector: 'prcins-ai-complete-enrollment',
  templateUrl: './ai-complete-enrollment.component.html',
  styleUrls: ['./ai-complete-enrollment.component.css']
})
export class AiCompleteEnrollmentComponent implements OnInit {

  @Input() invitationDetails;
  showSpinner = false;
  alreadyRegisteredAccountPopup = false;
  registrationError = false;
  errorMessage: string;
  userPolicyDetailsResp: any;
  eDocEnrollmentLandingForm = this.utilityProvider.getEDocEnrollmentLandingForm();
  registeredAccountAlreadyExists: string;
  verificationId: string;
  showCreatePasswordForm = false;
  showEdocPromisedDisclaimer = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private formValidator: FormValidatorService,
    private utilityProvider: UtilityProviderService,
    private claimsBasicService: ClaimsBasicService,
    private registrationProvider: RegistrationService,
    private aiEnrollmentService: AiEnrollmentService) { }

  ngOnInit() {
    sessionStorage.setItem('passwordActionType', 'Create');
    this.verificationId = sessionStorage.getItem('verificationId');

    if (this.invitationDetails && !this.invitationDetails.edocEnrollmentAllowed) {
      this.eDocEnrollmentLandingForm.removeControl('enrollEDOC');
    }

	if (this.invitationDetails && this.invitationDetails.edocEnrollmentAllowed && this.invitationDetails.defaultEdoc && typeof this.invitationDetails.defaultEdoc === 'string') {
      if (this.invitationDetails.defaultEdoc=== 'PROMISE') {
        this.showEdocPromisedDisclaimer = true;
      }
      this.eDocEnrollmentLandingForm.patchValue({
        enrollEDOC: (this.invitationDetails.defaultEdoc=== 'PROMISE'? 'Y' : 'N')
      });
    }

    if (this.invitationDetails && this.invitationDetails.prePopulateEmail && this.invitationDetails.emailAddress) {
      this.eDocEnrollmentLandingForm.controls['userName'].setValue(this.invitationDetails.emailAddress);
    }

    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        if (sessionStorage.getItem('verificationId')) {
          this.aiEnrollmentService.abandonRequest(this.invitationDetails.landingPage, this.verificationId).subscribe((resp) => {
            this.registrationProvider.invalidateEndorsementSession();
          });
        }
      }
    });
  }

  onSubmit() {
    if (this.eDocEnrollmentLandingForm.valid) {
      this.showSpinner = true;
      let formValue = this.eDocEnrollmentLandingForm.value;
      formValue.verificationId = this.verificationId;
      if (this.invitationDetails && this.invitationDetails.edocEnrollmentAllowed) {
        if (formValue.enrollEDOC === 'Y') {
          formValue.enrollEDOC = true;
        } else {
          formValue.enrollEDOC = false;
        }
      }
      formValue.dob = moment(formValue.dob).format('YYYY-MM-DD');
      delete formValue.confirmPassword;

      const payload = {
        "policyNumber": "",
        "agreementType": "GENERALTC",
        "agreementType2": "ELECTRONICCONSENT",
        "source": "ESERVICE",
        "email": formValue.userName
      }
      this.claimsBasicService.eDiscloserAudit(payload).subscribe();


      this.aiEnrollmentService
        .completeEnrollment(formValue)
        .subscribe((resp: any) => {
          if (resp.messageCode === 'REGISTRATION_SUCCESS') {
            resp.userId = formValue.userName;
            sessionStorage.setItem(USER_LOGIN, 'Y');
            sessionStorage.setItem(USER_ID, formValue.userName);
            sessionStorage.setItem(TOKEN, resp.token);
            sessionStorage.setItem('randomNumber', Math.random().toString());
            this.registrationProvider.invalidateEndorsementSession();
            this.registrationProvider.userRegistrationForm$.next(null);
            this.showSpinner = false;
            let routePath = ['eservice/home'];
            if(this.route.snapshot.queryParams && this.route.snapshot.queryParams.redirectType && this.route.snapshot.queryParams.redirectType === 'claimdetails' && this.route.snapshot.queryParams.claimid) {
              sessionStorage.setItem("currentPolicy",this.route.snapshot.queryParams.pn);
              routePath = ['eservice/home/claims/'+this.route.snapshot.queryParams.claimid];
              this.router.navigate(routePath,{ queryParams: { restoreSession: 'true' } } );
            } else {
              this.router.navigate(routePath);
            }
            
            
          } else if (resp.messageCode === 'DOB_NOT_VERIFIED') {
            this.registrationError = true;
            this.errorMessage = 'Oops! Date of Birth is incorrect. Please try that again.';
          } else if (resp.messageCode === 'ACCOUNT_EXISTS') {
            this.alreadyRegisteredAccountPopup = true;
          } else if (resp.messageCode === 'VERIFICATION_FAILED' || resp.messageCode === 'PASSWORD_NOT_UPDATED' || resp.messageCode === 'PASSWORD_INVALID' || resp.messageCode === 'REGISTRATION_UNSUCCESS') {
            this.registrationError = true;
            this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
          } else {
			this.registrationError = true;
            this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
		  }
          this.showSpinner = false;
        }, (error: HttpErrorResponse) => {
          this.showSpinner = false;
        });
    } else {
      this.formValidator.validateAllFormFields(this.eDocEnrollmentLandingForm);
    }
  }

  registeredAccount(event) {
    if (event.value) {
      this.registeredAccountAlreadyExists = event.value;
    }
  }

  alreadyRegisteredAccount() {
    if (this.registeredAccountAlreadyExists === 'EXISTING') {
      this.router.navigate(['../login'], {
        relativeTo: this.route,
        state: {
          email: this.eDocEnrollmentLandingForm.value.userName
        }
      });
    } else if (this.registeredAccountAlreadyExists === 'NEW_POLICY') {
      this.router.navigate(['/eservice/link-policies'], {
        relativeTo: this.route,
        state: {
          email: this.eDocEnrollmentLandingForm.value.userName
        }
      });
    }
  }

}
