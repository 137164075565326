<div class="min-h-screen relative">
  <header class=" lg:flex xl:flex p-4 bg-es-blue justify-between items-center border-t-4 border-es-orange">
    <a href="{{mainPage}}" target="_blank">
      <img src="assets/svgs/brands/{{brand}}/header-logo.svg">
    </a>
  </header>
  <main class="flex justify-center w-full ">
    <div class=" w-full flex justify-between flex-wrap px-8 pb-24 lg:px-40">
      <section class=" w-full">
        <router-outlet></router-outlet>
        
        <h1 class="es-h1 mt-4">Driver Information Submitted</h1>
        <p>Thanks for verifying all the drivers on your policy renewal.  You are all set, you may close this page!</p>
        <div class="pt-4">
          <div *ngFor="let driver of driverData.driverDetails; let i = index">
            <div >
              <div class="flex bg-es-gray text-white px-4 py-3 items-center justify-between rounded-t">
                <div class="font-bold flex items-center">
                  <span header-left>
                    <div>Driver {{i + 1}}</div>
                  </span>
                </div>
              </div>
              <div class="border rounded border-gray-300 mb-8">

                <div class="flex flex-wrap lg:flex-row px-4 pb-3">
                  <div class="flex flex-wrap lg:flex-col w-full lg:w-2/12">
                    <div class="add-form-label">First Name</div>
                    <div class="w-full">
                      {{driver.firstName}}
                    </div>
                  </div>
                  <div class="flex flex-wrap lg:flex-col w-full lg:w-2/12">
                    <div class="add-form-label">Last Name</div>
                    <div class="w-full">
                      {{driver.lastName}}
                    </div>
                  </div>
                  <div class="flex flex-wrap lg:flex-col w-full lg:w-2/12">
                    <div class="add-form-label">Date of Birth</div>
                    <div class="w-full">
                      {{driver.dob}}
                    </div>
                  </div>
                  <div class="flex flex-wrap lg:flex-col w-full lg:w-2/12">
                    <div class="add-form-label">License Status</div>
                    <div class="w-full">
                      {{driver.licenseStatus}}
                    </div>
                  </div>
                  <div class="flex flex-wrap lg:flex-col w-full lg:w-2/12">
                    <div class="add-form-label">License State</div>
                    <div class="w-full">
                      {{driver.licenseState}}
                    </div>
                  </div>
                  <div class="flex flex-wrap lg:flex-col w-full lg:w-2/12">
                    <div class="add-form-label">License Number</div>
                    <div class="w-full">
                      {{driver.licenseNumber}}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
    </div>
  </main>
<prcins-footer></prcins-footer>
</div>

<!-- <prcins-footer></prcins-footer> -->