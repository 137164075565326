import { Component, Input } from '@angular/core';

@Component({
  selector: 'prcins-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {
  @Input() headerClass: string;
  @Input() pt = "pt-3";
}
