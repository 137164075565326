import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'prcins-intermediate-registration-complete',
  templateUrl: './intermediate-registration-complete.component.html',
  styleUrls: ['./intermediate-registration-complete.component.css']
})

export class IntermediateRegistrationCompleteComponent implements OnInit {

  
  destinationLandingRoute: string;

  continueButtonLabel: string;

  constructor( private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      if (params['sourcePage'] === 'QPAY_ENTRY') {
        this.destinationLandingRoute = 'eservice/home/payment/entry';
        this.continueButtonLabel = 'Continue to Payment';
      } else {
        this.destinationLandingRoute = 'eservice/home';
        this.continueButtonLabel = 'Continue to Your Account'
      }
    });
  }

  ngOnInit() {
  }

  onContinueToEserviceRoute() {
    const routePath = [this.destinationLandingRoute];
    this.router.navigate(routePath);
  }
  
}
