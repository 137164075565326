<prcins-card headerClass="bg-es-blue text-white" *ngIf="billingDetails$ | async as billingDetails; else loading">
    <span header-left>Billing & Payments</span>
    <div body class="mx-4 my-2">
        <prcins-pending-payments (hasPendingPayments)="showLastPaymentsSection($event)"
            [currentPaymentDetails]="paymentDetails" [currentPayPlan]="payPlan"></prcins-pending-payments>

        <ng-template #payRollCheck>
            <ng-container *ngIf="billingDetails.messageCode==='PAY_ROLL_DEDUCT'; else notFound">
                <div class="flex justify-between font-bold mt-4">
                    <span>If you pay your premium through payroll deduction, please contact your agent with any billing
                        questions.</span>
                </div>
            </ng-container>
        </ng-template>
        <ng-template #notFound>
            <ng-container *ngIf="policyStatus$ | async as policyStatus; else nestedLoading">
                <ng-container *ngIf="policyIssuance$ | async as policyIssuance; else nestedLoading">
                    <div class="px-4 py-4 border rounded bg-es-sky-blue">
                        <div class=" flex justify-between" *ngIf="policyStatus.inactive">
                            <span>
                                We're sorry, your billing information is no longer available for this term. You can view
                                and print past bills and notices <a class="mt-8 w-32 sm:w-48 text-center text-blue-700"
                                    [routerLink]="['../documents/policy-documents']">here.</a>
                            </span>
                        </div>
                        <div class="flex justify-between" *ngIf="policyIssuance.isNewPolicy">
                            <span>Billing details for your <prcins-policy-type-resolver [lob]="lob"
                                    [productCode]="productCode">
                                </prcins-policy-type-resolver> policy are not available yet. Please check back in 24
                                hours.</span>
                        </div>
                        <div class="flex justify-between"
                            *ngIf="!policyStatus.inactive && !policyIssuance.isNewPolicy && billingDetails.messageCode==='NOT_FOUND'">
                            <span>We're sorry, your policy and billing information are no longer available. You can view
                                and
                                print past documents like Bills and Dec pages <a
                                    class="mt-8 w-32 sm:w-48 text-center text-blue-700"
                                    [routerLink]="['../documents/policy-documents']">here.</a></span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-template>
        <ng-container *ngIf="billingDetails.messageCode==='FOUND'; else payRollCheck">
            <div class="px-8 py-4 border rounded bg-es-sky-blue"
                *ngIf="showPaymentSection && billingDetails.nextPaymentAmnt && billingDetails.nextPaymentAmnt >0 && !paymentDetails.pendingCancellation">
                <div class="flex justify-between flex-wrap-reverse flex-col-reverse sm:flex-row">
                    <div class="flex flex-col items-center sm:items-start">
                        <span class="font-bold">{{onSheduledPayPlan? 'Scheduled For' : 'Next Payment Due'}}</span>
                        <span class="sm:mt-4">{{(noic && noic.data.cancellationDate? noic.data.cancellationDate :
                            billingDetails.nextPaymentDueDt) | date : 'EEEE MM/dd/yyyy'}}</span>
                    </div>
                    <div class="flex flex-col items-center sm:items-end">
                        <span class="font-bold">Payment Amount</span>
                        <span class="text-es- text-3xl mb-4 sm:mb-0">{{billingDetails.nextPaymentAmnt | currency :
                            'USD'}}</span>
                    </div>
                </div>
            </div>

            <div class="mt-4 sm:px-16">

                <a *ngIf="showPaymentSection && billingDetails.nextPaymentAmnt && billingDetails.nextPaymentAmnt >0"
                    [routerLink]="['../payment/entry']" [queryParams]="{m:'2'}"
                    class="es-link-primary">{{onSheduledPayPlan? ((!paymentDetails.pendingCancellation)?'Make an
                    Additional Payment':'Pay Now') : 'Make Payment'}}</a>

            </div>
            <!-- <ng-container *ngIf="billingDetails.pastDueAmnt!='0'">
                <div class="flex justify-between font-bold mt-4">
                    <span>Previous Balance</span>
                    <span>{{billingDetails.pastDueAmnt | currency : 'USD'}}</span>
                </div>
                <div class="border-b-2 py-2">(Period: Pending to Pending)</div>
            </ng-container> -->
            <ng-container *ngIf="showPaymentSection && billingDetails.balance!='0.00'; else noBalance">
                <div class="flex justify-between font-bold mt-4">
                    <span>Balance</span>
                    <span>{{billingDetails.balance | currency : 'USD'}}</span>
                </div>
                <div class="py-2">(For coverage through {{billingDetails.expiryDate | date : 'MM/dd/yyyy'}})
                </div>
                <ng-container>
                    <div *ngIf="showBalaceDeatilsLink" class="text-blue-700 py-2"><a
                            [routerLink]="['../balance/details']">View Balance Details</a></div>
                </ng-container>

                <div class="border-b-2"></div>
            </ng-container>

            <ng-template #noBalance *ngIf="showPaymentSection">
                <div class="flex justify-between font-bold mt-4">
                    <span> As of today, your <prcins-policy-type-resolver [lob]="lob" [productCode]="productCode">
                        </prcins-policy-type-resolver> policy is paid up until
                        {{billingDetails.expiryDate | date : 'MM/dd/yyyy'}}</span>
                </div>
                <div class="border-b-2 py-2">(For coverage through {{billingDetails.expiryDate | date : 'MM/dd/yyyy'}})
                </div>

            </ng-template>

            <ng-container *ngIf="showPaymentSection && billingDetails?.lastPaymentAmnt">
                <div class="flex justify-between font-bold mt-4">
                    <span>Last payment of</span>
                    <span>{{billingDetails.lastPaymentAmnt | currency : 'USD'}}</span>
                </div>
                <div class="flex justify-between py-2">
                    <span>Paid</span>
                    <span>{{billingDetails.lastPaymentDt | date : 'MM/dd/yyyy'}}</span>
                </div>
                <div class="text-blue-700 py-2"><a [routerLink]="['../billing/history']">View Payment
                        History</a>
                </div>
            </ng-container>
            <!-- <div class="flex justify-between mt-4 ">
                <span class="font-bold w-1/3 text-left">Pay Plan</span>
                <span class="text-right">{{billingDetails.payPlanLabel}} <a *ngIf="isCPPAllowed"
                        [routerLink]="['../billing/change-plan']" class="ml-2 text-blue-700">Edit</a></span>
            </div> -->

            <span *ngIf="eligibleForTXAlertsEnrollment" class="flex flex-col items-center pt-4 border-t-2">
                <p class="flex text-es-blue-light pb-2 text-center font-bold"><img class="mr-2 p-1" style="height:23px"
                        src="assets/svgs/home-alert-bell.svg" alt="">
                    <a  [routerLink]="['../account/manage-alerts']">Manage
                        Email
                        and Text Alerts</a>
                </p>
            </span>
        </ng-container>
    </div>
</prcins-card>

<ng-template #loading>
    <prcins-card pt="pt-0">
        <div body>
            <prcins-skeleton numOfRows="10"></prcins-skeleton>
        </div>
    </prcins-card>
</ng-template>

<ng-template #nestedLoading>
    <prcins-skeleton numOfRows="10"></prcins-skeleton>
</ng-template>