import { Component, OnInit } from '@angular/core';
import { PolicyProviderService } from '../services/policy-provider.service';
import { CURRENT_POLICY } from '@prcins/constants';

@Component({
  selector: 'prcins-balance-details',
  templateUrl: './balance-details.component.html',
  styleUrls: ['./balance-details.component.css']
})
export class BalanceDetailsComponent implements OnInit {
  loadFailed: boolean;
  balanceDetails: any;
  showBalance: boolean;
  currentTermBalance: any;
  futureTermBalance: any;

  constructor(private billingProvider: PolicyProviderService) { }

  ngOnInit() {
    let policyNumber = sessionStorage.getItem(CURRENT_POLICY);
    this.billingProvider.balanceDetails(policyNumber).subscribe(data => {
      this.showBalance = true;
      this.balanceDetails = data["data"]["balanceDetails"];
      this.currentTermBalance = this.balanceDetails ? (this.balanceDetails["currentTermBalance"] - this.balanceDetails["pastTermBalance"]) : 0;
      this.futureTermBalance = this.balanceDetails ? (this.balanceDetails["futureTermBalance"] - this.balanceDetails["currentTermBalance"]) : 0;
    }, error => {
      this.loadFailed = true;
      this.showBalance = true;
    });
  }

}
