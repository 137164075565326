<div class="md:min-h-screen relative">
  <div class="flex flex-col items-center w-full es-app-container">
    <prcins-welcome-banner class="w-full"></prcins-welcome-banner>
    <div class="desktop-only-container">
      <main class="px-8 pb-8 pt-4">
        <h1 class="es-h1 mt-4 text-center">Hello, great to see you here!</h1>
        <div class="flex justify-center"><img src="assets/svgs/new-profile-incomplete-status-icon.svg"></div>
        <p class="font-semibold text-center mt-4 mb-4">We’re working to get your online account ready for you.</p>
        <p class="text-center px-38">Once you receive a new policy declarations page, you can log back in to take
          advantage of our online services.</p>
      </main>
    </div>
  </div>
</div>
