export class Wallet {
    public walletId: number;
    public paymentMethodId: string;
    public debitAccount: string;
    public creditAccount: string;
    public accountEmail: string;
    public paymentType: string;
    public transType: string;
    public defaultInd: string;
    public defaultImage: string;
    public modifiedDate: string;
    public accountNickName: string;
}

export class Card extends Wallet {
    public expirationMonth: number;
    public expirationYear: number;
    public expirationDate: string;
    public expiration: string;
    public cardType: string;
    public imageUrl: string;
    public cardDisplayType: string;
    public cvv: number;
    public debitZip: string;
}

export class Bank extends Wallet {
    public debitRouting: number;
    public accountType: string;
}
