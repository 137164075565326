/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-banner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./mobile-banner.component";
var styles_MobileBannerComponent = [i0.styles];
var RenderType_MobileBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileBannerComponent, data: {} });
export { RenderType_MobileBannerComponent as RenderType_MobileBannerComponent };
export function View_MobileBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "sm:flex md:flex flex-col w-full lg:hidden xl:hidden relative mb-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "welcome-banner flex justify-center pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "welcome-banner"], ["class", "banner-image"], ["src", "assets/svgs/banner-mobile.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "flex justify-center absolute w-full top-40"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "welcome-icon"], ["class", "welcome-icon"], ["src", "assets/svgs/welcome-icon.svg"]], null, null, null, null, null))], null, null); }
export function View_MobileBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-mobile-banner", [], null, null, null, View_MobileBannerComponent_0, RenderType_MobileBannerComponent)), i1.ɵdid(1, 49152, null, 0, i2.MobileBannerComponent, [], null, null)], null, null); }
var MobileBannerComponentNgFactory = i1.ɵccf("prcins-mobile-banner", i2.MobileBannerComponent, View_MobileBannerComponent_Host_0, {}, {}, []);
export { MobileBannerComponentNgFactory as MobileBannerComponentNgFactory };
