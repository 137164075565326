/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./primary.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./primary.component";
var styles_PrimaryComponent = [i0.styles];
var RenderType_PrimaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrimaryComponent, data: {} });
export { RenderType_PrimaryComponent as RenderType_PrimaryComponent };
export function View_PrimaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "bg-blue-200 p-4 w-full text-black rounded shadow-lg mb-4"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_PrimaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-primary", [], null, null, null, View_PrimaryComponent_0, RenderType_PrimaryComponent)), i1.ɵdid(1, 114688, null, 0, i2.PrimaryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrimaryComponentNgFactory = i1.ɵccf("prcins-primary", i2.PrimaryComponent, View_PrimaryComponent_Host_0, {}, {}, ["*"]);
export { PrimaryComponentNgFactory as PrimaryComponentNgFactory };
