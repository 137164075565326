<ng-container *ngIf="!isTimedOut; else timedOut">
  <div class="skelton">
    <div *ngFor="let item of looper" class="line">
    </div>
  </div>
</ng-container>
<ng-template #timedOut>
  <prcins-notification *ngIf="!hideTimeoutMessage" [notification]="notification" [pinned]="true">
    Oops! We seem to be having a problem. Please try again later or contact us for help.
  </prcins-notification>

  <!--     
  <span class="opacity-75">
    <span class="flex justify-center items-center cursor-pointer">
      <img src="assets/png/reload.png" alt="reload" (click)="onReload()" class="w-8">
    </span>
  </span>-->
</ng-template>