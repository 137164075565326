import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DateTimeValidators, FormValidatorService, RegistrationService, EservicePromotionBroadcasterService } from "@prcins/utils";
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { USER_ID, USER_LOGIN, TOKEN, CURRENT_POLICY_CHANNEL, CURRENT_POLICY, POLICY_ZIP_CODE } from '@prcins/constants';
import * as moment from 'moment';

@Component({
  selector: 'prcins-intermediate-registeration-form',
  templateUrl: './intermediate-registeration-form.component.html',
  styleUrls: ['./intermediate-registeration-form.component.css']
})
export class IntermediateRegisterationFormComponent implements OnInit {

  registrationError = false;
  errorMessage;
  showEdocPromisedDisclaimer = true;

  maskPassword = false;
  actionType: string;

  passwordForm: FormGroup = this.fb.group({
    userName: ['', [Validators.required, Validators.pattern(
      '^[A-Za-z0-9._+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
    )]],
    dob: ['', [Validators.required, DateTimeValidators.ValidDate(), DateTimeValidators.validateAge(), DateTimeValidators.noFutureDate(), DateTimeValidators.minDate(100, "years")]],
    password: ['', [Validators.required, Validators.pattern('^(?=[^a-z]*[a-z])(?=\\D*\\d)[A-Za-z\\d!$%@#£€*?&]{7,}$')]],
    confirmPassword: [
      '',
      {
        validators: [Validators.required, this.passwordValidator('password')],
        updateOn: 'blur'
      }
    ],
    enrollEDOC: ['Y', [Validators.required]],
    //enrollBillingTextAlert: ['', [Validators.required]],
    //billingTextAlertPhoneNumber
  })
  showSpinner = false;
  timesChecked = 1;
  showBillingAlertEnrollSection = false;
  alreadyRegisteredAccountPopup = false;
  registeredAccountAlreadyExists: string;


  @Input() set registrationEmailAddress(emailAddress: string) {
    this.passwordForm.patchValue({
      userName: emailAddress
    });
  };

  @Input() set showBillingAlertEnrollment(showBillingAlert: boolean) {
    this.showBillingAlertEnrollSection = showBillingAlert;
    if (showBillingAlert) {
      this.passwordForm.addControl("enrollBillingTextAlert", this.fb.control('', Validators.required));
      this.passwordForm.updateValueAndValidity();
    }
  };

  isNYAIPPolicy(): boolean {
    return sessionStorage.getItem(CURRENT_POLICY).startsWith('NYR');
  }
  @Output() submitted = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
    private formValidator: FormValidatorService,
    private registrationProvider: RegistrationService,
    private eServicePromotionBroadcaster : EservicePromotionBroadcasterService) { }

  ngOnInit() {

    this.actionType = 'Create';
    this.eServicePromotionBroadcaster.broadcastPromotion(true);
    if (this.isNYAIPPolicy() ){
      this.passwordForm.removeControl("enrollEDOC");
    }
  }

  ngOnDestroy() {
    this.eServicePromotionBroadcaster.broadcastPromotion(false);
  }

  onBillingTextAlertEnrollmentSelect(enrollmentValue: string) {
    if (enrollmentValue && enrollmentValue === "Y") {
      this.passwordForm.addControl("billingTextAlertPhoneNumber", this.fb.control('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]));
    } else {
      this.passwordForm.removeControl("billingTextAlertPhoneNumber");
    }
    this.passwordForm.updateValueAndValidity();
    //ChangeDetectorRef.detectChanges();

  }
  ngDoCheck() {
    console.log('checking for ', ++this.timesChecked);
  }

  onSubmit() {
    this.registrationError = false;
    if (this.passwordForm.valid) {
      this.showSpinner = true;
      let formValue = this.passwordForm.value;
      if (formValue.enrollEDOC === 'Y') {
        formValue.enrollEDOC = true;
      } else {
        formValue.enrollEDOC = false;
      }

      if (formValue.enrollBillingTextAlert === 'Y') {
        formValue.enrollBillingTextAlert = true;
      } else {
        formValue.enrollBillingTextAlert = false;
      }

      let policyNumber = sessionStorage.getItem(CURRENT_POLICY);
      formValue.policyNumber = policyNumber;
      let policyZip = sessionStorage.getItem(POLICY_ZIP_CODE);
      formValue.zipCode = policyZip;

      formValue.dob = moment(formValue.dob).format('YYYY-MM-DD');
      delete formValue.confirmPassword;
      this.registrationProvider.createQuickPayAccount(formValue).subscribe((resp: any) => {
        if (resp.messageCode === 'REGISTRATION_SUCCESS') {
          resp.userId = formValue.userName;
          sessionStorage.setItem(USER_LOGIN, 'Y');
          sessionStorage.setItem(USER_ID, formValue.userName);
          sessionStorage.setItem(TOKEN, resp.token);
          sessionStorage.setItem('randomNumber', Math.random().toString());
          this.registrationProvider.invalidateEndorsementSession();
          this.registrationProvider.userRegistrationForm$.next(null);


          this.showSpinner = false;
          this.submitted.emit({ responseCode: "REGISTRATION_SUCCESS" });
          //this.updateSafeToNavigate(true);
          //const routePath = ['eservice/home'];
          //this.router.navigate(routePath);
        } else if (resp.messageCode === 'ACCOUNT_EXISTS') {
          this.alreadyRegisteredAccountPopup = true;
        } else if (resp.messageCode === 'PASSWORD_NOT_UPDATED' || resp.messageCode === 'PASSWORD_INVALID' || resp.messageCode === 'REGISTRATION_UNSUCCESS' || resp.messageCode === 'POLICY_ALREADY_REGISTERED') {
          this.registrationError = true;
          this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
        }
        this.showSpinner = false;
      }, (error: HttpErrorResponse) => {
        this.showSpinner = false;
        this.registrationError = true;
        this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
      });

    } else {
      this.formValidator.validateAllFormFields(this.passwordForm);
    }
  }


  hasNumber(str) {
    return /\d/.test(str);
  }

  hasLowerCase(str) {
    return (/[a-z]/.test(str));
  }

  registeredAccount(event) {
    if (event.value) {
      this.registeredAccountAlreadyExists = event.value;
    }
  }

  onContinue() {
    if (this.registeredAccountAlreadyExists === 'EXISTING') {

      this.submitted.emit({ responseCode: "EXISTING", userName: this.passwordForm.value.userName });

    } else if (this.registeredAccountAlreadyExists === 'NEW_POLICY') {

      this.submitted.emit({ responseCode: "NEW_POLICY", userName: this.passwordForm.value.userName });

    }
  }

  passwordValidator(confirmPasswordInput: string) {
    let confirmPasswordControl: FormControl;
    let passwordControl: FormControl;

    return (control: FormControl) => {
      if (!control.parent) {
        return null;
      }

      if (!confirmPasswordControl) {
        confirmPasswordControl = control;
        passwordControl = control.parent.get(
          confirmPasswordInput
        ) as FormControl;
        passwordControl.valueChanges.subscribe(() => {
          confirmPasswordControl.updateValueAndValidity();
        });
      }

      if (!confirmPasswordControl.value) {
        return {
          required: true
        };
      } else if (passwordControl.value !== confirmPasswordControl.value) {
        return {
          notMatch: true
        };
      }
      return null;
    };
  }
}
