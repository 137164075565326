/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mfa-verification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./mfa-verification-options/mfa-verification-options.component.ngfactory";
import * as i3 from "./mfa-verification-options/mfa-verification-options.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/router";
import * as i6 from "../services/form-validator.service";
import * as i7 from "../services/registration.service";
import * as i8 from "./mfa-code-verification/mfa-code-verification.component.ngfactory";
import * as i9 from "./mfa-code-verification/mfa-code-verification.component";
import * as i10 from "../es-popup/es-popup.component.ngfactory";
import * as i11 from "../es-popup/es-popup.component";
import * as i12 from "@angular/common";
import * as i13 from "./mfa-verification.component";
import * as i14 from "../services/mfa.service";
var styles_MfaVerificationComponent = [i0.styles];
var RenderType_MfaVerificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MfaVerificationComponent, data: {} });
export { RenderType_MfaVerificationComponent as RenderType_MfaVerificationComponent };
function View_MfaVerificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "prcins-mfa-verification-options", [], null, [[null, "submitted"], [null, "cancelled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onFormSubmitted($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelled" === en)) {
        var pd_1 = (_co.onCancelClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MfaVerificationOptionsComponent_0, i2.RenderType_MfaVerificationOptionsComponent)), i1.ɵdid(2, 114688, null, 0, i3.MfaVerificationOptionsComponent, [i4.FormBuilder, i5.Router, i5.ActivatedRoute, i6.FormValidatorService, i7.RegistrationService], { userPolicyDetailsResp: [0, "userPolicyDetailsResp"], verificationMode: [1, "verificationMode"], verificationType: [2, "verificationType"], description: [3, "description"] }, { submitted: "submitted", cancelled: "cancelled" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userPolicyDetailsResp; var currVal_1 = _co.verificationMode; var currVal_2 = _co.type; var currVal_3 = _co.description; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_MfaVerificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "prcins-mfa-code-verification", [], null, [[null, "submitted"], [null, "backClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onTokenSubmitted($event) !== false);
        ad = (pd_0 && ad);
    } if (("backClicked" === en)) {
        var pd_1 = (_co.onBackClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_MfaCodeVerificationComponent_0, i8.RenderType_MfaCodeVerificationComponent)), i1.ɵdid(2, 114688, null, 0, i9.MfaCodeVerificationComponent, [i4.FormBuilder, i5.Router, i5.ActivatedRoute, i6.FormValidatorService, i7.RegistrationService], { verificationOptionsForm: [0, "verificationOptionsForm"], userPolicyDetailsResp: [1, "userPolicyDetailsResp"] }, { backClicked: "backClicked", submitted: "submitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.verificationOptionsForm; var currVal_1 = _co.userPolicyDetailsResp; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_MfaVerificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "prcins-es-popup", [["headerClass", "bg-es-blue text-white"]], null, null, null, i10.View_EsPopupComponent_0, i10.RenderType_EsPopupComponent)), i1.ɵdid(1, 49152, null, 0, i11.EsPopupComponent, [], { headerClass: [0, "headerClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["header-left", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, 2, 5, "div", [["body", ""]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MfaVerificationComponent_1)), i1.ɵdid(8, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MfaVerificationComponent_2)), i1.ɵdid(10, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bg-es-blue text-white"; _ck(_v, 1, 0, currVal_0); var currVal_2 = (!_co.isModeSelected && _co.userPolicyDetailsResp); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isModeSelected; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); }); }
export function View_MfaVerificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-mfa-verification", [], null, null, null, View_MfaVerificationComponent_0, RenderType_MfaVerificationComponent)), i1.ɵdid(1, 114688, null, 0, i13.MfaVerificationComponent, [i14.MfaService, i5.Router, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MfaVerificationComponentNgFactory = i1.ɵccf("prcins-mfa-verification", i13.MfaVerificationComponent, View_MfaVerificationComponent_Host_0, { verificationData: "verificationData", type: "type", title: "title", description: "description" }, { cancelled: "cancelled", tokenSubmitted: "tokenSubmitted" }, ["[modifiedData]"]);
export { MfaVerificationComponentNgFactory as MfaVerificationComponentNgFactory };
