/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invitation-landing.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../login/login.component.ngfactory";
import * as i3 from "../login/login.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/router";
import * as i6 from "../../../../utils/src/lib/services/login.service";
import * as i7 from "../../../../utils/src/lib/services/form-validator.service";
import * as i8 from "ngx-cookie-service";
import * as i9 from "../../../../utils/src/lib/services/tealium/tealium-tracker.service";
import * as i10 from "../../../../claims/src/lib/claims-basic.service";
import * as i11 from "../../../../utils/src/lib/services/registration.service";
import * as i12 from "../../../../utils/src/lib/services/mfa.service";
import * as i13 from "../../../../utils/src/lib/services/feature-flag.service";
import * as i14 from "../ai-complete-enrollment/ai-complete-enrollment.component.ngfactory";
import * as i15 from "../ai-complete-enrollment/ai-complete-enrollment.component";
import * as i16 from "../../../../utils/src/lib/services/utility-provider.service";
import * as i17 from "../../../../utils/src/lib/services/ai-enrollment.service";
import * as i18 from "../ai-link-policy/ai-link-policy.component.ngfactory";
import * as i19 from "../ai-link-policy/ai-link-policy.component";
import * as i20 from "../enroll-edocs/enroll-edocs.component.ngfactory";
import * as i21 from "../enroll-edocs/enroll-edocs.component";
import * as i22 from "../expired-policies/expired-policies.component.ngfactory";
import * as i23 from "../expired-policies/expired-policies.component";
import * as i24 from "@angular/common";
import * as i25 from "./invitation-landing.component";
var styles_InvitationLandingComponent = [i0.styles];
var RenderType_InvitationLandingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvitationLandingComponent, data: {} });
export { RenderType_InvitationLandingComponent as RenderType_InvitationLandingComponent };
function View_InvitationLandingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "prcins-login", [], null, null, null, i2.View_LoginComponent_0, i2.RenderType_LoginComponent)), i1.ɵdid(2, 4308992, null, 0, i3.LoginComponent, [i4.FormBuilder, i5.Router, i6.LoginService, i7.FormValidatorService, i5.ActivatedRoute, i8.CookieService, i9.TealiumTrackerService, i10.ClaimsBasicService, i11.RegistrationService, i12.MfaService, i13.FeatureFlagService], { invitationDetails: [0, "invitationDetails"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invitationDetails; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InvitationLandingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "prcins-ai-complete-enrollment", [], null, null, null, i14.View_AiCompleteEnrollmentComponent_0, i14.RenderType_AiCompleteEnrollmentComponent)), i1.ɵdid(2, 114688, null, 0, i15.AiCompleteEnrollmentComponent, [i4.FormBuilder, i5.Router, i5.ActivatedRoute, i7.FormValidatorService, i16.UtilityProviderService, i10.ClaimsBasicService, i11.RegistrationService, i17.AiEnrollmentService], { invitationDetails: [0, "invitationDetails"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invitationDetails; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InvitationLandingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "prcins-ai-link-policy", [], null, null, null, i18.View_AiLinkPolicyComponent_0, i18.RenderType_AiLinkPolicyComponent)), i1.ɵdid(2, 114688, null, 0, i19.AiLinkPolicyComponent, [i4.FormBuilder, i5.Router, i5.ActivatedRoute, i7.FormValidatorService, i10.ClaimsBasicService, i11.RegistrationService, i17.AiEnrollmentService], { invitationDetails: [0, "invitationDetails"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invitationDetails; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InvitationLandingComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "prcins-enroll-edocs", [], null, null, null, i20.View_EnrollEdocsComponent_0, i20.RenderType_EnrollEdocsComponent)), i1.ɵdid(2, 114688, null, 0, i21.EnrollEdocsComponent, [i4.FormBuilder, i7.FormValidatorService, i5.ActivatedRoute, i5.Router, i6.LoginService, i17.AiEnrollmentService, i11.RegistrationService], { invitationDetails: [0, "invitationDetails"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invitationDetails; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InvitationLandingComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "prcins-expired-policies", [], null, null, null, i22.View_ExpiredPoliciesComponent_0, i22.RenderType_ExpiredPoliciesComponent)), i1.ɵdid(2, 114688, null, 0, i23.ExpiredPoliciesComponent, [i11.RegistrationService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_InvitationLandingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i24.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationLandingComponent_2)), i1.ɵdid(4, 278528, null, 0, i24.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i24.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationLandingComponent_3)), i1.ɵdid(6, 278528, null, 0, i24.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i24.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationLandingComponent_4)), i1.ɵdid(8, 278528, null, 0, i24.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i24.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationLandingComponent_5)), i1.ɵdid(10, 278528, null, 0, i24.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i24.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationLandingComponent_6)), i1.ɵdid(12, 278528, null, 0, i24.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i24.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invitationDetails.landingPage; _ck(_v, 2, 0, currVal_0); var currVal_1 = "LOGIN_PAGE"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "CREATE_ACCOUNT"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "LINK_POLICY"; _ck(_v, 8, 0, currVal_3); var currVal_4 = "EDOCS_ENROLLMENT"; _ck(_v, 10, 0, currVal_4); var currVal_5 = "ERROR_PAGE"; _ck(_v, 12, 0, currVal_5); }, null); }
export function View_InvitationLandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationLandingComponent_1)), i1.ɵdid(1, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invitationDetails; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InvitationLandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-invitation-landing", [], null, null, null, View_InvitationLandingComponent_0, RenderType_InvitationLandingComponent)), i1.ɵdid(1, 114688, null, 0, i25.InvitationLandingComponent, [i5.ActivatedRoute, i17.AiEnrollmentService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvitationLandingComponentNgFactory = i1.ɵccf("prcins-invitation-landing", i25.InvitationLandingComponent, View_InvitationLandingComponent_Host_0, {}, {}, []);
export { InvitationLandingComponentNgFactory as InvitationLandingComponentNgFactory };
