import * as i0 from "@angular/core";
export class TealiumUtagService {
    // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
    constructor() {
        this.script_src = '';
        window.utag_cfg_ovrd = { noview: true };
        window.utag_data = {};
    }
    // Generic script loader with callback
    getScript(src, callback) {
        let d = document;
        let o = { callback: callback || function () { } };
        let s, t;
        if (typeof src == 'undefined') {
            return;
        }
        s = d.createElement('script');
        s.language = 'javascript';
        s.type = 'text/javascript';
        s.async = 1;
        s.charset = 'utf-8';
        s.src = src;
        if (typeof o.callback == 'function') {
            if (d.addEventListener) {
                s.addEventListener('load', function () {
                    o.callback();
                }, false);
            }
            else {
                // old IE support
                s.onreadystatechange = function () {
                    if (this.readyState == 'complete' || this.readyState == 'loaded') {
                        this.onreadystatechange = null;
                        o.callback();
                    }
                };
            }
        }
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
    }
    // Config settings used to build the path to the utag.js file
    setConfig(config) {
        if (config.account !== undefined &&
            config.profile !== undefined &&
            config.environment !== undefined) {
            this.script_src =
                'https://tags.tiqcdn.com/utag/' +
                    config.account +
                    '/' +
                    config.profile +
                    '/' +
                    config.environment +
                    '/utag.js' +
                    '?ts=' + new Date().getTime();
        }
    }
    // Data layer is optional set of key/value pairs
    track(tealium_event, data) {
        if (this.script_src === '') {
            console.log('Tealium config not set.');
            return;
        }
        if (window.utag === undefined) {
            this.getScript(this.script_src, function () {
                window.utag.track(tealium_event, data);
            });
        }
        else {
            window.utag.track(tealium_event, data);
        }
    }
    view(data) {
        this.track('view', data);
    }
    link(data) {
        this.track('link', data);
    }
}
TealiumUtagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TealiumUtagService_Factory() { return new TealiumUtagService(); }, token: TealiumUtagService, providedIn: "root" });
