import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telephone'
})
export class TelephonePipe implements PipeTransform {
  transform(value: string): string {
    value += '';
    const numberRegex = value.match(/\d/g);
    const telephoneNumber = numberRegex ? numberRegex.join('') : '';
    let transformedNumber = '';

    if (telephoneNumber.length === 10) {
      transformedNumber =
        '(' +
        telephoneNumber.slice(0, 3) +
        ') ' +
        telephoneNumber.slice(3, 6) +
        '-' +
        telephoneNumber.slice(6, 10);
    }
    return transformedNumber;
  }
}
