<div class="lg:hidden xl:hidden w-full">
  <header class="sm:flex md:flex p-4 bg-es-blue flex justify-between items-center">
    <a href="{{mainPage}}" target="_blank">
      <img src="assets/svgs/brands/{{brand}}/mobile-logo.svg">
    </a>
    <a (click)="toggleNavMenu()">
      <img *ngIf="!showNavigationPanel" src="assets/svgs/hamburger.svg">
      <img *ngIf="showNavigationPanel" src="assets/svgs/close-hamburger.svg">
    </a>
  </header>
  <div *ngIf="showNavigationPanel" class="bg-es-blue text-white">
    <a class="es-mobile-header-menu justify-start items-center" [routerLink]="['/eservice/login']"><img class="mr-2"
        src="assets/svgs/logout.svg" alt="">Login</a>
    <a class="es-mobile-header-menu justify-start items-center" [routerLink]="['/qpay/login']"><img class="mr-2"
        src="assets/svgs/payment-icon.svg" alt="">Quick Pay</a>
    <a class="es-mobile-header-menu justify-start items-center" href="{{efnolURL}}"
      target="_blank"><img class="mr-2" src="assets/svgs/claims-icon.svg" alt="">Report a Claim</a>
    <a class="es-mobile-header-menu justify-start items-center border-b" href="{{contactUs}}"
      target="_blank"><img class="mr-2" src="assets/svgs/mailing-icon.svg" alt="">Contact Us</a>
  </div>
</div>