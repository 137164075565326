import { OnInit, OnDestroy } from '@angular/core';
import { PolicyBroadcasterService } from '../services/policy-broadcaster.service';
import { CURRENT_POLICY } from '@prcins/constants';

export abstract class PolicyBaseComponent implements OnInit, OnDestroy {
  broadcastSubscription;
  loaded = false;

  constructor(private policyBroadcasterP: PolicyBroadcasterService) {}

  ngOnInit() {
    if (!this.loaded) {
      this.loaded = true;
      this.loadUiData(sessionStorage.getItem(CURRENT_POLICY));
      this.broadcastSubscription = this.policyBroadcasterP.policySelected$.subscribe(
        policy => {
          this.loadUiData(policy.policyNumber);
        }
      );
    }
  }

  abstract loadUiData(policyNumber);

  ngOnDestroy(): void {
    if (this.broadcastSubscription) {
      this.broadcastSubscription.unsubscribe();
    }
  }
}
