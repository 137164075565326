<form class="mt-3" [formGroup]="eftForm">
  <div *ngIf="eftForm.get('accountHolderName')">
    <div class="mb-2"><label>Account Holder Name</label></div>
    <div class="flex"><input class="es-textfield flex-grow" type="text" trimInput formControlName="accountHolderName"
        prcins-tealium-tracker="blur" prcins-tealium-event="payment_holder_name"></div>
    <prcins-error-label [control]="eftForm.get('accountHolderName')"
      [controlValue]="eftForm.get('accountHolderName').value" controlLabel="eftForm_accountHolderName"
      [controlTouched]="eftForm.get('accountHolderName').touched">
    </prcins-error-label>
  </div>

  <div class="mt-4" *ngIf="eftForm.get('accountEmailId')">
    <div class="mb-2"><label>Email Address</label></div>
    <div class="flex"><input class="es-textfield flex-grow" type="text" trimInput formControlName="accountEmailId"
        prcins-tealium-tracker="blur" prcins-tealium-event="payment_holder_email"></div>
    <prcins-error-label [control]="eftForm.get('accountEmailId')" [controlValue]="eftForm.get('accountEmailId').value"
      controlLabel="eftForm_accountEmailId" [controlTouched]="eftForm.get('accountEmailId').touched">
    </prcins-error-label>
  </div>

  <div class="mt-4">
    <div class="mb-2"><label>Account Type</label></div>
    <span class="inline-block border border-r-0 rounded-l-lg p-3 cursor-pointer {{checkingSelectedCls}}"
      (click)="checkingSelected()">Checking</span>
    <span class="inline-block border rounded-r-lg p-3 cursor-pointer {{savingsSelectedCls}}"
      (click)="savingSelected()">Savings</span>
    <prcins-error-label [control]="eftForm.get('accountType')" [controlValue]="eftForm.get('accountType').value"
      controlLabel="eftForm_accountType" [controlTouched]="eftForm.get('accountType').touched">
    </prcins-error-label>
  </div>

  <div class="mt-4">
    <div class="mb-2"><label>Account Number</label></div>
    <div class="flex"><input class="es-textfield flex-grow prcins-mask-data" [class.custom_error]="accountMismatchError" type="text"
        prcinsOnlyNumber formControlName="maskedAccountNumber" maxlength="17"
        (focus)="handleMaskOnFocus('maskedAccountNumber', 'accountNumber')"
        (blur)="handleMaskOnBlur('maskedAccountNumber', 'accountNumber')" prcins-tealium-tracker="blur"
        prcins-tealium-event="payment_account_number"  autocomplete="off"><img class="ml-2 cursor-pointer"
        src="assets/svgs/question-round-blue.svg" alt="" (click)="showHelpText('accountNumber')"></div>
    <prcins-error-label [control]="eftForm.get('accountNumber')" [controlValue]="eftForm.get('accountNumber').value"
      controlLabel="eftForm_accountNumber" [controlTouched]="eftForm.get('accountNumber').touched">
    </prcins-error-label>
  </div>

  <div class="mt-4">
    <div class="mb-2"><label>Re-Enter Account Number</label></div>
    <div class="flex"><input class="es-textfield flex-grow prcins-mask-data" [class.custom_error]="accountMismatchError" type="text"
        prcinsOnlyNumber formControlName="maskedReAccountNumber" maxlength="17"
        (focus)="handleMaskOnFocus('maskedReAccountNumber', 'reAccountNumber')"
        (blur)="handleMaskOnBlur('maskedReAccountNumber', 'reAccountNumber')" prcins-tealium-tracker="blur"
        prcins-tealium-event="payment_account_number_reenter"  autocomplete="off"></div>
    <prcins-error-label [control]="eftForm.get('reAccountNumber')" [controlValue]="eftForm.get('reAccountNumber').value"
      controlLabel="eftForm_reAccountNumber" [controlTouched]="eftForm.get('reAccountNumber').touched">
    </prcins-error-label>
    <p *ngIf="accountMismatchError" class="es-error-field mt-2">The account numbers do not match</p>
  </div>

  <div class="mt-4">
    <div class="mb-2"><label>Routing Number</label></div>
    <div class="flex"><input class="es-textfield flex-grow prcins-mask-data" type="text" trimInput type="text" trimInput
        [class.custom_error]="routingError" prcinsOnlyNumber formControlName="maskedRoutingNumber" maxlength="9"
        (focus)="handleMaskOnFocus('maskedRoutingNumber', 'routingNumber')"
        (blur)="handleMaskOnBlur('maskedRoutingNumber', 'routingNumber', 2)" prcins-tealium-tracker="blur"
        prcins-tealium-event="payment_routing_number"  autocomplete="off"><img class="ml-2 cursor-pointer"
        (click)="showHelpText('routingNumber')" src="assets/svgs/question-round-blue.svg" alt=""></div>
    <prcins-error-label [control]="eftForm.get('routingNumber')" [controlValue]="eftForm.get('routingNumber').value"
      controlLabel="eftForm_routingNumber" [controlTouched]="eftForm.get('routingNumber').touched">
    </prcins-error-label>
  </div>

  <ng-container *ngIf="isChangePayPlan">
    <div class="mt-4 flex items-center">
      <prcins-square-checkbox [sliderValue]="eftForm.get('plan_terms').value" sliderKey="plan_terms"
        (sliderChanged)="onTermsChanged($event)">
      </prcins-square-checkbox>
      <label class="ml-2 font-bold">
        I agree to the Automatic Payments (EFT) <a href="https://www.plymouthrock.com/customer-service/manage-policy/autopay-eft/terms"
          target="_blank" class="text-blue-600">terms and conditions</a> and I <a
          href="https://www.plymouthrock.com/internet-policy/consent-to-conduct" target="_blank"
          class="text-blue-600">Consent to Conduct Business Electronically</a>.
      </label>
    </div>
    <prcins-error-label [control]="eftForm.get('plan_terms')" [controlValue]="eftForm.get('plan_terms').value"
      controlLabel="eftForm_terms" [controlTouched]="eftForm.get('plan_terms').touched">
    </prcins-error-label>
  </ng-container>

</form>
<prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="helpFlags.accountNumber">
  <div header-left>
    <span>Account Number</span>
  </div>
  <div header-right>
    <img class="cursor-pointer" height='15px' width='15px' (click)="hideHelpText()"
      src="assets/svgs/close-hamburger.svg">
  </div>
  <div body>
    <p>We will attempt to validate the routing and account numbers provided are eligible for electronic payments before processing. Your bank routing number and account number can be located at the bottom of your check. Do not include any spaces when you enter this number.</p>
    <img alt='check' src='assets/png/payments/check.jpg'>
  </div>
</prcins-es-popup>
<prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="helpFlags.routingNumber">
  <div header-left>
    <span>Routing Number</span>
  </div>
  <div header-right>
    <img class="cursor-pointer" height='15px' width='15px' (click)="hideHelpText()"
      src="assets/svgs/close-hamburger.svg">
  </div>
  <div body>
    <p>We will attempt to validate the routing and account numbers provided are eligible for electronic payments before processing. Your bank routing number and account number can be located at the bottom of your check. Do not include any spaces when you enter this number.</p>
    <img alt='check' src='assets/png/payments/check.jpg'>
  </div>
</prcins-es-popup>