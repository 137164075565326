/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./desktop-header/desktop-header.component.ngfactory";
import * as i3 from "./desktop-header/desktop-header.component";
import * as i4 from "./mobile-header/mobile-header.component.ngfactory";
import * as i5 from "./mobile-header/mobile-header.component";
import * as i6 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-desktop-header", [], null, null, null, i2.View_DesktopHeaderComponent_0, i2.RenderType_DesktopHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.DesktopHeaderComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "prcins-mobile-header", [], null, null, null, i4.View_MobileHeaderComponent_0, i4.RenderType_MobileHeaderComponent)), i1.ɵdid(3, 49152, null, 0, i5.MobileHeaderComponent, [], null, null)], null, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i6.HeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("prcins-header", i6.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
