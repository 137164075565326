import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PolicyEndorseDriverQuery } from "@prcins/utils";
import { Apollo } from 'apollo-angular';
import { environment } from '@prcins/environments';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class OpenamendmentService {

  apiUrl = environment.BASE_URL + "/endorsement/openForAmend";
  deleteDriverUrl = environment.BASE_URL + "/endorsement/driver/addmore";
  submitDriverUrl = environment.BASE_URL + "/endorsement/driver/submit";
  cancelEndorseUrl = environment.BASE_URL + "/endorsement/abandon?aiGuid=";
  driverAdapterUrl = environment.BASE_URL + "/v2/core/";
  openEndorsementUrl = environment.BASE_URL + "/v2/";

  constructor(private http: HttpClient, private apollo: Apollo) { }

  // openForAmendment(request: IOpenAmendmentRequest): Observable<any> {
  //   return this.http.post(this.apiUrl, request);

  // }
  recordEndorsmentBlocked(type: string, guid: string): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    return this.http.put(this.openEndorsementUrl + 'core/' + policyNum + '/endorsements/recordEndorsmentBlocked?type=' + type + (guid?'&guid=' + guid:''), {});
  }

  createEndorsement(type: string): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    return this.http.post(this.openEndorsementUrl + 'core/' + policyNum + '/endorsements?type=' + type, {});
  }

  deleteDriver(request: any): Observable<any> {
    return this.http.post(this.deleteDriverUrl, request);

  }

  submitDriver(request: any): Observable<any> {
    return this.http.post(this.submitDriverUrl, request);

  }

  getDriverAdapterDetails(policyNum, guid): Observable<any> {
    return this.http.get(this.driverAdapterUrl + policyNum + '/endorsements/' + guid + '/driveradapter');
  }

  setEndorsementPolicyInput(actionType: string, policy: IEndorsePolicy) {
    sessionStorage.setItem(actionType, JSON.stringify(policy));

  }
  getEndorseDriverInfo(): any {
    return JSON.parse(sessionStorage.endorseDriver);

  }
  updateEndorsementPolicyInput(driverId: number, driverSeqNum: number) {
    let obj = JSON.parse(sessionStorage.endorseDriver) as IEndorsePolicy;
    obj.driverId = driverId;
    obj.driverSeqNum = driverSeqNum;
    sessionStorage.setItem("endorseDriver", JSON.stringify(obj));
  }

  setEndorsementAllDriversInfo(drivers: any) {
    sessionStorage.setItem("allDriversInfo", JSON.stringify(drivers));

  }

  getPolicyDriverInformation(policyNumber): Observable<any> {
    return this.apollo
      .watchQuery({
        query: PolicyEndorseDriverQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }: any) => data.policyDetails));
  }

  endorsementConfig(
    policyNumber?: String
  ): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery({
      query: EndorsementConfigQuery,
      fetchPolicy: 'network-only',
      variables: {
        policyNumber
      }
    }).valueChanges;
  }

  getEndorsementConfig(policyNumber: String): Observable<any> {
    return this.endorsementConfig(policyNumber).pipe(
      map(result => result.data.getEndorsementResourceConfig)
    );
  }

  //Deprecated in favor of new core APIs
  cancelEndorsement_deprecated(): Observable<any> {
    let url = this.cancelEndorseUrl + sessionStorage.getItem("aiguid") + "&source=CI"
    return this.http.delete(url);

  }

  cancelEndorsement(): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : sessionStorage.getItem('splPolicyNumber');
    let guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : sessionStorage.getItem('splGuid');
    let url = this.driverAdapterUrl + policyNum + '/endorsements/' + guid
    return this.http.delete(url);
  }

  driverQuery(
    policyNumber?: String,
    term?: String,
    guid?: String,
    aiGuid?: String,
    filter?: String

  ): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery({
      query: EndorsmentPolicyDetailsQuery,
      fetchPolicy: 'network-only',
      variables: {
        policyNumber,
        term,
        guid,
        aiGuid,
        filter
      }
    }).valueChanges;
  }

  getEndorseDriverDetails(policyNumber: String, term: String, guid: String, aiGuid: String, filter: String): Observable<any> {
    return this.driverQuery(policyNumber, term, guid, aiGuid, filter).pipe(
      map(result => result.data.endorsementPolicyDetails)
    );
  }
}

export interface IOpenAmendmentRequest {
  policyNumber: string,
  term: number,
  company: string,
  state: string,
  product: string,
  channel: string,
  accountEmail: string
  policyEffectiveDate: string
}

export interface IEndorsePolicy {
  guid?: string,
  aiguid?: string
  policyNumber?: string,
  term?: number,
  company?: string,
  state?: string,
  product?: string,
  channel?: string,
  accountEmail?: string,
  policyEffectiveDate?: string,
  agencyId?: string
  policyExpiryDate?: string,
  driverId?: number,
  driverSeqNum?: number,
  primaryAddress?: IAddress,
}
export interface IAddress {
  address1Txt: string,
  city: string,
  state: string,
  zip: string

}


export const EndorsementConfigQuery = gql`
  query GetEndorsementResourceConfig($policyNumber: String) {
    getEndorsementResourceConfig(policyNumber: $policyNumber) {
      addDriver
      updateDriver
      deleteDriver
      endorseEligiblePolicyInd
   }
  }
`;

export const EndorsmentPolicyDetailsQuery = gql`
  query EndorsementPolicyDetails($policyNumber: String, $term:String, $guid: String, $aiGuid: String,,$filter: String) {
    endorsementPolicyDetails(policyNumber: $policyNumber, term: $term, guid: $guid, aiGuid: $aiGuid) {
      guid
      messageCode
      driverDetails(filter:$filter){
        firstName
        middleName
        lastName
       
 }
 vehiclesAssignedToDriver(filter:$filter){
  vehicleId
  year
  make
  model
  vin
}
otherDriversOnPolicy(filter:$filter){
  firstName
  lastName
  driverId
}

    }
  }
`;
