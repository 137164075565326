/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../utils/src/lib/danger/danger.component.ngfactory";
import * as i3 from "../../../../utils/src/lib/danger/danger.component";
import * as i4 from "../../../../utils/src/lib/es-popup/es-popup.component.ngfactory";
import * as i5 from "../../../../utils/src/lib/es-popup/es-popup.component";
import * as i6 from "../../../../utils/src/lib/spinner/spinner.component.ngfactory";
import * as i7 from "../../../../utils/src/lib/spinner/spinner.component";
import * as i8 from "@angular/common";
import * as i9 from "../../../../utils/src/lib/card-form/card-form.component.ngfactory";
import * as i10 from "../../../../utils/src/lib/card-form/card-form.component";
import * as i11 from "@angular/router";
import * as i12 from "./add-card.component";
import * as i13 from "../../../../utils/src/lib/services/card-form-provider.service";
import * as i14 from "../services/manage-payments-provider.service";
import * as i15 from "../../../../utils/src/lib/services/form-validator.service";
var styles_AddCardComponent = [i0.styles];
var RenderType_AddCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddCardComponent, data: {} });
export { RenderType_AddCardComponent as RenderType_AddCardComponent };
function View_AddCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "prcins-danger", [], null, null, null, i2.View_DangerComponent_0, i2.RenderType_DangerComponent)), i1.ɵdid(1, 4243456, null, 0, i3.DangerComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" Oops! There is a problem with the card you entered. "]))], null, null); }
function View_AddCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "prcins-es-popup", [["headerClass", "bg-es-blue text-white"]], null, null, null, i4.View_EsPopupComponent_0, i4.RenderType_EsPopupComponent)), i1.ɵdid(1, 49152, null, 0, i5.EsPopupComponent, [], { headerClass: [0, "headerClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["header-left", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirmation"])), (_l()(), i1.ɵeld(5, 0, null, 2, 7, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "text-center text-lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to cancel this transaction?"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "flex flex-wrap-reverse justify-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "es-link-secondary  w-half-2 mt-8  cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showGuardPopup = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "es-btn-primary w-half-2 mt-8 cursor-pointer"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelTransaction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"]))], function (_ck, _v) { var currVal_0 = "bg-es-blue text-white"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AddCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-spinner", [], null, null, null, i6.View_SpinnerComponent_0, i6.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i7.SpinnerComponent, [], null, null)], null, null); }
export function View_AddCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "es-h1 mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Card"])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddCardComponent_1)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "prcins-card-form", [], null, null, null, i9.View_CardFormComponent_0, i9.RenderType_CardFormComponent)), i1.ɵdid(7, 114688, null, 0, i10.CardFormComponent, [i11.ActivatedRoute], { cardForm: [0, "cardForm"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "es-btn-primary w-full mt-4"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAddCard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["class", "es-link-secondary w-full mt-4 cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showGuardPopup = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddCardComponent_2)), i1.ɵdid(14, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddCardComponent_3)), i1.ɵdid(16, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showError; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.cardForm; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.showGuardPopup; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.showSpinner; _ck(_v, 16, 0, currVal_3); }, null); }
export function View_AddCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-add-card", [], null, null, null, View_AddCardComponent_0, RenderType_AddCardComponent)), i1.ɵdid(1, 114688, null, 0, i12.AddCardComponent, [i13.CardFormProviderService, i14.ManagePaymentsProviderService, i11.Router, i11.ActivatedRoute, i15.FormValidatorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddCardComponentNgFactory = i1.ɵccf("prcins-add-card", i12.AddCardComponent, View_AddCardComponent_Host_0, {}, {}, []);
export { AddCardComponentNgFactory as AddCardComponentNgFactory };
