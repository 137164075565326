/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./es-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./es-popup.component";
var styles_EsPopupComponent = [i0.styles];
var RenderType_EsPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EsPopupComponent, data: {} });
export { RenderType_EsPopupComponent as RenderType_EsPopupComponent };
export function View_EsPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "popup-fixed-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "popup-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "popup-item-container rounded"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵncd(null, 0), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "px-5 py-5"]], null, null, null, null, null)), i1.ɵncd(null, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "flex px-4 py-3 items-center justify-between rounded-t ", _co.headerClass, ""); _ck(_v, 4, 0, currVal_0); }); }
export function View_EsPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-es-popup", [], null, null, null, View_EsPopupComponent_0, RenderType_EsPopupComponent)), i1.ɵdid(1, 49152, null, 0, i2.EsPopupComponent, [], null, null)], null, null); }
var EsPopupComponentNgFactory = i1.ɵccf("prcins-es-popup", i2.EsPopupComponent, View_EsPopupComponent_Host_0, { headerClass: "headerClass" }, {}, ["[header-left]", "[header-right]", "[body]"]);
export { EsPopupComponentNgFactory as EsPopupComponentNgFactory };
