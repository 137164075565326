import { Injectable } from "@angular/core";
import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ScrollToInvalidService {

    public scrollToFirstInvalidControl(elementRef) {
        const firstInvalidControl: HTMLElement = elementRef.nativeElement.querySelector(
            ".ng-invalid"
        );

        if (!firstInvalidControl) {
            return;
        }

        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: "smooth"
        });

        fromEvent(window, "scroll")
            .pipe(
                debounceTime(100),
                take(1)
            )
            .subscribe(() => firstInvalidControl.focus());
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }

}


