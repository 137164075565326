import gql from 'graphql-tag';

export const AddressListQuery = gql`
  fragment AddressFragment on AddressDTO {
    id
    addressLine1
    addressLine2
    zipCode
    city
    state
  }
  fragment VehicleFragment on VehicleDTO {
    id
    unitYear
    make
    model
    vin
    bodyType
  }
  query AddressList($policyNumber: String) {
    addressList(policyNumber: $policyNumber) {
      primary
      mailing
      garaging {
        address
        vehicles
      }
      addresses {
        ...AddressFragment
      }
      vehicles {
        ...VehicleFragment
      }
    }
  }
`;
// export const GaragingQuery = gql`
//   query GaragingQuery($policyNumber: String) {
//     addressList(policyNumber: $policyNumber) {
//       primary
//       garaging {
//         address
//         vehicles
//       }
//       addresses {
//         id
//         addressLine1
//         addressLine2
//         zipCode
//         city
//         state
//       }
//       vehicles {
//         id
//         unitYear
//         make
//         model
//         vin
//         vehicleCC
//         bodyType
//         insurableInterests{
//           nameBusiness
//           interestTypeCode
//         }
//       }
//     }
//   }
// `;

export const GaragingQuery = gql`
  query GaragingQuery($policyNumber: String, $guid: String) {
    policyDetailsV2(policyNumber: $policyNumber, guid: $guid) {
      isEOAIEligible
      product{
        programRsCode
      }
      drivers {
        identifier
        lastName
        firstName
        middleName
        suffix
    }
    vehicles {
        id
        unitYear
        make
        model
        bodyType
        vin,
        vehicleCC
        vehicleDrivers {
            driverId
        }
        insurableInterests {
            nameBusiness
        }
        vehicleAddress {
            sameAsPrimary
            addressLine1
            addressLine2
            zipCode
            city
            state
        }
    }
    }
  }
`;
