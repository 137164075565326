import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TealiumUtagService } from '../services/tealium/utag.service';
import { TealiumTrackerService } from '../services/tealium/tealium-tracker.service';
import { CURRENT_POLICY, CURRENT_POLICY_LOB, CURRENT_POLICY_STATE, CURRENT_POLICY_CHANNEL } from '@prcins/constants';

@Directive({
  selector: 'prcins-tealium-page-tracker'
})
export class PageTrackerDirective implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private tealiumTracker: TealiumTrackerService) { }


  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {

      let root: ActivatedRoute = this.activatedRoute.root;
      let segments = this.getSubSections(root);
      console.log(segments);
      this.buildPageContext(segments);

    });
  }

  private getSubSections(route: ActivatedRoute, subSections: string[] = []): string[] {
    const ROUTE_DATA_TEALIUM: string = "tealium";
    let children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return subSections;
    }

    for (let child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (child.snapshot.data.hasOwnProperty(ROUTE_DATA_TEALIUM)) {
        if (subSections.indexOf(child.snapshot.data[ROUTE_DATA_TEALIUM]) == -1) {
          subSections.push(child.snapshot.data[ROUTE_DATA_TEALIUM]);
        }
      }

      return this.getSubSections(child, subSections);
    }
  }




  buildPageContext(segments) {
    let display = "web";
    if (self != top) {
      display = "native";
    }


    let site_section, site_subsection1, site_subsection2, site_subsection3, site_subsection4;
    if (segments.length >= 0) {
      site_section = segments[0].subSection;
    }

    site_subsection1 = segments[1] ? site_section + ":" + segments[1].subSection : site_section;
    site_subsection2 = segments[2] ? site_subsection1 + ":" + segments[2].subSection : site_subsection1;
    site_subsection3 = segments[3] ? site_subsection2 + ":" + segments[3].subSection : site_subsection2;
    site_subsection4 = segments[4] ? site_subsection3 + ":" + segments[4].subSection : site_subsection3;

    console.log(`${site_section} ${site_subsection1} ${site_subsection2} ${site_subsection3} ${site_subsection4}`);

    let pageContext = {
      page_type: "content", // The Tealium variable to identify the type of page or template eg. home,section,category,search,content,etc.
      page_name: site_subsection4, // The user-friendly name of the page
      site_section: site_section, // The top-level section of your site eg. Travel,Sports,News,etc.
      site_subsection1: site_subsection1, // The 1st level category of the page hierarchy
      site_subsection2: site_subsection2, // The 2nd level category of the page hierarchy
      site_subsection3: site_subsection3, // The 3rd level category of the page hierarchy
      site_subsection4: site_subsection4, // The 4th level category of the page hierarchy
      page_friendly_url: "", // The canonical URL of the page,might be different than the URL in the browser location bar
      site_language_code: "en", // The two-character language code of the site eg. us,es,fr,etc.
      site_country_code: "us", // The two-character country code of the site eg. us,uk,mx,ca,jp,etc.
      site_display_format: display, // The display format of the site in view eg. 'desktop','tablet',or 'mobile'
      page_number: "", // For paginated content,the current page number in view eg. '2'
      /* tracking_code: queryParams,*/
      source: site_section == 'eService' ? site_section : 'Direct'
    }


    let policyNumber = sessionStorage.getItem(CURRENT_POLICY);
    let lob = sessionStorage.getItem(CURRENT_POLICY_LOB);
    let channel = sessionStorage.getItem(CURRENT_POLICY_CHANNEL);
    let state = sessionStorage.getItem(CURRENT_POLICY_STATE);
    if (policyNumber) {
      pageContext['policy_number'] = policyNumber;
    }
    if (lob) {
      pageContext['lob'] = lob;
    }
    if (channel) {
      pageContext['channel'] = channel;
    }
    if (state) {
      pageContext['statusCode'] = state;
    }

    this.tealiumTracker.pageContext = pageContext;

    this.tealiumTracker.view(pageContext);

    console.log(`${pageContext}`);
  }

}

