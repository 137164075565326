import { HttpClient } from '@angular/common/http';
import { PolicyEndorseDriverQuery } from "@prcins/utils";
import { Apollo } from 'apollo-angular';
import { environment } from '@prcins/environments';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
export class OpenamendmentService {
    constructor(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.apiUrl = environment.BASE_URL + "/endorsement/openForAmend";
        this.deleteDriverUrl = environment.BASE_URL + "/endorsement/driver/addmore";
        this.submitDriverUrl = environment.BASE_URL + "/endorsement/driver/submit";
        this.cancelEndorseUrl = environment.BASE_URL + "/endorsement/abandon?aiGuid=";
        this.driverAdapterUrl = environment.BASE_URL + "/v2/core/";
        this.openEndorsementUrl = environment.BASE_URL + "/v2/";
    }
    // openForAmendment(request: IOpenAmendmentRequest): Observable<any> {
    //   return this.http.post(this.apiUrl, request);
    // }
    recordEndorsmentBlocked(type, guid) {
        let policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.put(this.openEndorsementUrl + 'core/' + policyNum + '/endorsements/recordEndorsmentBlocked?type=' + type + (guid ? '&guid=' + guid : ''), {});
    }
    createEndorsement(type) {
        let policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.post(this.openEndorsementUrl + 'core/' + policyNum + '/endorsements?type=' + type, {});
    }
    deleteDriver(request) {
        return this.http.post(this.deleteDriverUrl, request);
    }
    submitDriver(request) {
        return this.http.post(this.submitDriverUrl, request);
    }
    getDriverAdapterDetails(policyNum, guid) {
        return this.http.get(this.driverAdapterUrl + policyNum + '/endorsements/' + guid + '/driveradapter');
    }
    setEndorsementPolicyInput(actionType, policy) {
        sessionStorage.setItem(actionType, JSON.stringify(policy));
    }
    getEndorseDriverInfo() {
        return JSON.parse(sessionStorage.endorseDriver);
    }
    updateEndorsementPolicyInput(driverId, driverSeqNum) {
        let obj = JSON.parse(sessionStorage.endorseDriver);
        obj.driverId = driverId;
        obj.driverSeqNum = driverSeqNum;
        sessionStorage.setItem("endorseDriver", JSON.stringify(obj));
    }
    setEndorsementAllDriversInfo(drivers) {
        sessionStorage.setItem("allDriversInfo", JSON.stringify(drivers));
    }
    getPolicyDriverInformation(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyEndorseDriverQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data.policyDetails));
    }
    endorsementConfig(policyNumber) {
        return this.apollo.watchQuery({
            query: EndorsementConfigQuery,
            fetchPolicy: 'network-only',
            variables: {
                policyNumber
            }
        }).valueChanges;
    }
    getEndorsementConfig(policyNumber) {
        return this.endorsementConfig(policyNumber).pipe(map(result => result.data.getEndorsementResourceConfig));
    }
    //Deprecated in favor of new core APIs
    cancelEndorsement_deprecated() {
        let url = this.cancelEndorseUrl + sessionStorage.getItem("aiguid") + "&source=CI";
        return this.http.delete(url);
    }
    cancelEndorsement() {
        let policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : sessionStorage.getItem('splPolicyNumber');
        let guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : sessionStorage.getItem('splGuid');
        let url = this.driverAdapterUrl + policyNum + '/endorsements/' + guid;
        return this.http.delete(url);
    }
    driverQuery(policyNumber, term, guid, aiGuid, filter) {
        return this.apollo.watchQuery({
            query: EndorsmentPolicyDetailsQuery,
            fetchPolicy: 'network-only',
            variables: {
                policyNumber,
                term,
                guid,
                aiGuid,
                filter
            }
        }).valueChanges;
    }
    getEndorseDriverDetails(policyNumber, term, guid, aiGuid, filter) {
        return this.driverQuery(policyNumber, term, guid, aiGuid, filter).pipe(map(result => result.data.endorsementPolicyDetails));
    }
}
OpenamendmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpenamendmentService_Factory() { return new OpenamendmentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: OpenamendmentService, providedIn: "root" });
export const EndorsementConfigQuery = gql `
  query GetEndorsementResourceConfig($policyNumber: String) {
    getEndorsementResourceConfig(policyNumber: $policyNumber) {
      addDriver
      updateDriver
      deleteDriver
      endorseEligiblePolicyInd
   }
  }
`;
export const EndorsmentPolicyDetailsQuery = gql `
  query EndorsementPolicyDetails($policyNumber: String, $term:String, $guid: String, $aiGuid: String,,$filter: String) {
    endorsementPolicyDetails(policyNumber: $policyNumber, term: $term, guid: $guid, aiGuid: $aiGuid) {
      guid
      messageCode
      driverDetails(filter:$filter){
        firstName
        middleName
        lastName
       
 }
 vehiclesAssignedToDriver(filter:$filter){
  vehicleId
  year
  make
  model
  vin
}
otherDriversOnPolicy(filter:$filter){
  firstName
  lastName
  driverId
}

    }
  }
`;
