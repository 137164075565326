import { Apollo } from 'apollo-angular';
import { ClaimsSearchQuery } from '@prcins/utils';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
export class ClaimsSearchService {
    constructor(apollo) {
        this.apollo = apollo;
    }
    claimsSearchQuery(policyNumber) {
        return this.apollo.watchQuery({
            query: ClaimsSearchQuery,
            variables: {
                policyNumber
            }
        }).valueChanges;
    }
}
ClaimsSearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClaimsSearchService_Factory() { return new ClaimsSearchService(i0.ɵɵinject(i1.Apollo)); }, token: ClaimsSearchService, providedIn: "root" });
