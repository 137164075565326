/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./enroll-automatic-payment.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../libs/utils/src/lib/card/card.component.ngfactory";
import * as i3 from "../../../../../libs/utils/src/lib/card/card.component";
import * as i4 from "../../../../../libs/utils/src/lib/tealium/click-tracker.directive";
import * as i5 from "../../../../../libs/utils/src/lib/services/tealium/tealium-tracker.service";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "./enroll-automatic-payment.component";
var styles_EnrollAutomaticPaymentComponent = [i0.styles];
var RenderType_EnrollAutomaticPaymentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnrollAutomaticPaymentComponent, data: {} });
export { RenderType_EnrollAutomaticPaymentComponent as RenderType_EnrollAutomaticPaymentComponent };
export function View_EnrollAutomaticPaymentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "prcins-card", [["pt", "pt-0"]], null, null, null, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i3.CardComponent, [], { pt: [0, "pt"] }, null), (_l()(), i1.ɵeld(2, 0, null, 2, 7, "div", [["body", ""], ["class", "enroll-automatic-payment-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "tree"], ["src", "assets/png/lightening.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "flex justify-center text-center info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "a", [["prcins-tealium-event", "enroll_eft"], ["prcins-tealium-tracker", "click"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 81920, null, 0, i4.ClickTrackerDirective, [i5.TealiumTrackerService, i1.ElementRef, i1.Renderer2], { eventType: [0, "eventType"], eventName: [1, "eventName"] }, null), i1.ɵdid(7, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵted(-1, null, ["Enroll in Automatic Payments(EFT)"]))], function (_ck, _v) { var currVal_0 = "pt-0"; _ck(_v, 1, 0, currVal_0); var currVal_3 = "click"; var currVal_4 = "enroll_eft"; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _ck(_v, 8, 0, "../home/billing/change-plan"); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 7).target; var currVal_2 = i1.ɵnov(_v, 7).href; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_EnrollAutomaticPaymentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-enroll-automatic-payments", [], null, null, null, View_EnrollAutomaticPaymentComponent_0, RenderType_EnrollAutomaticPaymentComponent)), i1.ɵdid(1, 114688, null, 0, i8.EnrollAutomaticPaymentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnrollAutomaticPaymentComponentNgFactory = i1.ɵccf("prcins-enroll-automatic-payments", i8.EnrollAutomaticPaymentComponent, View_EnrollAutomaticPaymentComponent_Host_0, {}, {}, []);
export { EnrollAutomaticPaymentComponentNgFactory as EnrollAutomaticPaymentComponentNgFactory };
