
<div class="md:min-h-screen relative">
  <header class=" lg:flex xl:flex p-4 bg-es-blue justify-between items-center border-t-4 border-es-orange">
    <a href="{{mainPage}}" target="_blank">
      <img src="assets/svgs/brands/{{brand}}/header-logo.svg">
    </a>
  </header>
  <main class="flex justify-center w-full es-app-container">
    <div class="es-container w-full flex justify-between flex-wrap">
      <section class="es-main-container w-full">
        <router-outlet></router-outlet>
        <div class="add-form-body">
          <h1 class="add-title">Verify Your Zip Code</h1>
          <form [formGroup]="policyLookupForm" (ngSubmit)="onPolicyLookup()">
            <div class="add-form-element">
              <label class="add-form-label ">Policy Number</label>
              <div class="  my-3">
                <input disabled oninput="this.value = this.value.toUpperCase();" maxlength="20"
                 
                  class="lg:w-6/12 add-textfield  ng-untouched ng-pristine ng-invalid disabled:bg-es-gray" type="text" trimInput
                  formControlName="policyNumber" #policyNumberField prcins-tealium-tracker="blur"
                  [prcins-tealium-evar]="{'policy_number':policyNumberField.value}">
              </div>

            </div>
            <div *ngIf="!policyLookupForm.get('policyNumber').valid  && (policyLookupForm.get('policyNumber').touched)">
              <div class="input-invalid-text" *ngIf="policyLookupForm.get('policyNumber').errors['required']"> Oops!
                You forgot to give us your
                policy
                number. </div>
              <div class="input-invalid-text" *ngIf="policyLookupForm.get('policyNumber').errors['pattern']">
                Oops! We can't seem to find you. Please try that policy number again.
              </div>
            </div>
            <div class="add-form-element">
              <label class="add-form-label">Zip Code</label>
              <div class="  my-3">
                <input mask="0{6}" prcinsOnlyNumber maxlength="5"
                  class="ng-untouched ng-pristine ng-invalid add-textfield lg:w-6/12" type="text" trimInput
                  formControlName="zipCode" prcinsNoRightClick #zipCodeField prcins-tealium-tracker="blur"
                  [prcins-tealium-evar]="{'qpay_zip_code':zipCodeField.value}">
              </div>
            </div>
            <div *ngIf="!policyLookupForm.get('zipCode').valid   && policyLookupForm.get('zipCode').touched">
              <div class="input-invalid-text" *ngIf="policyLookupForm.get('zipCode').errors['required']">
                Oops! You forgot to give us your zip code. </div>

              <div class="input-invalid-text"
                *ngIf="!policyLookupForm.get('zipCode').errors['required'] && policyLookupForm.get('zipCode').errors['pattern']">
                Oops! We can't seem to find you. Please try that zip code again.
              </div>


            </div>
            <div class="input-invalid-text" *ngIf="authError">
              We couldn't find your policy with this zip code. Please re-enter.
            </div>
            <!-- <div class="add-form-label">Policy Number</div>
            <div class="add-form-input">
              <input type="text" formControlName="policynumber" class="add-textfield add-w-full ng-untouched ng-pristine ng-invalid"  ng-reflect-name="policynumber">
            </div>
            <div *ngIf="form.get('zipcode').hasError('required') && form.get('zipcode').touched" class="error-message">Zip Code is required.</div>
            <div class="add-form-label">Zip Code</div>
            <div class="add-form-input">
              <input type="text" formControlName="zipcode" class="add-textfield add-w-full"></div>
              <div *ngIf="form.get('zipcode').hasError('required') && form.get('zipcode').touched" class="error-message">Zip Code is required.</div>
            <div class="add-btns">
              <button class="add-btn add-btn-primary" type="submit">Continue</button>
            </div> -->
            <button class="es-btn-primary lg:w-6/12 mt-8 mb-8" type="submit">Continue</button>
          </form>
          <!-- </div> -->

        </div>
      </section>
    </div>

  </main>

</div>
<prcins-footer></prcins-footer>
<prcins-spinner  *ngIf="isLoading"></prcins-spinner>