class Environment {
  production = false;
  AUTH_URL="";
  BASE_URL = "";
  GQL_URL = "";
  EFNOL_URL = "";
  RR_URL="";
  NY_RR_URL="";
  ESERVICE_URL = "";
  QPAY_URL = "";
  QPAY_BASE_URL = "";
  IP_ADDRESS = "";
  LOGGER_URL = "";
  ENDORSE_DRIVER_JS = "";
  ENDORSE_DRIVER_CSS = "";
  ENDORSE_MORTGAGE_JS = "";
  ENDORSE_MORTGAGE_CSS = "";
  ENDORSE_EDIT_COVERAGE_JS = "";
  ENDORSE_EDIT_COVERAGE_CSS = "";
  PDF_URL ="";
  INVISIBLE_CAPTCHA_KEY="";
  INTERACTIVE_CAPTCHA_KEY="";
  SCRIPTS = [];
  API_KEY = "";
  API_KEY_VALUE = "";
  CROSS_SELL_URL = "";
  ENABLE_CAPTCHA = false;
  ENABLE_CAPTCHA_IOS = false;

}
export const environment: Environment = (() => {
  const env = new Environment();
  const request = new XMLHttpRequest();
  request.open('GET', '/config/app.json',false);
  request.send(null);
  if (request.status === 200) {
      const response = JSON.parse(request.responseText);
      for (const key in response) {
          if (env.hasOwnProperty(key)) {
              env[key] = response[key];
          }
      }
  }
  return env;
})();
 /* For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
