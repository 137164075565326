export const NOTIFICATIONS = {
    LOGIN_INFO_EMAIL_SUCCESS: { type: 'INFO', message: 'Success! You\'ve changed your email address.' },
    LOGIN_INFO_EMAIL_FAILURE: { type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' },
    LOGIN_INFO_EMAIL_ALREADY_REGISTERED: { type: 'ERROR', message: 'Email already registered in eService.' },
    LOGIN_INFO_EMAIL_TOKEN_RETRY: { type: 'ERROR', message: 'Oops! Verification failed. Please try that again.' },
    LOGIN_INFO_PASSWORD_SUCCESS: { type: 'INFO', message: 'Success! You\'ve changed your password.' },
    LOGIN_INFO_PASSWORD_FAILURE: { type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' },
    LOGIN_INFO_SECQ_SUCCESS: { type: 'INFO', message: 'Success! You\'ve changed your security questions.' },
    LOGIN_INFO_SECQ_FAILURE: { type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' }
}
