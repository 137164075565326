
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[prcinsNoCopyPaste]'
})
export class NoCopyPasteDirective {

  constructor(private el: ElementRef) { }

  @Input("prcinsNoCopyPaste") NoCopyPaste: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;
    if (this.NoCopyPaste) {
        if (e.keyCode === 86 && e.ctrlKey === true) {
            e.preventDefault();
        }
      }
  }
}