export const allowed_menus = {
  PA: ['payments_pa', 'policy', 'claims', 'documents', 'account'],
  MC: ['payments_mc', 'policy_mc', 'claims', 'documents', 'account'],
  HO: ['payments_ho', 'policy_ho', 'claims_ho', 'documents_ho', 'account_ho'],
  CA: ['payments', 'claims', 'documents_ca', 'account'],
  UMB: ['payments_umb', 'claims_umb', 'documents_umb', 'account']
};

const payments = {
  label: 'Payments',
  id: 'payments',
  icon: 'assets/svgs/payment-icon.svg',
  children: [
    {
      label: 'Make a Payment',
      route: ['../home/payment/entry'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Future Installments',
      route: ['../home/billing/upcoming'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Payment History',
      route: ['../home/billing/history'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Change Pay Plan',
      route: ['../home/billing/change-plan'],
      disable: true,
      waitConditionally: true
    }
  ]
};

const payments_umb = {
  label: 'Payments',
  id: 'payments',
  icon: 'assets/svgs/payment-icon.svg',
  children: [
    {
      label: 'Make a Payment',
      route: ['../home/payment/entry'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Future Installments',
      route: ['../home/billing/upcoming'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Payment History',
      route: ['../home/billing/history'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage Payment Methods',
      route: ['../home/payment/manage'],
      disable: false,
      waitConditionally: false
    }
  ]
};

const payments_ho = {
  label: 'Payments',
  id: 'payments',
  icon: 'assets/svgs/payment-icon.svg',
  children: [
    {
      label: 'Make a Payment',
      route: ['../home/payment/entry'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Future Installments',
      route: ['../home/billing/upcoming'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Payment History',
      route: ['../home/billing/history'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage Payment Methods',
      route: ['../home/payment/manage'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Change Pay Plan',
      route: ['../home/billing/change-plan'],
      disable: true,
      waitConditionally: true
    }
  ]
};

const payments_mc = {
  label: 'Payments',
  id: 'payments',
  icon: 'assets/svgs/payment-icon.svg',
  children: [
    {
      label: 'Make a Payment',
      route: ['../home/payment/entry'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Future Installments',
      route: ['../home/billing/upcoming'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Payment History',
      route: ['../home/billing/history'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage Payment Methods',
      route: ['../home/payment/manage'],
      disable: false,
      waitConditionally: false
    }
  ]
};


const payments_pa = {
  label: 'Payments',
  id: 'payments',
  icon: 'assets/svgs/payment-icon.svg',
  children: [
    {
      label: 'Make a Payment',
      route: ['../home/payment/entry'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Future Installments',
      route: ['../home/billing/upcoming'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Payment History',
      route: ['../home/billing/history'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage Payment Methods',
      route: ['../home/payment/manage'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Change Pay Plan',
      route: ['../home/billing/change-plan'],
      disable: true,
      waitConditionally: true
    }
  ]
};

const policy_mc = {
  label: 'Policy',
  id: 'policy',
  icon: 'assets/svgs/car-header-icon.svg',
  children: [
    {
      label: 'Coverage Details',
      route: ['../home/documents/policy-declaration'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Riders(s)',
      route: ['../home/policy/riders'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Vehicle(s)',
      route: ['../home/policy/vehicles'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Address(es)',
      route: ['../home/address'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'View Discounts & Perks',
      route: ['../home/policy/discounts-perks'],
      disable: false,
      waitConditionally: false
    }
  ]
};

const policy = {
  label: 'Policy',
  id: 'policy',
  icon: 'assets/svgs/car-header-icon.svg',
  children: [
    {
      label: 'Coverage Details',
      route: ['../home/documents/policy-declaration'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Driver(s)',
      route: ['../home/policy/drivers'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Vehicle(s)',
      route: ['../home/policy/vehicles'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Address(es)',
      route: ['../home/address'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'View Discounts & Perks',
      route: ['../home/policy/discounts-perks'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Road Rewards',
      route: ['../home/policy/road-rewards'],
      disable: false,
      waitConditionally: false
    }
  ]
};

const policy_ho = {
  label: 'Policy',
  id: 'policy',
  icon: 'assets/svgs/home-white-icon.svg',
  children: [
    {
      label: 'Coverage Details',
      route: ['../home/documents/policy-declaration'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Policy Owner/Mortgagee',
      route: ['../home/policy/property-info'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Address(es)',
      route: ['../home/address'],
      disable: false
    },
    {
      label: 'View Discounts & Perks',
      route: ['../home/policy/discounts-perks'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Additional Protection',
      route: ['../home/policy/additional-protection'],
      disable: false,
      waitConditionally: false
    }
  ]
};

const claims = {
  label: 'Claims',
  id: 'claims',
  icon: 'assets/svgs/claims-icon.svg',
  children: [
    {
      label: 'Report a Claim',
      click: 'gotoReportClaim'
    },
    {
      label: 'View Claims',
      route: ['../home/claims/claimslist']
    },
    {
      label: 'Find a Repair Shop',
      link: 'https://www.plymouthrock.com/claims/repairshop'
    }
  ]
};

const claims_umb = {
  label: 'Claims',
  id: 'claims',
  icon: 'assets/svgs/claims-icon.svg',
  children: [
    {
      label: 'Report a Claim',
      click: 'gotoReportClaim'
    },
    {
      label: 'View Claims',
      route: ['../home/claims/claimslist']
    }
  ]
};

const claims_ho = {
  label: 'Claims',
  id: 'claims',
  icon: 'assets/svgs/claims-icon.svg',
  children: [
    {
      label: 'Report a Claim',
      click: 'gotoReportClaim'
    },
    {
      label: 'View Claims',
      route: ['../home/claims/claimslist']
    }
  ]
};

const documents = {
  label: 'Documents',
  id: 'documents',
  icon: 'assets/svgs/documents-icon.svg',
  children: [
    {
      label: 'ID Cards or Proof of Insurance',
      route: ['../home/documents/idcards'],
      disable: true,
      waitConditionally: true
    },
    {
      label: 'Policy Documents',
      route: ['../home/documents/policy-documents'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage eDocument Preferences',
      route: ['../home/documents/manage-edocs'],
      disable: false,
      waitConditionally: false
    }
  ]
};

const documents_ho = {
  label: 'Documents',
  id: 'documents',
  icon: 'assets/svgs/documents-icon.svg',
  children: [
    {
      label: 'Evidence of Insurance',
      route: ['../home/documents/idcards'],
      disable: true,
      waitConditionally: true
    },
    {
      label: 'Policy Documents',
      route: ['../home/documents/policy-documents'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage eDocument Preferences',
      route: ['../home/documents/manage-edocs'],
      disable: false,
      waitConditionally: false
    }
  ]
};

const documents_ca = {
  label: 'Documents',
  id: 'documents',
  icon: 'assets/svgs/documents-icon.svg',
  children: [
    {
      label: 'ID Cards or Proof of Insurance',
      route: ['../home/documents/idcards'],
      disable: true,
      waitConditionally: true
    },
    {
      label: 'Policy Documents',
      route: ['../home/documents/policy-documents'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage eDocument Preferences',
      route: ['../home/documents/manage-edocs'],
      disable: false,
      waitConditionally: false
    }
  ]
};

const documents_umb = {
  label: 'Documents',
  id: 'documents',
  icon: 'assets/svgs/documents-icon.svg',
  children: [
    {
      label: 'Policy Documents',
      route: ['../home/documents/policy-documents'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage eDocument Preferences',
      route: ['../home/documents/manage-edocs'],
      disable: false,
      waitConditionally: false
    }
  ]
};

const account = {
  label: 'Account',
  id: 'account',
  icon: 'assets/svgs/accounts-icon.svg',
  children: [
    {
      label: 'Login Info',
      route: ['../home/account/login-info'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage Alerts',
      route: ['../home/account/manage-alerts'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage Policies',
      route: ['../home/account/manage-policies'],
      disable: false,
      waitConditionally: false
    }
  ]
};

const account_ho = {
  label: 'Account',
  id: 'account',
  icon: 'assets/svgs/accounts-icon.svg',
  children: [
    {
      label: 'Login Info',
      route: ['../home/account/login-info'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage Alerts',
      route: ['../home/account/manage-alerts'],
      disable: false,
      waitConditionally: false
    },
    {
      label: 'Manage Policies',
      route: ['../home/account/manage-policies'],
      disable: false,
      waitConditionally: false
    }
  ]
};

export const menus = {
  payments,
  payments_umb,
  payments_pa,
  payments_ho,
  payments_mc,
  policy,
  policy_mc,
  claims,
  claims_ho,
  claims_umb,
  documents,
  documents_ho,
  account,
  account_ho,
  policy_ho,
  documents_ca,
  documents_umb
};
