import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@prcins/environments';
import { Observable } from 'rxjs';
import packageJson from '../../../../../package.json';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public version: string = packageJson.version;
  

  constructor(private http: HttpClient,private router: Router) { }

  transferSession(target: string, handler: string): Observable<any> {
    const URL = `${environment.AUTH_URL}/session/redirect`;
    let { currentPolicy:selectedPolicy, userId } = sessionStorage;
    return this.http.post(URL, { target, handler, selectedPolicy, userId });
  }

  transferEndorsementSession(target: string, endorsementContext: any): Observable<any> {
    const URL = `${environment.AUTH_URL}/session/redirect`;
    let { currentPolicy: selectedPolicy, userId } = sessionStorage;
    return this.http.post(URL, { target, handler: null, selectedPolicy, userId, endorsementContext });
  }

  hydrateSession(session: string): Observable<any> {
    const URL = `${environment.AUTH_URL}/session/restore?session=${session}`;
    return this.http.post(URL, {});
  }

  invalidateCacheAfterNewDeploy(frequency = 1000 * 60 * 5) {
    
    setInterval(() => {
      if(this.router.url === '/eservice/login' || this.router.url === '/eservice/home') {
        console.log(this.router.url)
        this.checkVersion('/version/version.json'); //use assets for local
      }
      
  }, frequency);

  }


  private checkVersion(url) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime())
        .pipe()
        .subscribe(
            (response: any) => {
             if(this.version === response.version) {
               //do nothing
             } else {
               this.clearCache();
             // window.location.reload(true); as passing true deprecated using below line of code
              window.location.href = window.location.href;
             }
            },
             (err) => {
                    console.error(err, 'Could not get version');
                }
            )
      }

  clearCache() {
    if('caches' in window) {
      caches.keys().then((names) => {
          names.forEach(async (name) => {
              await caches.delete(name)
          })
      })

  }
  }
}
