import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { DiscardComponent } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { FormValidatorService, RegistrationService, UtilityProviderService } from '@prcins/utils';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { USER_ID, USER_LOGIN, TOKEN } from '@prcins/constants';

@Component({
  selector: 'prcins-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.css']
})
export class CreatePasswordComponent extends DiscardComponent implements OnInit {

  showSpinner = false;
  alreadyRegisteredAccountPopup = false;
  registrationError = false;
  errorMessage: string;
  userPolicyDetailsResp: any;
  createPasswordForm = this.utilityProvider.getCreatePasswordForm();
  registeredAccountAlreadyExists: string;
  verificationId: string;
  showCreatePasswordForm = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private formValidator: FormValidatorService,
    private utilityProvider: UtilityProviderService,
    private registrationProvider: RegistrationService) {
    super();
  }

  ngOnInit() {
    this.verificationId = sessionStorage.getItem('verificationId');

    if (this.verificationId) {
      this.updateSafeToNavigate(false);
    }

    this.registrationProvider.getUserDetails(this.verificationId).subscribe((data) => {
      if (data && data.edocEnrollmentAllowed) {
        this.createPasswordForm.addControl('enrollEDOC', new FormControl('', [Validators.required]));
      }
      this.showCreatePasswordForm = true;
    }, (error: HttpErrorResponse) => {
      this.registrationProvider.invalidateEndorsementSession();
      this.router.navigate(['../login'], {
        relativeTo: this.route,
      });
    });
  }

  onSubmit() {
    if (this.createPasswordForm.valid) {
      this.showSpinner = true;
      let formValue = this.createPasswordForm.value;
      formValue.verificationId = this.verificationId;
      if (formValue.enrollEDOC === 'Y') {
        formValue.enrollEDOC = true;
      } else if(formValue.enrollEDOC === 'N'){
        formValue.enrollEDOC = false;
      } else {
        formValue.enrollEDOC = null;
      }
      delete formValue.confirmPassword;
      this.registrationProvider
        .createAccount(formValue)
        .subscribe((resp: any) => {
          if (resp.messageCode === 'REGISTRATION_SUCCESS') {

            resp.userId = formValue.userName;
            sessionStorage.setItem(USER_LOGIN, 'Y');
            sessionStorage.setItem(USER_ID, formValue.userName);
            sessionStorage.setItem(TOKEN, resp.token);
            sessionStorage.setItem('randomNumber', Math.random().toString());
            this.registrationProvider.invalidateEndorsementSession();
            this.registrationProvider.userRegistrationForm$.next(null);
            this.updateSafeToNavigate(true);
            this.showSpinner = false;
            const routePath = ['eservice/home'];
            this.router.navigate(routePath);
          } else if (resp.messageCode === 'ACCOUNT_EXISTS') {
            this.alreadyRegisteredAccountPopup = true;
          } else if (resp.messageCode === 'PASSWORD_NOT_UPDATED' || resp.messageCode === 'PASSWORD_INVALID' || resp.messageCode === 'REGISTRATION_UNSUCCESS'|| resp.messageCode ==='POLICY_ALREADY_REGISTERED') {
            this.registrationError = true;
            this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
          }
          this.showSpinner = false;
        }, (error: HttpErrorResponse) => {
          this.showSpinner = false;
		  this.registrationError = true;
          this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
        });
    } else {
      this.formValidator.validateAllFormFields(this.createPasswordForm);
    }
  }

  registeredAccount(event) {
    if (event.value) {
      this.registeredAccountAlreadyExists = event.value;
    }
  }

  onContinue() {
    if (this.registeredAccountAlreadyExists === 'EXISTING') {
      this.updateSafeToNavigate(true);
      this.router.navigate(['../login'], {
        relativeTo: this.route,
        state: {
          email: this.createPasswordForm.value.userName
        }
      });
    } else if (this.registeredAccountAlreadyExists === 'NEW_POLICY') {
      this.updateSafeToNavigate(true);
      this.router.navigate(['/eservice/link-policies'], {
        relativeTo: this.route,
        state: {
          email: this.createPasswordForm.value.userName
        }
      });
    }
  }

  cancelTransaction() {

    this.registrationProvider.invalidateEndorsementSession();
    // this.chooseDeactivate(true);
    this.updateSafeToNavigate(true);
    this.router.navigate(['../login'], {
      relativeTo: this.route,
    });
  }

}
