<header class="header bg-es-blue border-t-4 border-es-orange">
  <div class="flex items-center">
    <a>
      <img src="assets/svgs/brands/{{brand}}/header-logo.svg" class="logo">
    </a>
  </div>
</header>
<prcins-spinner  *ngIf="showSpinner"></prcins-spinner>
<div class="md:min-h-screen relative">
  <div class="flex justify-center w-full es-app-container">
    <div class="es-container w-full flex justify-between flex-wrap">
      <section class="es-main-container w-full">
        <div class="esign-body">
          <h1 class="es-h1 mt-4">Your Signature Is Required</h1>

          <p class="full-name">{{currentForm.fullName}}</p>
          <p>Policy Number: <span class="policy-number">{{currentForm.policyNumber}}</span></p>
          <br>
          <p>You have documents available to electronically sign before continuing to your online account. Please review and check
            each of the following documents. Sign by typing your name in the space below. Once done, we'll email you a copy of
            your signed forms.</p>
          <br>
          <ul class="form-list">
            <li class="form-name" *ngFor="let form of currentForm.esignFormsList"
                (click)="generateDocURL(form)">{{form.formDesc}}</li>
          </ul>
          <br>
          <div class="privacy-text">
            <p>
              By entering my name below and clicking on "Complete eSignature", I am providing a legally binding electronic
              signature confirming that all information on this application is correct and complete to the best of my knowledge.
              I have read and understand all documents listed above and agree to all of their terms and conditions. If any
              information on my insurance application or any other document listed above is inaccurate or if I do not understand
              any of the information, I should call Plymouth Rock or my Plymouth Rock agent for help.
            </p><br/>
          </div>

          <div class="signature">
            <input type="text" class="es-textfield w-full" value="{{currentForm.fullName}}" disabled>
            <p class="signature-hint">Please sign by typing your name exactly as shown here.</p>
            <input type="text" class="es-textfield w-full input-upper" placeholder="Signature" #signature>
            <p class="signature-error" *ngIf="showSignatureError">Signature should exactly match.</p>
            <input type="text" class="es-textfield w-full" value="{{currDateTime | date: 'short'}}" disabled>
            <button class="es-btn-primary w-full mt-4" (click)="submitESign(signature.value)">Complete eSignature</button>
          </div>
        </div>
      </section>
      <section class="es-right-container w-full">
        <prcins-card headerClass="text-es-orange" pt="pt-0">
          <ng-container header-left>
            <img src="assets/svgs/help.svg" alt="">
            <span class="ml-4">Helpful Information</span>
          </ng-container>
          <ng-container body>
            <div class="flex text-es-blue-light mb-2" *ngIf="currentForm?.variance?.state === 'NJ'">
              <img class="right-pointer" src="assets/svgs/down-icon-blue.svg" alt="">
              <a [href]="bgUrl" target="_blank">New Jersey Buyer's Guide</a>
            </div>
            <div class="flex text-es-blue-light mb-2" *ngIf="currentForm?.variance?.state === 'NJ'">
              <img class="right-pointer" src="assets/svgs/down-icon-blue.svg" alt="">
              <a [href]="brUrl" target="_blank">Bill of Rights</a>
            </div>
            <div class="flex text-es-blue-light mb-2">
              <img class="right-pointer" src="assets/svgs/down-icon-blue.svg" alt="">
              <a [href]="faqUrl" target="_blank">FAQ's</a>
            </div>
            <div class="flex text-es-blue-light mb-2">
              <img class="right-pointer" src="assets/svgs/down-icon-blue.svg" alt="">
              <a [href]="glossaryUrl" target="_blank">Glossary</a>
            </div>
          </ng-container>

        </prcins-card>
      </section>
    </div>
  </div>

</div>


<prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="showSkipESignPopup">
  <div header-left>
    <span>Unable to download document</span>
  </div>
  <div body>
    <p class="text-center text-lg">Your electronic signature is required on documents we previously sent to you in an
      email with the subject line “Important Forms for your Plymouth Rock Policy”. Please complete this action as soon
      as possible. If you do not see it in your inbox, remember to check your spam folder.</p>
    <div class="flex flex-wrap-reverse justify-center">
      <button class="es-btn-primary w-half-2 mt-8 cursor-pointer" type="button"
              (click)="skipESign()">Continue to eService
      </button>
    </div>
  </div>
</prcins-es-popup>
