import { Component, OnInit } from '@angular/core';
import { GENERAL_URL } from '@prcins/constants';
import packageJson from '../../../../../package.json';

@Component({
  selector: 'prcins-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  links;
  year = new Date().getFullYear()
  public version: string = packageJson.version;
  
  constructor() { }

  ngOnInit() {
    this.links = GENERAL_URL.urlFooter;
  }

}
