import { HttpClient } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { LOGIN_URL } from './constants/urls.constants';
import { PAYMENT_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PolicyLookupService {
    constructor(http) {
        this.http = http;
    }
    policyLookup(policy) {
        if (this.isQPayOpen(policy.policyNumber)) {
            return this.authenticate(policy);
        }
        return of({});
    }
    authenticate(payload) {
        console.log("payload", payload);
        return this.http.post(LOGIN_URL.Authenticate, payload).pipe(catchError((error) => {
            return throwError(error);
        }));
    }
    eDiscloserAudit(payload) {
        console.log("payload", payload);
        return this.http.post(LOGIN_URL.AuditUrl, payload).pipe(catchError((error) => {
            return throwError(error);
        }));
    }
    isQPayOpen(policyNumber) {
        const inActivePrefixes = [
            'PRU',
            'HPU',
            'MAA',
            'FFA',
            'TLA',
            'MWU'
        ];
        const prefix = policyNumber.substring(0, 3);
        const result = inActivePrefixes.indexOf(prefix) > -1 ? false : true;
        return result;
    }
    loadUserFromToken(token) {
        return this.http.post(`${PAYMENT_URL.TokenLoad}`, { token });
    }
}
PolicyLookupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PolicyLookupService_Factory() { return new PolicyLookupService(i0.ɵɵinject(i1.HttpClient)); }, token: PolicyLookupService, providedIn: "root" });
