<ng-container *ngIf="!showSpinner; else loading">
  <ng-container *ngIf="!eSignRequired || isAdminUser(); else eSign">
    <prcins-header></prcins-header>
    <prcins-policy-selector [registeredPolicies]="registeredPolicies"></prcins-policy-selector>
    <div class="md:min-h-screen relative">
      <main class="flex justify-center w-full es-app-container">
        <div class="es-container w-full flex justify-between flex-wrap">
          <section [ngClass]="policyStatusCode !== 'Incomplete' ? 'es-main-container w-full' : 'w-full'">
            <ng-container *ngIf="edocsNotification$ | async as eDocsNotification">
              <div class="px-4 mb-8 mt-4">
                <prcins-notification *ngIf="eDocsNotification" [notification]="eDocsNotification">
                </prcins-notification>
              </div>
            </ng-container>

            <ng-container *ngIf="eSignCompletedMsgDisplay">
              <div class="px-4 mb-8 mt-4">
                <prcins-notification [notification]="eSignSuccessNotification">
                </prcins-notification>
              </div>
            </ng-container>
            <router-outlet></router-outlet>
          </section>
          <section [ngClass]="{'w-full es-right-container' : policyStatusCode !== 'Incomplete'}">
            <prcins-right-rails></prcins-right-rails>
          </section>
        </div>
      </main>
      <prcins-footer></prcins-footer>
    </div>
  </ng-container>
</ng-container>

<ng-template #eSign>
  <div class="md:min-h-screen relative">
    <main class="es-app-container">
      <prcins-esign
        [eligibleForms]="eSignEligibleForms"
        (eSignCompleted)="eSignRequired = false; eSignCompletedMsgDisplay = true;"
        (eSignSkipped)="eSignRequired = false; eSignCompletedMsgDisplay = false;"
      ></prcins-esign>
    </main>
    <prcins-footer></prcins-footer>
  </div>
</ng-template>

<ng-template #loading>
  <prcins-spinner></prcins-spinner>
</ng-template>