   <div class="mt-4 mb-4">
      <div class="border rounded border-gray-300">
        <div *ngIf="productCode !== 'PAIP'" class="flex bg-es-sky-blue">
         
            <div style="background-color: #ff6319;" class="flex items-center w-3/12 sm:w-2/12 px-4 py-4 rounded-l">
               <img src="assets/png/eDocs 3-Leaf_icon_2x.png">
             </div>
             <div class="small-banner flex flex-wrap w-9/12 py-2">
               <div class="flex flex-col w-full px-2">
                  <strong>Get your documents faster!</strong>
                  <div>Go paperless with eDocuments and save paper. Sign up and you can receive policy and billing docs by email.</div>
               </div>
               <div class="flex flex-col w-full items-end px-2 py-2">
                  <div><a class="es-btn-primary" (click)="goToEdocs()" >Enroll now</a></div>               
               </div>
            </div>   
            <div class="large-banner flex w-10/12 py-2">
               <div class="flex flex-col w-3/5 px-2">
                  <strong>Get your documents faster!</strong>
                  <div>Go paperless with eDocuments and save paper. Sign up and you can receive policy and billing docs by email.</div>
               </div>
               <div class="flex flex-col w-2/5 justify-center px-3">
                  <div><a class="es-link-primary" (click)="goToEdocs()">Enroll now</a></div>               
               </div>
            </div>         
         </div>
        
      </div>
   </div>

   