import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import { Logger } from '../logger/logger';

@Injectable()
export class LoggingErrorHandler extends ErrorHandler {

  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any): void {
    super.handleError(error);

    try {
      if (error instanceof Error) {
        this.logStackTrace(error, StackTrace.fromError(error));
      } else {
        this.logStackTrace(error, Promise.resolve(['']));
      }
    } catch (e) {
      console.error('Error handler threw exception : ', e, 'Error for handling was : ', error);
    }
  }

  private logStackTrace(error: any, stack: Promise<any>) {
    const logger = this.getLogger();
    const errorMessage = this.getErrorMessage(error);
    const url = this.getUrl(error);

    stack.then((stackframes) => Promise.resolve(stackframes.map((sf) => sf.toString()).join('\n')))
      .then((stackString) => logger.error(this.createLoggerPayload(errorMessage, url, stackString), error))
      .catch((err) => logger.error('Unable to generate error stack trace : ' + this.getErrorMessage(err), error));
  }

  private createLoggerPayload(errorMessage: string, url = '', stackString = ''): any {
    return {
      url,
      message: JSON.stringify({
        errorMessage,
        stackTrace: stackString
      }, null, 0),
    };
  }

  private getLogger(): Logger {
    return this.injector.get(Logger);
  }

  private getUrl(error: any): string {
    if (error instanceof HttpErrorResponse) {
      return error.url;

    } else {
      const location = this.injector.get(LocationStrategy);
      return location instanceof PathLocationStrategy ? location.path() : '';
    }
  }

  private getErrorMessage(error: any): string {
    return error.message ? error.message : error.toString();
  }

}
