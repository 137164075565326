import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secureMask'
})
export class MaskPipe implements PipeTransform {

  transform(value: any, mask: string): any {
    if (!mask) {
      return value;
    }

    const padding = value.length - mask.length;
    if (padding > 0) {
      mask = mask.padStart(value.length, '*');
    } else if (padding < 0) {
      value = value.padStart(mask.length, '*');
    }

    return value.split('').reduce((response, character, index) => {
      if (mask.charAt(index) === '*') {
        response = response.concat('*');
      } else {
        response = response.concat(character);
      }
      return response;
    }, "");
  }
}
