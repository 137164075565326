import { Directive, Input, OnInit, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { TealiumUtagService } from '../services/tealium/utag.service';
import { TealiumTrackerService } from '../services/tealium/tealium-tracker.service';

@Directive({
  selector: '[prcins-tealium-tracker]'
})
export class ClickTrackerDirective {

  @Input('prcins-tealium-tracker')
  eventType;

  @Input('prcins-tealium-evar')
  evarData;

  @Input('prcins-tealium-event')
  eventName;

  listener;

  constructor(private tealiumTracker: TealiumTrackerService, private elRef: ElementRef, private renderer: Renderer2) { 
    
  }

  ngOnInit() {
    if (this.eventType == 'load') { 
      this.eventHandler(null);
     
    } else {
      this.listener = this.renderer.listen(this.elRef.nativeElement, this.eventType, e => this.eventHandler(e));
    }
  }
  
  eventHandler(event: any) {
    let eventContext = { event_name: this.eventName };
    let pageContext = this.tealiumTracker.pageContext;
    Object.assign(eventContext, pageContext, this.evarData);
    this.tealiumTracker.view(eventContext);
    console.log(pageContext);
  }

}
