import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { Observable, BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
export class RegistrationService {
    constructor(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.userRegistrationForm$ = new BehaviorSubject({});
        this.userRegistrationFormObservable = this.userRegistrationForm$.asObservable();
        this.verifyUserRegisteredOrNot$ = new BehaviorSubject({});
        this.verifyUserRegisteredOrNotObservable = this.verifyUserRegisteredOrNot$.asObservable();
        this.apiUrl = environment.BASE_URL + "/registration/v2/account/";
    }
    getPasswordActionTypeInfo() {
        return sessionStorage.passwordActionType;
    }
    getUserDetails(verificationId) {
        return this.http.get(this.apiUrl + 'user/verify/' + verificationId);
    }
    userDetails(details) {
        return this.http.post(this.apiUrl + 'user/verify', details);
    }
    selectValidationMethod(mode) {
        return this.http.post(this.apiUrl + 'token', mode);
    }
    verifyAuthorizationCode(code) {
        return this.http.post(this.apiUrl + 'token/verify', code);
    }
    forgotEmail(account) {
        return this.http.post(this.apiUrl + 'user/forgotEmail', account);
    }
    forgotPassword(info) {
        return this.http.post(this.apiUrl + 'user/forgotPassword', info);
    }
    changePassword(info) {
        return this.http.post(this.apiUrl + 'user/changePassword', info);
    }
    createAccount(account) {
        return this.http.post(this.apiUrl + 'user/createAccount', account);
    }
    linkPolicy(linkPolicyRequest) {
        return this.http.post(this.apiUrl + 'user/linkPolicy', linkPolicyRequest);
    }
    checkIfPolicyAlreadyRegisteredInEservice(policyNumber) {
        return this.http.get(this.apiUrl + 'user/checkPolicyRegistered/' + policyNumber);
    }
    // {
    //   "billingTextAlertPhoneNumber": "4012980927",
    //   "dob": "1983-01-16",
    //   "enrollBillingTextAlert": true,
    //   "enrollEDOC": true,
    //   "password": "test1test",
    //   "policyNumber": "PRA00008096323",
    //   "userName": "htandon@plymouthrock.com",
    //   "zipCode": "02191"
    // }
    createQuickPayAccount(newAccount) {
        return this.http.post(this.apiUrl + 'user/quickPay/createAccount', newAccount);
    }
    invalidateEndorsementSession() {
        sessionStorage.removeItem('verificationId');
        sessionStorage.removeItem('passwordActionType');
        sessionStorage.removeItem('origin');
    }
}
RegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: RegistrationService, providedIn: "root" });
