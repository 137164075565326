export enum Message {
  STARTED = 'STARTED',
  CONTINUE_REGISTRATION = 'CONTINUE_REGISTRATION',
  REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
  DASHBOARD = 'DASHBOARD',
  LOGIN = 'LOGIN',
  AUTHENTICATE = 'AUTHENTICATE',
  LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL',
  NO_POLICY_FOUND = "Oops! We can't seem to find you. Please try that again.",
  POLICY_TYPE_MISMATCH = "Oops! That policy number doesn't go with the type of policy you selected. Please try that policy number or the policy type again.",
  EMAIL_ALREADY_REGISTERED = "Oops! Did you forget you already signed up? If you <a class='underline font-bold text-es-blue-light' href='/eservice/login'>forgot your password</a>, don't sweat it! Or <a class='underline font-bold text-es-blue-light' href='https://www.plymouthrock.com/contact-us' target='_blank'>give us a call</a>."
}
