import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
export class OpenVehicleAmendmentService {
    // apiUrl = "http://apis.dev.prcaws.net/eservice-ws/v2/";
    constructor(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.apiUrl = environment.BASE_URL + "/v2/";
    }
    getCurrentPolicyInfo() {
        return sessionStorage.currentPolicy;
    }
    getGUID() {
        return sessionStorage.guid;
    }
    getActionTypeInfo() {
        return sessionStorage.actionType;
    }
    endorsementConfig(policyNumber) {
        return this.apollo.watchQuery({
            query: VehicleEndorsementConfigQuery,
            fetchPolicy: 'network-only',
            variables: {
                policyNumber
            }
        }).valueChanges;
    }
    getEndorsementConfig(policyNumber) {
        return this.endorsementConfig(policyNumber).pipe(map(result => result.data.getEndorsementResourceConfig));
    }
    recordEndorsmentBlocked(type, guid) {
        let policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.put(this.apiUrl + 'core/' + policyNum + '/endorsements/recordEndorsmentBlocked?type=' + type + (guid ? '&guid=' + guid : ''), {});
    }
    createEndorsement(type) {
        let policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.post(this.apiUrl + 'core/' + policyNum + '/endorsements?type=' + type, {});
    }
    cancelEndorsement() {
        let policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : sessionStorage.getItem('splPolicyNumber');
        let guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : sessionStorage.getItem('splGuid');
        let url = this.apiUrl + 'core/' + policyNum + '/endorsements/' + guid;
        return this.http.delete(url);
    }
    rateEndorsement() {
        let policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : '';
        let guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : '';
        return this.http.put(this.apiUrl + 'core/' + policyNum + '/endorsements/' + guid + '/rate', {});
    }
}
OpenVehicleAmendmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpenVehicleAmendmentService_Factory() { return new OpenVehicleAmendmentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: OpenVehicleAmendmentService, providedIn: "root" });
export const VehicleEndorsementConfigQuery = gql `
query GetEndorsementResourceConfig($policyNumber: String) {
  getEndorsementResourceConfig(policyNumber: $policyNumber) {
    addVehicle
    updateVehicle
    deleteVehicle
    replaceVehicle
 }
}
`;
