import gql from 'graphql-tag';

export const PolicyDocumentQuery = gql`
  query PolicyDocuments($policyNumber: String, $documentType: String) {
    policyDocuments(policyNumber: $policyNumber, documentType: $documentType) {
      docId
      fileNumber
      docDate
      docType
      docName
      accessToken
   }
  }
`;

export const DocumentQuery = gql`
  query DocumentQuery($policyNumber: String, $documentType: String) {
    policyDocuments(policyNumber: $policyNumber, documentType: $documentType) {
      docId
      fileNumber
      docDate
      docType
      docName
      accessToken
      description
   }
  }
`;



