import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Directive for text input fields that need to have input values converted to uppercase equivalents.
 * Solution from https://stackoverflow.com/questions/46825078/directive-to-upper-case-input-fields.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[type="text"], input[type="email"]',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '(input)': '$event'
  }
})

export class InputtrimDirective {


  lastValue: string;
  upperCase = 'blur';
  constructor(public ref: ElementRef) { }

  @HostListener('blur', ['$event.target', '$event.target.value'])
  onBlur(el: any, value: string): void {

    if (value) {

      this.convertValue(el, value);
      //this.dispatchEvent(el, 'blur'); // in case updateOn is set to blur

    }

  }
  private convertValue(el, value) {

    el.value = value.trim();

    this.dispatchEvent(el, 'input');

  }
  private dispatchEvent(el, eventType) {

    const event = document.createEvent('Event');
    event.initEvent(eventType, false, false);
    el.dispatchEvent(event);

  }

}
