import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { DiscardComponent } from '../discard-component/DiscardComponent';

@Injectable({
  providedIn: 'root'
})
export class DiscardChangesGuard implements CanDeactivate<DiscardComponent>  {
  canDeactivate(component: DiscardComponent): boolean | Observable<boolean> {
    return component.isSafeToNavigate
      ? component.isSafeToNavigate
      : component.promptDeactivate();
  }
}
