import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { IPolicy, PolicyBroadcasterService } from '@prcins/utils';
import {
  CURRENT_POLICY,
  CURRENT_POLICY_LOB,
  USER_FNAME,
  USER_LNAME,
  CURRENT_POLICY_PRIME,
  CURRENT_POLICY_STATE,
  CURRENT_POLICY_PRODUCT_CODE,
  CURRENT_POLICY_STATUS,
  CURRENT_POLICY_COMPANY_CODE, GUEST_USER_ID
} from '@prcins/constants';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'prcins-policy-selector',
  templateUrl: './policy-selector.component.html',
  styleUrls: ['./policy-selector.component.css']
})
export class PolicySelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() registeredPolicies: IPolicy[];
  allowedPolicies: IPolicy[];
  isLarge: boolean;
  boundEvent;
  isHome: boolean;
  hide = false;
  noSelector = false;
  loaded = false;

  constructor(
    private policyBroadcaster: PolicyBroadcasterService,
    private router: Router
  ) { }

  isPreview() {
    return sessionStorage.getItem(GUEST_USER_ID) !== null;
  }

  ngOnInit() {
    this.boundEvent = this.setWindowSize.bind(this);
    this.boundEvent();
    window.addEventListener('resize', this.boundEvent);

    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        this.checkRouter(routerEvent.url);
      }
    });
    console.log(this.registeredPolicies);
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (this.registeredPolicies && this.registeredPolicies.length > 0) {

      this.checkRouter(this.router.url);
      if (sessionStorage.getItem(CURRENT_POLICY)) {
        const matchedPolicy = this.registeredPolicies.find(
          p => p.policyNumber === sessionStorage.getItem(CURRENT_POLICY)
        );
        if (matchedPolicy) {
          this.onPolicySelected(matchedPolicy);
        } else {
          this.onPolicySelected(this.registeredPolicies[0]);
        }
      } else {
        this.onPolicySelected(this.registeredPolicies[0]);
      }
      setTimeout(() => {
        this.loaded = true;
      }, 0);
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.boundEvent);
  }

  setWindowSize() {
    const viewportWidth = document.documentElement.clientWidth;
    if (viewportWidth >= 768 && !this.isLarge) {
      this.isLarge = true;
    } else if (viewportWidth < 768 && this.isLarge) {
      this.isLarge = false;
    }
  }

  onPolicySelected(policy: IPolicy) {
    const previousPolicy = sessionStorage.getItem(CURRENT_POLICY);
    const me = this;
    sessionStorage.setItem(USER_FNAME, policy.policyInfo.firstName);
    sessionStorage.setItem(USER_LNAME, policy.policyInfo.lastName);
    sessionStorage.setItem(CURRENT_POLICY, policy.policyNumber);
    sessionStorage.setItem(CURRENT_POLICY_LOB, policy.policyInfo.lob);
    sessionStorage.setItem(CURRENT_POLICY_STATUS, policy.policyInfo.statusCode);
    sessionStorage.setItem(CURRENT_POLICY_COMPANY_CODE, policy.policyInfo.companyCode);

    sessionStorage.setItem(
      CURRENT_POLICY_PRODUCT_CODE,
      policy.policyInfo.productCode
    );
    sessionStorage.setItem(CURRENT_POLICY_STATE, policy.policyInfo.state);
    sessionStorage.setItem(
      CURRENT_POLICY_PRIME,
      policy.policyInfo.isPrime ? 'Y' : 'N'
    );
    setTimeout(() => {
      me.policyBroadcaster.broadcastSelectedPolicy(policy, previousPolicy);
    }, 100);
  }

  checkRouter(url: string) {
    this.hide = false;
    this.isHome = false;
    this.noSelector = false;
    if (url === '/eservice/home') {
      this.isHome = true;
      this.noSelector = false;
      const currentPolicy = sessionStorage.getItem(CURRENT_POLICY);
      if (currentPolicy && this.loaded) {
        const policy = this.registeredPolicies.find(
          p => p.policyNumber === currentPolicy
        );
        this.onPolicySelected(policy);
      }
    } else if (
      url.indexOf('/documents/manage-edocs') !== -1 ||
      url.indexOf('/home/account/') !== -1 ||
      url.indexOf('/home/link-a-policy') !== -1
    ) {
      this.hide = true;
    } else if (
      url.indexOf('/payment/entry') !== -1 ||
      url.indexOf('/payment/review') !== -1 ||
      url.indexOf('/payment/confirm') !== -1 ||
      url.indexOf('/billing/change-plan') !== -1 ||
      url.indexOf('/address/change') !== -1 ||
      (url.indexOf('/claims/claimslist') === -1 && url.indexOf('/claims') !== -1)
    ) {
      this.noSelector = true;
    }

    if (url.indexOf('/documents/idcards') !== -1) {
      this.allowedPolicies = this.registeredPolicies.filter(
        r => !(r.policyInfo.lob === "CA" && (r.policyInfo.productCode === "PCAT" || r.policyInfo.productCode === "ALN_UMB"))
      );
    } else if (url.indexOf('/home/address') !== -1) {
      this.allowedPolicies = this.registeredPolicies.filter(
        r => r.policyInfo.lob !== "CA"
      );
    } else if (
      url.indexOf('/policy/drivers') !== -1 ||
      url.indexOf('/policy/vehicles') !== -1 ||
      url.indexOf('/policy/riders') !== -1
    ) {
      this.allowedPolicies = this.registeredPolicies.filter(
        r => r.policyInfo.lob === 'PA' || r.policyInfo.lob === 'MC'
      );
    } else {
      this.allowedPolicies = this.registeredPolicies;
    }
  }
}

