import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { FormValidatorService, INotification, AiEnrollmentService, RegistrationService } from '@prcins/utils';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { USER_ID } from '@prcins/constants';
import { LoginService } from '@prcins/utils';

@Component({
  selector: 'prcins-enroll-edocs',
  templateUrl: './enroll-edocs.component.html',
  styleUrls: ['./enroll-edocs.component.css']
})
export class EnrollEdocsComponent implements OnInit {
  @Input() invitationDetails;
  verificationId;
  enrollForm: FormGroup;
  email: string;
  policyNumber: string;
  showSpinner: boolean;
  notification: INotification;
  additionalInvitationMessage: string;

  constructor(private fb: FormBuilder,
    private formValidator: FormValidatorService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private aiEnrollmentService: AiEnrollmentService,
    private registrationProvider: RegistrationService) { }

  ngOnInit() {
    this.verificationId = sessionStorage.getItem('verificationId');
    this.enrollForm = this.fb.group({
      // zipCode: ['', [Validators.required, Validators.pattern(/^(\d{5}-\d{4}|\d{5})$/)]],
      agreeTerms: [false, [Validators.required, this.termValidator()]]
    });

    let { userId, policyNumber } = this.route.snapshot.params;
    if (this.invitationDetails) {
      this.email = this.invitationDetails.emailAddress ? this.invitationDetails.emailAddress.toLowerCase() : this.invitationDetails.emailAddress;
      this.policyNumber = this.invitationDetails.invitedPolicyNumber ? this.invitationDetails.invitedPolicyNumber.toUpperCase() : this.invitationDetails.invitedPolicyNumber;
      this.additionalInvitationMessage = this.invitationDetails && this.invitationDetails.additionalMessage ? this.invitationDetails.additionalMessage : "";

      this.router.events.subscribe(routerEvent => {
        if (routerEvent instanceof NavigationEnd) {
          if (sessionStorage.getItem('verificationId')) {
            this.aiEnrollmentService.abandonRequest(this.invitationDetails.landingPage, this.verificationId).subscribe((resp) => {
              this.registrationProvider.invalidateEndorsementSession();
            });
          }
        }
      });

    } else {
      this.email = userId;
      this.policyNumber = policyNumber;
    }
  }

  onEnroll() {
    console.log(this.enrollForm);
    if (this.enrollForm.valid) {
      this.showSpinner = true;
      this.notification = null;



      let payload = {
        "policyNumber": this.policyNumber,
        "agreementType": "EDOCENROLL",
        "agreementType2": "ELECTRONICCONSENT",
        "source": "ESERVICE",
        "email": this.email
      }

      this.loginService.eDiscloserAudit(payload).subscribe(data => console.log(data));

      if (this.verificationId && this.invitationDetails) {
        const request = {
          userName: this.invitationDetails.emailAddress.toLowerCase(),
          verificationId: this.verificationId,
          zipCode: this.enrollForm.value.zipCode
        };

        this.aiEnrollmentService
          .aiEdocEnrollment(request)
          .subscribe((result: any) => {
            if (result.messageCode === 'edocs.update.success') {
              this.notification = { type: 'INFO', message: 'Congratulations! You have successfully changed your eDoc preferences.' };
            } else if (result.messageCode === 'ZIP_CODE_MISMATCH') {
              this.notification = { type: 'ERROR', message: 'We are unable to find an account for the information you provided. Please make sure the information is correct. If you still cannot sign up, please contact us.' };
            } else if (result.messageCode === 'ALREADY_ENROLLED') {
              this.notification = { type: 'ERROR', message: 'You have already signed up for eDocuments!' };
            } else {
              this.notification = { type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' };
            }
            this.showSpinner = false;
            sessionStorage.removeItem('verificationId');

          }, error => {
            this.notification = { type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' }
            this.showSpinner = false;
          });
      } else {
        const request = {
          emailAddress: this.email,
          policyNumber: this.policyNumber,
          source: "email",
          subscriptionFlag: "Y",
          // zipCode: this.enrollForm.value.zipCode
        };
        this.loginService.enrollEdocs(request).subscribe(
          result => {
            if (result.messageCode === 'edocs.update.success') {
              this.showSpinner = false;
              if (result.route === 'LOGIN') {
                this.router.navigate(['/eservice/login'], { state: { edocRedirectStatus: "SUCCESS", email:  request.emailAddress} });
              } else if (result.route === 'REGISTER') {
                this.router.navigate(['/eservice/register'], { state: { edocRedirectStatus: "SUCCESS" } });
              } else {
                this.notification = { type: 'INFO', message: 'Congratulations! You have successfully changed your eDoc preferences.' };
              }
            } else if (result.errorMsg === 'zip.mismatch') {
              this.showSpinner = false;
              this.notification = { type: 'ERROR', message: 'We are unable to find an account for the information you provided. Please make sure the information is correct. If you still cannot sign up, please contact us.' };
            } else if (result.errorMsg === 'alreadySigned') {
              this.showSpinner = false;
              this.notification = { type: 'ERROR', message: 'You have already signed up for eDocuments!' };
            } else {
              this.showSpinner = false;
              this.notification = { type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' };
            }
          },
          error => {
            this.notification = { type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' }
            this.showSpinner = false;
          }
        );
      }

    } else {
      this.formValidator.validateAllFormFields(this.enrollForm);
    }
  }
  termValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value ? null : { agreeTerms: true };
    };
  }

}

