import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class PolicyBroadcasterService {
    constructor(router, route) {
        this.router = router;
        this.route = route;
        this.policySelectedSource = new Subject();
        this.policyLoaded = new Subject();
        this.billingLoaded = new Subject();
        this.policyListLoaded = new Subject();
        this.policySelected$ = this.policySelectedSource.asObservable();
        this.billingLoaded$ = this.billingLoaded.asObservable();
        this.policyLoaded$ = this.policyLoaded.asObservable();
        this.policyListLoaded$ = this.policyListLoaded.asObservable();
    }
    broadcastSelectedPolicy(policy, previousPolicy) {
        this.policySelectedSource.next(policy);
        if (previousPolicy !== policy.policyNumber) {
            this.router.navigate(['/eservice/home'], { relativeTo: this.route });
        }
    }
    broadcastPolicyLoaded(policyDetails) {
        this.policyLoaded.next(policyDetails);
    }
    broadcastBillingLoaded(billingDetails) {
        this.billingLoaded.next(billingDetails);
    }
    broadcastPolicyListLoaded() {
        this.policyListLoaded.next();
    }
}
PolicyBroadcasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PolicyBroadcasterService_Factory() { return new PolicyBroadcasterService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute)); }, token: PolicyBroadcasterService, providedIn: "root" });
