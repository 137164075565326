import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@prcins/environments";
import { CURRENT_POLICY, TOKEN } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RenewalquestionnaireService {
    constructor(handler, http) {
        this.handler = handler;
        this.http = http;
        this.httpClient = new HttpClient(handler);
    }
    getDriverDetails(pol) {
        console.log("inside the renewalquestion service and calling a post");
        console.log(environment.BASE_URL);
        const headers = new HttpHeaders({
            'Authorization': 'Dearer ' + sessionStorage.getItem(TOKEN),
            'Content-Type': 'application/json',
            'pn': sessionStorage.getItem(CURRENT_POLICY)
        });
        return this.http.post('/rq/rq-submit', pol, { headers });
    }
}
RenewalquestionnaireService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RenewalquestionnaireService_Factory() { return new RenewalquestionnaireService(i0.ɵɵinject(i1.HttpBackend), i0.ɵɵinject(i1.HttpClient)); }, token: RenewalquestionnaireService, providedIn: "root" });
