import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "@prcins/environments";


@Injectable({
  providedIn: 'root'
})
export class EsignService {
  private httpClient: HttpClient;

  constructor(
    private handler: HttpBackend,
    private http: HttpClient
  ) {
    this.httpClient = new HttpClient(handler);
  }

  getEligibleForms(policies: any) {
    const body = {policies};
    return this.http.post(environment.BASE_URL + "/esign/eligible/forms", body);

  }

  viewDoc(policyNumber: any, eSignId: any, formId: any, token: any) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const apiUrl = environment.BASE_URL + `/esign/forms/pdf/${policyNumber}/${eSignId}/${formId}`;

    return this.httpClient.get(apiUrl, {headers: headers, responseType: 'text'});
  }

  submitESign(currentForm: any) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${currentForm.token}`
    });
    const apiUrl = environment.BASE_URL + `/esign/forms`;
    const body = {
      "policyNumber": currentForm.policyNumber,
      "firstName": currentForm.firstName,
      "lastName": currentForm.lastName,
      "prefix": null,
      "initial": null,
      "sob": "AI",
      "esignId": currentForm.eSignId,
      "fid": null,
      "esignDate": null,
      "appName": "ESERVICE"
    };
    return this.httpClient.post(apiUrl, body, {headers: headers})
  }
}
