/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tooltip.component";
var styles_TooltipComponent = [i0.styles];
var RenderType_TooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipComponent, data: {} });
export { RenderType_TooltipComponent as RenderType_TooltipComponent };
export function View_TooltipComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { element: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["tooltip", 1]], null, 3, "div", [["class", "tooltip text-es-blue-light"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵncd(null, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "tooltiptext ", _co.yStyle, " ", _co.xStyle, ""); _ck(_v, 3, 0, currVal_0); }); }
export function View_TooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-tooltip", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i1.ɵdid(1, 4308992, null, 0, i2.TooltipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TooltipComponentNgFactory = i1.ɵccf("prcins-tooltip", i2.TooltipComponent, View_TooltipComponent_Host_0, {}, {}, ["[tooltip-label]", "[tooltip-text]"]);
export { TooltipComponentNgFactory as TooltipComponentNgFactory };
