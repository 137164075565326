import { Apollo } from 'apollo-angular';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentQuery, EdocsListQuery, PolicyInfoQuery } from '@prcins/utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DOCUMENTS_URL } from '../constants/documents.urls';
import { EDOCS_URL, DOCS_URL, LOGIN_URL } from '@prcins/constants';
import { environment } from '@prcins/environments';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "@angular/common/http";
export class DocumentService {
    constructor(apollo, httpClient) {
        this.apollo = apollo;
        this.httpClient = httpClient;
        this.CACHE = {};
        this.docsServiceError$ = new Subject();
    }
    getDocuments(policyNumber, documentType) {
        return this.apollo
            .watchQuery({
            query: DocumentQuery,
            variables: {
                policyNumber,
                documentType
            }
        })
            .valueChanges.pipe(map(({ data }) => {
            const sortedData = data.policyDocuments;
            if (sortedData.length > 0) {
                sortedData.sort((a, b) => {
                    return b.docDate - a.docDate;
                });
            }
            return sortedData;
        }));
    }
    getFirstName(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyInfoQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data.policySearchInfo.firstName));
    }
    requestAutoIdCard(userId, policyNumber, term, actionType) {
        const params = new HttpParams({})
            .set("actionType", actionType);
        return this.httpClient.post(`${DOCUMENTS_URL.auto.request}/${userId}/${policyNumber}/${term}`, {}, { params });
    }
    requestToken(policyNumber, docType) {
        const params = new HttpParams({});
        return this.httpClient.get(`${DOCUMENTS_URL.requestToken}/${policyNumber}/${docType}`);
    }
    getEdocsList(username) {
        return this.apollo
            .watchQuery({
            query: EdocsListQuery,
            variables: {
                username
            },
            fetchPolicy: 'network-only'
        })
            .valueChanges.pipe(map(({ data }) => data.account));
    }
    saveEdocsPreference(updatedEdocs, policies) {
        const updatePostList = policies.map(policyNumber => {
            const { emailAddress, subscriptionFlag } = updatedEdocs[policyNumber];
            return this.httpClient.post(EDOCS_URL.SavePreference, {
                policyNumber,
                emailAddress,
                subscriptionFlag,
                source: 'CI'
            });
        });
        return forkJoin([...updatePostList]);
    }
    eDiscloserAudit(payload) {
        return this.httpClient.post(LOGIN_URL.AuditUrl, payload);
    }
    getClaimEstimateDocuments(policyNumber, claimNumber) {
        const claimEstimateURL = `${environment.BASE_URL}/docs/${policyNumber}/claims/${claimNumber}/estimates`;
        return this.httpClient.get(claimEstimateURL).pipe(map((data) => {
            const sortedData = data;
            if (sortedData.length > 0) {
                sortedData.sort((a, b) => {
                    return b.docDate - a.docDate;
                });
            }
            return sortedData;
        }));
    }
    validateDeclarationPageAccess(policyNumber) {
        return this.httpClient.get(`${DOCS_URL.validateDecPage}/${policyNumber}`);
    }
    pdfURL(url) {
        //return this.httpClient.get(url);
        return this.httpClient.get(url, { responseType: 'arraybuffer' });
    }
    buildDeclarationPageUrl(policyNumber, token) {
        return `${DOCS_URL.downloadDeclaration}/${policyNumber}`;
    }
    checkIfDocumentAvailableInIR(documentURL) {
        return this.httpClient.get(documentURL, { responseType: 'arraybuffer' });
    }
}
DocumentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentService_Factory() { return new DocumentService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.HttpClient)); }, token: DocumentService, providedIn: "root" });
