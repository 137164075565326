/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-conditions.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./terms-conditions.component";
var styles_TermsConditionsComponent = [i0.styles];
var RenderType_TermsConditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsConditionsComponent, data: {} });
export { RenderType_TermsConditionsComponent as RenderType_TermsConditionsComponent };
export function View_TermsConditionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "flex justify-center text-xs mt-8"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["By signing up, you agree to Plymouth Rock's "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "ml-1 text-es-blue-light"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["terms and conditions"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.terms, ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_TermsConditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-terms-conditions", [], null, null, null, View_TermsConditionsComponent_0, RenderType_TermsConditionsComponent)), i1.ɵdid(1, 49152, null, 0, i2.TermsConditionsComponent, [], null, null)], null, null); }
var TermsConditionsComponentNgFactory = i1.ɵccf("prcins-terms-conditions", i2.TermsConditionsComponent, View_TermsConditionsComponent_Host_0, {}, {}, []);
export { TermsConditionsComponentNgFactory as TermsConditionsComponentNgFactory };
