import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@prcins/environments';

@Injectable({
  providedIn: 'root'
})
export class UploadAdminDocsService {

  constructor(private http: HttpClient) { }

  uploadBulkInviteDocument(formData = new FormData()): Observable<any> {
    const url = environment.BASE_URL + '/admin/uploadFileToS3/' ;
    return this.http.post(url, formData);
  }

  uploadEndorsementBlockDocument(formData = new FormData()): Observable<any> {
    const url = environment.BASE_URL + '/endorsement/block/config/upload';
    return this.http.post(url, formData);
  }

}
