export const STATES = {
  CT: { id: "CT", name: "Connecticut" },
  MA: { id: "MA", name: "Massachusetts" },
  NH: { id: "NH", name: "New Hampshire" },
  NJ: { id: "NJ", name: "New Jersey" },
  PA: { id: "PA", name: "Pennsylvania" },
  NY: { id: "NY", name: "New York" }
};

export const FULL_STATES = {
  AL: { id: "AL", name: "Alabama" },
  AK: { id: "AK", name: "Alaska" },
  AZ: { id: "AZ", name: "Arizona" },
  AR: { id: "AR", name: "Arkansas" },
  CA: { id: "CA", name: "California" },
  CO: { id: "CO", name: "Colorado" },
  CT: { id: "CT", name: "Connecticut" },
  DE: { id: "DE", name: "Delaware" },
  DC: { id: "DC", name: "District of Columbia" },
  FL: { id: "FL", name: "Florida" },
  GA: { id: "GA", name: "Georgia" },
  HI: { id: "HI", name: "Hawaii" },
  ID: { id: "ID", name: "Idaho" },
  IL: { id: "IL", name: "Illinois" },
  IN: { id: "IN", name: "Indiana" },
  IA: { id: "IA", name: "Iowa" },
  KS: { id: "KS", name: "Kansas" },
  KY: { id: "KY", name: "Kentucky" },
  LA: { id: "LA", name: "Louisiana" },
  ME: { id: "ME", name: "Maine" },
  MD: { id: "MD", name: "Maryland" },
  MA: { id: "MA", name: "Massachusetts" },
  MI: { id: "MI", name: "Michigan" },
  MN: { id: "MN", name: "Minnesota" },
  MS: { id: "MS", name: "Mississippi" },
  MO: { id: "MO", name: "Missouri" },
  MT: { id: "MT", name: "Montana" },
  NE: { id: "NE", name: "Nebraska" },
  NV: { id: "NV", name: "Nevada" },
  NH: { id: "NH", name: "New Hampshire" },
  NJ: { id: "NJ", name: "New Jersey" },
  NM: { id: "NM", name: "New Mexico" },
  NY: { id: "NY", name: "New York" },
  NC: { id: "NC", name: "North Carolina" },
  ND: { id: "ND", name: "North Dakota" },
  OH: { id: "OH", name: "Ohio" },
  OK: { id: "OK", name: "Oklahoma" },
  OR: { id: "OR", name: "Oregon" },
  PA: { id: "PA", name: "Pennsylvania" },
  RI: { id: "RI", name: "Rhode Island" },
  SC: { id: "SC", name: "South Carolina" },
  SD: { id: "SD", name: "South Dakota" },
  TN: { id: "TN", name: "Tennessee" },
  TX: { id: "TX", name: "Texas" },
  UT: { id: "UT", name: "Utah" },
  VT: { id: "VT", name: "Vermont" },
  VA: { id: "VA", name: "Virginia" },
  WA: { id: "WA", name: "Washington" },
  WV: { id: "WV", name: "West Virginia" },
  WI: { id: "WI", name: "Wisconsin" },
  WY: { id: "WY", name: "Wyoming" }
};
