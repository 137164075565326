<form [formGroup]="passwordForm">
  <div class="flex flex-wrap">
    <div class="w-full sm:w-2/2 md:w-3/3 lg:w-4/6 xl:w-4/6">
      <!-- Email -->
      <ng-container *ngIf="actionType === 'Create'">
        <div class="flex items-center justify-between flex-wrap mt-4 userName">
          <label class="mb-2 font-semibold" for=""></label>
          <i class="lg:w-3/5">This will be your username</i>
        </div>
        <div class="flex items-center justify-between flex-wrap mt-1">
          <label class="mb-2 font-semibold" for="">Email Address</label>
          <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="userName">
        </div>
        <div class="flex lg:justify-end">
          <prcins-error-label [control]="passwordForm.get('userName')"
            [controlValue]="passwordForm.get('userName').value" controlLabel="passwordForm_userName"
            [controlTouched]="passwordForm.get('userName').touched">
          </prcins-error-label>
        </div>
      </ng-container>
      <!-- Forgot Password Email -->
      <ng-container *ngIf="actionType === 'Reset' || actionType === 'linkPolicies'">
        <div class="flex flex-wrap mt-1">
          <label>Please set a new password for: </label>
          <p class="ml-2 font-semibold">{{passwordForm.controls['userName'].value}}</p>
        </div>
      </ng-container>
      <!-- Date of Birth -->
      <ng-container *ngIf="passwordForm.controls.dob">
        <div class="flex items-center justify-between flex-wrap mt-4">
          <label class="mb-2 font-semibold" for="">Date of Birth</label>
          <div class="flex flex-wrap w-full lg:w-3/5">
            <input type="tel"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="10" appSecureUSDateFormatMask placeholder="MM/DD/YYYY" formControlName="dob"
              class="w-10/12 lg:w-10/12 loginHelptext es-textfield">
            <span class="add-form-help-text" style="margin-top: 5px;">
              <prcins-help-text id="primaryUse" title="Date of Birth"
                text="The date of birth should match the first or second named insured on a policy.">
              </prcins-help-text>
            </span>
          </div>
        </div>
        <div class="flex lg:justify-end">
          <prcins-error-label [control]="passwordForm.get('dob')" [controlValue]="passwordForm.get('dob').value"
            controlLabel="userRegisterForm_displayDob" [controlTouched]="passwordForm.get('dob').touched">
          </prcins-error-label>
        </div>
      </ng-container>
      <!-- Password -->
      <ng-container>
        <div class="flex items-center justify-end flex-wrap mt-4 showPassword">
          <a *ngIf="!maskPassword; else hidePassword" class="text-es-blue-light cursor-pointer"
            (click)="maskPassword = true">Show Password</a>
          <ng-template #hidePassword>
            <a class="text-es-blue-light cursor-pointer" (click)="maskPassword = false">Hide Password</a>
          </ng-template>
        </div>
        <div class="flex items-center justify-between flex-wrap">
          <label *ngIf="actionType === 'Create'" class="mb-2 font-semibold" for="">Create a Password</label>
          <label *ngIf="actionType === 'Reset'" class="mb-2 font-semibold" for="">New Password</label>
          <input [type]="maskPassword ? 'text' : 'password'" trimInput class="w-full lg:w-3/5 es-textfield"
            formControlName="password">
        </div>
        <div class="flex lg:justify-end">
          <prcins-error-label [control]="passwordForm.get('password')"
            [controlValue]="passwordForm.get('password').value" controlLabel="passwordForm_password"
            [controlTouched]="passwordForm.get('password').touched">
          </prcins-error-label>
        </div>
      </ng-container>
      <!-- Confirm Password -->
      <ng-container>
        <div class="flex items-center justify-between flex-wrap mt-4">
          <label *ngIf="actionType === 'Create'" class="mb-2 font-semibold" for="">Confirm Password</label>
          <label *ngIf="actionType === 'Reset'" class="mb-2 font-semibold" for="">Confirm New Password</label>
          <input [type]="maskPassword ? 'text' : 'password'" [prcinsNoCopyPaste]="true" prcinsNoRightClick trimInput
            class="w-full lg:w-3/5 es-textfield" formControlName="confirmPassword">
        </div>
        <div class="flex lg:justify-end">
          <prcins-error-label [control]="passwordForm.get('confirmPassword')"
            [controlValue]="passwordForm.get('confirmPassword').value" controlLabel="passwordForm_confirmPassword"
            [controlTouched]="passwordForm.get('confirmPassword').touched">
          </prcins-error-label>
        </div>
      </ng-container>
      <!-- Go Paperless with eDocuments -->
      <ng-container *ngIf="passwordForm.controls.enrollEDOC">
        <div class="flex items-center justify-between flex-wrap mt-4">
          <label class="mb-2 font-semibold w-full lg:w-2/5" for="">Go Paperless with eDocuments</label>
          <div class="add-double-checkboxes">
            <input class="checkbox" id="activeDriver" type="radio" formControlName="enrollEDOC" value="Y" />
            <label class="left-label" for="activeDriver">Yes</label>
            <input class="checkbox" id="activeDriverF" type="radio" formControlName="enrollEDOC" value="N" />
            <label class="right-label" for="activeDriverF">No</label>
            <span class="ml-2 mt-2">
              <prcins-help-text id="primaryUse" title="Go Paperless with eDocuments" text="eDocuments is the quickest and easiest way to get your policy and billing documents while helping to save the
                  environment! We'll send you a secure email on the same business day your documents become available. 
                  And don't worry, we'll still print and mail any of the items we are required to physically send.">
              </prcins-help-text>
            </span>
          </div>
        </div>
        <div class="flex lg:justify-end">
          <prcins-error-label [control]="passwordForm.get('enrollEDOC')"
            [controlValue]="passwordForm.get('enrollEDOC').value" controlLabel="passwordForm_enrollEDOC"
            [controlTouched]="passwordForm.get('enrollEDOC').touched">
          </prcins-error-label>
        </div>
        <div *ngIf= "!!edocPromised" class="flex lg:justify-end">
          <label class="mb-2 font-semibold" for=""></label>
          <i class="lg:w-3/5">If you choose "No" for eDocuments, the cost of your insurance policy may increase as you will no longer be eligible for policy savings</i>
        </div>
      </ng-container>
      <!-- Sign Up for Billing Text Alerts -->
      <ng-container *ngIf="passwordForm.controls.enrollBillingTextAlert">
        <div class="flex items-center justify-between flex-wrap mt-4">
          <label class="mb-2 font-semibold w-full lg:w-2/5" for="">Sign Up for Billing Text Alerts</label>
          <div class="add-double-checkboxes">
            <input class="checkbox" id="billingTextAlert" type="radio" formControlName="enrollBillingTextAlert" value="Y" />
            <label class="left-label" for="billingTextAlert">Yes</label>
            <input class="checkbox" id="billingTextAlertF" type="radio" formControlName="enrollBillingTextAlert" value="N" />
            <label class="right-label" for="billingTextAlertF">No</label>
            <span class="ml-2 mt-2">
              <prcins-help-text id="billingTextAlertHelpText" title="Billing Text Alerts" text="Billing text alerts will send information about payment activities abd billing reminders to your phone.">
              </prcins-help-text>
            </span>
          </div>
        </div>
        <div class="flex lg:justify-end">
          <prcins-error-label [control]="passwordForm.get('enrollBillingTextAlert')"
            [controlValue]="passwordForm.get('enrollBillingTextAlert').value" controlLabel="passwordForm_enrollBillingTextAlert"
            [controlTouched]="passwordForm.get('enrollBillingTextAlert').touched">
          </prcins-error-label>
        </div>
      </ng-container>
      <ng-container *ngIf="passwordForm.controls.billingTextAlertPhoneNumber">
        <div class="flex items-center justify-between flex-wrap mt-4">
          <div class="flex items-center justify-between flex-wrap mt-1">
            <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="billingTextAlertPhoneNumber">
          </div>
        </div>
        <div class="flex lg:justify-end">
          <prcins-error-label [control]="passwordForm.get('billingTextAlertPhoneNumber')"
            [controlValue]="passwordForm.get('billingTextAlertPhoneNumber').value" controlLabel="passwordForm_billingTextAlertPhoneNumber"
            [controlTouched]="passwordForm.get('billingTextAlertPhoneNumber').touched">
          </prcins-error-label>
        </div>
      </ng-container>
    </div>
    <div class="mt-4 sm:w-1/3 md:w-1/3 lg:w-2/6 xl:w-2/6 lg:p-4"
      [ngClass]="{'eDocPasswordCriteria': passwordForm.controls.dob}">
      <div>
        <label class="mb-2 font-semibold" for="">Password Criteria</label>
      </div>
      <div class="bg-light-gray text-center mt-4 mb-4 p-1"
        [ngClass]="{'passwordCriteria': passwordForm.get('password').value.length >= 7}">
        <label class="mb-2" for="">7 characters</label>
      </div>
      <div class="bg-light-gray text-center mb-4 p-1"
        [ngClass]="{'passwordCriteria': hasLowerCase(passwordForm.get('password').value)}">
        <label class="mb-2" for="">One lowercase letter</label>
      </div>
      <div class="bg-light-gray text-center mb-4 p-1"
        [ngClass]="{'passwordCriteria': hasNumber(passwordForm.get('password').value)}">
        <label class="mb-2" for="">1 non-alpha character</label>
      </div>
    </div>
  </div>
</form>
