import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prcins-primary',
  templateUrl: './primary.component.html',
  styleUrls: ['./primary.component.css']
})
export class PrimaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
