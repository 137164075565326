<div class="bg-es-twilight-blue text-white flex justify-center" (mouseleave)="onLeaveSecondPolicy()">
  <div class="flex flex-wrap es-container w-full" *ngIf="!noSelector; else selectedPolicy">
    <span class="flex items-center flex-wrap">
      <div class="flex items-center p-4 cursor-pointer hover:underline"
        [class.active_policy]="policy.policyNumber === currentSelectedPolicy.policyNumber"
        *ngFor="let policy of formattedPolicies" (click)="onPolicySelected(policy)">
        <ng-container [ngSwitch]="policy.policyInfo.lob">
          <span class="flex items-center" *ngSwitchCase="'PA'">
            <img class="mr-2" src="assets/svgs/car-white-icon.svg" alt="">
            {{policy.policyInfo.lob | lobLabel: 'Policy'}}
          </span>
          <span class="flex items-center" *ngSwitchCase="'MC'">
            <img class="mr-2" src="assets/png/motor.png" alt="" width="45" height="45">
            {{policy.policyInfo.lob | lobLabel: 'Policy'}}
          </span>
          <span class="flex items-center" *ngSwitchCase="'HO'">
            <img class="mr-2" src="assets/svgs/home-white-icon.svg" alt="">
            {{policy.policyInfo.lob | lobLabel: 'Policy'}}
          </span>
          <span class="flex items-center" *ngSwitchCase="'CA'">
            <ng-container
              *ngIf="policy.policyInfo.productCode==='PCAT' || policy.policyInfo.productCode==='ALN_UMB'; else businessAutoGroup">
              <img class="mr-2 lob-icon" src="assets/png/white umbrella icon.png" alt="">
              {{policy.policyInfo.productCode| lobLabel: 'Policy'}}
            </ng-container>
            <ng-template #businessAutoGroup>
              <img class="mr-2 lob-icon" src="assets/png/white truck icon.png" alt="">
              {{policy.policyInfo.lob| lobLabel}}
            </ng-template>
          </span>
        </ng-container>
        <span class="ml-2">{{policy.displayPolicy}}</span>
      </div>
      <div class="flex items-center cursor-pointer hover:underline">
        <span class="ml-2 flex" *ngIf="!showMorePolicy; else morePolicy" (click)="linkPolicy()">
          <img class="mr-2" src="assets/svgs/plus-icon.svg" alt="">
          Link a Policy
        </span>
      </div>
      <ng-template #morePolicy>
        <div class="relative">
          <div class="p-4" (mouseenter)="onMorePolicyRollOver()">
            <img class="inactive-icon" src="assets/svgs/down-icon.svg" alt="">
          </div>
          <div *ngIf="showSecondRow;" class="absolute bg-es-blue text-white policy-second-container">
            <div class="flex items-center p-4 cursor-pointer hover:underline"
              [class.active_policy]="policy.policyNumber === currentSelectedPolicy.policyNumber"
              *ngFor="let policy of additionalPolicies" (click)="onPolicySelected(policy)">
              <ng-container [ngSwitch]="policy.policyInfo.lob">
                <span class="flex items-center" *ngSwitchCase="'PA'">
                  <img class="mr-2" src="assets/svgs/car-white-icon.svg" alt="">
                  {{policy.policyInfo.lob | lobLabel: 'Policy'}}
                </span>
                <span class="flex items-center" *ngSwitchCase="'MC'">
                  <img class="mr-2" src="assets/png/motor.png" alt="" width="30" height="30">
                  {{policy.policyInfo.lob | lobLabel: 'Policy'}}
                </span>
                <span class="flex items-center" *ngSwitchCase="'HO'">
                  <img class="mr-2" src="assets/svgs/home-white-icon.svg" alt="">
                  {{policy.policyInfo.lob | lobLabel: 'Policy'}}
                </span>
                <span class="flex items-center" *ngSwitchCase="'PCAT'">
                  <img class="mr-2 lob-icon" src="assets/png/white umbrella icon.png" alt="">
                  {{policy.policyInfo.lob | lobLabel: 'Policy'}}
                </span>
                <span class="flex items-center" *ngSwitchCase="'CA'">
                  <ng-container
                    *ngIf="policy.policyInfo.productCode==='PCAT' || policy.policyInfo.productCode==='ALN_UMB'; else businessAutoGroup">
                    <img class="mr-2 lob-icon" src="assets/png/white umbrella icon.png" alt="">
                    {{policy.policyInfo.productCode| lobLabel: 'Policy'}}
                  </ng-container>
                  <ng-template #businessAutoGroup>
                    <img class="mr-2 lob-icon" src="assets/png/white truck icon.png" alt="">
                    {{policy.policyInfo.lob| lobLabel}}
                  </ng-template>
                </span>
              </ng-container>
              <span class="ml-2">{{policy.displayPolicy}}</span>
            </div>
            <span class="flex p-4" (click)="linkPolicy()">
              <img class="mr-2" src="assets/svgs/plus-icon.svg" alt="">
              Link a Policy
            </span>
          </div>
        </div>
      </ng-template>
    </span>

  </div>

  <ng-template #selectedPolicy>
    <div class="flex flex-wrap es-container w-full justify-end">
      <div class="flex items-center p-4">
        <ng-container [ngSwitch]="currentSelectedPolicy?.policyInfo.lob">
          <span class="flex items-center" *ngSwitchCase="'PA'">
            <img class="mr-2" src="assets/svgs/car-white-icon.svg" alt="">
          </span>
          <span class="flex items-center" *ngSwitchCase="'MC'">
            <img class="mr-2" src="assets/png/motor.png" alt="" width="30" height="30">
          </span>
          <span class="flex items-center" *ngSwitchCase="'HO'">
            <img class="mr-2" src="assets/svgs/home-white-icon.svg" alt="">
          </span>
          <span class="flex items-center" *ngSwitchCase="'PCAT'">
            <img class="mr-2 lob-icon" src="assets/png/white umbrella icon.png" alt="">
          </span>
          <span class="flex items-center" *ngSwitchCase="'CA'">
            <ng-container
              *ngIf="currentSelectedPolicy?.policyInfo.productCode==='PCAT' || currentSelectedPolicy?.policyInfo.productCode==='ALN_UMB'; else businessAutoGroup">
              <img class="mr-2 lob-icon" src="assets/png/white umbrella icon.png" alt="">
            </ng-container>
            <ng-template #businessAutoGroup>
              <img class="mr-2 lob-icon" src="assets/png/white truck icon.png" alt="">
            </ng-template>
          </span>
        </ng-container>
        <span>Policy #: {{currentSelectedPolicy?.policyNumber}}</span>
      </div>
    </div>
  </ng-template>
</div>
