import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IPolicy } from '../models/policy';
import { Router, ActivatedRoute } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class EservicePromotionBroadcasterService {

  private showingEservicePromotion = new Subject<boolean>();
  showingEservicePromotion$ = this.showingEservicePromotion.asObservable();

  constructor(private router: Router, private route: ActivatedRoute) { }

  broadcastPromotion(isShowing) {
    this.showingEservicePromotion.next(isShowing);
  }
}
