import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';
import { HttpClient } from '@angular/common/http';
import { CURRENT_POLICY, USER_ID } from '@prcins/constants';
import { CLAIM_URL } from './constant/claims.urls';
import { ClaimsSearchQuery, ClaimsDetailsQuery } from '@prcins/utils';
import { LOGIN_URL } from '@prcins/constants';
import { environment } from '@prcins/environments';

@Injectable({
  providedIn: 'root'
})
export class ClaimsBasicService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  claimsSearchQuery(policyNumber?: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: ClaimsSearchQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(
        map(({ data }: any) => {
          const sortedData: any[] = data.claimsSearch;
          sortedData.sort((a, b) => {
            return (
              new Date(b.dateOfLoss).getTime() -
              new Date(a.dateOfLoss).getTime()
            );
          });

          return sortedData;
        })
      );
  }

  claimDetailsQuery(
    policyNumber?: String,
    claimNumber?: String
  ): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery({
      query: ClaimsDetailsQuery,
      variables: {
        policyNumber,
        claimNumber
      }
    }).valueChanges;
  }

  getClaimsTalkUrl(data): Observable<any> {
    return this.http.post(CLAIM_URL.claimstalkurl, data);
  }

  claimPaymentOptions(claimNumber?: String) {
    return this.http.get(
      CLAIM_URL.getclaimspaymentoptionurl + '/' + claimNumber
    );
  }

  claimPaymentEftAccounts(policyNumber?: String, claimNumber?: String) {
    console.log(policyNumber);
    return this.http.get(
      CLAIM_URL.getclaimeftaccounturl + '/' + policyNumber + '/' + claimNumber
    );
  }

  updateClaimsPaymentOption(claimNumber?: String, data?: any): Observable<any> {
    return this.http.post(
      CLAIM_URL.updateClaimsPaymentOption + '/' + claimNumber,
      data
    );
  }

  eDiscloserAudit(payload: any) {
    return this.http.post(LOGIN_URL.AuditUrl, payload);
  }

  getNotificationDetails(claimNumber: string) {
    return this.http.get(CLAIM_URL.getclaimNotificationurl + '/' + claimNumber);
  }

  updateNotificationDetails(claimNumber: string, payload) {
    return this.http.post(
      CLAIM_URL.updateClaimNotificationurl + '/' + claimNumber,
      payload
    );
  }

  selectRepairShop() {
    // TODO - implement HTTP call when API will be available
  }

  callCarPicsApp(claimId: string, claimNumber: string) {
    // TODO - implement HTTP call when API will be available
    // return this.http.get(
    //   CLAIM_URL.getAppraisalDetailsUrl + '/' + claimNumber
    // );
    return new Observable<any>();
  }

  searchRepairShop(searchCity: string) {
    // TODO - implement HTTP call when API will be available
  }


  createDispatch(data?: any): Observable<any> {
    data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
    return this.http.post(
      CLAIM_URL.createDispatch ,
      data
    );
  }

  getDispatch(claimNumber?: String): Observable<any> {
    return this.http.get(
      `${CLAIM_URL.getDispatch}/${claimNumber}/${sessionStorage.getItem(CURRENT_POLICY)}`
    );
  }

  assignRepresentative(data?: any): Observable<any> {
    data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
    return this.http.post(
      CLAIM_URL.assignRepresentative ,
      data
    );
  }

  getRepresentatives(data?: any): Observable<any> {
    data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
    return this.http.post(
      CLAIM_URL.getRepresentatives ,
      data
    );
  }

  reassignRepresentative(data?: any): Observable<any> {
    data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
    return this.http.post(
      CLAIM_URL.reassignRepresentative ,
      data
    );
  }

  getClaimSpecificDocs(claimNumber?: String): Observable<any> {
    let url =  `${environment.BASE_URL}/docs/${sessionStorage.getItem(CURRENT_POLICY)}/claims/${claimNumber}`
    return this.http.get(
     url
    );
  }

  getClaimDashboard(claimNumber: any) {
    let url =  `${environment.BASE_URL}/claims/dashboard/${sessionStorage.getItem(CURRENT_POLICY)}/${claimNumber}`
    return this.http.get(
      url
    );
  }

  getClaimFaqs() {
    let url =  `${environment.BASE_URL}/common/faqs/all`
    return this.http.get(
      url
    );
  }

}
