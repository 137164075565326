<div class="mt-8">
  <div class="border rounded border-gray-300">
    <div class="flex {{headerClass}} px-4 py-3 items-center justify-between rounded-t">
      <div class="font-bold flex items-center">
        <ng-content select="[header-left]"></ng-content>
      </div>
      <ng-content select="[header-right]"></ng-content>
    </div>
    <div class="px-4 pb-3 {{pt}}">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
</div>

<!-- Sample Usage ----
  <prcins-card>
      <span header-left>img and title</span>
      <span header-right>link</span>
      <div body>html content</div>
  </prcins-card> 
-->