import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { USER_ID, CURRENT_POLICY, USER_LOGIN, TOKEN } from '@prcins/constants';
import { SessionService } from '../services/session.service';

@Component({
  selector: 'prcins-mobile-redirect',
  templateUrl: './mobile-redirect.component.html',
  styleUrls: ['./mobile-redirect.component.css']
})
export class MobileRedirectComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private sessionService: SessionService) { }

  ngOnInit() {

    let { session, token } = this.route.snapshot.queryParams;
    sessionStorage.setItem(TOKEN, token);
    this.sessionService.hydrateSession(session).subscribe((data) => {
      sessionStorage.setItem(CURRENT_POLICY, data['selectedPolicy']);
      sessionStorage.setItem(USER_ID, data[USER_ID]);
      sessionStorage.setItem(USER_LOGIN, 'Y');
      if (data.endorsementContext) {
        if (data.endorsementContext.id !== null) { sessionStorage.setItem('guid', data.endorsementContext.id); }
        sessionStorage.setItem('actionType', data.endorsementContext.action);
        sessionStorage.setItem('endorsementStarted', data.endorsementContext.type);
        switch (data.endorsementContext.type) {
          case 'Vehicle':
            sessionStorage.setItem('vehicleId', data.endorsementContext.entityId);
            break;
          case 'Driver':
            sessionStorage.setItem('driverId', data.endorsementContext.entityId);
            break;
        }
      }
      this.router.navigate([data['target']]);
    }, (error) => {
      console.log(error);
      this.router.navigate(['eservice', 'login']);
    });
  }

}
