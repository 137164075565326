import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NavigationProviderService } from '@prcins/utils';
import { BRAND, Brand, ADMIN } from '@prcins/constants';

@Component({
  selector: 'prcins-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.css']
})
export class DesktopHeaderComponent implements OnInit {
  @Input() menus;
  @Input() activeMenus;
  @Input() selectedHeader;
  @Output() logOut = new EventEmitter();
  @Output() softLogOut = new EventEmitter();


  isAdmin;
  brand = localStorage.getItem(BRAND);
  mainPage = Brand[this.brand].urlEnterpriseWeb;

  navMenu;

  constructor(private navigationProvider: NavigationProviderService) { }

  ngOnInit(): void {
    this.navMenu = this.activeMenus;
    this.isAdmin = sessionStorage.getItem(ADMIN) === 'Y';
  }

  menuNavigationHandler(methodName) {
    this.navigationProvider[methodName]();
  }

  onLogout(): void {
    this.logOut.next();
  }

  onSoftLogout(): void {
    this.softLogOut.next();
  }

  onSubMenuClicked(menu) {
    this.onHideSubMenu();
  }

  onShowSubMenu(menu) {
    this.navMenu = {
      ...this.activeMenus,
      [menu]: true
    };
  }

  onHideSubMenu() {
    this.navMenu = {
      ...this.activeMenus
    };
  }
  showLink(subMenu) {
    if (!subMenu.route) {
      return false;
    }

    if (subMenu.disable) {
      if (subMenu.waitConditionally) {
        return true;
      } else {
        return false;
      }

    } else {
      return true;
    }

  }
}
