/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./card.component";
var styles_CardComponent = [i0.styles];
var RenderType_CardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
export function View_CardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "mt-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "border rounded border-gray-300"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "font-bold flex items-center"]], null, null, null, null, null)), i1.ɵncd(null, 0), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵncd(null, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "flex ", _co.headerClass, " px-4 py-3 items-center justify-between rounded-t"); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "px-4 pb-3 ", _co.pt, ""); _ck(_v, 6, 0, currVal_1); }); }
export function View_CardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i2.CardComponent, [], null, null)], null, null); }
var CardComponentNgFactory = i1.ɵccf("prcins-card", i2.CardComponent, View_CardComponent_Host_0, { headerClass: "headerClass", pt: "pt" }, {}, ["[header-left]", "[header-right]", "[body]"]);
export { CardComponentNgFactory as CardComponentNgFactory };
