import gql from 'graphql-tag';

export const ClaimsSearchQuery = gql`
query ClaimSearch($policyNumber: String) {
    claimsSearch(policyNumber: $policyNumber) {
      claimNumber
      dateOfLoss
      status
  }}
`;


export const ClaimsDetailsQuery = gql`
query ClaimInquiry($policyNumber: String, $claimNumber:String) {
    claimsInquiry(policyNumber: $policyNumber,claimNumber: $claimNumber) {
      claimDetails {
        claimId
        claimNumber
        status
        dateOfLoss
        isEligibleForManageAppraisal
        payments {
          street
          street2
          city
          zip
          state
          country
          description
          lossType
          amount
          payeeName
          dateIssued
          checkNumber
          checkStatus
          inPaymentOf
          transactionTypeName
          splitPayAllocation
          splitPayCoverage
          mailName
          paymentMethodType
        }
        representatives {
          coverageAbbreviation 
          supervisor {
            firstName
            lastName
            phone
            fax
            email
            functionalRole
            supervisorOf
            userId
            userLoginName
          }
          adjuster {
            firstName
            lastName
            phone
            fax
            email
            functionalRole
            supervisorOf
            userId
            userLoginName
          }
        }
        claimUnit {
          year
          make
          model
        }
      }
  }}
`;
