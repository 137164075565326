import { Component } from '@angular/core';
import {
  UtilityProviderService,
  FormValidatorService,
  PolicyBroadcasterService
} from '@prcins/utils';
import { PolicyProviderService } from '../services/policy-provider.service';
import { USER_ID, TOKEN, CURRENT_POLICY } from '@prcins/constants';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'prcins-link-policy',
  templateUrl: './link-policy.component.html',
  styleUrls: ['./link-policy.component.css']
})
export class LinkPolicyComponent {
  linkError = false;
  errorMessage: string;
  showSpinner = false;
  linkPolicyForm = this.utilityProvider.getUserForm();

  constructor(
    private utilityProvider: UtilityProviderService,
    private formValidator: FormValidatorService,
    private policyProvider: PolicyProviderService,
    private pb: PolicyBroadcasterService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  linkPolicy() {
    if (this.linkPolicyForm.valid) {
      this.showSpinner = true;
      this.linkError = false;
      const {
        firstName,
        lastName,
        lob,
        policyNumber,
        policyZip,
        companyName
      } = this.linkPolicyForm.value;
      const email = sessionStorage.getItem(USER_ID);
      const payload = {
        firstName,
        lastName,
        lob,
        policyNumber,
        policyZip,
        email,
        companyName
      };

      this.policyProvider
        .linkPolicy(payload, email, this.utilityProvider.getUserType())
        .subscribe(
          (resp: any) => {
            if (resp.messageCode === 'ADD_POLICY_SUCCESS') {
              if (resp.token) sessionStorage.setItem(TOKEN, resp.token);
              this.pb.broadcastPolicyListLoaded();
              sessionStorage.setItem(CURRENT_POLICY, payload.policyNumber);
              this.router.navigate(['../../home'], {
                relativeTo: this.route
              });
            } else {
              this.linkError = true;
              // this.errorMessage = `Unable to link policy now due to: ${
              //   resp.messageCode
              // }. Please contact us.`;
              this.errorMessage = `Oops! We can't seem to find you. Please try that again`
              this.showSpinner = false;
            }
          },
          err => {
            this.showSpinner = false;
            this.linkError = true;
            this.errorMessage =
              'Sorry unable to link policy now. Please try again or contact us.';
          }
        );
    } else {
      this.formValidator.validateAllFormFields(this.linkPolicyForm);
    }
  }
}
