import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  cardNumberValidatorFn,
  expiryDateValidatorFn
} from '../validators/payment-form.validators';

@Injectable({
  providedIn: 'root'
})
export class CardFormProviderService {
  constructor(private fb: FormBuilder) {}

  getForm(isZipRequired = false) {
    let formControls: any = {
      cardNumber: [
        '',
        [Validators.required, Validators.minLength(15), cardNumberValidatorFn()]
      ],
      cardType: [''],
      maskedCardNumber: ['', [Validators.required, Validators.minLength(15)]],
      expirationMonth: ['%%', Validators.pattern(/^[0-9]*$/)],
      expirationYear: ['%%', Validators.pattern(/^[0-9]*$/)],
      expirationDate: ['', [Validators.required, expiryDateValidatorFn()]]
    }
    if(isZipRequired){
      formControls = {
        ...formControls,
        cardZip: ['',[Validators.required,Validators.pattern(/\d{5}/)]]
      }
    }
    return this.fb.group(formControls);
  }


  getEditCardForm(isZipRequired = false) {
    let formControls: any = {
      cardType: [''],
      expirationMonth: ['%%', Validators.pattern(/^[0-9]*$/)],
      expirationYear: ['%%', Validators.pattern(/^[0-9]*$/)],
      expirationDate: ['', [Validators.required, expiryDateValidatorFn()]],
      securityCode: ['', [Validators.required, Validators.minLength(3)]],
      maskedSecurityCode: ['', [Validators.required, Validators.minLength(3)]]
    }
    if(isZipRequired){
      formControls = {
        ...formControls,
        cardZip: ['',[Validators.required,Validators.pattern(/\d{5}/)]]
      }
    }
    return this.fb.group(formControls);
  }
}
