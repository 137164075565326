import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingInstallmentsQuery, HomeEndorsementControlAgentQuery } from '@prcins/utils';
@Injectable({
  providedIn: 'root'
})
export class OpenmortgageamendmentService {

  apiUrl = environment.BASE_URL + "/v2/";
  // apiUrl = "http://apis.dev.prcaws.net/eservice-ws/v2/";

  constructor(private http: HttpClient, private apollo: Apollo) { }

  getInstallmentDetails(): Observable<any> {
    let policyNumber = sessionStorage.getItem('currentPolicy');
    return this.apollo
      .watchQuery({
        query: BillingInstallmentsQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }) => data));
  }

  getInstallmentDetailsFromCache(policyNumber) {
    return this.apollo
      .getClient()
      .readQuery({
        query: BillingInstallmentsQuery,
        variables: {
          policyNumber
        }
      });
  }

  updateInstallmentDetailsFromCache(policyNumber, payload) {
    return this.apollo
      .getClient()
      .writeQuery({
        query: BillingInstallmentsQuery,
        variables: {
          policyNumber
        },
        data: payload
      });
  }

  getAdrControlAgent(policyNumber: string){
    return this.apollo
      .watchQuery({
        query: HomeEndorsementControlAgentQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }) => data));
  }

  recordEndorsmentBlocked(type: string, guid: string): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    return this.http.put(this.apiUrl + 'core/' + policyNum + '/endorsements/recordEndorsmentBlocked?type=' + type + (guid?'&guid=' + guid:''), {});
  }

  createEndorsement(type: string): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    return this.http.post(this.apiUrl + policyNum + '/endorsements?type=' + type, {});
  }

  getEndorsementSummary(policyNum, guid): Observable<any> {
    return this.http.get(this.apiUrl + policyNum + '/endorsements/' + guid);
  }

  rateEndorsement(): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    let guid = sessionStorage.getItem('guid');
    return this.http.put(this.apiUrl + policyNum + '/endorsements/' + guid + '/rate', {});
  }

  issueEndorsePolicyV2(policyNum, guid, request: any): Observable<any> {
    return this.http.put(this.apiUrl + policyNum + '/endorsements/' + guid + '/issue', request)
    .pipe(map((response: any) => {
      let { error, billingEndorsements: [ { endorsementEntity: { endorsementBillingDetails: { payplanLabel } } } ],
        summary: { metaInfo: { fileId } } } = response;
      return { errorCode: error, fileId, payplanLabel };
    }));
  }

  cancelEndorsement(): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : sessionStorage.getItem('splPolicyNumber');
    let guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : sessionStorage.getItem('splGuid');
    let url = this.apiUrl + policyNum + '/endorsements/' + guid
    return this.http.delete(url);
  }

  cancelEndorsementWithParams(policyNum, guid): Observable<any> {
    let url = this.apiUrl + policyNum + '/endorsements/' + guid
    return this.http.delete(url);
  }

  deleteMortgageeEndorsement(mortgageeId): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    let guid = sessionStorage.getItem('guid');
    let url = this.apiUrl + policyNum + '/endorsements/' + guid + '/mortgagees/' + mortgageeId
    return this.http.delete(url);
  }
}
