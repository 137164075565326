/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./incomplete-status.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../libs/utils/src/lib/welcome-banner/welcome-banner.component.ngfactory";
import * as i3 from "../../../../../libs/utils/src/lib/welcome-banner/welcome-banner.component";
import * as i4 from "../../../../../libs/utils/src/lib/services/login.service";
import * as i5 from "./incomplete-status.component";
var styles_IncompleteStatusComponent = [i0.styles];
var RenderType_IncompleteStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IncompleteStatusComponent, data: {} });
export { RenderType_IncompleteStatusComponent as RenderType_IncompleteStatusComponent };
export function View_IncompleteStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "md:min-h-screen relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "flex flex-col items-center w-full es-app-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "prcins-welcome-banner", [["class", "w-full"]], null, null, null, i2.View_WelcomeBannerComponent_0, i2.RenderType_WelcomeBannerComponent)), i1.ɵdid(3, 114688, null, 0, i3.WelcomeBannerComponent, [i4.LoginService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "desktop-only-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "main", [["class", "px-8 pb-8 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "es-h1 mt-4 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hello, great to see you here!"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "flex justify-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["src", "assets/svgs/new-profile-incomplete-status-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [["class", "font-semibold text-center mt-4 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We\u2019re working to get your online account ready for you."])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [["class", "text-center px-38"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Once you receive a new policy declarations page, you can log back in to take advantage of our online services."]))], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_IncompleteStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-incomplete-status", [], null, null, null, View_IncompleteStatusComponent_0, RenderType_IncompleteStatusComponent)), i1.ɵdid(1, 114688, null, 0, i5.IncompleteStatusComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IncompleteStatusComponentNgFactory = i1.ɵccf("prcins-incomplete-status", i5.IncompleteStatusComponent, View_IncompleteStatusComponent_Host_0, {}, {}, []);
export { IncompleteStatusComponentNgFactory as IncompleteStatusComponentNgFactory };
