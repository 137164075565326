/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-redirect.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./mobile-redirect.component";
import * as i3 from "@angular/router";
import * as i4 from "../services/session.service";
var styles_MobileRedirectComponent = [i0.styles];
var RenderType_MobileRedirectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileRedirectComponent, data: {} });
export { RenderType_MobileRedirectComponent as RenderType_MobileRedirectComponent };
export function View_MobileRedirectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Redirecting..."]))], null, null); }
export function View_MobileRedirectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-mobile-redirect", [], null, null, null, View_MobileRedirectComponent_0, RenderType_MobileRedirectComponent)), i1.ɵdid(1, 114688, null, 0, i2.MobileRedirectComponent, [i3.Router, i3.ActivatedRoute, i4.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileRedirectComponentNgFactory = i1.ɵccf("prcins-mobile-redirect", i2.MobileRedirectComponent, View_MobileRedirectComponent_Host_0, {}, {}, []);
export { MobileRedirectComponentNgFactory as MobileRedirectComponentNgFactory };
