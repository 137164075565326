import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';

import { ClaimsSearchQuery } from '@prcins/utils';

@Injectable({
  providedIn: 'root'
})
export class ClaimsSearchService {
  constructor(private apollo: Apollo) {}

  claimsSearchQuery(policyNumber?: String): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery({
      query: ClaimsSearchQuery,
      variables: {
        policyNumber
      }
    }).valueChanges;
  }
}
