import { environment } from '@prcins/environments';
 
export const DOCUMENTS_URL = {
    auto:{
        request: `${environment.PDF_URL}/docs/idcard/auto`,
        download: `${environment.PDF_URL}/docs/idcard/auto/pdf`
    },
    home:{
       download: `${environment.PDF_URL}/docs/homeEOI/pdf`
    },
    requestToken: `${environment.PDF_URL}/docs/requestToken`,
    documentPdf: `${environment.PDF_URL}/docs/imageright/document`,
    googleAppLink:"https://play.google.com/store/apps/details?id=com.plymouthrock.pracmobile&hl=en",
    appleAppLink:"https://apps.apple.com/us/app/plymouth-rock-assurance/id632820710?ls=1"
}
 
 
//http://co1jselqv71:8480/eservice-ws/service/imageright/documents/PNH00007022361/EOI 
 
 
// Auto - /test/eservice/document/idcard/pdf/MWA00008015137
 
// Home - /test/eservice/document/idcard/pdf/PNH00007022361/bde463a9ae4c1fe997ac990a4922e5f409b0e8f96d7581524608e6ddc1cfb30e
