import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormValidatorService, RegistrationService } from '@prcins/utils';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'prcins-mfa-code-verification',
  templateUrl: './mfa-code-verification.component.html',
  styleUrls: ['./mfa-code-verification.component.css']
})
export class MfaCodeVerificationComponent implements OnInit {

  @Input() verificationOptionsForm;
  @Input() userPolicyDetailsResp;
  @Output() backClicked = new EventEmitter<string>();
  @Output() submitted = new EventEmitter<string>();
  showSpinner = false;
  resendCode = false;
  verificationRetryMessage = false;
  errorMessage: string;
  codeVerificationForm: FormGroup = this.fb.group({
    mode: [''],
    token: ['', [Validators.required]],
    verificationId: ['']
  });
  isMobileApp = (self != top) ? true : false;
  isMobileBrowser: boolean;

  constructor(private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private formValidator: FormValidatorService,
    private registrationProvider: RegistrationService) { }

  ngOnInit() {
    this.isMobileBrowser = !!navigator.userAgent.match(
      /iPad|iPhone|Android|IEMobile|BlackBerry/i
    );
  }

  onSubmit() {
    this.verificationRetryMessage = false;
    if (this.codeVerificationForm.valid) {
      this.codeVerificationForm.value.mode = this.verificationOptionsForm.mode;
      this.codeVerificationForm.value.verificationId = this.userPolicyDetailsResp.id;
      this.submitted.emit(this.codeVerificationForm.value);
   /*   this.showSpinner = true;
      this.registrationProvider
        .verifyAuthorizationCode(this.codeVerificationForm.value)
        .subscribe((resp: any) => {
          if (resp.messageCode === 'VERIFIED') {
            let actionType = sessionStorage.getItem('passwordActionType');
            if (actionType === 'Create') {
              this.router.navigate(['../../create-password'], {
                relativeTo: this.route
              });
            } else {
              this.router.navigate(['../../forgot-password'], {
                relativeTo: this.route,
                state: {
                  email: resp.email
                }
              });
            }
          } else if (resp.messageCode === 'RETRY') {
            this.verificationRetryMessage = true;
            this.errorMessage = "Oops! Verification failed. Please try that again.";
          } else if (resp.messageCode === 'VERIFICATION_FAILED') {
            this.backClicked.emit(this.verificationOptionsForm.mode);
          } else {
            this.verificationRetryMessage = true;
            this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
          }
          this.showSpinner = false;
        }, (error: HttpErrorResponse) => {
          this.showSpinner = false;
        });*/
    } else {
      this.formValidator.validateAllFormFields(this.codeVerificationForm);
    }
  }

  onBackClicked() {
    this.verificationRetryMessage = false;
    this.backClicked.emit('');
  }

  resendVerificationCode() {
    this.verificationRetryMessage = false;
    this.showSpinner = true;
    this.resendCode = true;
    this.registrationProvider
      .selectValidationMethod(this.verificationOptionsForm)
      .subscribe((resp: any) => {
        this.showSpinner = false;
      }, (error: HttpErrorResponse) => {
        this.showSpinner = false;
      });
  }

}
