import { Component } from '@angular/core';

@Component({
  selector: 'prcins-payment',
  template: `
    <main class="w-full px-4">
      <router-outlet></router-outlet>
    </main>
  `,
  styles: []
})
export class PaymentComponent {}
