import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CURRENT_POLICY } from '@prcins/constants';
import { CLAIM_URL } from './constant/claims.urls';
import { ClaimsSearchQuery, ClaimsDetailsQuery } from '@prcins/utils';
import { LOGIN_URL } from '@prcins/constants';
import { environment } from '@prcins/environments';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "@angular/common/http";
export class ClaimsBasicService {
    constructor(apollo, http) {
        this.apollo = apollo;
        this.http = http;
    }
    claimsSearchQuery(policyNumber) {
        return this.apollo
            .watchQuery({
            query: ClaimsSearchQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => {
            const sortedData = data.claimsSearch;
            sortedData.sort((a, b) => {
                return (new Date(b.dateOfLoss).getTime() -
                    new Date(a.dateOfLoss).getTime());
            });
            return sortedData;
        }));
    }
    claimDetailsQuery(policyNumber, claimNumber) {
        return this.apollo.watchQuery({
            query: ClaimsDetailsQuery,
            variables: {
                policyNumber,
                claimNumber
            }
        }).valueChanges;
    }
    getClaimsTalkUrl(data) {
        return this.http.post(CLAIM_URL.claimstalkurl, data);
    }
    claimPaymentOptions(claimNumber) {
        return this.http.get(CLAIM_URL.getclaimspaymentoptionurl + '/' + claimNumber);
    }
    claimPaymentEftAccounts(policyNumber, claimNumber) {
        console.log(policyNumber);
        return this.http.get(CLAIM_URL.getclaimeftaccounturl + '/' + policyNumber + '/' + claimNumber);
    }
    updateClaimsPaymentOption(claimNumber, data) {
        return this.http.post(CLAIM_URL.updateClaimsPaymentOption + '/' + claimNumber, data);
    }
    eDiscloserAudit(payload) {
        return this.http.post(LOGIN_URL.AuditUrl, payload);
    }
    getNotificationDetails(claimNumber) {
        return this.http.get(CLAIM_URL.getclaimNotificationurl + '/' + claimNumber);
    }
    updateNotificationDetails(claimNumber, payload) {
        return this.http.post(CLAIM_URL.updateClaimNotificationurl + '/' + claimNumber, payload);
    }
    selectRepairShop() {
        // TODO - implement HTTP call when API will be available
    }
    callCarPicsApp(claimId, claimNumber) {
        // TODO - implement HTTP call when API will be available
        // return this.http.get(
        //   CLAIM_URL.getAppraisalDetailsUrl + '/' + claimNumber
        // );
        return new Observable();
    }
    searchRepairShop(searchCity) {
        // TODO - implement HTTP call when API will be available
    }
    createDispatch(data) {
        data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
        return this.http.post(CLAIM_URL.createDispatch, data);
    }
    getDispatch(claimNumber) {
        return this.http.get(`${CLAIM_URL.getDispatch}/${claimNumber}/${sessionStorage.getItem(CURRENT_POLICY)}`);
    }
    assignRepresentative(data) {
        data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
        return this.http.post(CLAIM_URL.assignRepresentative, data);
    }
    getRepresentatives(data) {
        data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
        return this.http.post(CLAIM_URL.getRepresentatives, data);
    }
    reassignRepresentative(data) {
        data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
        return this.http.post(CLAIM_URL.reassignRepresentative, data);
    }
    getClaimSpecificDocs(claimNumber) {
        let url = `${environment.BASE_URL}/docs/${sessionStorage.getItem(CURRENT_POLICY)}/claims/${claimNumber}`;
        return this.http.get(url);
    }
    getClaimDashboard(claimNumber) {
        let url = `${environment.BASE_URL}/claims/dashboard/${sessionStorage.getItem(CURRENT_POLICY)}/${claimNumber}`;
        return this.http.get(url);
    }
    getClaimFaqs() {
        let url = `${environment.BASE_URL}/common/faqs/all`;
        return this.http.get(url);
    }
}
ClaimsBasicService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClaimsBasicService_Factory() { return new ClaimsBasicService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.HttpClient)); }, token: ClaimsBasicService, providedIn: "root" });
