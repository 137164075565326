/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./spinner.component";
var styles_SpinnerComponent = [i0.styles];
var RenderType_SpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
function View_SpinnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Address Change "]))], null, null); }
function View_SpinnerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "blink-image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "assets/svgs/brands/", _co.brand, "/eservice-spinner-logo.png"); _ck(_v, 0, 0, currVal_0); }); }
function View_SpinnerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "address-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please wait."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["All changes will be lost if you navigate away from this page."]))], null, null); }
export function View_SpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "loader-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "spinner p-4 bg-white rounded"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "flex justify-center items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/png/loading.gif"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAddressSpinner; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.isAddressSpinner; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.isAddressSpinner; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_SpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-spinner", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, null); }
var SpinnerComponentNgFactory = i1.ɵccf("prcins-spinner", i3.SpinnerComponent, View_SpinnerComponent_Host_0, { isAddressSpinner: "isAddressSpinner" }, {}, []);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
