import { Component, OnInit, Input } from '@angular/core';
import { INotification } from '../models/notification.model';
import { Router, NavigationEnd, Event } from '@angular/router';

@Component({
  selector: 'prcins-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  @Input("notification")
  notification: INotification;

  @Input()
  pinned: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.notification = null;
      }
    });
    console.log(this.notification)
  }

  dismiss() {
    this.notification = null;
  }

}
