<div class="desktop-only-container">
  <main class="px-8 pb-8 pt-4">
    <h1 class="es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0">Create an Account</h1>
    <prcins-danger *ngIf="registerError">
      <span *ngIf="!alreadyRegisterError">
        {{errorMessage}}
      </span>
      <span *ngIf="alreadyRegisterError">
        Oops! Did you forget you already signed up? If you <a class="underline font-bold text-es-blue-light"
          href='/eservice/login'>forgot your password</a>, don't sweat it! Or <a
          class="underline font-bold text-es-blue-light" href='https://www.plymouthrock.com/contact-us'
          target='_blank'>give us a call</a>.
      </span>
    </prcins-danger>
    <div class="flex">
      <span>First, let’s find your policy. If you have more than one policy with us and already have a registered
        account,<a class="ml-2 text-es-blue-light cursor-pointer" [routerLink]="['../../link-policies']">click
          here</a>.</span>
    </div>
    <prcins-register-user-form [userForm]="registrationForm"></prcins-register-user-form>
    <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
  </main>
</div>

<div class="flex flex-wrap-reverse justify-center lg:justify-between items-center px-8 lg:px-0">
  <span class="flex">Already have an account? <a class="ml-2 text-es-blue-light" [routerLink]="['../../login']">Sign
      in</a></span>
  <button class="es-btn-primary mb-2 lg:w-64" (click)="onRegister()" type="button">Continue</button>
</div>
