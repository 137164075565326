import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingInstallmentsQuery, HomeEndorsementControlAgentQuery } from '@prcins/utils';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class OpenVehicleAmendmentService {

  apiUrl = environment.BASE_URL + "/v2/";
  // apiUrl = "http://apis.dev.prcaws.net/eservice-ws/v2/";

  constructor(private http: HttpClient, private apollo: Apollo) { }

  getCurrentPolicyInfo() {
    return sessionStorage.currentPolicy;
  }

  getGUID() {
    return sessionStorage.guid;
  }

  getActionTypeInfo() {
    return sessionStorage.actionType;
  }

  endorsementConfig(
    policyNumber?: String
  ): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery({
      query: VehicleEndorsementConfigQuery,
      fetchPolicy: 'network-only',
      variables: {
        policyNumber
      }
    }).valueChanges;
  }

  getEndorsementConfig(policyNumber: String): Observable<any> {
    return this.endorsementConfig(policyNumber).pipe(
      map(result => result.data.getEndorsementResourceConfig)
    );
  }

  recordEndorsmentBlocked(type: string, guid: string): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    return this.http.put(this.apiUrl + 'core/' + policyNum + '/endorsements/recordEndorsmentBlocked?type=' + type + (guid?'&guid=' + guid:''), {});
  }

  createEndorsement(type: string): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    return this.http.post(this.apiUrl + 'core/' + policyNum + '/endorsements?type=' + type, {});
  }

  cancelEndorsement(): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : sessionStorage.getItem('splPolicyNumber');
    let guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : sessionStorage.getItem('splGuid');
    let url = this.apiUrl + 'core/' + policyNum + '/endorsements/' + guid
    return this.http.delete(url);
  }

  rateEndorsement(): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : '';
    let guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : '';
    return this.http.put(this.apiUrl + 'core/' + policyNum + '/endorsements/' + guid +'/rate', {});
  }

}

export const VehicleEndorsementConfigQuery = gql`
query GetEndorsementResourceConfig($policyNumber: String) {
  getEndorsementResourceConfig(policyNumber: $policyNumber) {
    addVehicle
    updateVehicle
    deleteVehicle
    replaceVehicle
 }
}
`;
