/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./balance-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../libs/utils/src/lib/danger/danger.component.ngfactory";
import * as i3 from "../../../../../libs/utils/src/lib/danger/danger.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../libs/utils/src/lib/card/card.component.ngfactory";
import * as i7 from "../../../../../libs/utils/src/lib/card/card.component";
import * as i8 from "../../../../../libs/utils/src/lib/skeleton/skeleton.component.ngfactory";
import * as i9 from "../../../../../libs/utils/src/lib/skeleton/skeleton.component";
import * as i10 from "./balance-details.component";
import * as i11 from "../services/policy-provider.service";
var styles_BalanceDetailsComponent = [i0.styles];
var RenderType_BalanceDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BalanceDetailsComponent, data: {} });
export { RenderType_BalanceDetailsComponent as RenderType_BalanceDetailsComponent };
function View_BalanceDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "prcins-danger", [], null, null, null, i2.View_DangerComponent_0, i2.RenderType_DangerComponent)), i1.ɵdid(1, 4243456, null, 0, i3.DangerComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Sorry service failed, please try after a while or contact us."]))], null, null); }
function View_BalanceDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "flex justify-between mt-3 border-b pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "font-whitney-semibold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Renewal Term Balance"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.futureTermBalance)); _ck(_v, 4, 0, currVal_0); }); }
function View_BalanceDetailsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "flex justify-between mt-3 pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "font-whitney-semibold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current Term Balance"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.currentTermBalance)); _ck(_v, 4, 0, currVal_0); }); }
function View_BalanceDetailsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "flex justify-between mt-3 pb-4 border-t"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "font-whitney-semibold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Previous Term Balance"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.balanceDetails.pastTermBalance)); _ck(_v, 4, 0, currVal_0); }); }
function View_BalanceDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "mt-4 border rounded border-gray-300"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "px-4 pb-3 pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceDetailsComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceDetailsComponent_4)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceDetailsComponent_5)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "flex flex-wrap-reverse justify-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "a", [["class", "es-link-secondary w-full mt-16 sm:w-40"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), (_l()(), i1.ɵted(-1, null, ["Go Back"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.balanceDetails.futureTermBalance; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.balanceDetails.currentTermBalance; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.balanceDetails.pastTermBalance; _ck(_v, 9, 0, currVal_2); var currVal_5 = _ck(_v, 13, 0, "../.."); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 12).target; var currVal_4 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_3, currVal_4); }); }
function View_BalanceDetailsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "prcins-card", [["pt", "pt-0"]], null, null, null, i6.View_CardComponent_0, i6.RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i7.CardComponent, [], { pt: [0, "pt"] }, null), (_l()(), i1.ɵeld(2, 0, null, 2, 2, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "prcins-skeleton", [["numOfRows", "3"]], null, null, null, i8.View_SkeletonComponent_0, i8.RenderType_SkeletonComponent)), i1.ɵdid(4, 245760, null, 0, i9.SkeletonComponent, [], { numOfRows: [0, "numOfRows"] }, null)], function (_ck, _v) { var currVal_0 = "pt-0"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "3"; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BalanceDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "main", [["class", "w-full px-4 mb-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "es-h1 mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Balance Details"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceDetailsComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceDetailsComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_BalanceDetailsComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadFailed; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.showBalance; var currVal_2 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_1, currVal_2); }, null); }
export function View_BalanceDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-balance-details", [], null, null, null, View_BalanceDetailsComponent_0, RenderType_BalanceDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i10.BalanceDetailsComponent, [i11.PolicyProviderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BalanceDetailsComponentNgFactory = i1.ɵccf("prcins-balance-details", i10.BalanceDetailsComponent, View_BalanceDetailsComponent_Host_0, {}, {}, []);
export { BalanceDetailsComponentNgFactory as BalanceDetailsComponentNgFactory };
