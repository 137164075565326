import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CURRENT_POLICY, USER_ID} from '@prcins/constants';
import { PaymentProviderService } from '../services/payment-provider.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'prcins-mobile-registration',
  templateUrl: './mobile-registration.component.html',
  styleUrls: ['./mobile-registration.component.css']
})
export class MobileRegistrationComponent implements OnInit {
  stepOneForm = this.fb.group({
    phoneNumberMask: [
      '',
      [Validators.required, Validators.minLength(12), Validators.maxLength(12)]
    ],
    phoneNumber: [
      '',
      [Validators.required, Validators.minLength(10), Validators.maxLength(10)]
    ],
    termsAndCondition: ['', [Validators.required]]
  });

  stepTwoForm = this.fb.group({
    securityCode: [
      '',
      [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
    ]
  });

  isLoading = false;
  policyNumber;
  showWidget = false;
  showStepOne = false;
  showStepTwo = false;
  showStepThree = false;
  stepOneFailed = false;
  stepInProgress = false;
  stepTwoFailed = false;
  isExpired :boolean =false;
  isStepOneSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private paymentProvider: PaymentProviderService
  ) {}

  ngOnInit() {
    this.policyNumber = sessionStorage.getItem(CURRENT_POLICY);
    this.isLoading = true;
    this.paymentProvider
      .getPayloadForRegistration(this.policyNumber)
      .pipe(
        switchMap((resp: any) => {
          const payplan = resp.billingDetails.payplan;
          const {
            companyCode: company,
            productCode: product,
            state,
            statusCode: status
          } = resp.policySearchInfo;
          return this.paymentProvider.showRegistrationWidget({
            policyNumber: this.policyNumber,
            payplan,
            state,
            company,
            product,
            status
          });
        })
      )
      .subscribe(
        (resp: any) => {
          this.showWidget = resp.showWidget;
          this.showStepOne = resp.showWidget;
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );

      this.stepOneForm.get('phoneNumberMask').valueChanges.subscribe( formattedPhoneNumber => {
        this.stepOneForm.get('phoneNumber').setValue(formattedPhoneNumber.replace(/-/g,''));
      });
  }

  onTermsChanged(item) {
    this.stepOneForm.get('termsAndCondition').setValue(item.value? item.value: '');
  }

  onStepOneContinue() {
    this.isStepOneSubmitted = true;
    const { phoneNumber, termsAndCondition } = this.stepOneForm.value;
    if (this.stepOneForm.valid && termsAndCondition) {
      let payload = {
        "policyNumber":this.policyNumber, 
        "agreementType":" BILLTXTALERT", 
        "agreementType2":"ELECTRONICCONSENT",
        "source":"QPAY"
        }
      this.paymentProvider.eDiscloserAudit(payload).subscribe(data=>console.log(data));

      this.stepInProgress = true;
      this.showStepOne = false;
      this.stepOneFailed = false;
      this.stepTwoFailed = false;
      this.paymentProvider
        .stepOneRegistration({
          policyNumber: this.policyNumber,
          phoneNumber
        })
        .subscribe(
          (resp: any) => {
            if (!resp.messageCode) {
              this.stepOneFailed = false;
              this.showStepOne = false;
              this.showStepTwo = true;
            } else {
              this.stepOneFailed = true;
              this.showStepOne = true;
            }
            this.stepInProgress = false;
          },
          () => {
            this.stepOneFailed = true;
            this.showStepOne = true;
            this.stepInProgress = false;
          }
        );
      this.isStepOneSubmitted = false;
    }
  }

  onStepTwoContinue() {
    if (this.stepTwoForm.valid) {
      this.stepInProgress = true;
      this.showStepTwo = false;
      this.stepOneFailed = false;
      this.stepTwoFailed = false;
      this.paymentProvider
        .stepTwoRegistration({
          policyNumber: this.policyNumber,
          phoneNumber: this.stepOneForm.get('phoneNumber').value,
          code: this.stepTwoForm.get('securityCode').value,
          userID: sessionStorage.getItem(USER_ID)
        })
        .subscribe(
          (resp: any) => {
            if (!resp.messageCode) {
              this.stepTwoFailed = false;
              this.showStepTwo = false;
              this.showStepThree = true;
            } else {
              if(resp.expired=='true'){
                this.isExpired=true;
              }else{
              this.stepTwoFailed = true;
              }
              this.showStepTwo = true;
            }
            this.stepInProgress = false;
          },
          () => {
            this.stepTwoFailed = true;
            this.showStepTwo = true;
            this.stepInProgress = false;
          }
        );
    }else{
      this.stepTwoFailed = true;
    }

  }
  resendCode(){
    this.stepOneFailed = false;
    this.stepTwoFailed = false;
    this.isExpired=false;
    this.paymentProvider
        .stepOneRegistration({
          policyNumber: this.policyNumber,
          phoneNumber: this.stepOneForm.get('phoneNumber').value
        })
        .subscribe(
          (resp: any) => {
            if (!resp.messageCode) {
            } else {
              this.stepOneFailed = true;
              //this.showStepOne = true;
            }
            this.stepInProgress = false;
          },
          () => {
            this.stepOneFailed = true;
            //this.showStepOne = true;
            this.stepInProgress = false;
          }
        );
  }
}
