import { Component, OnInit } from '@angular/core';
import { TogglerComponent } from '../toggler/toggler.component';

@Component({
  selector: 'prcins-round-toggle-switch',
  templateUrl: './round-toggle-switch.component.html',
  styleUrls: ['./round-toggle-switch.component.css']
})
export class RoundToggleSwitchComponent extends TogglerComponent
  implements OnInit {}

/* -- Usage ---
  <prcins-round-toggle-switch 
    [sliderValue]="value" 
    [sliderKey]="key" 
    (sliderChanged)="onChanged($event)">
  </prcins-round-toggle-switch> 
--*/
