<div class="desktop-only-container">
  <main class="px-8 pb-8 pt-4">
    <h1 class="es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0">Login to Your Existing Account</h1>
    <prcins-danger *ngIf="linkError">{{errorMessage}}</prcins-danger>
    <prcins-danger *ngIf="error">{{error}}</prcins-danger>

    <div class="w-full">
      <span>{{invitationDetails.accountLob }} Account Email: </span>
      <span class="font-semibold ml-2"> {{invitationDetails.emailAddress}}</span>
    </div>
    <div class="w-full">
      <span>{{invitationDetails.accountLob }} Policy Number: </span>
      <span class="font-semibold ml-2"> {{invitationDetails.registeredPolicyNumber}}</span>
    </div>
    <p class="my-4">We see you already have a {{invitationDetails.accountLob}} policy registered to this email address.
      Please enter your password
      so we can securely link both policies to one account.</p>
    <form [formGroup]="aiLinkPolicyForm">
      <!-- Password -->
      <ng-container>
        <div class="w-full sm:w-2/2 md:w-3/3 lg:w-9/12 xl:w-9/12">
          <div class="flex items-center justify-end flex-wrap mt-4 showPassword">
            <a *ngIf="!maskPassword; else hidePassword" class="text-es-blue-light cursor-pointer"
              (click)="maskPassword = true">Show Password</a>
            <ng-template #hidePassword>
              <a class="text-es-blue-light cursor-pointer" (click)="maskPassword = false">Hide Password</a>
            </ng-template>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full sm:w-2/2 md:w-3/3 lg:w-9/12 xl:w-9/12">
            <div class="flex items-center justify-between flex-wrap">
              <label class="mb-2 font-semibold w-full lg:w-2/6" for="">Password</label>
              <input [type]="maskPassword ? 'text' : 'password'" trimInput class="w-full lg:w-4/6 es-textfield"
                formControlName="password">
            </div>
            <div class="flex lg:justify-end">
              <prcins-error-label [control]="aiLinkPolicyForm.get('password')"
                [controlValue]="aiLinkPolicyForm.get('password').value" controlLabel="linkPolicyForm_password"
                [controlTouched]="aiLinkPolicyForm.get('password').touched">
              </prcins-error-label>
            </div>
          </div>
          <div class="w-full sm:w-2/2 md:w-3/3 lg:w-3/12 xl:w-3/12">
            <div class="flex items-center justify-between flex-wrap mt-1">
              <span class="ml-2 mt-1"><a class="text-es-blue-light cursor-pointer" (click)="forgotPassword()">Forgot
                  Password?</a>
              </span>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Go Paperless with eDocuments -->
      <ng-container *ngIf="aiLinkPolicyForm.controls.enrollEDOC">
        <div class="flex items-center justify-between flex-wrap mt-4">
          <label class="mb-2 font-semibold w-full lg:w-3/12" for="">Go Paperless with eDocuments</label>
          <div class="add-double-checkboxes w-full lg:w-9/12">
            <input class="checkbox" id="activeDriver" type="radio" formControlName="enrollEDOC" value="Y" />
            <label class="left-label" for="activeDriver">Yes</label>
            <input class="checkbox" id="activeDriverF" type="radio" formControlName="enrollEDOC" value="N" />
            <label class="right-label" for="activeDriverF">No</label>
            <span class="ml-2 mt-4">
              <prcins-help-text id="primaryUse" title="Go Paperless with eDocuments"
                text="eDocuments is the quickest and easiest way to get your policy and billing documents while helping to save the
                              environment! We'll send you a secure email on the same business day your documents become available. 
                              And don't worry, we'll still print and mail any of the items we are required to physically send.">
              </prcins-help-text>
            </span>
          </div>
        </div>
        <div class="flex lg:justify-center" >
          <prcins-error-label [control]="aiLinkPolicyForm.get('enrollEDOC')"
            [controlValue]="aiLinkPolicyForm.get('enrollEDOC').value" controlLabel="passwordForm_enrollEDOC"
            [controlTouched]="aiLinkPolicyForm.get('enrollEDOC').touched">
          </prcins-error-label>
        </div>
        <div *ngIf= "!!showEdocPromisedDisclaimer" class="flex flex-wrap"  style="justify-content: space-evenly;">
          <label class="mb-2 font-semibold" for=""></label>
          <i class="lg:w-3/5">If you choose "No" for eDocuments, the cost of your insurance policy may increase as you will no longer be eligible for policy savings</i>
        </div>
      </ng-container>
    </form>
    <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
  </main>
</div>
<div class="flex justify-end px-8 lg:px-0">
  <button class="w-full lg:w-2/5 es-btn-primary" (click)="onSubmit()" type="button">Continue</button>
</div>

<div class="flex justify-center m-8">
  <label class="flex">
    <p class="terms"> Don't have an account? <a class="text-es-blue hover:underline font-semibold cursor-pointer"
        (click)="goToRegister()">Sign up</a>
    </p>
  </label>
</div>
