import { Component, Input, OnInit} from '@angular/core';
import { environment } from '@prcins/environments';

const UTM_PARAMS = {
  "Payment_Confirmation":	"utm_source=Cross-sell&utm_medium=payment-confirmation&ad=desktop",
  "Address_Change":	"utm_source=Cross-sell&utm_medium=address-change&ad=desktop",
  "Eservice_Homepage": "utm_source=Cross-sell&utm_medium=eservice-homepage&ad=desktop",
  "Coverage_Details": "utm_source=Cross-sell&utm_medium=cov-details&ad=desktop"
}
const CROSS_SELL_URL = `${environment.CROSS_SELL_URL}`;

@Component({
  selector: 'prcins-cross-sell-promotion-widget',
  templateUrl: './cross-sell-promotion-widget.component.html',
  styleUrls: ['./cross-sell-promotion-widget.component.css']
})
export class CrossSellPromotionWidgetComponent implements OnInit {
  _utmParams: string ="";
  crossPromotionURL: string = CROSS_SELL_URL;

  @Input() set pageType(pageType: string) {
    if (pageType && UTM_PARAMS[pageType]) {
      this._utmParams = UTM_PARAMS[pageType];
      this.crossPromotionURL = CROSS_SELL_URL+"/#/?"+this._utmParams;
    } else {
      this.crossPromotionURL = CROSS_SELL_URL;
    }     
  };
  
  constructor() { }

  ngOnInit() {
  }

}
