import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { IPolicy } from '@prcins/utils';
import { CURRENT_POLICY, CURRENT_POLICY_LOB, CURRENT_POLICY_PRODUCT_CODE } from '@prcins/constants';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'prcins-desktop-selector',
  templateUrl: './desktop-selector.component.html',
  styleUrls: ['./desktop-selector.component.css']
})
export class DesktopSelectorComponent implements OnChanges {
  @Input() registeredPolicies: IPolicy[];
  @Input() isHome: boolean;
  @Input() noSelector: boolean;
  @Output() policySelected = new EventEmitter<IPolicy>();
  formattedPolicies;
  additionalPolicies;
  showMorePolicy = false;
  showSecondRow = false;
  isPolicySelected = false;
  currentSelectedPolicy: IPolicy;
  showGuardPopup = false;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnChanges(): void {
    if (this.registeredPolicies) {
      this.currentSelectedPolicy = {
        policyNumber: sessionStorage.getItem(CURRENT_POLICY),
        policyInfo: {
          lob: sessionStorage.getItem(CURRENT_POLICY_LOB),
          productCode: sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE)
        }
      };
      this.formattedPolicies = this.registeredPolicies.map(policy => {
        const policyNumber = policy.policyNumber;
        return {
          ...policy,
          displayPolicy: '(..' + policyNumber.slice(-4) + ')'
        };
      });
      if (this.formattedPolicies.length > 4) {
        this.additionalPolicies = this.formattedPolicies.slice(4);
        this.formattedPolicies = this.formattedPolicies.slice(0, 4);
        this.showMorePolicy = true;
      } else {
        this.showMorePolicy = false;
      }
    }
  }

  onMorePolicyRollOver() {
    this.showSecondRow = true;
  }

  onLeaveSecondPolicy() {
    if (this.showSecondRow) {
      setTimeout(() => {
        this.showSecondRow = false;
      }, 250);
    }
  }

  onPolicySelected(policy: IPolicy) {
    if (policy.policyNumber != sessionStorage.getItem(CURRENT_POLICY) && !sessionStorage.getItem('guid')) {
      this.isPolicySelected = true;
      this.currentSelectedPolicy = policy;
      this.policySelected.next(policy);
      this.onLeaveSecondPolicy();
    } else {
      return;
    }

  }



  linkPolicy() {
    this.onLeaveSecondPolicy();
    this.router.navigate(['../home/link-a-policy'], { relativeTo: this.route });
  }
}
