import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { setContext } from "apollo-link-context";
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { environment } from '@prcins/environments';
import introspectionQueryResultData from "./utils/fragment.types";
import { TOKEN } from '@prcins/constants';
import { ApolloLink } from 'apollo-link';

const baseURL = sessionStorage.getItem('baseURL') || environment.BASE_URL;
const uri = baseURL + "/api";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const auth = setContext((_, { headers }) => {

  const token = sessionStorage.getItem(TOKEN);

  if (!headers) {
    headers = {};
  }

  /*if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }*/

  return {
    headers
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      alert("GQL Error");
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export function createApollo(httpLink: HttpLink) {

  const httpLinkWithErrorHandling = ApolloLink.from([
    // errorLink,
    httpLink.create({ uri }),
  ]);
  return {
    link: auth.concat(httpLinkWithErrorHandling).concat(errorLink),
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: object => {
        if (object.__typename === "AddressDTO") {
          return object.id + "_Address";
        } else if (object.__typename === "VehicleDTO") {
          return object.id + "_Vehicle";
        }
        return object.id;
      }
    })
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
