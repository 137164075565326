import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { map } from 'rxjs/operators';
import { BillingInstallmentsQuery, HomeEndorsementControlAgentQuery } from '@prcins/utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
export class OpenmortgageamendmentService {
    // apiUrl = "http://apis.dev.prcaws.net/eservice-ws/v2/";
    constructor(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.apiUrl = environment.BASE_URL + "/v2/";
    }
    getInstallmentDetails() {
        let policyNumber = sessionStorage.getItem('currentPolicy');
        return this.apollo
            .watchQuery({
            query: BillingInstallmentsQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    getInstallmentDetailsFromCache(policyNumber) {
        return this.apollo
            .getClient()
            .readQuery({
            query: BillingInstallmentsQuery,
            variables: {
                policyNumber
            }
        });
    }
    updateInstallmentDetailsFromCache(policyNumber, payload) {
        return this.apollo
            .getClient()
            .writeQuery({
            query: BillingInstallmentsQuery,
            variables: {
                policyNumber
            },
            data: payload
        });
    }
    getAdrControlAgent(policyNumber) {
        return this.apollo
            .watchQuery({
            query: HomeEndorsementControlAgentQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    recordEndorsmentBlocked(type, guid) {
        let policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.put(this.apiUrl + 'core/' + policyNum + '/endorsements/recordEndorsmentBlocked?type=' + type + (guid ? '&guid=' + guid : ''), {});
    }
    createEndorsement(type) {
        let policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.post(this.apiUrl + policyNum + '/endorsements?type=' + type, {});
    }
    getEndorsementSummary(policyNum, guid) {
        return this.http.get(this.apiUrl + policyNum + '/endorsements/' + guid);
    }
    rateEndorsement() {
        let policyNum = sessionStorage.getItem('currentPolicy');
        let guid = sessionStorage.getItem('guid');
        return this.http.put(this.apiUrl + policyNum + '/endorsements/' + guid + '/rate', {});
    }
    issueEndorsePolicyV2(policyNum, guid, request) {
        return this.http.put(this.apiUrl + policyNum + '/endorsements/' + guid + '/issue', request)
            .pipe(map((response) => {
            let { error, billingEndorsements: [{ endorsementEntity: { endorsementBillingDetails: { payplanLabel } } }], summary: { metaInfo: { fileId } } } = response;
            return { errorCode: error, fileId, payplanLabel };
        }));
    }
    cancelEndorsement() {
        let policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : sessionStorage.getItem('splPolicyNumber');
        let guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : sessionStorage.getItem('splGuid');
        let url = this.apiUrl + policyNum + '/endorsements/' + guid;
        return this.http.delete(url);
    }
    cancelEndorsementWithParams(policyNum, guid) {
        let url = this.apiUrl + policyNum + '/endorsements/' + guid;
        return this.http.delete(url);
    }
    deleteMortgageeEndorsement(mortgageeId) {
        let policyNum = sessionStorage.getItem('currentPolicy');
        let guid = sessionStorage.getItem('guid');
        let url = this.apiUrl + policyNum + '/endorsements/' + guid + '/mortgagees/' + mortgageeId;
        return this.http.delete(url);
    }
}
OpenmortgageamendmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpenmortgageamendmentService_Factory() { return new OpenmortgageamendmentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: OpenmortgageamendmentService, providedIn: "root" });
