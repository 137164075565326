import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lobLabel'
})
export class LobLabelPipe implements PipeTransform {
  transform(value: string, customLabel?: string): string {
    let policy;
    switch (value) {
      case 'PA':
        policy = 'Auto';
        break;
      case 'MC':
        policy = 'Motorcycle';
        break;
      case 'HO':
        policy = 'Home';
        break;
      case 'CA':
        policy = 'Business Auto';
        break;
      case 'PCAT':
        policy = 'Umbrella';
        break;
      case 'ALN_UMB':
        policy = 'Umbrella';
        break;
      default:
        policy = '';
    }
    if (customLabel) {
      policy = `${policy} ${customLabel}`;
    }
    return policy;
  }
}
