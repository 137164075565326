import { setContext } from "apollo-link-context";
import { HttpLink } from 'apollo-angular-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { environment } from '@prcins/environments';
import introspectionQueryResultData from "./utils/fragment.types";
import { TOKEN } from '@prcins/constants';
import { ApolloLink } from 'apollo-link';
const baseURL = sessionStorage.getItem('baseURL') || environment.BASE_URL;
const uri = baseURL + "/api";
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});
const ɵ0 = (_, { headers }) => {
    const token = sessionStorage.getItem(TOKEN);
    if (!headers) {
        headers = {};
    }
    /*if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }*/
    return {
        headers
    };
};
const auth = setContext(ɵ0);
const ɵ1 = ({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
            alert("GQL Error");
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
    if (networkError)
        console.log(`[Network error]: ${networkError}`);
};
const errorLink = onError(ɵ1);
export function createApollo(httpLink) {
    const httpLinkWithErrorHandling = ApolloLink.from([
        // errorLink,
        httpLink.create({ uri }),
    ]);
    return {
        link: auth.concat(httpLinkWithErrorHandling).concat(errorLink),
        cache: new InMemoryCache({
            fragmentMatcher,
            dataIdFromObject: object => {
                if (object.__typename === "AddressDTO") {
                    return object.id + "_Address";
                }
                else if (object.__typename === "VehicleDTO") {
                    return object.id + "_Vehicle";
                }
                return object.id;
            }
        })
    };
}
export class GraphQLModule {
}
export { ɵ0, ɵ1 };
