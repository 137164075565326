<div class="desktop-only-container">
  <main class="px-8 pb-8 pt-4">
    <h1 class="es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0">
      Create Password</h1>
    <prcins-danger *ngIf="registrationError">
      <span>
        {{errorMessage}}
      </span>
    </prcins-danger>
    <ng-container *ngIf="showCreatePasswordForm; else loading">
      <prcins-password-form [passwordForm]="createPasswordForm">
      </prcins-password-form>
    </ng-container>

    <ng-template #loading>
      <prcins-card pt="pt-0">
        <div body>
          <prcins-skeleton numOfRows="6"></prcins-skeleton>
        </div>
      </prcins-card>
    </ng-template>

    <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
  </main>
</div>

<div class="flex px-8 lg:px-0 mb-4" *ngIf="showCreatePasswordForm">
  <label class="flex items-center">
    <strong class="terms"> I have read and agree to the <a class="text-es-blue hover:underline"
        href="https://www.plymouthrock.com/utility/internet-policy-popup" target="_blank"
        rel="noopener noreferrer">Terms and
        Conditions</a> and I <a href="https://www.plymouthrock.com/internet-policy/consent-to-conduct" target="_blank"
        class="text-blue-600">Consent to Conduct
        Business Electronically</a>.</strong>
  </label>
</div>

<div class="flex justify-end flex-wrap px-8 lg:px-0" *ngIf="showCreatePasswordForm">
  <button class="w-full lg:w-2/5 es-btn-primary mt-2" (click)="onSubmit()" type="button">Create my Account</button>
</div>

<prcins-es-popup *ngIf="alreadyRegisteredAccountPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Email Address</span>
  </div>
  <div body>
    <p>We found a registered account associated with that email
      address, please select one of the following:</p>
    <div class="flex mt-4 items-center">
      <prcins-round-radio radioName="registeredAccount" radioValue="EXISTING"
        (radioChanged)="registeredAccount($event)">
      </prcins-round-radio>
      <span class="ml-2">Login to my existing account</span>
    </div>
    <div class="flex mt-4 items-center">
      <prcins-round-radio radioName="registeredAccount" radioValue="NEW_POLICY"
        (radioChanged)="registeredAccount($event)">
      </prcins-round-radio>
      <span class="ml-2">Add a new policy to my existing
        account</span>
    </div>
    <div class="add-btns">
	<button class="add-btn add-btn-primary" type="button" (click)="onContinue()">Continue</button>
      <button class="add-btn add-btn-cancel" type="button"
        (click)="alreadyRegisteredAccountPopup = false">Cancel</button>      
    </div>
  </div>
</prcins-es-popup>

<prcins-es-popup *ngIf="showGuardPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Confirmation</span>
  </div>
  <div body>
    <p class="text-center text-lg">Are you sure you want to cancel this registration?</p>
    <div class="flex flex-wrap-reverse justify-between">
      <a class="es-link-secondary  w-half-2 mt-8  cursor-pointer" (click)="chooseDeactivate(false)">No</a>
      <button class="es-btn-primary w-half-2 mt-8 cursor-pointer" type="button"
        (click)="cancelTransaction()">Yes</button>
    </div>
  </div>
</prcins-es-popup>
