/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./danger.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./danger.component";
var styles_DangerComponent = [i0.styles];
var RenderType_DangerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DangerComponent, data: {} });
export { RenderType_DangerComponent as RenderType_DangerComponent };
export function View_DangerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { element: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["danger", 1]], null, 1, "div", [["class", "bg-red-300 p-4 w-full text-black rounded shadow-lg mb-4"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_DangerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-danger", [], null, null, null, View_DangerComponent_0, RenderType_DangerComponent)), i1.ɵdid(1, 4243456, null, 0, i2.DangerComponent, [], null, null)], null, null); }
var DangerComponentNgFactory = i1.ɵccf("prcins-danger", i2.DangerComponent, View_DangerComponent_Host_0, {}, {}, ["*"]);
export { DangerComponentNgFactory as DangerComponentNgFactory };
