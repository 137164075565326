import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import packageJson from '../../../../../package.json';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class SessionService {
    constructor(http, router) {
        this.http = http;
        this.router = router;
        this.version = packageJson.version;
    }
    transferSession(target, handler) {
        const URL = `${environment.AUTH_URL}/session/redirect`;
        let { currentPolicy: selectedPolicy, userId } = sessionStorage;
        return this.http.post(URL, { target, handler, selectedPolicy, userId });
    }
    transferEndorsementSession(target, endorsementContext) {
        const URL = `${environment.AUTH_URL}/session/redirect`;
        let { currentPolicy: selectedPolicy, userId } = sessionStorage;
        return this.http.post(URL, { target, handler: null, selectedPolicy, userId, endorsementContext });
    }
    hydrateSession(session) {
        const URL = `${environment.AUTH_URL}/session/restore?session=${session}`;
        return this.http.post(URL, {});
    }
    invalidateCacheAfterNewDeploy(frequency = 1000 * 60 * 5) {
        setInterval(() => {
            if (this.router.url === '/eservice/login' || this.router.url === '/eservice/home') {
                console.log(this.router.url);
                this.checkVersion('/version/version.json'); //use assets for local
            }
        }, frequency);
    }
    checkVersion(url) {
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            .pipe()
            .subscribe((response) => {
            if (this.version === response.version) {
                //do nothing
            }
            else {
                this.clearCache();
                // window.location.reload(true); as passing true deprecated using below line of code
                window.location.href = window.location.href;
            }
        }, (err) => {
            console.error(err, 'Could not get version');
        });
    }
    clearCache() {
        if ('caches' in window) {
            caches.keys().then((names) => {
                names.forEach(async (name) => {
                    await caches.delete(name);
                });
            });
        }
    }
}
SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: SessionService, providedIn: "root" });
