/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./verification-options/verification-options.component.ngfactory";
import * as i3 from "./verification-options/verification-options.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/router";
import * as i6 from "../../../../utils/src/lib/services/form-validator.service";
import * as i7 from "../../../../utils/src/lib/services/registration.service";
import * as i8 from "./code-verification/code-verification.component.ngfactory";
import * as i9 from "./code-verification/code-verification.component";
import * as i10 from "@angular/common";
import * as i11 from "./verification.component";
var styles_VerificationComponent = [i0.styles];
var RenderType_VerificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerificationComponent, data: {} });
export { RenderType_VerificationComponent as RenderType_VerificationComponent };
function View_VerificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "prcins-verification-options", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onFormSubmitted($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VerificationOptionsComponent_0, i2.RenderType_VerificationOptionsComponent)), i1.ɵdid(2, 114688, null, 0, i3.VerificationOptionsComponent, [i4.FormBuilder, i5.Router, i5.ActivatedRoute, i6.FormValidatorService, i7.RegistrationService], { userPolicyDetailsResp: [0, "userPolicyDetailsResp"], verificationMode: [1, "verificationMode"] }, { submitted: "submitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userPolicyDetailsResp; var currVal_1 = _co.verificationMode; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_VerificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "prcins-code-verification", [], null, [[null, "backClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backClicked" === en)) {
        var pd_0 = (_co.onBackClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_CodeVerificationComponent_0, i8.RenderType_CodeVerificationComponent)), i1.ɵdid(2, 114688, null, 0, i9.CodeVerificationComponent, [i4.FormBuilder, i5.Router, i5.ActivatedRoute, i6.FormValidatorService, i7.RegistrationService], { verificationOptionsForm: [0, "verificationOptionsForm"], userPolicyDetailsResp: [1, "userPolicyDetailsResp"] }, { backClicked: "backClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.verificationOptionsForm; var currVal_1 = _co.userPolicyDetailsResp; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_VerificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerificationComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerificationComponent_2)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isModeSelected && _co.userPolicyDetailsResp); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isModeSelected; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_VerificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-verification", [], null, null, null, View_VerificationComponent_0, RenderType_VerificationComponent)), i1.ɵdid(1, 114688, null, 0, i11.VerificationComponent, [i7.RegistrationService, i5.Router, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerificationComponentNgFactory = i1.ɵccf("prcins-verification", i11.VerificationComponent, View_VerificationComponent_Host_0, {}, {}, []);
export { VerificationComponentNgFactory as VerificationComponentNgFactory };
