import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UsDateFormatMaskDirective } from './us-date-format-mask.directive';

/**
 * An extension of USDateFormatMaskDirective that replaces the month and date fields
 * with '*' when the user focuses out of the field and when rendering the initial
 * field value.
 */
@Directive({
  selector: '[formControlName] [appSecureUSDateFormatMask]'
})
export class SecureUsDateFormatMaskDirective extends UsDateFormatMaskDirective {
  constructor(public ngControl: NgControl, private elemRef: ElementRef) {
    super(ngControl);
  }

  @HostListener('focus', ['$event'])
  onFocus() {
    const date: any = this.ngControl.value;
    let month: string;
    let day: string;
    let year: string = '';
    if (date != '') {
      month = date.split('/')[0];
      if (month.length === 1) {
        month = '0' + month;
      }
      day = date.split('/')[1];
      if (day.length === 1) {
        day = '0' + day;
      }
      if (date.length >= 7) {
        year = date.split('/')[2];
      }
      this.ngControl.valueAccessor.writeValue(month + '/' + day + '/' + year);
    } else {
      this.ngControl.valueAccessor.writeValue(this.ngControl.value);
    }
  }

}
