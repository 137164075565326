/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-email.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../utils/src/lib/danger/danger.component.ngfactory";
import * as i3 from "../../../../utils/src/lib/danger/danger.component";
import * as i4 from "../../../../utils/src/lib/spinner/spinner.component.ngfactory";
import * as i5 from "../../../../utils/src/lib/spinner/spinner.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../../utils/src/lib/register-user-form/register-user-form.component.ngfactory";
import * as i8 from "../../../../utils/src/lib/register-user-form/register-user-form.component";
import * as i9 from "@angular/router";
import * as i10 from "./forgot-email.component";
import * as i11 from "../../../../utils/src/lib/services/login.service";
import * as i12 from "../../../../utils/src/lib/services/form-validator.service";
import * as i13 from "../../../../utils/src/lib/services/utility-provider.service";
import * as i14 from "../../../../utils/src/lib/services/registration.service";
var styles_ForgotEmailComponent = [i0.styles];
var RenderType_ForgotEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotEmailComponent, data: {} });
export { RenderType_ForgotEmailComponent as RenderType_ForgotEmailComponent };
function View_ForgotEmailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "prcins-danger", [], null, null, null, i2.View_DangerComponent_0, i2.RenderType_DangerComponent)), i1.ɵdid(1, 4243456, null, 0, i3.DangerComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 3, 0, currVal_0); }); }
function View_ForgotEmailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-spinner", [], null, null, null, i4.View_SpinnerComponent_0, i4.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i5.SpinnerComponent, [], null, null)], null, null); }
export function View_ForgotEmailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "desktop-only-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "main", [["class", "px-8 pb-8 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Forgot Email?"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotEmailComponent_1)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Don't worry, we'll quickly locate your account. First enter the following information associated with this account."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "prcins-register-user-form", [], null, null, null, i7.View_RegisterUserFormComponent_0, i7.RenderType_RegisterUserFormComponent)), i1.ɵdid(9, 114688, null, 0, i8.RegisterUserFormComponent, [], { userForm: [0, "userForm"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotEmailComponent_2)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "flex justify-between flex-wrap px-8 lg:px-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["class", "w-full lg:w-2/5 es-btn-secondary mt-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i9.RouterLink, [i9.Router, i9.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(15, 1), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "w-full lg:w-2/5 es-btn-primary mt-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onForgotEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isPolicyFound; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.forgotEmailForm; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.showSpinner; _ck(_v, 11, 0, currVal_2); var currVal_3 = _ck(_v, 15, 0, "../../login"); _ck(_v, 14, 0, currVal_3); }, null); }
export function View_ForgotEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-forgot-email", [], null, null, null, View_ForgotEmailComponent_0, RenderType_ForgotEmailComponent)), i1.ɵdid(1, 114688, null, 0, i10.ForgotEmailComponent, [i11.LoginService, i9.Router, i9.ActivatedRoute, i12.FormValidatorService, i13.UtilityProviderService, i14.RegistrationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotEmailComponentNgFactory = i1.ɵccf("prcins-forgot-email", i10.ForgotEmailComponent, View_ForgotEmailComponent_Host_0, {}, {}, []);
export { ForgotEmailComponentNgFactory as ForgotEmailComponentNgFactory };
