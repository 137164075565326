const USStateSpecs = {
  AL: { // 7 Numeric
    pattern: '^[0-9]{7}$',
    length: 7,
    modifiers: 'i'
  },
  AK: { // Up To 7 Digits
    pattern: '^[0-9]{1,7}$',
    length: 7,
    modifiers: 'i'
  },
  AZ: { // 1 Alpha 8 Digits; or 9 Numeric (SSN)
    pattern: '^[A-Za-z][0-9]{8}$|^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  AR: { // 9 numeric (SSN); or 8 Numeric
    pattern: '^[0-9]{8,9}$',
    length: 9,
    modifiers: 'i'
  },
  CA: { // 1 Alpha 7 Numeric
    pattern: '^[A-Za-z][0-9]{7}$',
    length: 8,
    modifiers: 'i'
  },
  CO: { // 9 Numeric (Not SSN)
    pattern: '^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  CT: { // 9 Numeric (Not SSN)
    pattern: '^[0][1-9][0-9]{7}$|^[1][0-9]{8}$|^[2][0-4][0-9]{7}$',
    length: 9,
    modifiers: 'i'
  },
  DE: { // 1-7 Numeric
    pattern: '^[0-9]{1,7}$',
    length: 7,
    modifiers: 'i'
  },
  DC: { // 9 Numeric (SSN); or 7 Numeric
    pattern: '^[0-9]{7}$|^[0-9]{9}$|[A-Za-z0-9]{2}[0-9]{8}$',
    length: 10,
    modifiers: 'i'
  },
  FL: { // 1 Alpha 12 Numeric
    pattern: '^[A-Za-z][0-9]{12}|[A-Za-z][0-9]{11}$',
    length: 13,
    modifiers: 'i'
  },
  GA: { // 7-9 Numeric
    pattern: '^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  HI: { // 9 Numeric
    pattern: '^[0-9]{9}|^(H)[0-9]{8}$',
    length: 9,
    modifiers: 'i'
  },
  ID: { // 2 Alpha 6 Numeric 1 Alpha or 9  Numeric (SSN)
    pattern: '^[A-Za-z]{2}[0-9]{6}[A-Za-z]{1}$|^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  IL: { // First Letter Of Last Name And 11 Digits
    pattern: '^[A-Za-z][0-9]{11}$',
    length: 12,
    modifiers: 'i'
  },
  IN: { // 10 Numeric; or 9 Numeric  (SSN)
    pattern: '^[0-9]{9,10}$',
    length: 10,
    modifiers: 'i'
  },
  IA: { // 9 Numeric (SSN); or 3 Numeric 2 Alpha 4 Numeric
    pattern: '^[0-9]{3}[A-Za-z]{2}[0-9]{4}$|^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  KS: { // 9 Numeric (SSN); or 1 Alpha 8 Numeric
    pattern: '^[0-9]{9}$|^[A-Za-z][0-9]{8}$',
    length: 9,
    modifiers: 'i'
  },
  KY: { // 9 Numeric (SSN); or 1 Alpha 8 Numeric
    pattern: '^[0-9]{9}$|^[A-Za-z][0-9]{8}$',
    length: 9,
    modifiers: 'i'
  },
  LA: { // 9 Numeric (First Two Numbers Are 00)
    pattern: '^[0]{2}[0-9]{7}$',
    length: 9,
    modifiers: 'i'
  },
  ME: { // 7 Numeric
    pattern: '^[0-9]{7}$',
    length: 7,
    modifiers: 'i'
  },
  MD: { // 1 Alpha (1st Letter Last Name) 12 Numeric
    pattern: '^[A-Za-z][0-9]{12}$',
    length: 13,
    modifiers: 'i'
  },
  MA: { // 1 Alpha 8 Numeric; or 9 Numeric (SSN)
    pattern: '^[^X][0-9]{8}$|^(SA)[0-9]{7}|^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  MI: { // 1 Alpha (1st Letter Last Name) 12 Numeric
    pattern: '^[A-Za-z][0-9]{12}$',
    length: 13,
    modifiers: 'i'
  },
  MN: { // 1 Alpha (1st Letter Last Name) 12 Numeric
    pattern: '^[A-Za-z][0-9]{12}$',
    length: 13,
    modifiers: 'i'
  },
  MS: { // 9 Numeric (Can Be SSN)
    pattern: '^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  MO: { // 9 Numeric (Can Be SSN); or 1 Alpha 5-9 Numeric
    pattern: '^[0-9]{9}$|^[A-Za-z][0-9]{5,9}$',
    length: 10,
    modifiers: 'i'
  },
  MT: { // 1Alpha+8Numeric or 13Numeric or 9Numeric or 14Numeric
    pattern: '^[A-Za-z][0-9]{8}$|^[0-9]{13}$|^[0-9]{9}$|^[0-9]{14}$',
    length: 14,
    modifiers: 'i'
  },
  NE: { // 1 Alpha 3-8 Numeric
    pattern: '^[A-Za-z][0-9]{3,8}$',
    length: 9,
    modifiers: 'i'
  },
  NV: { // 10 Numeric; or 12 Numeric; or X Followed By 8 Numeric
    pattern: '^[0-9]{10}$|^[0-9]{12}$|^x{1}[0-9]{8}$',
    length: 12,
    modifiers: 'i'
  },
  NH: { // 2 Numeric 3 Alpha 5 Numeric or NEW NH format 'NHL' 8 numbers
    pattern: '^[0-9]{2}[A-Za-z]{3}[0-9]{4}[1-9]{1}$|^NHL\\d{8}',
    length: 11,
    modifiers: 'i'
  },
  NJ: { // 1 Alpha (1st Letter Last Name) 14 Numeric
    pattern: '^[A-Za-z][0-9]{14}$',
    length: 15,
    modifiers: 'i'
  },
  NY: { // 9 Numeric
    pattern: '^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  NM: { // 9 Numeric; or 9 Numeric (SSN)
    pattern: '^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  NC: { // 1-8 Numeric
    pattern: '^[0-9]{1,12}$',
    length: 12,
    modifiers: 'i'
  },
  ND: { // 9 Numeric (SSN); or 9 Numeric
    pattern: '^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  OH: { // 2 Alpha 6 Numeric
    pattern: '^[A-Za-z]{2}[0-9]{6}$',
    length: 8,
    modifiers: 'i'
  },
  OK: { // 9 Numeric (SSN); or 9 Numeric
    pattern: '^[0-9]{9}$',
    length: 9,
    modifiers: 'i'
  },
  OR: { // 1-7 Numeric
    pattern: '^[0-9]{1,7}$',
    length: 7,
    modifiers: 'i'
  },
  PA: { // 8 Numeric
    pattern: '^[0-9]{8}$',
    length: 8,
    modifiers: 'i'
  },
  RI: { // 7 Numeric
    pattern: '^[0-9]{7,8}|^(V)[0-9]{7}$',
    length: 8,
    modifiers: 'i'
  },
  SC: { // 9 Numeric
    pattern: '^[0-9]{1,10}$',
    length: 10,
    modifiers: 'i'
  },
  SD: { // 9 Numeric (SSN); or 8 Numeric
    pattern: '^[0-9]{8,9}$',
    length: 9,
    modifiers: 'i'
  },
  TN: { // 7,8 or 9 Numeric
    pattern: '^[0-9]{7,9}$',
    length: 9,
    modifiers: 'i'
  },
  TX: { // 8 Numeric
    pattern: '^[0-9]{8}$',
    length: 8,
    modifiers: 'i'
  },
  UT: { // 4-10 Numeric
    pattern: '^[0-9]{4,10}$',
    length: 10,
    modifiers: 'i'
  },
  VT: { // 8 Numeric; or 7 Numeric 1 Alpha
    pattern: '^[0-9]{8}$|^[0-9]{7}(A)$',
    length: 8,
    modifiers: 'i'
  },
  VA: { // 9 Numeric (SSN); or 1 Alpha 8 Numeric
    pattern: '^[0-9]{9}$|^[0-9]{12}$|^[A-Za-z][0-9]{8}$',
    length: 12,
    modifiers: 'i'
  },
  WA: { // 12 Characters
    pattern: '^(WDL)[A-Z0-9]{9}|^[A-Z0-9]{9}$',
    length: 12,
    modifiers: 'i'
  },
  WV: { // 7 Numeric; or 1 Alpha 6 Numeric
    pattern: '^(XX)[0-9]{5}|^[1]{1}(X)[0-9]{5}$|^[ABCDEFGIORS]{1}[0-9]{6}|^[0]{1}[0-9]{6}$',
    length: 7,
    modifiers: 'i'
  },
  WI: { // 1 Alpha 13 Numeric
    pattern: '^[A-Za-z][0-9]{13}$',
    length: 14,
    modifiers: 'i'
  },
  WY: { // 9-10 Numeric
    pattern: '^[0-9]{9,10}$',
    length: 10,
    modifiers: 'i'
  },
  CDA: { // 1 Alpha (1st Letter Last Name) 14 Numeric
    pattern: '^[A-Za-z][0-9]{14}$',
    length: 15,
    modifiers: 'i'
  },
  FI: { // letters and numbers only
    pattern: '',
    length: 20,
    modifiers: 'i'
  }
};

export const DefaultLicenseNumberSpecs = {
  pattern: new RegExp('[A-Za-z0-9]+', 'i'),
  length: 14
};

export interface ILicenseNumberSpecs {
  pattern: RegExp;
  length: number;
}

/**
 * Value object describing the license number specifications for a state
 */
export class LicenseNumberSpecs implements ILicenseNumberSpecs {
  private _pattern: RegExp;
  private _length: number;

  private constructor() { }

  /**
   * Returns an instance of LicenseNumberSpecs for the given state, or DefaultLicenseNumberSpecs
   * the state is not a valid US state
   * @param state the US state code
   * @param defaultSpecs the default LicenseNumberSpecs to return if no defined specs for the given 
   * state can be found
   */
  // tslint:disable-next-line: function-name
  static forState(state: string, defaultSpecs = DefaultLicenseNumberSpecs): LicenseNumberSpecs {
    const result = new LicenseNumberSpecs();

    const specs = USStateSpecs[state] || defaultSpecs;

    result._pattern = specs && specs.pattern ? new RegExp(specs.pattern, specs.modifiers || '') : null;
    result._length = specs ? specs.length : 0;

    return result;
  }

  get pattern(): RegExp {
    return this._pattern;
  }

  get length(): number {
    return this._length;
  }
}
