import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { LicenseNumberSpecs } from '../utils/license-number-specs'

export function dateValidator(format = 'M/D/YYYY'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const valueAsMoment = format ? moment(control.value, format, true) : moment(control.value);

    return valueAsMoment.isValid() ? null : { invalidDate: { value: control.value } };
  };
}

export function dateWithinRange(min: number, max: number, format = 'MM/DD/YYYY', unitOfTime: moment.unitOfTime.Diff = 'years'): ValidatorFn {
  return (control: AbstractControl): { dateWithinRange: { type: 'max' | 'min' | 'invalid', value: any } } | null => {
    if (!control.value) {
      return null;
    }
    const valueAsMoment = format ? moment(control.value, format, false) : moment(control.value);

    if (!valueAsMoment.isValid()) {
      const dateInParts = control.value.split('/');
      if (control.value.length >= 8 && control.value.length <= 10 && control.value.includes('/') && dateInParts[2].length === 4
        && (dateInParts[1].length === 1 || dateInParts[0].length === 1)) {
        if (dateInParts[0] === '0' || dateInParts[1] === '0') {
          return { dateWithinRange: { type: 'invalid', value: control.value } };
        }
        return { dateWithinRange: { type: 'invalid', value: control.setValue(control.value) } };
      } else {
        return { dateWithinRange: { type: 'invalid', value: control.value } };
      }
    } else {
      if (!moment(control.value, 'MM/DD/YYYY', true).isValid()) {
        const dateInParts = control.value.split('/');
        if (!(control.value.length >= 8 && (dateInParts[0].length === 1 || dateInParts[1].length === 1))) {
          return { dateWithinRange: { type: 'invalid', value: control.value } };
        }
      }
    }
    if (control.value.length >= 10) {
      const diff = moment().diff(valueAsMoment, unitOfTime);

      if (diff > max) {
        return { dateWithinRange: { type: 'max', value: max } };

      } else if (diff < min) {
        return { dateWithinRange: { type: 'min', value: min } };

      } else if (isNaN(diff)) {
        return { dateWithinRange: { type: 'invalid', value: control.value } };
      }
    }
    return null;
  };
}

export function maxDateValidator(maxDate = new Date(), format = 'M/D/YYYY'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const valueAsMoment = format ? moment(control.value, format, true) : moment(control.value);

    if (!valueAsMoment.isValid()) {
      return null;
    }

    if (valueAsMoment.isSameOrBefore(moment(maxDate), 'day')) {
      return null;
    }

    return { maxDate: { value: moment(maxDate).format(format) } };
  };
}

export function courseCompletionDateValidator(maxDate = (moment().subtract(0, 'day').toDate()), format = 'M/D/YYYY'): ValidatorFn {
  return Validators.compose([
    Validators.required,
    dateValidator(format),
    maxDateValidator(maxDate, format),
  ]);
}



export function usZipValidators(): ValidatorFn {
  return Validators.compose([
    Validators.required,
    Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)
  ]);
}

export function licenseNumberValidator(state: string): ValidatorFn {
  if (!state) {
    return Validators.nullValidator;
  }

  const stateSpecs = LicenseNumberSpecs.forState(state);

  return Validators.compose([
    Validators.required,
    Validators.maxLength(stateSpecs.length),
    Validators.pattern(stateSpecs.pattern)
  ]);
}
