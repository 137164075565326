import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import * as _ from 'lodash';

/**
 * Mask for input fields accepting dates in the 'MM/DD/YYYY' format. Automatically
 * adds forward slashes between the month, date and year components as
 * the date is being typed, and prepends '0' to single-digit months and days.
 */
@Directive({
  selector: '[formControlName] [appUSDateFormatMask]'
})
export class UsDateFormatMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }
  two: boolean = false;
  four: boolean = false;
  // tslint:disable-next-line: max-func-body-length
  onInputChange(event, backspace) {
    if (!event) {
      return;
    }

    let originalVal = event;
    let newVal = event.replace(/\D/g, '');

    if (backspace) {
      if (_.includes(event, '*')) {
        this.ngControl.valueAccessor.writeValue('');
      } else {
        this.ngControl.valueAccessor.writeValue(event);
      }

    } else {
      if (!_.includes(event, '*')) {
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 2) {
          this.two = true;
          if (newVal > 12) {
            newVal = newVal.charAt(1) > 3 ?
              '0' + newVal.charAt(0) + '/0' + newVal.charAt(1) + '/' :
              '0' + newVal.charAt(0) + '/' + newVal.charAt(1);
          } else {
            newVal = newVal.replace(/^(\d{0,2})/, '$1');
          }

        } else if (newVal.length <= 4) {
          this.four = true;
          if (newVal.substring(2, 4) > 31) {
            newVal = newVal.substring(0, 3);
            newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
          } else {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
          }

        } else if (newVal.length <= 8) {
          if ((this.two == true && this.four == true) || (newVal.length === 5)) {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
            this.two = false;
            this.four = false;
          } else {
            newVal = originalVal;
          }
        } else if (newVal.length > 8) {
          newVal = newVal.substring(0, 8);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
        } else {
          newVal = newVal.substring(0, 8);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
        }
        this.ngControl.valueAccessor.writeValue(newVal);
      } else {
        this.ngControl.valueAccessor.writeValue(event.substring(0, 10));
      }
    }

  }

}
