<!--*ngIf="premiumDetails$ | async as premiumDetails; else loading"-->

<ng-container>
    <div class="p-4 lg:px-2 lg:pb-0 mt-8 es-banner">
        <div class="hidden lg:flex xl:flex w-1/3 relative mb-0 pb-0"><img class="absolute bottom-0" src="assets/svgs/rr-banner.svg" /></div>
        <div class="w-full lg:w-2/3 text-justify">
            <div class="es-banner-h1 text-center lg:text-left">Drive Safe. Get Rewarded.</div>
            <div class="pb-2 mr-12">Enroll in Road Rewards, drive safe and earn everyday rewards like Amazon.com gift cards, Starbucks
                cards and more.
            </div>
            <div class="pb-2 flex self-center">
                <a class="es-link-primary mt-0 cursor-pointer w-1/3" (click)="openRRPage()">Enroll Now</a>
            </div>
        </div>
    </div>
</ng-container>


<ng-template #loading>
    <prcins-card pt="pt-0">
        <div body>
            <prcins-skeleton numOfRows="5"></prcins-skeleton>
        </div>
    </prcins-card>
</ng-template>