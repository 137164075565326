import { TealiumUtagService } from '../tealium/utag.service';
import { environment } from '@prcins/environments';

const tealium = new TealiumUtagService();
tealium.setConfig({
  account: 'plymouth',
  profile: 'eservice',
  environment: environment.production? 'prod':'qa'
});

export function view(component) {
  console.log('component', component);
  return function(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    console.log('target', target);
    console.log('propertyKey', propertyKey);
    console.log('descriptor', descriptor);
    tealium.view(component);
  };
}

export function link(component) {
  console.log('component', component);
  return function(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    console.log('target', target);
    console.log('propertyKey', propertyKey);
    console.log('descriptor', descriptor);
    tealium.link(component);
  };
}
