import { Router, ActivatedRoute } from '@angular/router';
import { UtilityProviderService, LoginService } from '@prcins/utils';
import { Apollo } from 'apollo-angular';
import { USER_LOGIN } from '@prcins/constants';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "../../../../../libs/utils/src/lib/services/login.service";
import * as i3 from "../../../../../libs/utils/src/lib/services/utility-provider.service";
import * as i4 from "@angular/router";
import * as i5 from "ngx-cookie-service";
export class AuthorizeGuard {
    constructor(apollo, loginService, utilityProviderService, router, route, cookieService) {
        this.apollo = apollo;
        this.loginService = loginService;
        this.utilityProviderService = utilityProviderService;
        this.router = router;
        this.route = route;
        this.cookieService = cookieService;
    }
    canActivateChild(childRoute, state) {
        return this.canActivate(childRoute, state);
    }
    canActivate(route, state) {
        const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : this.cookieService.get('token');
        if (sessionStorage.getItem(USER_LOGIN) === 'Y' && token)
            return true;
        this.onLogout(!token);
        return false;
    }
    onLogout(clearToken = true) {
        sessionStorage.clear();
        this.cookieService.delete('token', '/');
        this.cookieService.set('token', '', -1, '/');
        if (clearToken) {
            const domain = location.hostname;
            this.cookieService.delete('token', '/', domain);
            if (this.cookieService.get('token')) {
                this.cookieService.set('token', '', -1, '/', domain, true, 'None');
                //this.cookieService.set('token', '', -1, '/', domain, true, 'None');
            }
        }
        this.apollo.getClient().stop();
        this.apollo.getClient().resetStore();
        this.loginService.userLoggedIn$.next(false);
        this.router.navigate(['/eservice/login'], { relativeTo: this.route });
    }
}
AuthorizeGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizeGuard_Factory() { return new AuthorizeGuard(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.LoginService), i0.ɵɵinject(i3.UtilityProviderService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i4.ActivatedRoute), i0.ɵɵinject(i5.CookieService)); }, token: AuthorizeGuard, providedIn: "root" });
