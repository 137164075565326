const paymentForm_paymentAmount = {
  required: 'Please select or enter a payment amount',
  min: 'Payments can be between .01 and $10,000. Please enter a valid amount.',
  max: 'Payments can be between .01 and $10,000. Please enter a valid amount.',
  amountGreater:
    'Payment cannot exceed the Balance Due on the policy. Please enter an amount that is less than the Balance Due.'
};

const paymentForm_savedCardSecurityCode = {
  required: 'Please enter security code',
  minlength:
    'Please enter a valid CVV code - 3 digits for Visa, MasterCard and Discover and 4 digits for American Express.'
};

const paymentForm_paymentOptionSelected = {
  required: 'Please select one payment method'
};

const eftForm_accountHolderName = {
  required: 'Please enter account holder name'
};

const eftForm_accountEmailId = {
  required: 'Please enter account holder email id',
  email: 'Please enter a valid email id'
};

const eftForm_accountType = {
  required: 'Oops! You forgot to select the account type.'
};

const eftForm_accountNumber = {
  required: 'Oops! You forgot to enter the account number.',
  minlength: 'Please enter a valid account number',
  pattern: 'Please enter numbers only',
  achRoutingAccountPair: 'The Routing Number and Account Number provided cannot be used. Please provide a different payment method to continue with your payment.',
  achAccount: 'The Account Number provided cannot be validated. Please correct the account number to continue with the payment.'
};

const eftForm_reAccountNumber = {
  required: 'Oops! You forgot to re-enter an account number'
};

const eftForm_routingNumber = {
  required: 'Oops! You forgot to enter the routing number.',
  invalidRoutingNumber: 'Please enter a valid routing number',
  minlength: 'Please enter a 9 digit routing number',
  achRoutingAccountPair: 'The Routing Number and Account Number provided cannot be used. Please provide a different payment method to continue with your payment.',
  achRouting: 'The Routing Number provided cannot be validated. Please correct the routing number to continue with the payment.'
};

const eftForm_terms = {
  required: 'Oops! You forgot to agree with the terms.'
};

const cardForm_terms = {
  required: 'Oops! You forgot to agree with the terms.'
};

const cardForm_cardHolderName = {
  required: 'Please enter card holder name'
};

const cardForm_cardHolderEmailId = {
  required: 'Please enter email address',
  email: 'Please enter a valid email id'
};

const cardForm_cardNumber = {
  required: 'Please enter card number',
  minlength: 'Card number should be at least 15 digits',
  invalidCardNumber: 'Please enter a valid card number'
};

const cardForm_securityCode = {
  required: 'Please enter security code',
  minlength:
    'Please enter a valid CVV code - 3 digits for Visa, MasterCard and Discover and 4 digits for American Express.'
};

const cardForm_expirationMonth = {
  pattern: 'Please select a month'
};

const cardForm_expirationYear = {
  pattern: 'Please select a year'
};

const cardForm_cardZip = {
  required: 'Please enter the zip code.',
  pattern: 'Please enter a valid zip code'
};

const cardForm_email = {
  required: 'Please enter card holder email id',
  email: 'Please enter a valid email id'
};

const questionnaireForm_fname = {
  required: 'Please enter driver first name'
};

const questionnaireForm_lname = {
  required: 'Please enter driver last name'
};

const questionnaireForm_displayDob = {
  invalidDate: "Please enter valid date(mm/dd/yyyy)",
  futureDate: "Please enter past date",
  minDate: "Please enter new date",
  validDob: "Please enter valid date of birth"
}

const questionnaireForm_modelYear = {
  inValidModelYear: "Please enter valid model year",

}
const questionnaireForm_ownerFirstName = {
  required: 'Please enter owner first name'
};

const questionnaireForm_ownerLastName = {
  required: 'Please enter owner last name'
};

const questionnaireForm_firstEvent = {
  required: 'Please enter the first event'
};

const questionnaireForm_eSignFirstName = {
  required: 'Please enter first name'
};

const questionnaireForm_initials = {
  required: 'Please enter initials'
};

const questionnaireForm_eSignLastName = {
  required: 'Please enter last name'
};

const questionnaireForm_dateOfAccident = {
  invalidDate: "Please enter valid date(mm/dd/yyyy)",
  futureDate: "Please enter past date",
  minDate: "Please enter new date"
}

const questionnaireForm_timeOfAccident = {
  pattern: 'Please enter 12 hours time format'
}
const questionnaireForm_cellPhone = {
  atleast_one_phone: 'Please enter at least one phone number',
  maxLength: 'Please enter a 10 digit cell phone number'
};

const questionnaireForm_workPhone = {
  maxLength: 'Please enter a 10 digit work phone number'
};

const questionnaireForm_homePhone = {
  maxLength: 'Please enter a 10 digit home phone number'
};

const questionnaireForm_accident_witness_zipCode = {
  'Mask error': 'Zip code must be 5 digits'
};

const questionnaireForm_accident_witness_phoneNumber = {
  'Mask error': 'Phone number must be 10 digits'
};

const addCellPhoneForm_cellPhone = {
  'Mask error': 'Phone number must be 10 digits',
  required: 'Please enter 10 digits phone number'
};

const userRegisterForm_displayDob = {
  required: 'Oops! You forgot to give your date of birth.',
  invalidDate: "Please enter valid date(mm/dd/yyyy)",
  futureDate: "Please enter past date",
  minDate: "Please enter new date",
  validDob: "Please enter valid date of birth"
}

const userRegisterForm_type = {
  required: "Please tell us whether it's a company or individual.",
}

const userRegisterForm_firstName = {
  required: 'Oops! You forgot to give us your first name.',
  pattern: "Please provide a valid first name."
}

const userRegisterForm_lastName = {
  required: 'Oops! You forgot to give us your last name.',
  pattern: "Please provide a valid last name."
}

const userRegisterForm_zipCode = {
  required: 'Oops! You forgot to give us your zip code.',
  pattern: "Please provide a valid zip code."
}

const userRegisterForm_companyName = {
  required: 'Oops! You forgot to give us the company name.'
}

const userRegisterForm_policyNumber = {
  required: 'Oops! You forgot to give us your policy number.',
  pattern: "Please provide a valid policy number."
}

const verificationOptionsForm_mode = {
  required: 'Please select one of the following verification method.'
}

const codeVerificationForm_digitCode = {
  required: 'Please provide a valid 6 digit code.'
}

const passwordForm_userName = {
  required: 'Oops! You forgot to give us your email address.',
  pattern: 'Please enter a valid email id.'
};

const passwordForm_password = {
  required: 'Sorry! You have to set up a password.',
  pattern: 'Oops! Not a valid password. Try again.'
};

const passwordForm_confirmPassword = {
  required: 'Sorry! You have to confirm your password.',
  notMatch: 'Oops! Not a match. Please try again.'
};

const passwordForm_enrollEDOC = {
  required: 'Oops! You forgot to give your eDocuments selection.'
};

const passwordForm_enrollBillingTextAlert = {
  required: 'Oops! You forgot to give your billing text alert selection.'
};
const passwordForm_billingTextAlertPhoneNumber = {
  'Mask error': 'Phone number must be 10 digits',
  required: 'Please enter 10 digits phone number'
};

const linkPolicyForm_policyNumber = {
  required: 'Oops! You forgot to give us your policy number.',
  pattern: "Please provide a valid policy number."
}

const linkPolicyForm_password = {
  required: 'Oops! You forgot to give your password.',
  pattern: 'Oops! Not a valid password. Try again.'
};

const linkPolicyForm_email = {
  required: 'Oops! You forgot to give us your email address.',
  pattern: 'Please enter a valid email id.'
};

export const errorLabels = {
  paymentForm_paymentAmount,
  paymentForm_paymentOptionSelected,
  paymentForm_savedCardSecurityCode,
  eftForm_accountHolderName,
  eftForm_accountEmailId,
  eftForm_accountType,
  eftForm_accountNumber,
  eftForm_reAccountNumber,
  eftForm_routingNumber,
  eftForm_terms,
  cardForm_cardHolderName,
  cardForm_cardHolderEmailId,
  cardForm_cardNumber,
  cardForm_securityCode,
  cardForm_expirationMonth,
  cardForm_expirationYear,
  cardForm_cardZip,
  cardForm_email,
  cardForm_terms,
  questionnaireForm_fname,
  questionnaireForm_lname,
  questionnaireForm_timeOfAccident,
  questionnaireForm_dateOfAccident,
  questionnaireForm_cellPhone,
  questionnaireForm_ownerFirstName,
  questionnaireForm_ownerLastName,
  questionnaireForm_firstEvent,
  questionnaireForm_eSignFirstName,
  questionnaireForm_initials,
  questionnaireForm_eSignLastName,
  questionnaireForm_workPhone,
  questionnaireForm_homePhone,
  questionnaireForm_accident_witness_zipCode,
  questionnaireForm_accident_witness_phoneNumber,
  addCellPhoneForm_cellPhone,
  questionnaireForm_displayDob,
  questionnaireForm_modelYear,
  userRegisterForm_displayDob,
  userRegisterForm_type,
  userRegisterForm_firstName,
  userRegisterForm_lastName,
  userRegisterForm_zipCode,
  userRegisterForm_companyName,
  userRegisterForm_policyNumber,
  verificationOptionsForm_mode,
  codeVerificationForm_digitCode,
  passwordForm_userName,
  passwordForm_password,
  passwordForm_confirmPassword,
  passwordForm_enrollEDOC,
  passwordForm_enrollBillingTextAlert,
  passwordForm_billingTextAlertPhoneNumber,
  linkPolicyForm_policyNumber,
  linkPolicyForm_password,
  linkPolicyForm_email
};
