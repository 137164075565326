/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link-policy.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../libs/utils/src/lib/danger/danger.component.ngfactory";
import * as i3 from "../../../../../libs/utils/src/lib/danger/danger.component";
import * as i4 from "../../../../../libs/utils/src/lib/spinner/spinner.component.ngfactory";
import * as i5 from "../../../../../libs/utils/src/lib/spinner/spinner.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../libs/utils/src/lib/user-form/user-form.component.ngfactory";
import * as i8 from "../../../../../libs/utils/src/lib/user-form/user-form.component";
import * as i9 from "../../../../../libs/utils/src/lib/services/utility-provider.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../../../libs/utils/src/lib/terms-conditions/terms-conditions.component.ngfactory";
import * as i12 from "../../../../../libs/utils/src/lib/terms-conditions/terms-conditions.component";
import * as i13 from "./link-policy.component";
import * as i14 from "../../../../../libs/utils/src/lib/services/form-validator.service";
import * as i15 from "../services/policy-provider.service";
import * as i16 from "../../../../../libs/utils/src/lib/services/policy-broadcaster.service";
var styles_LinkPolicyComponent = [i0.styles];
var RenderType_LinkPolicyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkPolicyComponent, data: {} });
export { RenderType_LinkPolicyComponent as RenderType_LinkPolicyComponent };
function View_LinkPolicyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "prcins-danger", [], null, null, null, i2.View_DangerComponent_0, i2.RenderType_DangerComponent)), i1.ɵdid(1, 4243456, null, 0, i3.DangerComponent, [], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 2, 0, currVal_0); }); }
function View_LinkPolicyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-spinner", [], null, null, null, i4.View_SpinnerComponent_0, i4.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i5.SpinnerComponent, [], null, null)], null, null); }
export function View_LinkPolicyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "main", [["class", "px-8 pb-8 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Link a Policy"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPolicyComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Wouldn't it be great if you could see all your policy info in one place? We think so too, that's why you can register your additional policies here."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "prcins-user-form", [], null, null, null, i7.View_UserFormComponent_0, i7.RenderType_UserFormComponent)), i1.ɵdid(8, 114688, null, 0, i8.UserFormComponent, [i9.UtilityProviderService], { userForm: [0, "userForm"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPolicyComponent_2)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "flex flex-wrap-reverse mt-8 justify-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["class", "w-full lg:w-2/5 es-link-secondary"], ["routerLink", "../"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "w-full lg:w-2/5 es-btn-primary mb-2 lg:mb-0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkPolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "prcins-terms-conditions", [], null, null, null, i11.View_TermsConditionsComponent_0, i11.RenderType_TermsConditionsComponent)), i1.ɵdid(18, 49152, null, 0, i12.TermsConditionsComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkError; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.linkPolicyForm; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.showSpinner; _ck(_v, 10, 0, currVal_2); var currVal_5 = "../"; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 13).target; var currVal_4 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_3, currVal_4); }); }
export function View_LinkPolicyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-link-policy", [], null, null, null, View_LinkPolicyComponent_0, RenderType_LinkPolicyComponent)), i1.ɵdid(1, 49152, null, 0, i13.LinkPolicyComponent, [i9.UtilityProviderService, i14.FormValidatorService, i15.PolicyProviderService, i16.PolicyBroadcasterService, i10.Router, i10.ActivatedRoute], null, null)], null, null); }
var LinkPolicyComponentNgFactory = i1.ɵccf("prcins-link-policy", i13.LinkPolicyComponent, View_LinkPolicyComponent_Host_0, {}, {}, []);
export { LinkPolicyComponentNgFactory as LinkPolicyComponentNgFactory };
