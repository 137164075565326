import { Injectable } from '@angular/core';
import { UtilityProviderService } from './utility-provider.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationProviderService {
  constructor(private utilityProvider: UtilityProviderService) {}

  gotoReportClaim() {
    this.utilityProvider.getClaimsToken().subscribe(data => {
      window.open(data, '_blank');
    });
  }
}
