<div>
    <h1 class="es-h1 mt-4">Add Bank Account</h1>
    <div body>
      <prcins-danger *ngIf="showError">
        Oops! There is a problem with the bank account you entered.
      </prcins-danger>
      <prcins-bank-form [eftForm]="eftForm"></prcins-bank-form>
      <div class="btns">
        <button class="es-btn-primary w-full mt-4" (click)="doAddBank()">Save</button>
        <a class="es-link-secondary w-full mt-4 cursor-pointer" (click)="showGuardPopup = true">Cancel</a>
      </div>
    </div>
</div>
<!-- <prcins-es-popup *ngIf="showError" headerClass="bg-es-blue text-white pop-up-width">
  <div header-left>
    <span>Add Bank</span>
  </div>
  <div body class="items-center justify-between">
    <div class="flex justify-center items-center"><b>Let us help you</b></div>
    <p *ngIf="channel === 'DIRECT'" class="mt-4">
      We would like to assist you with this change, please contact us for assistance.</p>
    <p *ngIf="channel != 'DIRECT'" class="mt-4">
      Your agent would like to assist you with this change. Please contact {{agentInfo.agencyName}} for
      assistance.
    </p>

    <div class="mt-4">
      <a *ngIf="channel === 'DIRECT'" href="tel:{{generalPhone}}">
        <button class="add-btn add-btn-primary">{{generalPhone}}</button>
      </a>
      <a *ngIf="channel != 'DIRECT' && agentInfo.agencyPhone" href="tel:{{agentInfo.agencyPhone}}">
        <button class="add-btn add-btn-primary">{{agentInfo.agencyPhone}}</button>
      </a>
      <a class="es-link-secondary w-full mt-4" (click)="showError = false">Close</a>
    </div>
  </div>
</prcins-es-popup> -->
<prcins-es-popup *ngIf="showGuardPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Confirmation</span>
  </div>
  <div body>
    <p class="text-center text-lg">Are you sure you want to cancel this transaction?</p>
    <div class="flex flex-wrap-reverse justify-between">
      <a class="es-link-secondary  w-half-2 mt-8  cursor-pointer" (click)="showGuardPopup = false">No</a>
      <button class="es-btn-primary w-half-2 mt-8 cursor-pointer" type="button"
        (click)="cancelTransaction()">Yes</button>
    </div>
  </div>
</prcins-es-popup>
<prcins-spinner *ngIf="showSpinner"></prcins-spinner>

