/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./open-claims.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../libs/utils/src/lib/card/card.component.ngfactory";
import * as i5 from "../../../../../libs/utils/src/lib/card/card.component";
import * as i6 from "../../../../../libs/utils/src/lib/skeleton/skeleton.component.ngfactory";
import * as i7 from "../../../../../libs/utils/src/lib/skeleton/skeleton.component";
import * as i8 from "./open-claims.component";
import * as i9 from "../services/policy-provider.service";
import * as i10 from "../services/claims-search.service";
import * as i11 from "../../../../../libs/documents/src/lib/services/documents.service";
var styles_OpenClaimsComponent = [i0.styles];
var RenderType_OpenClaimsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OpenClaimsComponent, data: {} });
export { RenderType_OpenClaimsComponent as RenderType_OpenClaimsComponent };
function View_OpenClaimsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text-es-status-green font-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["In Progress"]))], null, null); }
function View_OpenClaimsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text-es-status-green font-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.status; _ck(_v, 1, 0, currVal_0); }); }
function View_OpenClaimsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "mt-8 ml-8 w-32 sm:w-48 text-center text-blue-700"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(2, { docType: 0 }), i1.ɵpad(3, 1), (_l()(), i1.ɵted(-1, null, ["Documents"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "CLAIMS"); var currVal_3 = _ck(_v, 3, 0, "../documents/policy-documents"); _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OpenClaimsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "flex justify-between mt-4 sm:px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "font-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Claim #:", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenClaimsComponent_5)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenClaimsComponent_6)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "flex justify-between sm:px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 2), (_l()(), i1.ɵeld(12, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 8, "div", [["class", "flex justify-between sm:px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "a", [["class", "mt-8 w-32 sm:w-48 text-center text-blue-700"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(18, 2), (_l()(), i1.ɵted(-1, null, ["View Details"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenClaimsComponent_7)), i1.ɵdid(21, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 3, "div", [["class", "flex justify-between mt-4 sm:px-2"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(24, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(25, { "border-b-2": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.$implicit.status === "Open"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_v.context.$implicit.status != "Open"); _ck(_v, 7, 0, currVal_2); var currVal_6 = _ck(_v, 18, 0, "../claims", _v.context.$implicit.claimNumber); _ck(_v, 17, 0, currVal_6); var currVal_7 = _co.showClaimDocuments; _ck(_v, 21, 0, currVal_7); var currVal_8 = "flex justify-between mt-4 sm:px-2"; var currVal_9 = _ck(_v, 25, 0, (_v.context.index < (_co.openClaims.length - 1))); _ck(_v, 24, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.claimNumber; _ck(_v, 3, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.context.$implicit.dateOfLoss, "MM/dd/yyyy")); _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 17).target; var currVal_5 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_4, currVal_5); }); }
function View_OpenClaimsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "prcins-card", [["headerClass", "bg-es-blue text-white"]], null, null, null, i4.View_CardComponent_0, i4.RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i5.CardComponent, [], { headerClass: [0, "headerClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "span", [["header-left", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Open Claim(s)"])), (_l()(), i1.ɵand(16777216, null, 2, 2, null, View_OpenClaimsComponent_4)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bg-es-blue text-white"; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.openClaims, 0)); _ck(_v, 5, 0, currVal_1); }, null); }
function View_OpenClaimsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenClaimsComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.claimsListEmty; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OpenClaimsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenClaimsComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showOpenClaims; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OpenClaimsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "mt-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "prcins-card", [["pt", "pt-0"]], null, null, null, i4.View_CardComponent_0, i4.RenderType_CardComponent)), i1.ɵdid(2, 49152, null, 0, i5.CardComponent, [], { pt: [0, "pt"] }, null), (_l()(), i1.ɵeld(3, 0, null, 2, 2, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "prcins-skeleton", [["numOfRows", "6"]], null, null, null, i6.View_SkeletonComponent_0, i6.RenderType_SkeletonComponent)), i1.ɵdid(5, 245760, null, 0, i7.SkeletonComponent, [], { numOfRows: [0, "numOfRows"] }, null)], function (_ck, _v) { var currVal_0 = "pt-0"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "6"; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_OpenClaimsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenClaimsComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_OpenClaimsComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_OpenClaimsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-open-claims", [], null, null, null, View_OpenClaimsComponent_0, RenderType_OpenClaimsComponent)), i1.ɵdid(1, 573440, null, 0, i8.OpenClaimsComponent, [i9.PolicyProviderService, i10.ClaimsSearchService, i11.DocumentService], null, null)], null, null); }
var OpenClaimsComponentNgFactory = i1.ɵccf("prcins-open-claims", i8.OpenClaimsComponent, View_OpenClaimsComponent_Host_0, { policy: "policy" }, {}, []);
export { OpenClaimsComponentNgFactory as OpenClaimsComponentNgFactory };
