import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  public userRegistrationForm$ = new BehaviorSubject<any>({});
  userRegistrationFormObservable = this.userRegistrationForm$.asObservable();

  public verifyUserRegisteredOrNot$ = new BehaviorSubject<any>({});
  verifyUserRegisteredOrNotObservable = this.verifyUserRegisteredOrNot$.asObservable();

  apiUrl = environment.BASE_URL + "/registration/v2/account/";

  constructor(private http: HttpClient, private apollo: Apollo) { }

  getPasswordActionTypeInfo() {
    return sessionStorage.passwordActionType;
  }

  getUserDetails(verificationId): Observable<any> {
    return this.http.get(this.apiUrl + 'user/verify/' + verificationId);
  }

  userDetails(details): Observable<any> {
    return this.http.post(this.apiUrl + 'user/verify', details);
  }

  selectValidationMethod(mode) {
    return this.http.post(this.apiUrl + 'token', mode);
  }

  verifyAuthorizationCode(code) {
    return this.http.post(this.apiUrl + 'token/verify', code);
  }

  forgotEmail(account): Observable<any> {
    return this.http.post(this.apiUrl + 'user/forgotEmail', account);
  }

  forgotPassword(info): Observable<any> {
    return this.http.post(this.apiUrl + 'user/forgotPassword', info);
  }

  changePassword(info) {
    return this.http.post(this.apiUrl + 'user/changePassword', info);
  }

  createAccount(account): Observable<any> {
    return this.http.post(this.apiUrl + 'user/createAccount', account);
  }

  linkPolicy(linkPolicyRequest): Observable<any> {
    return this.http.post(this.apiUrl + 'user/linkPolicy', linkPolicyRequest);
  }

  checkIfPolicyAlreadyRegisteredInEservice(policyNumber: string): Observable<any> {
    return this.http.get(this.apiUrl + 'user/checkPolicyRegistered/' + policyNumber);
  }
  // {
  //   "billingTextAlertPhoneNumber": "4012980927",
  //   "dob": "1983-01-16",
  //   "enrollBillingTextAlert": true,
  //   "enrollEDOC": true,
  //   "password": "test1test",
  //   "policyNumber": "PRA00008096323",
  //   "userName": "htandon@plymouthrock.com",
  //   "zipCode": "02191"
  // }
  createQuickPayAccount(newAccount): Observable<any> {
    return this.http.post(this.apiUrl + 'user/quickPay/createAccount', newAccount);
  }

  invalidateEndorsementSession() {
    sessionStorage.removeItem('verificationId');
    sessionStorage.removeItem('passwordActionType');
    sessionStorage.removeItem('origin');
  }
}
