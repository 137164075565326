import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, ActivatedRoute, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilityProviderService, LoginService } from '@prcins/utils';
import { Apollo } from 'apollo-angular';
import { USER_LOGIN } from '@prcins/constants';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate, CanActivateChild {
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : this.cookieService.get('token')
    if (sessionStorage.getItem(USER_LOGIN) === 'Y' && token) return true;    
    this.onLogout(!token);
    return false;
  }
  constructor(private apollo: Apollo,
    private loginService: LoginService,
    private utilityProviderService: UtilityProviderService, private router: Router, private route: ActivatedRoute, private cookieService: CookieService) { }

  onLogout(clearToken = true): void {
  
  sessionStorage.clear();

    
    this.cookieService.delete('token', '/');  
    this.cookieService.set('token', '', -1, '/');  
    
    if (clearToken) {      
      const domain = location.hostname;
      this.cookieService.delete('token', '/', domain);

      if(this.cookieService.get('token')){      
          this.cookieService.set('token', '', -1, '/', domain, true, 'None');
        //this.cookieService.set('token', '', -1, '/', domain, true, 'None');
      }
      
    }

    this.apollo.getClient().stop();
    this.apollo.getClient().resetStore();
    this.loginService.userLoggedIn$.next(false);
    this.router.navigate(['/eservice/login'], { relativeTo: this.route });
  }
}
