<div *ngIf="!showAppDownloadBlocker">
  <prcins-header></prcins-header>

  <div class="md:min-h-screen relative">
    <div class="flex flex-col items-center w-full es-app-container">
      <prcins-message-banner></prcins-message-banner>
      <prcins-welcome-banner class="w-full"></prcins-welcome-banner>
      <router-outlet></router-outlet>
    </div>
    <prcins-footer></prcins-footer>
  </div>
</div>

<div *ngIf="showAppDownloadBlocker" class="app-download">
  <div class="header">
    <h1>Get Our New Android App!</h1>
    <p>Are you an iOS user? </p>
    <p>Please update your existing app.</p>
  </div>
  <div class="divider"></div>
  <div class="content">
    <div class="row">
      <div class="number">1</div>
      <div class="text">Download our new app! This is not an update; this app will no longer be supported.</div>
    </div>

    <div class="row">
      <div class="number">2</div>
      <div class="text">Once you download the new app, please delete the old app.</div>
      <div class="icon-wrapper">
        <div class="old-icon">
          <img src="assets/png/old-app-icon.png" width="50"/>
          <span class="icon-title">OLD</span>
        </div>
        <div class="new-icon">
          <img src="assets/png/new-app-icon.png" width="50"/>
          <span class="icon-title">NEW</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="number">3</div>
      <div class="text">You can login using the same credentials as you always have.</div>
    </div>
    <div class="row" *ngIf="false">
      <div class="button-wrapper">
        <a class="download-btn" href="{{androidAppDownloadLink}}" target="_blank">Download the New App</a>
      </div>
    </div>
  </div>
</div>
