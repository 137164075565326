export let tealiumMapper = new Map();

//event7
tealiumMapper.set('public/api/login', {
    "predicate": {
        "messageCode": "LOGIN_SUCCESSFUL"
    }, "payload": {
        "if": {
            "eVars": [
                { "eservice_user_id": "request:userId" }
            ]
        },
        "else": {
            "eventName": "failed_login"
        }
    }
});

//event1
tealiumMapper.set('public/api/setup', {
    "predicate": {
        "messageCode": "REGISTRATION_SUCCESS"
    }, "payload": {
        "if": {
            "eventName": "success_registration"
        },
        "else": {
            "eventName": "failed_registration"
        }
    }
});

//event38
// tealiumMapper.set('account/extedocs', {
//     "predicate": {
//         "messageCode": "edocs.update.success"
//     }, "payload": {
//         "if": {
//             "eventName": "eDoc_successful_sign_up"
//         }
//     }
// });

