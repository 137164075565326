import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ADMIN_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AdminService {
    constructor(http) {
        this.http = http;
        this.adminActivityError = new Subject();
    }
    disableAccount(email, policyNumber) {
        let url;
        if (policyNumber) {
            url = `${ADMIN_URL.disableAccount}/${email}/idg_user_group/${policyNumber}`;
        }
        else {
            url = `${ADMIN_URL.disableAccount}/${email}/idg_user_group/null`;
        }
        return this.http.delete(url, {});
    }
    validateSuperAdmin() {
        let url = ADMIN_URL.validateSuperAdmin + "?name=superUser";
        return this.http.get(url);
    }
}
AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.HttpClient)); }, token: AdminService, providedIn: "root" });
