<div class="hoxsell-desktop py-2">
        <a style="width:100%" target="_blank" href="{{crossPromotionURL}}">
            <img style="width:100%" src="assets/png/ho_xsell_ads_NoPTmatch_desktop.png">
        </a>
</div>
<div class="hoxsell-mobile py-2">
    <a style="width:100%" target="_blank" href="{{crossPromotionURL}}">
        <img style="width:100%" src="assets/png/ho_xsell_ads_NoPTmatch_mobile.png">
    </a>
</div>
