import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MfaService } from "../services/mfa.service";

@Component({
  selector: 'prcins-mfa-verification',
  templateUrl: './mfa-verification.component.html',
  styleUrls: ['./mfa-verification.component.css']
})
export class MfaVerificationComponent implements OnInit {
  @Output() cancelled = new EventEmitter<any>();
  @Output() tokenSubmitted = new EventEmitter<any>();
  @Input() verificationData: string = null;
  @Input() type: string;
  @Input() title: string;
  @Input() description: string = 'To proceed with this change, choose one of the following methods of receiving a code for validation:';

  isModeSelected = false;
  userPolicyDetailsResp: any;
  verificationOptionsForm: any;
  verificationMode = [];

  constructor(private mfaService: MfaService,
              private router: Router,
              private route: ActivatedRoute,) {
  }

  ngOnInit() {
    if (this.verificationData) {
      this.userPolicyDetailsResp = this.verificationData;
    } else {
      let userId = sessionStorage.getItem('userId')
      this.mfaService.getVerification(userId, this.type).subscribe((data) => {
        if (data) {
          if (data.verified) {
            this.router.navigate(['../login'], {
              relativeTo: this.route,
            });
          } else {
            this.userPolicyDetailsResp = data;
          }
        }
      }, (error: HttpErrorResponse) => {
        this.router.navigate(['../login'], {
          relativeTo: this.route,
        });
      });
    }
  }

  onFormSubmitted(value) {
    this.isModeSelected = true;
    this.verificationOptionsForm = value;
  }

  onCancelClicked(value) {
    this.isModeSelected = false;
    this.cancelled.emit(value);
  }

  onBackClicked(value) {
    this.isModeSelected = false;
    if (value) {
      this.verificationMode.push(value);
    }
  }

  onTokenSubmitted(value) {
    this.isModeSelected = true;
    this.tokenSubmitted.emit({...value, type: this.type});
  }

}
