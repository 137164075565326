import gql from 'graphql-tag';

export const FuturePaymentQuery = gql`
  query BillingDetails($policyNumber: String) {
    billingDetails(policyNumber: $policyNumber) {
      serviceCharge
      isNewEftServiceFee
      futureInstallments {
        transactionDt
        transactionAmnt
      }
    }
  }
`;

export const PastPaymentQuery = gql`
  query BillingDetails($policyNumber: String) {
    billingDetails(policyNumber: $policyNumber) {
      paymentHistory {
        transactionDt
        transactionAmnt
        transactionType
      }
    }
  }
`;

export const BillingDetailsQuery = gql`
  query BillingDetails($policyNumber: String) {
    billingDetails(policyNumber: $policyNumber) {
      balance
      nextPaymentAmnt
      nextPaymentDueDt
      pastDueAmnt
      lastPaymentAmnt
      lastPaymentDt
      payPlanLabel
      payplan
      effectiveDate
      expiryDate
      messageCode
      payPlanInstallment
    }
  }
`;

export const BillingInstallmentsQuery = gql`
  query Installments($policyNumber: String) {
    billingInstallments(policyNumber: $policyNumber) {
      code
      eftOnly
      orderby
      label
      recPayOnly
      payMethod{
        payplan
        payMethod
        payHelpMessage
      }
      priorCarrier
    }
    billingDetails(policyNumber: $policyNumber) {
      payplan
      payPlanInstallment
      payPlanLabel
      payPlanEditable
      paymentPendingSettlememt
      nextPaymentDueDt
      nextPaymentAmnt
    }
    policyDetails(policyNumber: $policyNumber) {
      product {
        state
        companyCode
        productCode
        effectiveDate
      }
      twoPayEligible
	    twoPayDiscountApplied
    }
  }
`;
export const HomeEndorsementControlAgentQuery = gql`
  query HomeEndorsementControlAgent($policyNumber: String) {
    homeEndorsementControlAgent(policyNumber:$policyNumber) {
      editMortgagee
      deleteMortgagee
    }
  }
`;

export const BillingEftQuery = gql`
  query BillingEftData($policyNumber: String, $payPlan: String) {
    billingEftData(policyNumber: $policyNumber, payplan: $payPlan) {
      messageCode
      eftAccountNumber
      eftRoutingNumber
      eftAccountType
      payplan
    }
  }
`;

export const BillingCCQuery = gql`
  query BillingCCData($policyNumber: String, $payPlan: String) {
    billingCreditCardData(policyNumber: $policyNumber, payplan: $payPlan) {
      ccType
      payplan
      ccAccountNumber
      expDate
    }
  }
`;

export const BalanceDetailsQuery = gql`
  query BalanceDetails($policyNumber: String) {
    balanceDetails(policyNumber: $policyNumber) {
      pastTermBalance
      currentTermBalance
      futureTermBalance
    }
  }
`;
