import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'prcins-toggler',
  template: ``,
  styles: []
})
export class TogglerComponent implements OnInit{
  private _sliderValue:boolean;
  @Input() sliderKey: string;
  @Input() set sliderValue(value){
    this._sliderValue = value;
    this.checkValue = !!value;
  }
  get sliderValue(){
    return this._sliderValue;
  }
  @Input() disabled: boolean;
  @Output() sliderChanged = new EventEmitter<{ key: string, value: boolean }>();
  checkValue: boolean;

  ngOnInit(): void {
    this.checkValue = !!this.sliderValue;
  }

  onSliderChange() {
    if (!this.disabled) {
      this.sliderValue = !this.sliderValue;
      this.sliderChanged.next({ key: this.sliderKey, value: this.sliderValue });
    }
  }

}
