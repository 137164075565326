<main class="w-full px-4 mb-20">
    <h1 class="es-h1 mt-4">Balance Details</h1>
    <prcins-danger *ngIf="loadFailed">Sorry service failed, please try after a while or contact us.</prcins-danger>

    <ng-container *ngIf="showBalance; else loading">

        <div class="mt-4 border rounded border-gray-300">

            <div class="px-4 pb-3 pt-3">


                <div body>
                    <div *ngIf="balanceDetails.futureTermBalance" class="flex justify-between mt-3 border-b pb-2">
                        <span class="font-whitney-semibold">Renewal Term Balance</span><span>{{futureTermBalance  | currency}}</span>
                    </div>
                    <div *ngIf="balanceDetails.currentTermBalance" class="flex justify-between mt-3 pb-2">
                        <span class="font-whitney-semibold">Current Term Balance</span><span>{{currentTermBalance | currency}}</span>
                    </div>
                    <div  *ngIf="balanceDetails.pastTermBalance" class="flex justify-between mt-3 pb-4 border-t">
                        <span class="font-whitney-semibold">Previous Term Balance</span><span>{{balanceDetails.pastTermBalance  | currency}}</span>
                    </div>


                </div>
            </div>
        </div>

        <div class="flex flex-wrap-reverse justify-between">
            <a class="es-link-secondary w-full mt-16 sm:w-40" [routerLink]="['../..']">Go Back</a>
            
        </div>

    </ng-container>

    <ng-template #loading>
        <prcins-card pt="pt-0">
            <div body>
              <prcins-skeleton numOfRows="3"></prcins-skeleton>
            </div>
          </prcins-card>
    
      </ng-template>





</main>