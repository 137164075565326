<form class="pt-8 border border-b-0 border-r-0 border-l-0 w-full" action="" (submit)="onLogin()" [formGroup]="loginForm"
  autocomplete="off">
  <div *ngIf="showEDocSuccessMessage()" class="relative p-4 border rounded border-gray-300 bg-es-sky-blue">
    Congratulations! You have successfully changed your eDoc preferences.
  </div>
  <div class="desktop-only-container">
    <main class="px-8 pb-8 pt-4">
      <h1 class="es-h1" *ngIf="!isAdmin; else admin">Login</h1>
      <ng-template #admin>
        <h1 class="es-h1">Admin Login</h1>
      </ng-template>
      <ng-container *ngIf="additionalInvitationMessage">
        <div class="flex mb-4 items-center justify-between flex-wrap">
          <label>{{additionalInvitationMessage}}</label>
        </div>
      </ng-container>
      <prcins-danger *ngIf="error">{{error}}</prcins-danger>
      <prcins-danger *ngIf="accountLockError">Oops! Your account has been temporarily locked. Please wait
        30 minutes and try again or let us help.
        <a class="underline font-bold text-es-blue-light" target="_blank"
          href="https://www.plymouthrock.com/contact-us">Give us a call! </a>
      </prcins-danger>
      <prcins-danger *ngIf="maxAttemptsError"> Oops! You've attempted
        {{warnLockouts}} unsuccessful passwords. If you make a
        {{(warnLockouts*1)+1}}th unsuccessful attempt, your account will be locked for 30
        minutes. Reset your password <a id="forgot_password" class="underline font-bold text-es-blue-light"
          (click)="goToResetPassword()">here</a>.
      </prcins-danger>

      <div class="flex items-center justify-between flex-wrap">
        <label class="mb-2" for="">Email</label>
        <input formControlName="userName" type="text" trimInput class="w-full lg:w-3/5 es-textfield" autocomplete="off">
      </div>

      <div class="flex justify-between flex-wrap"
        *ngIf="!loginForm.get('userName').valid  && (loginForm.get('userName').dirty || loginForm.get('userName').touched)">
        <div class="hidden lg:block"> &nbsp;</div>
        <div class="es-error-field mt-2 flex w-full lg:w-3/5" *ngIf="loginForm.get('userName').errors['required']">
          Oops! You forgot to give us your email address.
        </div>
        <div class="es-error-field mt-2 flex w-full lg:w-3/5"
          *ngIf="(loginForm.get('userName').errors['email'] || loginForm.get('userName').errors['pattern']) && (loginForm.get('userName').touched)">
          Hmmm, that doesn't look like a valid email address.
        </div>
      </div>

      <div class="flex items-center justify-end flex-wrap mt-6 showPassword">
        <a *ngIf="!maskPassword; else hidePassword" class="text-es-blue-light cursor-pointer"
          (click)="maskPassword = true">Show Password</a>
        <ng-template #hidePassword>
          <a class="text-es-blue-light cursor-pointer" (click)="maskPassword = false">Hide Password</a>
        </ng-template>
      </div>
      <div class="flex items-center justify-between flex-wrap">
        <label class="mb-2">Password</label>
        <input formControlName="password" [type]="maskPassword ? 'text' : 'password'"
          class="w-full lg:w-3/5 es-textfield" [prcinsNoCopyPaste]="true" prcinsNoRightClick autocomplete="off">
      </div>
      <div class="flex justify-between flex-wrap"
           *ngIf="!loginForm.get('password').valid  && (loginForm.get('password').dirty || loginForm.get('password').touched)">
        <div class="hidden lg:block"> &nbsp;</div>
        <div class="es-error-field mt-2 flex w-full lg:w-3/5"
          *ngIf="!loginForm.get('password').valid && (loginForm.get('password').dirty || loginForm.get('password').touched) && loginForm.get('password').errors['required']">
          Sorry! You have to enter your password.
        </div>
      </div>
      <div class="flex justify-between flex-wrap" [ngClass]="enableInteractiveCaptcha? 'mt-6' : ''">
        <div class="hidden lg:block"> &nbsp;</div>
        <div class="mt-2 flex w-full lg:w-3/5" id="prcinsLoginGrecaptcha"></div>
      </div>
      <div class="flex justify-between flex-wrap"
           *ngIf="!loginForm.get('captcha').valid  && showFormErrors">
        <div class="hidden lg:block"> &nbsp;</div>
        <div class="es-error-field mt-2 flex w-full lg:w-3/5"
             *ngIf="showFormErrors && !loginForm.get('captcha').valid && loginForm.get('captcha').errors['required']">
          Please check the box to verify you are not a robot to continue.
        </div>
      </div>
      <div class="flex flex-wrap lg:justify-end mt-6">
        <div class="rememberMe">
          <label class="flex items-center">
            <prcins-square-checkbox [sliderValue]="loginForm.get('rememberMe').value" sliderKey="rememberMe"
              (sliderChanged)="onRememberMeChanged($event)">
            </prcins-square-checkbox>
            <span class="ml-2">Remember me</span>
          </label>
        </div>
        <div class="mt-1 forgotEmailAndPassword">
          <span *ngIf="!forgotEmailFlow" class="ml-2"><a class="ml-2 cursor-pointer text-es-blue-light"
              (click)="goToForgotEmail()">Forgot
              Email</a>
          </span>
          <span class="ml-2"><a class="ml-2 text-es-blue-light cursor-pointer" (click)="goToResetPassword()"
              prcins-tealium-tracker="click" prcins-tealium-event="recover_password">Forgot
              Password</a>
          </span>
        </div>
      </div>
    </main>
  </div>

  <div class="flex px-8 lg:px-0 mb-4">
    <label class="flex items-center">
      <strong class="terms"> I have read and agree to the <a class="text-es-blue hover:underline"
          href="https://www.plymouthrock.com/utility/internet-policy-popup" target="_blank"
          rel="noopener noreferrer">Terms and
          Conditions</a> and I <a href="https://www.plymouthrock.com/internet-policy/consent-to-conduct" target="_blank"
          class="text-blue-600">Consent to Conduct Business Electronically</a>.</strong>
    </label>
  </div>

  <div class="flex justify-end items-center px-8 lg:px-0">
    <button class="es-btn-primary lg:w-64" type="submit">Agree and Continue</button>
  </div>
</form>

<div class="flex justify-center m-8">
  <label class="flex">
    <p class="terms"> Don't have an account? <a class="text-es-blue hover:underline font-semibold cursor-pointer"
        (click)="goToRegister()">Sign up</a>
    </p>
  </label>
</div>

<div class="flex px-8 lg:px-0 mb-4 justify-center">
  <label class="flex">
    <p class="terms">
      <!-- Link Policy section removed-->
    </p>
  </label>
</div>

<!-- <div class="flex flex-col mt-8 mb-16">
  <span class="flex justify-center mt-4"><a class="text-es-blue-light" [routerLink]="['../../forgot-email']">Forgot
      Email</a> <a class="ml-8 text-es-blue-light cursor-pointer" (click)="goToResetPassword()"
      prcins-tealium-tracker="click" prcins-tealium-event="recover_password">Forgot
      Password</a></span>
</div> -->
<ng-container *ngIf="showLoginVerificationMfa">
  <prcins-mfa-verification
    [verificationData]="verificationData"
    [type]="isAdmin ? 'LOGIN_A_ESV' : 'LOGIN_ESV'"
    [title]="'Login Verification'"
    [description]="'To access your account, choose one of the following methods of receiving a code for validation:'"
    (tokenSubmitted)="OnLoginTokenSubmit($event)"
    (cancelled)="onCancelLoginMfa($event)">
  </prcins-mfa-verification>
</ng-container>

<prcins-spinner *ngIf="showSpinner"></prcins-spinner>
<prcins-es-popup *ngIf="showMobileIdCardPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Download Mobile Id Card</span>
  </div>
  <div body>
    <p>{{displayMessage}}</p>
    <p *ngIf="showDownload">Your ID cards are being saved to this device so you can access them anytime and any place
      whether you are on-line or not. <br><br>Any policy updates will be applied on this device after you log into
      Manage My Policy from this mobile app.<br><br>Important Note: Although Plymouth Rock offers electronic insurance
      ID cards, they
      may not be accepted by law enforcement as proof of insurance in some states. Therefore, it is important that you
      always carry a paper copy of your insurance ID card for the vehicle you are operating, if required by law.</p>

    <div class="flex flex-wrap-reverse justify-between">
      <a *ngIf="!showDownload" class="es-link-secondary w-full mt-4 sm:w-40 cursor-pointer" (click)="goToHome()">Manage
        My Policy</a>
      <button *ngIf="showDownload" class="es-btn-primary w-full mt-4 sm:w-40" type="button"
        (click)="downloadIdCardData()">OK</button>
    </div>
  </div>

</prcins-es-popup>
