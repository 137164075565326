import { Subject } from 'rxjs';
import { ACCOUNTS_URL } from '@prcins/constants';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NotificationService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.notify$ = new Subject();
    }
    sendEmail(request) {
        return this.httpClient.post(`${ACCOUNTS_URL.EmailSupport}`, request);
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.HttpClient)); }, token: NotificationService, providedIn: "root" });
