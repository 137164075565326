<div class="desktop-only-container">
  <main class="px-8 pb-8 pt-4">
    <h1 class="es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0">Forgot Email?</h1>
    <prcins-danger *ngIf="!isPolicyFound">
      <span>
        {{errorMessage}}
      </span>
    </prcins-danger>
    <p>Don't worry, we'll quickly locate your account. First enter the following information associated with this
      account.</p>
    <prcins-register-user-form [userForm]="forgotEmailForm"></prcins-register-user-form>
    <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
  </main>
</div>
<div class="flex justify-between flex-wrap px-8 lg:px-0">
  <button class="w-full lg:w-2/5 es-btn-secondary mt-2" [routerLink]="['../../login']" type="button">Back</button>
  <button class="w-full lg:w-2/5 es-btn-primary mt-2" (click)="onForgotEmail()" type="button">Continue</button>
</div>
