<prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="display">
    <div header-left>
        <span>Evidence of Insurance</span>
    </div>
    <div header-right>
        <img class="cursor-pointer" height='15px' width='15px' (click)="hidePopup()"
            src="assets/svgs/close-hamburger.svg">
    </div>
    <div body>
        <p>There are no documents available.</p>
    </div>
</prcins-es-popup>