import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'prcins-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements OnInit, AfterViewInit {

  @ViewChild('tooltip', { static: false }) element: ElementRef;

  xStyle: string;
  yStyle: string;

  constructor() { }

  ngOnInit() {
    this.xStyle = '';
    this.yStyle = '';
  }

  ngAfterViewInit() {

    let {
      offsetLeft,
      offsetTop,
      offsetParent: { clientWidth, clientHeight }
    } = this.element.nativeElement;

    let midWidth = clientWidth / 2;
    let midHeight = clientHeight / 2;

    setTimeout(() => {
      this.xStyle = offsetLeft > midWidth ? 'left' : 'right';
      this.yStyle = offsetTop > midHeight ? 'top' : 'bottom';

      console.log(offsetLeft, offsetTop, clientHeight, clientWidth, this.xStyle, this.yStyle);
    });

  }

}
