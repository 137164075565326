/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./desktop-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./desktop-header.component";
import * as i5 from "../../../../../../libs/utils/src/lib/services/navigation-provider.service";
var styles_DesktopHeaderComponent = [i0.styles];
var RenderType_DesktopHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DesktopHeaderComponent, data: {} });
export { RenderType_DesktopHeaderComponent as RenderType_DesktopHeaderComponent };
function View_DesktopHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "small-loader"], ["src", "assets/png/loading.gif"]], null, null, null, null, null))], null, null); }
function View_DesktopHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "py-2"]], [[2, "disable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onSubMenuClicked(_v.parent.parent.parent.context.$implicit.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DesktopHeaderComponent_5)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.route; _ck(_v, 1, 0, currVal_3); var currVal_5 = _v.parent.context.$implicit.waitConditionally; _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.disable; var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_4); }); }
function View_DesktopHeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "py-2"], ["target", "_blank"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubMenuClicked(_v.parent.parent.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.link, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_1); }); }
function View_DesktopHeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "py-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubMenuClicked(_v.parent.parent.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.menuNavigationHandler(_v.parent.context.$implicit.click) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_DesktopHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DesktopHeaderComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DesktopHeaderComponent_6)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DesktopHeaderComponent_7)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLink(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.link; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.click; _ck(_v, 6, 0, currVal_2); }, null); }
function View_DesktopHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "sub-menu flex flex-col absolute border border-t-0 border-gray-400 rounded-b"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DesktopHeaderComponent_3)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.children; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DesktopHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "a", [["class", "es-desktop-header-menu menu cursor-pointer relative"]], [[2, "menu_hovered", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.onShowSubMenu(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.onHideSubMenu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "span", [["class", "flex items-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "img", [["alt", ""], ["class", "ml-2"], ["src", "assets/svgs/down-icon.svg"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { item_visible: 0, item_hidden: 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "img", [["alt", ""], ["class", "ml-2"], ["src", "assets/svgs/down-icon-blue.svg"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { item_visible: 0, item_hidden: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DesktopHeaderComponent_2)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "ml-2"; var currVal_3 = _ck(_v, 6, 0, !_co.navMenu[_v.context.$implicit.id], _co.navMenu[_v.context.$implicit.id]); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = "ml-2"; var currVal_5 = _ck(_v, 10, 0, _co.navMenu[_v.context.$implicit.id], !_co.navMenu[_v.context.$implicit.id]); _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = _co.navMenu[_v.context.$implicit.id]; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navMenu[_v.context.$implicit.id]; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.label; _ck(_v, 2, 0, currVal_1); }); }
function View_DesktopHeaderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "es-desktop-header-menu menu cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSoftLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "flex items-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Admin Link"]))], null, null); }
export function View_DesktopHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "header", [["class", "hidden lg:flex xl:flex bg-es-blue border-t-4 border-es-orange"]], null, [[null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseleave" === en)) {
        var pd_0 = (_co.onHideSubMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "flex ml-4 mr-16 items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 14, "div", [["class", "flex justify-between w-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "text-white flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "a", [["class", "es-desktop-header-menu menu"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "flex items-center"]], [[2, "header_active", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Home"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DesktopHeaderComponent_1)), i1.ɵdid(12, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DesktopHeaderComponent_8)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "text-white flex items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "a", [["class", "es-desktop-header-menu mr-4 cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "img", [["alt", ""], ["class", "mr-2"], ["src", "assets/svgs/logout.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Log Out"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 8, 0, "../home"); _ck(_v, 7, 0, currVal_3); var currVal_5 = _co.menus; _ck(_v, 12, 0, currVal_5); var currVal_6 = (_co.isAdmin && (((_co.menus == null) ? null : _co.menus.length) > 0)); _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "assets/svgs/brands/", _co.brand, "/header-logo.svg"); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).target; var currVal_2 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = ("home" === _co.selectedHeader); _ck(_v, 9, 0, currVal_4); }); }
export function View_DesktopHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-desktop-header", [], null, null, null, View_DesktopHeaderComponent_0, RenderType_DesktopHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.DesktopHeaderComponent, [i5.NavigationProviderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DesktopHeaderComponentNgFactory = i1.ɵccf("prcins-desktop-header", i4.DesktopHeaderComponent, View_DesktopHeaderComponent_Host_0, { menus: "menus", activeMenus: "activeMenus", selectedHeader: "selectedHeader" }, { logOut: "logOut", softLogOut: "softLogOut" }, []);
export { DesktopHeaderComponentNgFactory as DesktopHeaderComponentNgFactory };
