import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injector } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, timer } from "rxjs";
import { StaticInjectorService } from "../services/static-injector.service";
import { SessionRedirectErrorComponent } from "../session-redirect-error/session-redirect-error.component";
import { SpinnerComponent } from "../spinner/spinner.component";

export function SessionInitializer(configuration) {

    return function (target) {
        const preProcessorComplete$ = new BehaviorSubject<boolean>(null);
        target.prototype['preProcessorComplete$'] = preProcessorComplete$;
        const initializerComplete$ = new BehaviorSubject<boolean>(null);
        target.prototype['initializerComplete$'] = initializerComplete$;
        const postProcessorComplete$ = new BehaviorSubject<boolean>(null);
        target.prototype['postProcessorComplete$'] = postProcessorComplete$;
        const route = StaticInjectorService.Injector.get(ActivatedRoute);
        const appRef = StaticInjectorService.Injector.get(ApplicationRef);
        const componentFactoryResolver = StaticInjectorService.Injector.get(ComponentFactoryResolver);

        let rootComp: ComponentRef<any> = appRef.components[0];

		const urlParams = new URLSearchParams(window.location.search);
        const restoreSessionParam = urlParams.get('restoreSession');

        if (route.snapshot.queryParams['restoreSession'] === 'true' || restoreSessionParam === 'true') {
            const _pointcut = configuration.pointcut || 'ngOnInit';
            const _actual = target.prototype[_pointcut];
            const _preProcessor = target.prototype[configuration.preProcessor || 'preProcessor'] || (() => { preProcessorComplete$.next(true) });
            const _postProcessor = target.prototype[configuration.postProcessor || 'postProcessor'] || (() => { postProcessorComplete$.next(true) });
            target.prototype[_pointcut] = function (...args) {
                const spinnerComponentFactory = componentFactoryResolver.resolveComponentFactory(SpinnerComponent);
                const errorComponentFactory = componentFactoryResolver.resolveComponentFactory(SessionRedirectErrorComponent);
                const _spinnerComponentRef = rootComp.instance.viewRef.createComponent(spinnerComponentFactory);
                _preProcessor && _actual !== _preProcessor && _preProcessor.apply(this);
                preProcessorComplete$.asObservable().subscribe(preProcessorCompleted => {
                    if (preProcessorCompleted !== null) {
                        if (preProcessorCompleted) {
                            _actual && _actual.apply(this, args);
                            initializerComplete$.asObservable().subscribe(initializerCompleted => {
                                if (initializerCompleted !== null) {
                                    if (initializerCompleted) {
                                        _postProcessor && _postProcessor.apply(this);
                                        postProcessorComplete$.asObservable().subscribe(postProcessorCompleted => {
                                            if (postProcessorCompleted !== null) {
                                                if (postProcessorCompleted) {
                                                    _spinnerComponentRef.destroy();
                                                } else {
                                                    _spinnerComponentRef.destroy();
                                                    rootComp.instance.viewRef.createComponent(errorComponentFactory);
                                                }
                                            }
                                        });
                                    } else {
                                        _spinnerComponentRef.destroy();
                                        rootComp.instance.viewRef.createComponent(errorComponentFactory);
                                    }
                                }
                            });
                        } else {
                            _spinnerComponentRef.destroy();
                            rootComp.instance.viewRef.createComponent(errorComponentFactory);
                        }
                    }
                });
            }
        }
    }


}