/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./intermediate-registration-complete.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./intermediate-registration-complete.component";
import * as i3 from "@angular/router";
var styles_IntermediateRegistrationCompleteComponent = [i0.styles];
var RenderType_IntermediateRegistrationCompleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntermediateRegistrationCompleteComponent, data: {} });
export { RenderType_IntermediateRegistrationCompleteComponent as RenderType_IntermediateRegistrationCompleteComponent };
export function View_IntermediateRegistrationCompleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "flex flex-col items-center w-full es-app-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "w-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "px-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "es-h1 mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Registration Complete! "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You successfully registered for an account. You can now access your policy online or in the mobile app to view your information and make quicker payments."])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "flex justify-center pb-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "es-btn-primary w-full mt-4 eservice-register cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onContinueToEserviceRoute() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.continueButtonLabel; _ck(_v, 9, 0, currVal_0); }); }
export function View_IntermediateRegistrationCompleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-intermediate-registration-complete", [], null, null, null, View_IntermediateRegistrationCompleteComponent_0, RenderType_IntermediateRegistrationCompleteComponent)), i1.ɵdid(1, 114688, null, 0, i2.IntermediateRegistrationCompleteComponent, [i3.Router, i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntermediateRegistrationCompleteComponentNgFactory = i1.ɵccf("prcins-intermediate-registration-complete", i2.IntermediateRegistrationCompleteComponent, View_IntermediateRegistrationCompleteComponent_Host_0, {}, {}, []);
export { IntermediateRegistrationCompleteComponentNgFactory as IntermediateRegistrationCompleteComponentNgFactory };
