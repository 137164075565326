<div class="desktop-only-container" style="margin:0 auto;">
  <main class="px-4 max-w-md" style="margin:35px auto;">
    <h1 class="es-h1">Sign Up for eDocuments</h1>
    <prcins-notification [notification]="notification"></prcins-notification>
    <ng-content *ngIf="notification && notification.type==='INFO'" prcins-tealium-tracker="load"
      prcins-tealium-event="eDoc_successful_sign_up"></ng-content>
    <p>
      Go Paperless and save paper! We’ll email your documents to you and will be sure to mail any you need a physical
      copy of. Don’t worry about missing an email - your online account provides 24/7 access to all your documents,
      proof of insurance and more!
    </p>

    <prcins-card>
      <ng-container header-left *ngIf="additionalInvitationMessage">
        <div class="flex justify-between sm:px-2">
          <span>{{additionalInvitationMessage}}</span>
        </div>
      </ng-container>
      <div body>
        <div class="flex justify-between sm:px-2">
          <span>Policy Number:</span><span class="font-bold" *ngIf="policyNumber" prcins-tealium-tracker="load"
            [prcins-tealium-evar]="{'edocs_policyid':policyNumber}">{{policyNumber}}</span>
        </div>
        <div class="flex justify-between sm:px-2">
          <span>Email:</span><a class="font-bold">{{email}}</a>
        </div>


        <!-- <div class="flex justify-between mt-4 border-b-2 mb-4 sm:px-2"></div> -->
        <form [formGroup]="enrollForm" (ngSubmit)="onEnroll()">
          <!-- <div class="flex flex-col">
            <label class="mb-2" for="">Zip Code</label>
            <input formControlName="zipCode" prcinsOnlyNumber maxlength="5" type="text" trimInput class="es-textfield"
              #zipCodeField prcins-tealium-tracker="blur" [prcins-tealium-evar]="{'eDoc_ZipCode':zipCodeField.value}">
            <div
              *ngIf="!enrollForm.get('zipCode').valid  && (enrollForm.get('zipCode').dirty || enrollForm.get('zipCode').touched)">
              <div class="es-error-field" *ngIf="enrollForm.get('zipCode').errors['required']">
                Please enter a valid 5 digit zip code.
              </div>
            </div>
            <div
              *ngIf="!enrollForm.get('zipCode').valid  && (enrollForm.get('zipCode').dirty || enrollForm.get('zipCode').touched)">
              <div class="es-error-field" *ngIf="enrollForm.get('zipCode').errors['pattern']">
                Please enter a valid 5 digit zip code.
              </div>
            </div>
          </div> -->

		  <div class="mt-4">
            <label>
              <input formControlName="agreeTerms" class="mr-2 leading-tight" type="checkbox"
                prcins-tealium-tracker="click" prcins-tealium-event="eDoc_accept_tc">
              <span> I have read and agree to the <a class="ml-1 text-es-blue-light"
                  href="https://www.plymouthrock.com/utility/terms-eDocs-popup" target="_blank">Terms and
                  Conditions</a> and I<a class="ml-1 text-es-blue-light"
                  href="https://www.plymouthrock.com/internet-policy/consent-to-conduct" target="_blank">Consent to Conduct
                  Business Electronically</a></span>
            </label>
            <div
              *ngIf="!enrollForm.get('agreeTerms').valid  && (enrollForm.get('agreeTerms').dirty || enrollForm.get('agreeTerms').touched)">
              <div class="es-error-field" *ngIf="enrollForm.get('agreeTerms').errors['agreeTerms']">
                Please agree the Terms and Conditions.
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-4">
            <button class="es-btn-primary" type="submit" prcins-tealium-tracker="click"
              prcins-tealium-event="eDoc_sign_me_up">Enroll</button>
          </div>
        </form>
      </div>
    </prcins-card>
    <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
  </main>
</div>