import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prcins-incomplete-status',
  templateUrl: './incomplete-status.component.html',
  styleUrls: ['./incomplete-status.component.css']
})
export class IncompleteStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
