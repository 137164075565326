import { Component, OnInit } from '@angular/core';
import {
  IPolicy,
  UtilityProviderService,
  LoginService,
  PolicyBroadcasterService,
  EdocsBroadcasterService,
  INotification
} from '@prcins/utils';
import { DOCS_URL, GUEST_USER_ID, USER_ID, USER_TYPE } from '@prcins/constants';
import { Observable, Subject } from 'rxjs';
import { EsignService } from "../services/esign.service";

@Component({
  selector: 'prcins-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  registeredPolicies: IPolicy[];
  currentRoute;
  showSpinner = false;
  edocsNotification$: Subject<INotification>;
  lobPriority = { "PA": 1, "MC": 2, "HO": 3, "CA": 4, "PCAT": 5 };
  mobileApp = (self != top) ? true : false;
  policySelected$: Observable<IPolicy>;
  policyStatusCode: string;
  eSignRequired = false;
  eSignCompletedMsgDisplay = false;
  eSignEligibleForms: any = [];
  eSignSuccessNotification = {
    type: "INFO",
    message: "You've successfully e-signed your documents! <br> We just sent you an email for your records."
  };
  
  constructor(
    private utilityProvider: UtilityProviderService,
    private loginService: LoginService,
    private pb: PolicyBroadcasterService,
    private eb: EdocsBroadcasterService,
    private policyBroadcaster: PolicyBroadcasterService,
    private eSignService: EsignService
  ) {
  }

  ngOnInit() {
    this.edocsNotification$ = this.eb.notify$;
    this.utilityProvider.broadcastRoadRewardsLoaded(false);
    this.loadPolicies();

    this.pb.policyListLoaded$.subscribe(() => {
      this.utilityProvider.broadcastRoadRewardsLoaded(false);
      this.loadPolicies();
    });

    this.policySelected$ = this.policyBroadcaster.policySelected$;
    this.policySelected$.subscribe(policySelected => {
      if (policySelected) {
        this.policyStatusCode = policySelected.policyInfo.statusCode;
        this.updateRoadRewards(policySelected);
      }
    });
  }

  checkESign() {
    let policies = [];
    this.registeredPolicies.forEach(policy => {
      policies.push(policy.policyNumber);
    });

    if (policies.length) {
      this.eSignService.getEligibleForms(policies).subscribe(data => {
        this.eSignEligibleForms = data;
        if (this.eSignEligibleForms.length) {
          this.eSignRequired = true;
        } else {
          this.eSignRequired = false;
        }
        this.showSpinner = false;
      }, error => {
        console.error(error);
        this.eSignRequired = false;
        this.showSpinner = false;
      });
    } else {
      this.showSpinner = false;
    }


  }

  isGuestUser() {
    return sessionStorage.getItem(GUEST_USER_ID) !== null;
  }

  isAdminUser() {
    return sessionStorage.getItem(USER_TYPE) === 'admin';
  }

  updateRoadRewards(policy: IPolicy) {

    let validStates = "NY NH PA NJ MA CT";
    let validCompanies = "ALN_PRACNY ALN_PRAC ALN_PIC ALN_PSIA ALN_MWAC ALN_PRAPC";
    let lob = this.getLob(policy.policyInfo);
    let companyCode = policy.policyInfo.companyCode;
    let state = policy.policyInfo.state;
    let status = policy.policyInfo.statusCode;
    if (!this.mobileApp && lob == 'PA' && status.includes("Active") && validStates.includes(state) && validCompanies.includes(companyCode)) {

      sessionStorage.setItem("RoadRewardsZip", (policy.policyInfo.zip).substring(0, 5));
      this.utilityProvider.inquiryLoaded$.subscribe(isInquiryLoaded => {
        if(isInquiryLoaded){
                this.utilityProvider.getRoadRewardsDetails(policy.policyNumber)
              .subscribe((resp: any) => {

                if (resp["errorCode"] != "") {
                  sessionStorage.setItem("invalidRRDisplay", "Y");
                } else {
                  sessionStorage.setItem("invalidRRDisplay", resp["data"]);
                }

                this.utilityProvider.broadcastRoadRewardsLoaded(true);
                console.log(resp);
              });
        }});
 
    } else {
      sessionStorage.removeItem("invalidRRDisplay");
      sessionStorage.removeItem("RoadRewardsZip");
      this.utilityProvider.broadcastRoadRewardsLoaded(true);
    }
  }

  loadPolicies() {
    this.showSpinner = true;
    let registeredPolicy = null;
    if (this.isGuestUser()) {
      registeredPolicy = this.loginService.viewPolicyAsGuest(sessionStorage.getItem(GUEST_USER_ID));
    } else if (this.mobileApp) {
      registeredPolicy = this.loginService.getMobileRegisteredPolicies(sessionStorage.getItem(USER_ID));
    } else {
      registeredPolicy = this.loginService.getAllAssociatedPolicies(sessionStorage.getItem(USER_ID));
    }

    registeredPolicy.subscribe(
      val => {
        this.registeredPolicies = val.map(policy => {


          if (this.isGuestUser()) {
            sessionStorage.setItem(USER_ID, policy.inquiry.nameInsured[0].email);
          }

          let priority = this.lobPriority[this.getLob(policy.policyInfo)];
          policy.priority = (policy.policyInfo.statusCode.indexOf('Active') !== -1 || policy.policyInfo.statusCode.indexOf('Cancellation') !== -1) ? priority : priority + 0.5;
          return policy;
        }).sort(function (a, b) {
          return a.priority - b.priority;
        })
        this.checkESign();
        setTimeout(() => {
          this.utilityProvider.setUpClaimsToken(this.registeredPolicies);
        }, 0);

        if (this.mobileApp) {
          setTimeout(() => {
            this.autoUpdateMobileIdCards(val);
          }, 5000);
        }

      },
      (err) => {
        this.showSpinner = false;
        console.log(err);
      }
    );
  }

  getLob(policyInfo) {
    return (policyInfo["lob"] === 'CA' && (policyInfo["productCode"] === "PCAT" || policyInfo["productCode"] === "ALN_UMB")) ? "PCAT" : policyInfo["lob"];
  }

  autoUpdateMobileIdCards(data) {

    this.showSpinner = true;

    setTimeout(() => {
      //if($rootScope.appversioncheck != "old"){
      let href = window.location.origin + window.location.pathname + "/api/idcard/remove/document/" + sessionStorage.getItem(USER_ID);
      if (this.validMobileAutoDownLoad(data)) {
        // href = `${DOCUMENTS_URL.auto.downloadMobile}/${sessionStorage.getItem(USER_ID)}?idCardAutoDownload`;
        this.loginService
          .requestMobileIdCard(sessionStorage.getItem(USER_ID))
          .subscribe(response => {
            if (response.fileId) {
              var pdfOpenUrl = `${DOCS_URL.downloadMobile}/${sessionStorage.getItem(USER_ID)}/${response.fileId}`;
              href = pdfOpenUrl;
              href = [href, 'idCardAutoDownload'].join('&');
              const ua = navigator.userAgent.toLowerCase();

            }
          });
      }

      if (this.mobileOperatingSystem() === "iOS") {
        window.open(href, "_self");
      } else {
        window.open(href, "_system");
      }


      //}
    }, 2000);

    setTimeout(() => {
      this.showSpinner = false;
    }, 5000);


  }

  mobileOperatingSystem = function () {
    var userAgent = navigator.userAgent || navigator.vendor;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }
    return "unknown";
  }

  validMobileAutoDownLoad(data) {

    let checkAnyAutoPolicy = false;
    let validVehicleType = false;
    let noNyPolicy = true;

    data.forEach(policy => {
      console.log(policy);

      if ((policy.policyInfo.lob == "PA" || policy.policyInfo.lob == "MC") && (policy.policyInfo.statusCode.indexOf('Active') !== -1 || policy.policyInfo.statusCode.indexOf('Cancellation') !== -1)) {
        checkAnyAutoPolicy = true;
      }

      if (policy.policyInfo.state == "NY") {
        if ((policy.policyInfo.companyCode == "ALN_PIC" || policy.policyInfo.companyCode == "ALN_PRAPC" || policy.policyInfo.companyCode == "ALN_PRACNY")) {
          noNyPolicy = true;
        } else {
          noNyPolicy = false;
        }
      }

      if (checkAnyAutoPolicy && policy.inquiry.vehicles) {

        policy.inquiry.vehicles.forEach(veh => {
          console.log(veh.entityCode)
          if (veh.entityCode == "PPA" || veh.entityCode == "MH" || veh.entityCode == "AQ" || veh.entityCode == "CL" || veh.entityCode == "MC") {
            validVehicleType = true;
          }
        });

      }


    });

    return (checkAnyAutoPolicy && validVehicleType && noNyPolicy);

  }
}


