import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ADMIN_URL } from '@prcins/constants';
import { INotification } from '@prcins/utils';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  adminActivityError = new Subject<INotification>();

  constructor(private http: HttpClient) { }

  disableAccount(email: string, policyNumber: string): Observable<any> {
    let url;
    if (policyNumber) {
      url = `${ADMIN_URL.disableAccount}/${email}/idg_user_group/${policyNumber}`
    } else {
      url = `${ADMIN_URL.disableAccount}/${email}/idg_user_group/null`;
    }
    return this.http.delete(url, {});
  }
  validateSuperAdmin(): Observable<any> {

    let url = ADMIN_URL.validateSuperAdmin+"?name=superUser";

    return this.http.get(url);

  }
}
