/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quick-pay-registration-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./quick-pay-registration-header.component";
var styles_QuickPayRegistrationHeaderComponent = [i0.styles];
var RenderType_QuickPayRegistrationHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuickPayRegistrationHeaderComponent, data: {} });
export { RenderType_QuickPayRegistrationHeaderComponent as RenderType_QuickPayRegistrationHeaderComponent };
export function View_QuickPayRegistrationHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "es-h1 mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Create an Account "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerText; _ck(_v, 3, 0, currVal_0); }); }
export function View_QuickPayRegistrationHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-quick-pay-registration-header", [], null, null, null, View_QuickPayRegistrationHeaderComponent_0, RenderType_QuickPayRegistrationHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.QuickPayRegistrationHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuickPayRegistrationHeaderComponentNgFactory = i1.ɵccf("prcins-quick-pay-registration-header", i2.QuickPayRegistrationHeaderComponent, View_QuickPayRegistrationHeaderComponent_Host_0, { headerText: "headerText" }, {}, []);
export { QuickPayRegistrationHeaderComponentNgFactory as QuickPayRegistrationHeaderComponentNgFactory };
