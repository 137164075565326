import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TOKEN, tealiumMapper } from '@prcins/constants';
import { map } from 'rxjs/operators';
import { TealiumTrackerService } from '../services/tealium/tealium-tracker.service';
import { Router } from '@angular/router';
import { environment } from '@prcins/environments';
import { UtilityProviderService } from '../services/utility-provider.service';
import { CookieService } from 'ngx-cookie-service';
import { UUID } from 'angular2-uuid';
import * as _ from 'lodash';
import packageJson from '../../../../../package.json';
import * as i0 from "@angular/core";
import * as i1 from "../services/tealium/tealium-tracker.service";
import * as i2 from "@angular/router";
import * as i3 from "../services/utility-provider.service";
import * as i4 from "ngx-cookie-service";
export class JwtInterceptor {
    constructor(tealiumTracker, router, utilityProviderService, cookieService) {
        this.tealiumTracker = tealiumTracker;
        this.router = router;
        this.utilityProviderService = utilityProviderService;
        this.cookieService = cookieService;
        this.version = packageJson.version;
        this.apikeyValue = environment.API_KEY_VALUE;
    }
    intercept(request, next) {
        let token = sessionStorage.getItem(TOKEN);
        console.log(request.url);
        if (!token && _.includes(request.url, 'endorsement/abandon')) {
            token = sessionStorage.getItem('splToken');
        }
        if (token && token.length > 1) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                    'x-traceid': UUID.UUID(),
                    "ui-version": this.version,
                    "api-version": this.apikeyValue
                }
            });
        }
        else {
            request = request.clone({
                setHeaders: {
                    'x-traceid': UUID.UUID(),
                    "ui-version": this.version,
                    "api-version": this.apikeyValue
                }
            });
        }
        return next.handle(request).pipe(map((event) => {
            let startIndex = environment.BASE_URL.length + 1;
            let url = request.url.substring(startIndex);
            if (event instanceof HttpResponse) {
                if (_.includes(request.url, 'eservice-ws') && event.body && event.body.errors) {
                    let errors = event.body.errors;
                    if (errors.find(error => error['message'] === 'Unauthorized')) {
                        this.utilityProviderService.clearAppStorage();
                        this.utilityProviderService.clearCookies();
                        this.utilityProviderService.unauthorized$.next(true);
                        return;
                    }
                }
                else if (event.status === 401) {
                    this.utilityProviderService.clearAppStorage();
                    this.utilityProviderService.clearCookies();
                    this.utilityProviderService.unauthorized$.next(true);
                    return;
                }
                let mapping = tealiumMapper.get(url);
                if (mapping) {
                    for (let key in mapping.predicate) {
                        if (event.body[key] == mapping.predicate[key]) {
                            console.log(`Tracking ${url} :: ${key}=${mapping.predicate[key]}`);
                            this.processPayload(mapping.payload.if, request.body, event.body);
                        }
                        else {
                            console.log(`Tracking ${url} :: ${key}!=${mapping.predicate[key]}`);
                            this.processPayload(mapping.payload.else, request.body, event.body);
                        }
                    }
                }
            }
            return event;
        }));
    }
    processPayload(payloadTemplate, request, response) {
        let { eVars, eventName } = payloadTemplate;
        let payload = this.tealiumTracker.pageContext;
        let eVarPayload = {};
        let eventsPayload = {};
        if (eVars && eVars.length > 0) {
            for (let eVar of eVars) {
                let eVarName = Object.keys(eVar)[0];
                let eVarPath = Object.values(eVar)[0].toString();
                let [eVarPathPrefix, eVarPathVal] = eVarPath.split(':');
                if (eVarPathPrefix == 'request') {
                    if (request[eVarPathVal]) {
                        eVarPayload[eVarName] = request[eVarPathVal];
                    }
                }
                else {
                    if (response[eVarPathVal]) {
                        eVarPayload[eVarName] = request[eVarPathVal];
                    }
                }
            }
        }
        if (eventName) {
            eventsPayload = { event_name: eventName };
        }
        Object.assign(payload, eVarPayload, eventsPayload);
        this.tealiumTracker.link(payload);
    }
}
JwtInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtInterceptor_Factory() { return new JwtInterceptor(i0.ɵɵinject(i1.TealiumTrackerService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.UtilityProviderService), i0.ɵɵinject(i4.CookieService)); }, token: JwtInterceptor, providedIn: "root" });
