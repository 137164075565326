<label class="switch">
  <input type="checkbox" (click)="onSliderChange()" [(ngModel)]="checkValue" [disabled]="disabled">
  <span class="slider round"></span>
  <div class="tick"></div>
</label>

<!-- Usage ---
  <prcins-round-toggle-switch 
    [sliderValue]="value" 
    [sliderKey]="key" 
    (sliderChanged)="onChanged($event)">
  </prcins-round-toggle-switch> 
-->