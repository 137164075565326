import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CardFormProviderService, DiscardComponent, FormValidatorService } from '@prcins/utils';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { USER_ID } from '@prcins/constants';
import { Card } from '../model/wallet';

@Component({
  selector: 'prcins-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.css']
})
export class AddCardComponent extends DiscardComponent implements OnInit {
  cardForm: FormGroup;
  policyNumber: string;
  showSpinner = false;
  showError = false;
  channel: string;
  generalPhone: string;
  agentInfo = {};

  constructor(private cardFormProvider: CardFormProviderService,
    private managePaymentsProviderService: ManagePaymentsProviderService,
    private router: Router,
    private route: ActivatedRoute,
    private formValidator: FormValidatorService
  ) {
    super();
  }

  ngOnInit() {
    this.cardForm = this.cardFormProvider.getForm(false);
    // Add CVV
    this.cardForm.controls.securityCode = new FormControl('', [Validators.required, Validators.minLength(3)]);
    this.cardForm.controls.maskedSecurityCode = new FormControl('', [Validators.required, Validators.minLength(3)]);

    this.policyNumber = this.route.snapshot.queryParamMap.get('policyNumber');
    this.setAgentInfo();
  }

  doAddCard() {
    // form Validation
    this.formValidator.validateAllFormFields(this.cardForm);

    if (this.cardForm.valid) {
      this.showSpinner = true;
      let newCard = new Card();
      newCard.debitAccount = this.cardForm.get('cardNumber').value;
      newCard.cvv = this.cardForm.get('securityCode').value;
      newCard.expirationMonth = this.cardForm.get('expirationMonth').value;
      newCard.expirationYear = this.cardForm.get('expirationYear').value;
      newCard.creditAccount = this.policyNumber;
      newCard.accountEmail = sessionStorage.getItem(USER_ID);
      newCard.paymentType = "CC";
      newCard.transType = "CARD";
      newCard.accountNickName = new Date().getTime() + " : " + newCard.debitAccount; // TODO - remove after ACI Speedpay fix
      newCard.debitZip = "12345"; // TODO - remove after ACI Speedpay fix
  
      // Call HTTP request for the API
      this.managePaymentsProviderService.createWallet(newCard).subscribe((response) => {
        if (response == null || response.status === 'success' && response.error === "") {
          this.showSpinner = false;
          this.navigateToManagePayments('true');
        } else {
          this.showSpinner = false;
          alert(response.status + ", " + response.errorMessage);
        }
      }, (error: HttpErrorResponse) => {
        this.httpErrorResponse();
      });
    }
  }

  navigateToManagePayments(isSuccess: string) {
    sessionStorage.setItem('editWalletSuccess', isSuccess);
    this.router.navigate(['../manage'], {
      relativeTo: this.route
    });
  }

  cancelTransaction() {
    this.navigateToManagePayments('false');
  }

  httpErrorResponse() {
    this.showSpinner = false;
    this.showError = true;
  }

  setAgentInfo() {
    let agentDetails = sessionStorage.agentInfo;
    if (agentDetails) {
      this.agentInfo = JSON.parse(agentDetails);
    }
    this.generalPhone = sessionStorage.getItem('general-phone');
    this.channel = sessionStorage.channel;
  }
}
