import gql from 'graphql-tag';

export const EdocsListQuery = gql`
  query EdocsList($username: String) {
    account(username: $username, userType: "idg_user_group") {
      registeredPolicies {
        policyNumber
        policyInfo {
          lob
          productCode
          statusCode
        }
      }
      edocs {
        emailAddress
        subscriptionFlag
        policyNumber
        edocsEnabled
        changeEmail
      }
    }
  }
`;

export const EdocsQuery = gql`
  query Edocs($username: String) {
    account(username: $username, userType: "idg_user_group") {
      edocs {
        emailAddress
        subscriptionFlag
        policyNumber
        edocsEnabled
        changeEmail
      }
    }
  }
`;
