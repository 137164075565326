import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingInstallmentsQuery, HomeEndorsementControlAgentQuery } from '@prcins/utils';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';
@Injectable({
  providedIn: 'root'
})
export class OpenEditCoveragesAmendmentService {

  apiUrl = environment.BASE_URL + "/v2/";
  // apiUrl = "http://apis.dev.prcaws.net/eservice-ws/v2/";

  constructor(private http: HttpClient, private apollo: Apollo) { }

  getGUID() {
    return sessionStorage.guid;
  }

  recordEndorsmentBlocked(type: string, guid: string): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    return this.http.put(this.apiUrl + 'core/' + policyNum + '/endorsements/recordEndorsmentBlocked?type=' + type + (guid?'&guid=' + guid:''), {});
  }

  createEndorsement(type: string): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    return this.http.post(this.apiUrl + 'core/' + policyNum + '/endorsements?type=' + type, {});
  }

  getEndorsementSummary(policyNum, guid): Observable<any> {
    return this.http.get(this.apiUrl + policyNum + '/endorsements/' + guid);
  }

  rateEndorsement(): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    let guid = sessionStorage.getItem('guid');
    return this.http.put(this.apiUrl + policyNum + '/endorsements/' + guid + '/rate', {});
  }

  issueEndorsePolicyV2(policyNum, guid, request: any): Observable<any> {
    return this.http.put(this.apiUrl + policyNum + '/endorsements/' + guid + '/issue', request)
    .pipe(map((response: any) => {
      let { error, billingEndorsements: [ { endorsementEntity: { endorsementBillingDetails: { payplanLabel } } } ],
        summary: { metaInfo: { fileId } } } = response;
      return { errorCode: error, fileId, payplanLabel };
    }));
  }

  cancelEndorsement(): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : sessionStorage.getItem('splPolicyNumber');
    let guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : sessionStorage.getItem('splGuid');
    let url = this.apiUrl + 'core/' + policyNum + '/endorsements/' + guid
    return this.http.delete(url);
  }

  deleteMortgageeEndorsement(mortgageeId): Observable<any> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    let guid = sessionStorage.getItem('guid');
    let url = this.apiUrl + policyNum + '/endorsements/' + guid + '/mortgagees/' + mortgageeId
    return this.http.delete(url);
  }
  
  endorsementConfig(
    policyNumber?: String
  ): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery({
      query: AutoEndorsementConfigQuery,
      fetchPolicy: 'network-only',
      variables: {
        policyNumber
      }
    }).valueChanges;
  }

  getEndorsementConfig(): Observable<boolean> {
    let policyNum = sessionStorage.getItem('currentPolicy');
    return this.endorsementConfig(policyNum).pipe(
      map(result => result.data.getEndorsementResourceConfig)
    );
  }
}


export const AutoEndorsementConfigQuery = gql`
query GetEndorsementResourceConfig($policyNumber: String) {
  getEndorsementResourceConfig(policyNumber: $policyNumber) {
    addPhysicalDamangeCoverage
	  deletePhysicalDamangeCoverage
	  addCoverageEnhance
	  deleteCoverageEnhance
  }
}
`;
