import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, Subject, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DocumentQuery,
  EdocsListQuery,
  PolicyInfoQuery
} from '@prcins/utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DOCUMENTS_URL } from '../constants/documents.urls';
import { EDOCS_URL, DOCS_URL, LOGIN_URL } from '@prcins/constants';
import { environment } from '@prcins/environments';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  CACHE = {};
  claimsSubjects$;
  docsServiceError$ = new Subject<boolean>();
  constructor(private apollo: Apollo, private httpClient: HttpClient) { }

  getDocuments(policyNumber, documentType): Observable<any> {
    return this.apollo
      .watchQuery({
        query: DocumentQuery,
        variables: {
          policyNumber,
          documentType
        }
      })
      .valueChanges.pipe(
        map(({ data }: any) => {
          const sortedData: any[] = data.policyDocuments;
          if (sortedData.length > 0) {
            sortedData.sort((a, b) => {
              return b.docDate - a.docDate;
            });
          }
          return sortedData;
        })
      );
  }

  getFirstName(policyNumber: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: PolicyInfoQuery,
        variables: {
          policyNumber: policyNumber
        }
      })
      .valueChanges.pipe(
        map(({ data }: any) => data.policySearchInfo.firstName)
      );
  }

  requestAutoIdCard(
    userId: String,
    policyNumber: String,
    term: String,
    actionType: string
  ): Observable<any> {
    const params: HttpParams = new HttpParams({})
      .set("actionType", actionType);

    return this.httpClient.post(
      `${DOCUMENTS_URL.auto.request}/${userId}/${policyNumber}/${term}`,
      {}, { params }
    );
  }

  requestToken(
     policyNumber: String,
     docType : String,
  ): Observable<any> {
    const params: HttpParams = new HttpParams({});

    return this.httpClient.get(
      `${DOCUMENTS_URL.requestToken}/${policyNumber}/${docType}`
    );
  }

  getEdocsList(username) {
    return this.apollo
      .watchQuery({
        query: EdocsListQuery,
        variables: {
          username
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(map(({ data }: any) => data.account));
  }

  saveEdocsPreference(updatedEdocs, policies) {
    const updatePostList = policies.map(policyNumber => {
      const { emailAddress, subscriptionFlag } = updatedEdocs[policyNumber];
      return this.httpClient.post(EDOCS_URL.SavePreference, {
        policyNumber,
        emailAddress,
        subscriptionFlag,
        source: 'CI'
      });
    });
    return forkJoin([...updatePostList]);
  }

  eDiscloserAudit(payload: any) {
    return this.httpClient.post(LOGIN_URL.AuditUrl, payload);
  }

  getClaimEstimateDocuments(policyNumber: string, claimNumber: string) {
    const claimEstimateURL = `${environment.BASE_URL}/docs/${policyNumber}/claims/${claimNumber}/estimates`;
    return this.httpClient.get(claimEstimateURL).pipe(
      map((data: any) => {
        const sortedData: any[] = data;
        if (sortedData.length > 0) {
          sortedData.sort((a, b) => {
            return b.docDate - a.docDate;
          });
        }
        return sortedData;
      })
    );
  }

  validateDeclarationPageAccess(policyNumber): Observable<any> {
    return this.httpClient.get(`${DOCS_URL.validateDecPage}/${policyNumber}`);
  }
  pdfURL(url): Observable<any> {
    //return this.httpClient.get(url);
    return this.httpClient.get(url, { responseType: 'arraybuffer' as 'json' });
  }

  buildDeclarationPageUrl(policyNumber, token) {
    return `${DOCS_URL.downloadDeclaration}/${policyNumber}`
  }

  checkIfDocumentAvailableInIR(documentURL: any): Observable<any> {
    return this.httpClient.get(documentURL, {responseType: 'arraybuffer'});
}

}
