import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { EdocsQuery } from '../graphql/edocs-schema';
import { RightRailsEdocsQuery } from '../graphql/rightrails-schema';
import { INotification } from '../models/notification.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EdocsBroadcasterService {

  notify$: Subject<INotification> = new Subject();

  constructor(private apollo: Apollo) {}
  getEdocs(username) {
    try {
      return this.apollo.getClient().readQuery({
        query: EdocsQuery,
        variables: {
          username
        }
      });
    } catch (e) {
      return null;
    }
  }

  updateEdocs(username, edocs) {
    return this.apollo.getClient().writeQuery({
      query: EdocsQuery,
      variables: {
        username
      },
      data: edocs
    });
  }

  updateShowEdocs(policyNumber, showEdocs) {
    try {
      const rightRails = this.apollo.getClient().readQuery({
        query: RightRailsEdocsQuery,
        variables: {
          policyNumber
        }
      });
      rightRails.rightRail.showEdocs = showEdocs;
      if (rightRails) {
        this.apollo.getClient().writeQuery({
          query: RightRailsEdocsQuery,
          variables: {
            policyNumber
          },
          data: rightRails
        });
      }
    } catch (e) {}
  }
}
