<div class="md:min-h-screen relative">
  <header class=" lg:flex xl:flex p-4 bg-es-blue justify-between items-center border-t-4 border-es-orange">
    <a href="{{mainPage}}" target="_blank">
      <img src="assets/svgs/brands/{{brand}}/header-logo.svg">
    </a>
  </header>
  <main class="flex justify-center w-full min-h-screen">
    <div class=" w-full flex justify-between flex-wrap px-8 lg:px-40">
      <section class=" w-full">
        <router-outlet></router-outlet>
        <h1 class="es-h1 mt-4">Confirm Additional Driver Information</h1>
        <p>Lets get the additional information on the new driver you added to your umbrella policy <br>
          Make sure you have their license number on hand if they have an active license</p>

        <form [formGroup]="driverForm">
          <div formArrayName="drivers">
            <div *ngFor="let driver of driverControls.controls; let i = index" class="driver-row"
              style="margin-bottom: 1rem;">
              <div formGroupName="{{i}}">

                <prcins-card headerClass="text-white bg-es-gray">
                  <span header-left>
                    <div>Driver {{i + 1}}</div>
                  </span>
                  <span header-right><ng-container *ngIf="driverControls.length>1">
                      <img class="cursor-pointer" height='15px' width='15px' (click)="removeDriver(i)"
                        src="assets/svgs/close-hamburger.svg"></ng-container></span>
                  <div body>
                    <div class="flex flex-wrap lg:flex-row">
                      <div class="flex flex-wrap lg:flex-col w-full lg:w-2/12">
                        <div class="add-form-label">First Name</div>
                        <div class="add-form-input w-full">
                          <input type="text" formControlName="firstName"
                            class="add-textfield add-w-full ng-untouched ng-pristine ng-invalid"
                            oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div *ngIf="!driver.get('firstName').valid  && (driver.get('firstName').touched)">
                          <div class="es-error-field mt-2 flex justify-end"
                            *ngIf="driver.get('firstName').errors['required']"> Please provide a first name. </div>
                          <div class="es-error-field flex justify-end mt-2"
                            *ngIf="driver.get('firstName').errors['pattern']">
                            Please enter a name that does not include numbers or special characters other than an
                            apostrophe or hyphen.
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-wrap lg:flex-col w-full lg:w-1/6">
                        <div class="add-form-label">Last Name</div>
                        <div class="add-form-input">
                          <input type="text" formControlName="lastName" class="add-textfield add-w-full"
                            oninput="this.value = this.value.toUpperCase()" ng-reflect-name="lastName">
                        </div>
                        <div *ngIf="!driver.get('lastName').valid  && (driver.get('lastName').touched)"
                          class="es-error-field mt-2 flex">
                          <div *ngIf="driver.get('lastName').errors['required']"> Please provide a last
                            name. </div>
                          <div *ngIf="driver.get('lastName').errors['pattern']">
                            Please enter a name that does not include numbers or special characters other than an
                            apostrophe or hyphen.
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-wrap lg:flex-col w-full lg:w-1/6">
                        <div class="add-form-label ">Date of Birth</div>
                        <div class="add-form-input ">
                          <input type="tel" formControlName="dob"
                            class="add-textfield add-w-full ng-untouched ng-pristine ng-invalid"
                            placeholder="MM/DD/YYYY" ng-reflect-maxlength="10" ng-reflect-name="dob" maxlength="10"
                            appSecureUSDateFormatMask
                            oninput="javascript: if(this.value.length > this.maxLength) this.value = this.value.slice(0,this.maxLength);">
                        </div>
                        <div *ngIf="driver.get('dob').invalid && (driver.get('dob').dirty || driver.get('dob').touched)"
                          class="es-error-field flex mt-2">
                          <div
                            *ngIf="driver.get('dob').errors?.required || driver.get('dob').errors?.dateWithinRange?.type === 'invalid'">
                            Please provide a valid birth date.
                          </div>
                          <div *ngIf="driver.get('dob').errors?.dateWithinRange?.type === 'min'">
                            Only enter people who are {{ driver.get('dob').errors?.dateWithinRange.value }} or older
                          </div>
                          <div *ngIf="driver.get('dob').errors?.dateWithinRange?.type === 'max'">
                            We cannot quote drivers over {{ driver.get('dob').errors?.dateWithinRange.value }} online.
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-wrap lg:flex-col w-full lg:w-1/6">
                        <div class="add-form-label">License Status</div>
                        <div class="add-form-input">
                          <select formControlName="licenseStatus" class="add-textfield add-dropdown add-w-full"
                            (change)="licenseStatusChange($event, driver)">
                            <option value="active">Licensed</option>
                            <option value="notlicensed">Not Licensed</option>
                            <option value="suspended">Suspended</option>
                            <option value="revoked">Revoked</option>
                            <option value="permit">Permit</option>
                          </select>
                        </div>
                        <div
                          *ngIf="driver.get('licenseStatus').invalid && (driver.get('licenseStatus').dirty || driver.get('licenseStatus').touched)"
                          class="es-error-field flex mt-2">
                          <div *ngIf="driver.get('licenseStatus').errors?.required">
                            Please provide a valid license status.
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-wrap lg:flex-col w-full lg:w-1/6">
                        <div class="add-form-label">License State</div>

                        <div class="add-form-input">
                          <select formControlName="licenseState" class="add-textfield add-dropdown add-w-full  disabled:bg-es-gray">
                            <option *ngFor="let s of _usStateOptions; let i = index" [value]="s.code">
                              {{s.description}}
                            </option>
                          </select>
                        </div>
                        <div
                          *ngIf="driver.get('licenseState').invalid && (driver.get('licenseState').dirty || driver.get('licenseState').touched)"
                          class="es-error-field flex mt-2">
                          <div *ngIf="driver.get('licenseState').errors?.required">
                            Please provide a valid license state.
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-wrap lg:flex-col w-full lg:w-1/6">
                        <div class="add-form-label">License Number</div>
                        <div class="add-form-input">
                          <input type="text" formControlName="licenseNumber" class="add-textfield add-w-full  disabled:bg-es-gray">
                        </div>
                        <div
                          *ngIf="driver.get('licenseNumber').invalid && (driver.get('licenseNumber').dirty || driver.get('licenseNumber').touched)"
                          class="es-error-field flex mt-2">
                          <div *ngIf="driver.get('licenseNumber').errors?.required">
                            Please provide a valid license number.
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </prcins-card>
              </div>
            </div>
          </div>
          <div class="w-full mb-48 flex flex-wrap">
            <button class="es-btn-primary add-btn-primary1 lg:w-3/12 mt-4" (click)="addDriverRow()">Add Additional
              Driver</button>
            <button class="es-btn-primary add-btn-primary lg:w-3/12 lg:ml-4 mt-4" type="submit" (click)="onSubmit()">I'm
              all Done,
              Submit</button>
          </div>
        </form>
      </section>

    </div>

  </main>
  <prcins-footer></prcins-footer>
</div>
<prcins-spinner *ngIf="isLoading"></prcins-spinner>