import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class QpayDocumentsGuard {
    constructor(router) {
        this.router = router;
    }
    canActivate(route, state) {
        const allowedRoute = 'policy-documents';
        const currentRoute = state.url.split('/').pop();
        if (currentRoute === allowedRoute) {
            return true;
        }
        else {
            this.router.navigate(['/qpay/documents/policy-documents']);
            return false;
        }
    }
}
QpayDocumentsGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QpayDocumentsGuard_Factory() { return new QpayDocumentsGuard(i0.ɵɵinject(i1.Router)); }, token: QpayDocumentsGuard, providedIn: "root" });
