<footer id="es-footer" class="mt-4 bg-es-blue w-full absolute inset-x-0 bottom-0">
  <div class="mt-4 flex justify-center">
    <a href="{{links.fb}}" target="_blank"><img src="assets/svgs/fb.svg" alt="facebook" prcins-tealium-tracker="click"
        prcins-tealium-event="footer_facebook"></a>
    <a class="inline-block ml-4" href="{{links.twitter}}" target="_blank"><img src="assets/svgs/twitter.svg" alt="twitter"
        prcins-tealium-tracker="click" prcins-tealium-event="footer_twitter"></a>
    <a class="inline-block ml-4" href="{{links.insta}}" target="_blank"><img src="assets/svgs/insta.svg" alt="instagram"
        prcins-tealium-tracker="click" prcins-tealium-event="footer_google"></a>
    <a class="inline-block ml-4" href="{{links.blog}}" target="_blank"><img src="assets/svgs/blog.svg" alt="plymouth blogs"
        prcins-tealium-tracker="click" prcins-tealium-event="footer_blog"></a>
  </div>
  <div class="mt-4 flex justify-center text-white text-sm">
    <a class="inline-block ml-4" href="{{links.privacy}}" target="_blank" prcins-tealium-tracker="click" prcins-tealium-event="footer_privacy">Privacy & Security</a>
    <a class="inline-block ml-4" href="{{links.terms}}" target="_blank" prcins-tealium-tracker="click" prcins-tealium-event="footer_terms">Terms
      & Conditions</a>
  </div>
  <div class="flex justify-center">
    <span class="text-white text-xs mt-4">© {{year}} Plymouth Rock Assurance. All Rights Reserved.</span>
  </div>
  <div class="version">{{version}}</div>
</footer>