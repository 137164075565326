<ng-container *ngIf="!isLoading; else loading">
  <div *ngIf="showWidget" class="mt-8 border border-b-0 border-l-0 border-r-0">
    <prcins-card headerClass="bg-es-blue text-white">
      <span header-left>Mobile Billing Text Alerts</span>
      <div body class="mx-4 my-2">
        <prcins-danger *ngIf="stepOneFailed || stepTwoFailed">Oops! Please enter a valid code 
        </prcins-danger>
        <prcins-danger *ngIf="isExpired">Oops! That code has expired. Please select Request New Code.
        </prcins-danger>

        <form *ngIf="showStepOne" [formGroup]="stepOneForm" (submit)="onStepOneContinue()">
          <input class="mt-2 es-textfield w-full" prcinsPhoneMask prcinsPhoneValidation type="text" trimInput placeholder="Phone Number"
            formControlName="phoneNumberMask" maxlength="12">
          <div *ngIf="!stepOneForm.get('phoneNumberMask').valid  && (stepOneForm.get('phoneNumberMask').touched || isStepOneSubmitted)">
            <div class="es-error-field mt-2 flex justify-end">
              Oops&#33; Please enter a valid mobile number.
            </div>
          </div>
          <p class="mt-4 text-sm">Standard message and data rates may apply.</p>
          <div class="flex mt-4">
            <prcins-square-checkbox [sliderValue]="stepOneForm.get('termsAndCondition').value"
              sliderKey="termsAndCondition" (sliderChanged)="onTermsChanged($event)"></prcins-square-checkbox>
              
              <div class="ml-2">
                Yes! Please sign me up to receive billing alerts on my mobile device. By signing up, I agree to the <a
                  class="text-es-blue" href="https://www.plymouthrock.com/internet-policy/mobileterms"
                  target="_blank">Terms and Conditions</a> and I <a
                  href="https://www.plymouthrock.com/internet-policy/consent-to-conduct" target="_blank"
                  class="text-blue-600">Consent to Conduct Business Electronically.</a> Please provide a mobile phone # above. Standard message and data rates may apply.
                  <div *ngIf="!stepOneForm.get('termsAndCondition').valid && (stepOneForm.get('termsAndCondition').touched || isStepOneSubmitted)">
                    <p class="es-error-field mt-0 flex justify-end">
                      Oops&#33; You forgot to accept the terms and conditions.
                    </p>
                  </div>
              </div>
            
          </div>
          <div>
            <button class="es-btn-primary w-full mt-4" type="submit">Continue</button>
          </div>
        </form>

        <form *ngIf="showStepTwo" [formGroup]="stepTwoForm" (submit)="onStepTwoContinue()">
          <p>Please enter the six digit code that was texted to your mobile phone.</p>
          <input class="mt-2 es-textfield w-full prcins-mask-data" mask="0{6}" type="password" placeholder="Code"
            formControlName="securityCode">
          <button class="es-btn-primary w-full mt-4" type="submit">Verify</button>
          <span class="flex mt-4"><a class="text-es-blue-light cursor-pointer"
            (click)="resendCode()">Request New Code</a></span>
        </form>

        <div *ngIf="showStepThree">
          <prcins-primary>
            <h2>Yay&#33; You are now enrolled in billing alerts&#33;</h2>
          </prcins-primary>
        </div>

        <prcins-skeleton   *ngIf="stepInProgress" numOfRows="4"></prcins-skeleton>

      </div>
    </prcins-card>
  </div>
</ng-container>
<ng-template #loading>
  <prcins-card pt="pt-0">
    <div body>
      <prcins-skeleton   numOfRows="4"></prcins-skeleton>
    </div>
  </prcins-card>
</ng-template>