import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IPolicy } from '../models/policy';
import { Router, ActivatedRoute } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class PolicyBroadcasterService {
  private policySelectedSource = new Subject<IPolicy>();
  private policyLoaded = new Subject<any>();
  private billingLoaded = new Subject<any>();
  private policyListLoaded = new Subject<void>();

  policySelected$ = this.policySelectedSource.asObservable();
  billingLoaded$ = this.billingLoaded.asObservable();
  policyLoaded$ = this.policyLoaded.asObservable();
  policyListLoaded$ = this.policyListLoaded.asObservable();

  constructor(private router: Router, private route: ActivatedRoute) { }

  broadcastSelectedPolicy(policy: IPolicy, previousPolicy) {
    this.policySelectedSource.next(policy);
    if (previousPolicy !== policy.policyNumber) {
      this.router.navigate(['/eservice/home'], { relativeTo: this.route });
    }
  }

  broadcastPolicyLoaded(policyDetails: any) {
    this.policyLoaded.next(policyDetails);
  }

  broadcastBillingLoaded(billingDetails: any) {
    this.billingLoaded.next(billingDetails);
  }

  broadcastPolicyListLoaded() {
    this.policyListLoaded.next();
  }
}
