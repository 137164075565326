<div class="popup-fixed-container">
  <div class="popup-container">
    <div class="popup-item-container rounded">
      <div>
        <div class="flex px-4 py-3 items-center justify-between rounded-t {{headerClass}}">
          <ng-content select="[header-left]"></ng-content>
          <ng-content select="[header-right]"></ng-content>
        </div>
        <div class="px-5 py-5">
          <ng-content select="[body]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>