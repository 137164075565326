import { BehaviorSubject, of, Subject } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { PolicyInfoQuery } from '../graphql/policy-schemas';
import { map } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DateTimeValidators } from "@prcins/utils";
import { EDOCS_URL, USER_TYPE, USER_FNAME, USER_LNAME, CLAIMS_URL, CURRENT_POLICY, BRAND, LOGIN_URL, BASE_URL } from '@prcins/constants';
import { environment } from '@prcins/environments';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common/http";
import * as i4 from "ngx-cookie-service";
export class UtilityProviderService {
    constructor(apollo, fb, http, cookieService) {
        this.apollo = apollo;
        this.fb = fb;
        this.http = http;
        this.cookieService = cookieService;
        this.unauthorized$ = new Subject();
        this.roadRewardsLoaded = new BehaviorSubject(false);
        this.inquiryLoaded = new BehaviorSubject(false);
        this.claimsTokenMap = {};
        this.unauthorizedObservable = this.unauthorized$.asObservable();
        this.roadRewardsLoaded$ = this.roadRewardsLoaded.asObservable();
        this.inquiryLoaded$ = this.inquiryLoaded.asObservable();
    }
    broadcastRoadRewardsLoaded(status) {
        this.roadRewardsLoaded.next(status);
    }
    broadcastInquiryLoaded(status) {
        this.inquiryLoaded.next(status);
    }
    getPolicyGreetingDetails(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyInfoQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data.policySearchInfo));
    }
    getUserForm() {
        return this.fb.group({
            lob: ['PA'],
            firstName: ['', [Validators.required, Validators.pattern(/^[A-Za-z\-'\s]+$/)]],
            lastName: ['', [Validators.required, Validators.pattern(/^[A-Za-z\-'\s]+$/)]],
            policyNumber: ['', [Validators.required, Validators.pattern(/(^[a-zA-Z]{3}[0-9a-zA-Z]{11}$)|(^[0-9]{7,9}$)/)]],
            policyZip: ['', [Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]],
            businessName: ['%%', [Validators.pattern(/^[A-Z\-_]*$/)]],
            companyName: ['', [Validators.required]]
        });
    }
    getRegistrationUserForm() {
        return this.fb.group({
            type: ['', [Validators.required]],
            firstName: ['', [Validators.required, Validators.pattern(/^[A-Za-z\-'\s]+$/)]],
            lastName: ['', [Validators.required, Validators.pattern(/^[A-Za-z\-'\s]+$/)]],
            policyNumber: ['', [Validators.required, Validators.pattern(/(^[a-zA-Z]{3}[0-9a-zA-Z]{11}$)|(^[0-9]{7,9}$)/)]],
            zipCode: ['', [Validators.required, Validators.pattern(/^\d{5}/)]],
            dob: ['', [Validators.required, DateTimeValidators.ValidDate(), DateTimeValidators.validateAge(), DateTimeValidators.noFutureDate(), DateTimeValidators.minDate(100, "years")]],
            companyName: ['', [Validators.required]]
        });
    }
    getEDocEnrollmentLandingForm() {
        return this.fb.group({
            userName: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]],
            dob: ['', [Validators.required, DateTimeValidators.ValidDate(), DateTimeValidators.validateAge(), DateTimeValidators.noFutureDate(), DateTimeValidators.minDate(100, "years")]],
            password: ['', [Validators.required, Validators.pattern('^(?=[^a-z]*[a-z])(?=\\D*\\d)[A-Za-z\\d!$%@#£€*?&]{7,}$')]],
            confirmPassword: [
                '',
                {
                    validators: [Validators.required, this.passwordValidator('password')],
                    updateOn: 'blur'
                }
            ],
            enrollEDOC: ['', [Validators.required]],
            verificationId: ['']
        });
    }
    getInterMediateRegisterationForm() {
        return this.fb.group({
            userName: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]],
            dob: ['', [Validators.required, DateTimeValidators.ValidDate(), DateTimeValidators.validateAge(), DateTimeValidators.noFutureDate(), DateTimeValidators.minDate(100, "years")]],
            password: ['', [Validators.required, Validators.pattern('^(?=[^a-z]*[a-z])(?=\\D*\\d)[A-Za-z\\d!$%@#£€*?&]{7,}$')]],
            confirmPassword: [
                '',
                {
                    validators: [Validators.required, this.passwordValidator('password')],
                    updateOn: 'blur'
                }
            ],
            enrollEDOC: ['', [Validators.required]],
            enrollBillingTextAlert: ['', [Validators.required]],
            verificationId: ['']
        });
    }
    getCreatePasswordForm() {
        return this.fb.group({
            userName: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]],
            password: ['', [Validators.required, Validators.pattern('^(?=[^a-z]*[a-z])(?=\\D*\\d)[A-Za-z\\d!$%@#£€*?&]{7,}$')]],
            confirmPassword: [
                '',
                {
                    validators: [Validators.required, this.passwordValidator('password')],
                    updateOn: 'blur'
                }
            ],
            verificationId: ['']
        });
    }
    getResetPasswordForm() {
        return this.fb.group({
            userName: [''],
            password: ['', [Validators.required, Validators.pattern('^(?=[^a-z]*[a-z])(?=\\D*\\d)[A-Za-z\\d!$%@#£€*?&]{7,}$')]],
            confirmPassword: [
                '',
                {
                    validators: [Validators.required, this.passwordValidator('password')],
                    updateOn: 'blur'
                }
            ]
        });
    }
    signupForEdocs(policyNumber, emailAddress) {
        return this.http.post(EDOCS_URL.SavePreference, {
            policyNumber,
            emailAddress,
            subscriptionFlag: 'Y',
            source: 'CI'
        });
    }
    eDiscloserAudit(payload) {
        return this.http.post(LOGIN_URL.AuditUrl, payload);
    }
    getUserType() {
        return sessionStorage.getItem(USER_TYPE) === 'admin'
            ? 'idg_employee_group'
            : 'idg_user_group';
    }
    setUpClaimsToken(policies) {
        policies.forEach(p => {
            const policyNumber = p.policyNumber;
            const url = `${environment.EFNOL_URL}/#/claim/${policyNumber}/CI`;
            const payload = {
                policyNumber,
                fname: sessionStorage.getItem(USER_FNAME),
                lname: sessionStorage.getItem(USER_LNAME)
            };
            this.http
                .post(CLAIMS_URL.Claims_Token + '/' + policyNumber, payload)
                .subscribe((resp) => {
                this.claimsTokenMap[policyNumber] = `${url}?t=${resp['access_token']}`;
            });
        });
    }
    getClaimsToken() {
        const policyNumber = sessionStorage.getItem(CURRENT_POLICY);
        if (this.claimsTokenMap[policyNumber])
            return of(this.claimsTokenMap[policyNumber]);
        const payload = {
            policyNumber: policyNumber,
            fname: sessionStorage.getItem(USER_FNAME),
            lname: sessionStorage.getItem(USER_LNAME)
        };
        const url = `${environment.EFNOL_URL}/#/claim/${policyNumber}/CI`;
        return this.http
            .post(CLAIMS_URL.Claims_Token + '/' + policyNumber, payload)
            .pipe(map(resp => `${url}?t=${resp['access_token']}`));
    }
    getCovidMsgDetails(policyNumber) {
        // const policyNumber = sessionStorage.getItem(CURRENT_POLICY);
        return this.http.get(LOGIN_URL.covidRelief + '/' + policyNumber);
    }
    getRoadRewardsDetails(policyNumber) {
        return this.http.get(LOGIN_URL.roadRewardsSubsUrl + '/' + policyNumber);
    }
    clearAppStorage() {
        let aiguid = sessionStorage.getItem('aiguid');
        let splToken = sessionStorage.getItem('token');
        let splGuid = sessionStorage.getItem('guid');
        let splPolicyNumber = sessionStorage.getItem('currentPolicy');
        let baseURL = sessionStorage.getItem(BASE_URL);
        sessionStorage.clear();
        sessionStorage.setItem(BASE_URL, baseURL);
        if (aiguid || splGuid) {
            sessionStorage.setItem('aiguid', aiguid);
            sessionStorage.setItem('splToken', splToken);
            sessionStorage.setItem('splGuid', splGuid);
            sessionStorage.setItem('splPolicyNumber', splPolicyNumber);
        }
        this.apollo.getClient().stop();
        this.apollo.getClient().resetStore();
    }
    clearCookies() {
        const domain = location.hostname;
        this.cookieService.delete('token', '/', domain);
        this.cookieService.delete('token', '/');
        //auto correction
        this.cookieService.delete('token', '/', '.plymouthrock.com');
        this.cookieService.delete('es_Email', '/', '.plymouthrock.com');
        this.cookieService.delete('es_Email_RememberMe', '/', '.plymouthrock.com');
        this.cookieService.delete('es_Email', '/', domain);
        this.cookieService.delete('es_Email_RememberMe', '/', domain);
        if (this.cookieService.get('token')) {
            this.cookieService.set('token', '', -1, '/', domain, true, 'None');
            this.cookieService.set('token', '', -1, '/');
            //auto correction
            this.cookieService.set('token', '', -1, '/', '.plymouthrock.com');
            this.cookieService.set('es_Email', '', -1, '/', '.plymouthrock.com');
            this.cookieService.set('es_Email_RememberMe', '', -1, '/', '.plymouthrock.com');
            this.cookieService.set('es_Email', '', -1, '/', domain);
            this.cookieService.set('es_Email_RememberMe', '', -1, '/', domain);
        }
    }
    setupBrand() {
        const host = window.location.hostname.toLowerCase();
        const path = window.location.pathname.toLowerCase();
        const query = window.location.search.toLowerCase();
        if (host.indexOf('pilgrim') !== -1 ||
            path.indexOf('pilgrim') !== -1 ||
            query.indexOf('b=pilgrim') !== -1) {
            localStorage.setItem(BRAND, 'PL');
        }
        else {
            localStorage.setItem(BRAND, 'PR');
        }
    }
    passwordValidator(confirmPasswordInput) {
        let confirmPasswordControl;
        let passwordControl;
        return (control) => {
            if (!control.parent) {
                return null;
            }
            if (!confirmPasswordControl) {
                confirmPasswordControl = control;
                passwordControl = control.parent.get(confirmPasswordInput);
                passwordControl.valueChanges.subscribe(() => {
                    confirmPasswordControl.updateValueAndValidity();
                });
            }
            if (!confirmPasswordControl.value) {
                return {
                    required: true
                };
            }
            else if (passwordControl.value !== confirmPasswordControl.value) {
                return {
                    notMatch: true
                };
            }
            return null;
        };
    }
}
UtilityProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityProviderService_Factory() { return new UtilityProviderService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.FormBuilder), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.CookieService)); }, token: UtilityProviderService, providedIn: "root" });
