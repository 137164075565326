/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lob.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./lob.component";
var styles_LobComponent = [i0.styles];
var RenderType_LobComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LobComponent, data: {} });
export { RenderType_LobComponent as RenderType_LobComponent };
function View_LobComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_LobComponent_2(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 1), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_LobComponent_3(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 2), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_LobComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), i1.ɵncd(null, 3), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_LobComponent_6(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 4), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_LobComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LobComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["businessAutoGroup", 2]], null, 0, null, View_LobComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.account.policyInfo.productCode === "PCAT") || (_co.account.policyInfo.productCode === "ALN_UMB")); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LobComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LobComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LobComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LobComponent_3)), i1.ɵdid(7, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LobComponent_4)), i1.ɵdid(9, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.account.policyInfo.lob; _ck(_v, 1, 0, currVal_0); var currVal_1 = "PA"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "HO"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "MC"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "CA"; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_LobComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-lob", [], null, null, null, View_LobComponent_0, RenderType_LobComponent)), i1.ɵdid(1, 49152, null, 0, i3.LobComponent, [], null, null)], null, null); }
var LobComponentNgFactory = i1.ɵccf("prcins-lob", i3.LobComponent, View_LobComponent_Host_0, { account: "account" }, {}, ["[PA]", "[HO]", "[MC]", "[PCAT]", "[CA]"]);
export { LobComponentNgFactory as LobComponentNgFactory };
