import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormValidatorService, RegistrationService } from '@prcins/utils';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'prcins-mfa-verification-options',
  templateUrl: './mfa-verification-options.component.html',
  styleUrls: ['./mfa-verification-options.component.css']
})
export class MfaVerificationOptionsComponent implements OnInit {

  @Output() submitted = new EventEmitter<any>();
  @Output() cancelled = new EventEmitter<any>();
  @Input() userPolicyDetailsResp;
  @Input() verificationMode;
  @Input() verificationType;
  @Input() description;
  showSpinner = false;
  registerError = false;
  alreadyRegisterError = false;
  errorMessage: string;
  passwordActionType: string;
  verificationOptionsForm: FormGroup = this.fb.group({
    mode: ['', [Validators.required]],
    type: [''],
    verificationId: ['']
  });
  verificationFailed = false;
  verificationInvalid = false;
  inputVerificationTextFailed = false;
  inputVerificationEmailFailed = false;
  isMobileApp = (self != top) ? true : false;
  isMobileBrowser: boolean;

  constructor(private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private formValidator: FormValidatorService,
    private registrationProvider: RegistrationService) { }

  ngOnInit() {
    this.verificationOptionsForm.controls['type'].setValue(this.verificationType);

    if (this.verificationMode && this.verificationMode.length !== 0) {
      this.verificationMode.forEach(mode => {
        if (mode === 'TEXT') {
          this.inputVerificationTextFailed = true;
          this.userPolicyDetailsResp.phoneNumber = '';
        } else if (mode === 'EMAIL') {
          this.inputVerificationEmailFailed = true;
          this.userPolicyDetailsResp.emailAddress = '';
        }
      });
      this.verificationFailed = true;
    }

    if (this.userPolicyDetailsResp && !(this.userPolicyDetailsResp.emailAddress || this.userPolicyDetailsResp.phoneNumber)) {
      this.verificationOptionsForm.get('mode').setValue('POLICY_NUMBER');
      this.verificationOptionsForm.addControl('token', new FormControl('', [Validators.required, Validators.pattern(/(^[a-zA-Z]{3}[0-9a-zA-Z]{11}$)|(^[0-9]{7,9}$)/)]));
      this.verificationOptionsForm.removeControl('type');
      this.verificationFailed = false;
    }

    this.isMobileBrowser = !!navigator.userAgent.match(
      /iPad|iPhone|Android|IEMobile|BlackBerry/i
    );
  }

  onSubmit() {
    if (this.verificationOptionsForm.valid) {
      this.registerError = false;
      this.verificationInvalid = false;
      this.alreadyRegisterError = false;
      this.showSpinner = true;
      this.verificationOptionsForm.value.verificationId = this.userPolicyDetailsResp.id;
      if (this.verificationOptionsForm.value.mode === 'POLICY_NUMBER') {
        this.policyNumberVerification();
      } else {
        this.textAndEmailMode();
      }
    } else {
      this.formValidator.validateAllFormFields(this.verificationOptionsForm);
    }
  }

  policyNumberVerification() {
    this.registrationProvider
      .verifyAuthorizationCode(this.verificationOptionsForm.value)
      .subscribe((resp: any) => {
        let origin = sessionStorage.getItem('origin');
        if (resp.messageCode === 'VERIFIED' && origin === 'forgotEmailFlow') {
          sessionStorage.removeItem('origin');
          this.router.navigate(['../../login'], {
            relativeTo: this.route,
            state: {
              email: resp.email
            }
          });
        } else if (resp.messageCode === 'VERIFIED') {
          if (this.passwordActionType === 'Create') {
            this.router.navigate(['../../create-password'], {
              relativeTo: this.route
            });
          } else {
            this.router.navigate(['../../forgot-password'], {
              relativeTo: this.route,
              state: {
                email: resp.email
              }
            });
          }
        } else if (resp.messageCode === 'POLICY_NOT_ELIGIBLE') {
          this.registerError = true;
          this.errorMessage = "This policy is not eligible for enrollment.";
        } else if (resp.messageCode === 'POLICY_NOT_FOUND') {
          this.registerError = true;
          this.errorMessage = "Oops! We can't seem to find you. Please try that again.";
        } else if (resp.messageCode === 'RETRY') {
          this.registerError = true;
          this.errorMessage = "Oops! Verification failed. Please try that again.";
        } else if (resp.messageCode === 'POLICY_ALREADY_REGISTERED') {
          this.registerError = true;
          this.alreadyRegisterError = true;
        } else {
          this.verificationInvalid = true;
        }
        this.showSpinner = false;
      }, (error: HttpErrorResponse) => {
        this.registerError = true;
        this.errorMessage = "Oops! Verification failed. Please try that again.";
        this.showSpinner = false;
      });
  }

  textAndEmailMode() {
    this.registrationProvider
      .selectValidationMethod(this.verificationOptionsForm.value)
      .subscribe((resp: any) => {
        if (resp === null || resp.messageCode === '' || resp.messageCode === undefined) {
          this.submitted.emit(this.verificationOptionsForm.value);
        } else {
          this.registerError = true;
          this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
        }
        this.showSpinner = false;
      }, (error: HttpErrorResponse) => {
        this.showSpinner = false;
        this.registerError = true;
        this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
      });
  }

  changeMode(event) {
    this.verificationOptionsForm.get('mode').setValue(event.target.value);
    if (event.target.value === 'POLICY_NUMBER') {
      this.verificationOptionsForm.addControl('token', new FormControl('', [Validators.required, Validators.pattern(/(^[a-zA-Z]{3}[0-9a-zA-Z]{11}$)|(^[0-9]{7,9}$)/)]));
      this.verificationOptionsForm.removeControl('type');
    } else {
      this.verificationOptionsForm.removeControl('token');
      if (this.passwordActionType === 'Create') {
        this.verificationOptionsForm.addControl('type', new FormControl('REGISTRATION'));
      } else {
        this.verificationOptionsForm.addControl('type', new FormControl('FORGOT_PASSWORD'));
      }
    }
  }

  onBackClicked() {
    let origin = sessionStorage.getItem('origin');
    if (this.passwordActionType === 'Reset') {
      this.router.navigate(['eservice/login']);
    } else if (this.passwordActionType === 'Create') {
      this.router.navigate(['eservice/register']);
    } else if (this.passwordActionType === 'linkPolicies') {
      this.router.navigate(['eservice/link-policies']);
    } else if (origin === 'forgotEmailFlow') {
      this.router.navigate(['eservice/forgot-email']);
    }
  }

  onCancelClicked() {
    this.cancelled.emit();
  }

}
