import { environment } from '@prcins/environments';

export const CLAIM_URL = {
    claimstalkurl: `${environment.BASE_URL}/claims/claimstalkurl`,
    getclaimspaymentoptionurl: `${environment.BASE_URL}/claims/getclaimspayments`,
    getclaimeftaccounturl: `${environment.BASE_URL}/claims/getaccounts`,
    updateClaimsPaymentOption: `${environment.BASE_URL}/claims/updateclaimspayments`,
    getclaimNotificationurl: `${environment.BASE_URL}/claims/getclaimsNotications`,
    updateClaimNotificationurl: `${environment.BASE_URL}/claims/updateclaimsNotications`,
    createDispatch:`${environment.BASE_URL}/claims/dispatch/create`, 
    getRepresentatives:`${environment.BASE_URL}/claims/dispatch/representatives`, 
    assignRepresentative:`${environment.BASE_URL}/claims/dispatch/representatives/assign`, 
    reassignRepresentative:`${environment.BASE_URL}/claims/dispatch/representatives/carpics/reassign`, 
    getDispatch:`${environment.BASE_URL}/claims/dispatch`
    
}

export const CLAIMS_QUESTIONNAIRE_URL = {
    retrieve: `${environment.BASE_URL}/claims/retriveClaimQuestionniare`,
    save: `${environment.BASE_URL}/claims/saveQuestionniare`,
    submit: `${environment.BASE_URL}/claims/submitQuestionniare`,
    retrieveStatus: `${environment.BASE_URL}/claims/retriveClaimQuestionniareStatus`,
    accidentDiagram: `${environment.PDF_URL}/docs/claims/pdf/accident`
}