import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import gql from 'graphql-tag';
import { PaymentDetailsQuery, PolicyReviewQuery, MobileRegistrationQuery, MobileRegisteredPolicyQuery, BillingEftQuery } from '@prcins/utils';
import { PAYMENT_URL, QPAY_PAYMENT_DETAILS, REGISTRATION_URL } from '@prcins/constants';
import { LOGIN_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
export class PaymentProviderService {
    constructor(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.APP_NAME = 'qpay';
        this._ACHValidationFailureCount = 0;
    }
    showRegistrationWidget(payload) {
        return this.http.post(REGISTRATION_URL.Enrollment, payload);
    }
    stepOneRegistration(payload) {
        return this.http.post(REGISTRATION_URL.MobileVerification, payload);
    }
    stepTwoRegistration(payload) {
        return this.http.post(REGISTRATION_URL.MobileSecurityCodeVerification, payload);
    }
    eDiscloserAudit(payload) {
        return this.http.post(LOGIN_URL.AuditUrl, payload).pipe(catchError((error) => {
            return throwError(error);
        }));
    }
    getPayloadForRegistration(policyNumber) {
        return this.apollo
            .watchQuery({
            query: MobileRegistrationQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    getPaymentDetails(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PaymentDetailsQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    encryptPaymentDetails(paymentDetails, policyNumber) {
        return this.http.post(PAYMENT_URL.Secure, {
            ...paymentDetails,
            policyNumber
        });
    }
    validateCard(cardValidationPayload, underwriterCode) {
        const validateUrl = `${PAYMENT_URL.ValidateCard}/${cardValidationPayload.policyNumber}/${underwriterCode}/${this.APP_NAME}`;
        return this.http.post(validateUrl, cardValidationPayload);
    }
    setPaymentDetails(paymentDetails) {
        if (paymentDetails.paymentOptionSelected === 'cc') {
            paymentDetails.cardForm['__typename'] = 'CardDetails';
            paymentDetails.eftForm = {
                __typename: 'AccountDetails',
                accountEmailId: '',
                accountHolderName: '',
                accountType: '%%',
                accountNumber: '',
                reAccountNumber: '',
                routingNumber: '',
                maskedAccountNumber: '',
                maskedReAccountNumber: '',
                maskedRoutingNumber: ''
            };
        }
        else {
            paymentDetails.eftForm['__typename'] = 'AccountDetails';
            paymentDetails.cardForm = {
                __typename: 'CardDetails',
                cardHolderName: '',
                cardHolderEmailId: '',
                cardNumber: '',
                securityCode: '',
                transactionType: '%%',
                maskedCardNumber: '',
                expirationDate: ''
            };
        }
        this.apollo.getClient().writeData({
            data: {
                paymentDetails: {
                    ...paymentDetails,
                    __typename: 'PaymentDetails'
                }
            }
        });
    }
    getPaymentDetailsFromSession(policyNumber) {
        const pd = sessionStorage.getItem(QPAY_PAYMENT_DETAILS);
        if (pd && policyNumber) {
            return this.http.post(PAYMENT_URL.Decrypt, {
                pd,
                policyNumber
            });
        }
        return of(null);
    }
    getPolicyPaymentDetails() {
        try {
            return this.apollo.getClient().readQuery({
                query: gql `
          {
            paymentDetails {
              paymentAmount
              paymentOptionSelected
              cardForm {
                cardHolderName
                cardHolderEmailId
                transactionType
                cardNumber
                maskedCardNumber
                securityCode
                expirationDate
              }
              eftForm {
                accountEmailId
                accountHolderName
                accountType
                accountNumber
                reAccountNumber
                routingNumber
                maskedAccountNumber
                maskedReAccountNumber
                maskedRoutingNumber
                etfSavedPaymentMethod
              }
            }
          }
        `
            });
        }
        catch (ex) {
            return {};
        }
    }
    getPolicyReviewDetails(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyReviewQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    getMobileRegisteredPolicies(username) {
        return this.apollo
            .watchQuery({
            query: MobileRegisteredPolicyQuery,
            variables: {
                username: username
            },
            fetchPolicy: 'no-cache'
        })
            .valueChanges.pipe(map(({ data }) => data.account.registeredPolicies));
    }
    makePayment(type, payload) {
        const url = `${PAYMENT_URL.Payment}/${type}`;
        return this.http.post(url, payload);
    }
    getPendingPaymentsDetails(policyNumber) {
        return this.http.get(PAYMENT_URL.checkPendingPayments + '/' + policyNumber);
    }
    getEFTDetails(policyNumber, payPlan) {
        return this.apollo
            .watchQuery({
            query: BillingEftQuery,
            variables: {
                policyNumber,
                payPlan
            },
            fetchPolicy: "no-cache"
        })
            .valueChanges.pipe(map(({ data }) => data.billingEftData));
    }
    resetACHFailureCount() {
        this._ACHValidationFailureCount = 0;
    }
    incrementACHFailureCount() {
        this._ACHValidationFailureCount++;
    }
    getACHFailureCount() {
        return this._ACHValidationFailureCount;
    }
}
PaymentProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentProviderService_Factory() { return new PaymentProviderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: PaymentProviderService, providedIn: "root" });
