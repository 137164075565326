<ng-container *ngIf="notification">
  <div class="flex items-center mb-4 p-4 pr-6 border rounded font-bold relative" [ngClass]="{
    'border-es-pale-orange bg-es-pale-orange-light':notification.type!=='INFO',
    'border-gray-300 bg-es-sky-blue':notification.type==='INFO'
  }">
    <ng-container *ngIf="notification.type==='INFO'">
      <img alt="" class="p-4" src="assets/png/check-icon.png">
    </ng-container>
    <span class="absolute top-0 right-0 p-2 opacity-25" *ngIf="!pinned">
      <img class="cursor-pointer " height='10px' width='10px' (click)="dismiss()" src="assets/svgs/close-black.svg">
    </span>

    <span class="mr-4" *ngIf="notification.type==='WARN'">
      <img style="max-width:35px; height:35px" src="assets/svgs/help.svg">
    </span>
    <span [ngClass]="{'font-bold':notification.type==='WARN'}">
      <span #notificationContent>
        <ng-content></ng-content>
      </span>
      <ng-container *ngIf="notificationContent.children.length == 0">
        <span [innerHTML]="notification.message"></span>
      </ng-container>
    </span>
  </div>
</ng-container>