<form [formGroup]="userForm">
  <div class="mt-4 flex items-center justify-between flex-wrap">
    <label for="">Select Policy Type</label>
    <div class="inline-block relative w-full lg:w-3/5">
      <select class="es-textfield appearance-none w-full" (change)="onPolicyTypeSelected()" formControlName="lob">
        <option value="PA">Personal Auto / Motorcycle</option>
        <option value="HO">Homeowners</option>
        <option value="PCAT">Umbrella</option>
        <option value="CA">Business Auto</option>
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
      </div>
    </div>
  </div>
  <ng-container *ngIf="userForm.get('lob').value === 'CA'">
    <div class="mt-4 flex items-center justify-between flex-wrap">
      <label for="">Is your Business Auto policy under a company name or an individual's name?</label>
      <div class="inline-block relative w-full mt-2">
        <select class="es-textfield appearance-none w-full" (change)="onBusinessTypeSelected()"
          formControlName="businessName">
          <option value="%%">Select</option>
          <option value="I">Individual's Name</option>
          <option value="C">Company Name</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
        </div>
      </div>
    </div>
    <div class="es-error-field flex justify-end mt-2"
      *ngIf="!userForm.get('businessName').valid  && (userForm.get('businessName').dirty || userForm.get('businessName').touched)">
      Oops! You forgot to select whom it is under.
    </div>
  </ng-container>

  <ng-container *ngIf="userForm.get('lob').value !== 'CA' || userForm.get('businessName').value === 'I'">
    <div class="flex items-center justify-between flex-wrap mt-4">
      <label class="mb-2" for="">First Name</label>
      <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="firstName">
    </div>
    <div
      *ngIf="!userForm.get('firstName').valid  && (userForm.get('firstName').dirty || userForm.get('firstName').touched)">
      <div class="es-error-field flex justify-end mt-2" *ngIf="userForm.get('firstName').errors['required']">
        Oops! You forgot to give your first name.
      </div>
      <div class="es-error-field flex justify-end mt-2" *ngIf="userForm.get('firstName').errors['pattern']">
        Oops! We can't seem to find you. Please try that first name again.
      </div>
    </div>
    <div class="flex items-center justify-between flex-wrap mt-4">
      <label class="mb-2" for="">Last Name</label>
      <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="lastName">
    </div>
    <div
      *ngIf="!userForm.get('lastName').valid  && (userForm.get('lastName').dirty || userForm.get('lastName').touched)">
      <div class="es-error-field flex justify-end mt-2" *ngIf="userForm.get('lastName').errors['required']">
        Oops! You forgot to give your last name.
      </div>
      <div class="es-error-field flex justify-end mt-2" *ngIf="userForm.get('lastName').errors['pattern']">
        Oops! We can't seem to find you. Please try that last name again.
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="userForm.get('lob').value === 'CA' && userForm.get('businessName').value === 'C'">
    <div class="flex items-center justify-between flex-wrap mt-4">
      <label class="mb-2" for="">Company Name</label>
      <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="companyName">
    </div>
    <div
      *ngIf="!userForm.get('companyName').valid  && (userForm.get('companyName').dirty || userForm.get('companyName').touched)">
      <div class="es-error-field flex justify-end mt-2" *ngIf="userForm.get('companyName').errors['required']">
        Oops! You forgot to give us the company name.
      </div>
    </div>
  </ng-container>

  <div class="flex items-center justify-between flex-wrap mt-4">
    <label class="mb-2" for="">Policy Number</label>
    <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="policyNumber">
  </div>
  <div
    *ngIf="!userForm.get('policyNumber').valid  && (userForm.get('policyNumber').touched)">
    <div class="es-error-field flex justify-end mt-2" *ngIf="userForm.get('policyNumber').errors['required']">
      Oops! You forgot to give us your policy number.
    </div>
    <div class="es-error-field flex justify-end mt-2" *ngIf="userForm.get('policyNumber').errors['pattern']">
      Oops! We can't seem to find you. Please try that policy number again.
    </div>
  </div>

  <div class="flex items-center justify-between flex-wrap mt-4">
    <label class="mb-2" for="">Billing Zip Code</label>
    <input type="text" maxlength="5" prcinsOnlyNumber trimInput class="w-full lg:w-3/5 es-textfield prcins-mask-data" formControlName="policyZip">
  </div>
  <div
    *ngIf="!userForm.get('policyZip').valid  && (userForm.get('policyZip').touched)">
    <div class="es-error-field flex justify-end mt-2" *ngIf="userForm.get('policyZip').errors['required']">
      Oops! You forgot to give us your zip code.
    </div>
    <div class="es-error-field flex justify-end mt-2" *ngIf="userForm.get('policyZip').errors['pattern']">
      Oops! We can't seem to find you. Please try that zip code again.
    </div>
  </div>
</form>