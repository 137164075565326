<form class="mt-3" [formGroup]="cardForm">
  <div class="mt-4" *ngIf="cardForm.controls.cardHolderName">
    <div class="mb-2"><label>Card Holder Name</label></div>
    <div class="flex"><input class="es-textfield flex-grow" type="text" trimInput formControlName="cardHolderName"
        prcins-tealium-tracker="blur" prcins-tealium-event="payment_holder_name"></div>
    <prcins-error-label [control]="cardForm.get('cardHolderName')" [controlValue]="cardForm.get('cardHolderName').value"
      controlLabel="cardForm_cardHolderName" [controlTouched]="cardForm.get('cardHolderName').touched">
    </prcins-error-label>
  </div>

  <div class="mt-4" *ngIf="cardForm.controls.cardHolderEmailId">
    <div class="mb-2"><label>Email Address</label></div>
    <div class="flex"><input class="es-textfield flex-grow" type="text" trimInput formControlName="cardHolderEmailId"
        prcins-tealium-tracker="blur" prcins-tealium-event="payment_holder_email"></div>
    <prcins-error-label [control]="cardForm.get('cardHolderEmailId')"
      [controlValue]="cardForm.get('cardHolderEmailId').value" controlLabel="cardForm_cardHolderEmailId"
      [controlTouched]="cardForm.get('cardHolderEmailId').touched">
    </prcins-error-label>
  </div>

  <div class="mt-4" *ngIf="cardForm.controls.maskedCardNumber">
    <div class="mb-2"><label>Credit or Debit Card Number</label></div>
    <div class="flex"><input class="es-textfield flex-grow prcins-mask-data" [class.custom_error]="cardNumberError" type="text"
        formControlName="maskedCardNumber" (blur)="handleMaskOnBlur('maskedCardNumber', 'cardNumber', false)"
        maxlength="16" prcinsOnlyNumber (focus)="handleMaskOnFocus('maskedCardNumber', 'cardNumber')"
        prcins-tealium-tracker="blur" prcins-tealium-event="payment_card_number"  autocomplete="off"><img
        (click)="showHelpText('cardNumber')" class="ml-2 cursor-pointer" src="assets/svgs/question-round-blue.svg"
        alt=""></div>
    <prcins-error-label [control]="cardForm.get('cardNumber')" [controlValue]="cardForm.get('cardNumber').value"
      controlLabel="cardForm_cardNumber" [controlTouched]="cardForm.get('cardNumber').touched">
    </prcins-error-label>
  </div>

  <div class="flex mt-4" *ngIf="cardForm.controls.maskedCardNumber">
    <div class="relative">
      <img class="mr-4" alt="" src="assets/png/payments/visa-logo.png">
      <div *ngIf="cardType && cardType!=='Visa'" style="width:50px;height:32px;"
        class="absolute top-0 left-0 opacity-75 bg-gray-500"></div>
    </div>
    <div class="relative"><img class="mr-4" alt="" src="assets/png/payments/mastercard-logo.png">
      <div *ngIf="cardType && cardType!=='Master Card'" style="width:50px;height:32px;"
        class="absolute top-0 left-0 opacity-75 bg-gray-500"></div>
    </div>
    <div class="relative"><img class="mr-4" alt="" src="assets/png/payments/discover-logo.png">
      <div *ngIf="cardType && cardType!=='Discover'" style="width:50px;height:32px;"
        class="absolute top-0 left-0 opacity-75 bg-gray-500"></div>
    </div>
    <div class="relative"><img class="mr-4" alt="" src="assets/png/payments/amex-logo.gif">
      <div *ngIf="cardType && cardType!=='American Express'" style="width:50px;height:32px;"
        class="absolute top-0 left-0 opacity-75 bg-gray-500"></div>
    </div>
  </div>

  <div class="mt-4" *ngIf="cardForm.controls.securityCode">
    <div class="mb-2"><label>CVV Code</label></div>
    <div class="flex"><input class="es-textfield w-20 prcins-mask-data" type="text" trimInput formControlName="maskedSecurityCode"
        prcinsOnlyNumber (blur)="handleMaskOnBlur('maskedSecurityCode', 'securityCode', true)" minlength="{{cvvLength}}"
        maxlength="{{cvvLength}}" (focus)="handleMaskOnFocus('maskedSecurityCode', 'securityCode')"
        prcins-tealium-tracker="blur" prcins-tealium-event="payment_card_cvv"  autocomplete="off"><img
        (click)="showHelpText('securityCode')" class="ml-2 cursor-pointer" src="assets/svgs/question-round-blue.svg"
        alt=""></div>
    <p *ngIf="cardForm.get('securityCode').touched && !cardForm.get('securityCode').valid" class="es-error-field mt-2">
      Please enter a valid CVV code - 3 digits for Visa, MasterCard and Discover and 4 digits for American Express.</p>
  </div>

  <div class="mt-4">
    <div class="mb-2"><label>Expiration Date</label></div>
    <div class="flex items-center">
      <select class="es-textfield max-w-xs mr-4 prcins-mask-data" [class.custom_error]="invalidExpirationMonth || expirationMonthError"
        formControlName="expirationMonth" (change)="onExpiryMonthChanged()">
        <option value="%%">Month</option>
        <option *ngFor="let month of months" value="{{month.value}}">{{month.display}}</option>
      </select>
      <select class="es-textfield max-w-xs mr-4 prcins-mask-data" [class.custom_error]="invalidExpirationMonth || expirationYearError"
        formControlName="expirationYear" (change)="onExpiryYearChanged()">
        <option value="%%">Year</option>
        <option *ngFor="let year of years" value="{{year}}">{{year}}</option>
      </select>
      <img class="ml-2 cursor-pointer" (click)="showHelpText('expirationDate')"
        src="assets/svgs/question-round-blue.svg" alt="">
    </div>
    <prcins-error-label [control]="cardForm.get('expirationMonth')"
      [controlValue]="cardForm.get('expirationMonth').value" controlLabel="cardForm_expirationMonth"
      [controlTouched]="cardForm.get('expirationMonth').touched">
    </prcins-error-label>
    <prcins-error-label [control]="cardForm.get('expirationYear')" [controlValue]="cardForm.get('expirationYear').value"
      controlLabel="cardForm_expirationYear" [controlTouched]="cardForm.get('expirationYear').touched">
    </prcins-error-label>
    <p *ngIf="invalidExpirationMonth" class="mt-2 es-error-field">Expiry month and year should be greater than or same
      as current month and year.</p>
  </div>

  <div class="mt-4" *ngIf="cardForm.controls.cardZip">
    <div class="mb-2"><label>Billing Zip Code</label></div>
    <div class="flex"><input class="es-textfield prcins-mask-data" type="text" trimInput formControlName="cardZip" mask="0{5}"></div>
    <prcins-error-label [control]="cardForm.get('cardZip')" [controlValue]="cardForm.get('cardZip').value"
      controlLabel="cardForm_cardZip" [controlTouched]="cardForm.get('cardZip').touched">
    </prcins-error-label>
  </div>

  <div class="mt-4" *ngIf="cardForm.controls.email">
    <div class="mb-2"><label>Email Address</label></div>
    <div class="flex"><input class="es-textfield flex-grow" type="text" trimInput formControlName="email"></div>
    <prcins-error-label [control]="cardForm.get('email')" [controlValue]="cardForm.get('email').value"
      controlLabel="cardForm_email" [controlTouched]="cardForm.get('email').touched">
    </prcins-error-label>
  </div>

  <ng-container *ngIf="isChangePayPlan">
    <div class="mt-4 flex items-center">
      <prcins-square-checkbox [sliderValue]="cardForm.get('plan_terms').value" sliderKey="plan_terms"
        (sliderChanged)="onTermsChanged($event)">
      </prcins-square-checkbox>
      <label class="ml-2 font-whitney-semibold">
        I agree to the Automatic Credit Card <a
          href="https://www.plymouthrock.com/resource-center/manage-your-policy/eft/terms#AutoPaymentAuthAgreement"
          target="_blank" class="text-blue-600">terms and conditions</a> and I <a
          href="https://www.plymouthrock.com/internet-policy/consent-to-conduct" target="_blank"
          class="text-blue-600">Consent to Conduct Business Electronically</a>.
      </label>
    </div>
    <prcins-error-label [control]="cardForm.get('plan_terms')" [controlValue]="cardForm.get('plan_terms').value"
      controlLabel="cardForm_terms" [controlTouched]="cardForm.get('plan_terms').touched">
    </prcins-error-label>
  </ng-container>

</form>
<prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="helpFlags.cardNumber">
  <div header-left>
    <span>Card Number</span>
  </div>
  <div header-right>
    <img class="cursor-pointer" height='15px' width='15px' (click)="hideHelpText()"
      src="assets/svgs/close-hamburger.svg">
  </div>
  <div body>
    <p>Please enter your card number in this field. Do not include spaces or dashes in the number.</p>
  </div>
</prcins-es-popup>
<prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="helpFlags.securityCode">
  <div header-left>
    <span>CVV / Security Code</span>
  </div>
  <div header-right>
    <img class="cursor-pointer" height='15px' width='15px' (click)="hideHelpText()"
      src="assets/svgs/close-hamburger.svg">
  </div>
  <div body>
    <p>CVV is an anti-fraud security feature to help verify that you are in possession of your debit/credit card.<br>
    For Visa, MasterCard, and Discover, the three-digit CVV number is printed on the signature panel on the back of the
      card immediately after the account number.
      <img src="assets/svgs/back_of_card_cvv.svg"  width='100px' height='110px' alt="">
      For American Express, the four-digit CVV number is printed on the front
      of the card above the card account number.</p>
      <img src="assets/svgs/front_of_card_cvv.svg"  width='120px' height='120px' alt="">
  </div>
</prcins-es-popup>
<prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="helpFlags.expirationDate">
  <div header-left>
    <span>Expiration Date</span>
  </div>
  <div header-right>
    <img class="cursor-pointer" height='15px' width='15px' (click)="hideHelpText()"
      src="assets/svgs/close-hamburger.svg">
  </div>
  <div body>
    <p>Enter the credit card's two digit month and four digit year in this field (MM/YYYY).</p>
  </div>
</prcins-es-popup>
