import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@prcins/environments";
import { head } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class MfaService {

  constructor(private http: HttpClient) { }

  getVerification(userId, type): Observable<any> {
    return this.http.post(environment.BASE_URL + '/account/' + userId +'/verifyuser', {
      type
    });
  }

  saveChanges(verificationId, token, type, action) {
    return this.http.post(
      `${environment.BASE_URL}/mfa/saveAction?type=${type}`,
      {
        verificationId,
        token,
        action
      }
    );
  }

  login(verificationId, token, type, action, captchaToken) {
    const headers = { 'headers': { 'userid': action.userId, 'tkn': captchaToken } };
    return this.http.post(
      `${environment.AUTH_URL}/mfa/login?type=${type}`,
      {
        verificationId,
        token,
        action
      },
      headers
    );
  }

}
