<span (click)="showPopUpModal(targetId)" class="help" data-toggle="modal" [attr.data-target]="'#' + targetId">
  <img src="assets/svgs/question-round-blue.svg" />
</span>

<div class="en-popup-fixed-container fade" [attr.id]="targetId">
  <div class="en-popup-container">
    <div class="en-popup-item-container rounded">
      <div>
        <div class="en-modal-header">
          <div>Help</div>
          <div>
            <img (click)="closePopUpModal(targetId)" class="en-help-icon" src="assets/svgs/close-hamburger.svg">
          </div>
        </div>
        <div class="en-modal-body">
          <h6>{{title}}</h6>
          <p class="mt-4" [innerHTML]="text"></p>
        </div>
      </div>
    </div>
  </div>
</div>
