import { Component, OnInit, ViewContainerRef, NgZone } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { skip, filter } from "rxjs/operators";
import { USER_LOGIN } from '@prcins/constants';
import { Subscriber, Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { UtilityProviderService, LoginService } from '@prcins/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './services/session.service';
import { environment } from '@prcins/environments';
import packageJson from '../../../../package.json';

declare var dT_;

@Component({
  selector: 'prcins-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private unauthorizedSubscription: Subscription;
  private idleSubscriptions = {};
  showTimeout = false;
  countdown;
  isBuildVersionChanged = false;
  constructor(private userIdle: UserIdleService, private router: Router,
    private route: ActivatedRoute,
    private apollo: Apollo,
    private loginService: LoginService,
    private utilityProviderService: UtilityProviderService,
    public viewRef: ViewContainerRef,
    private http: HttpClient,
    private sessionService: SessionService,
    private ngZone: NgZone
  ) {
    (window as any).ngZone = this.ngZone;

    this.handleUrlHashes();
    if (typeof dT_ != 'undefined' && dT_.initAngularNg) { dT_.initAngularNg(http, HttpHeaders); }
  }

  ngOnInit(): void {
    // this.router.events.pipe(filter(routerEvent => routerEvent instanceof NavigationStart
    //   && routerEvent.url.startsWith('/eservice/mobile/redirect'))).subscribe(routerEvent => {
    //     this.route.queryParams.pipe(skip(1)).subscribe(paramMap => {
    //       this.sessionService.hydrateSession(paramMap["session"])
    //         .subscribe((sessionResponse) => {
    //           for (const key in sessionResponse) {
    //             sessionStorage.setItem(key, sessionResponse[key]);
    //           }
    //           const redirect = JSON.parse(sessionStorage.getItem("redirect"));
    //           this.router.navigate([redirect.path], { queryParams: { 'init': redirect.initialize } });
    //         },
    //           (error) => {
    //             this.router.navigate(['/eservice/login']);
    //           });
    //     });
    //   }
    //   );
    this.sessionService.invalidateCacheAfterNewDeploy();
  

    sessionStorage.setItem('baseURL', environment.BASE_URL);
    sessionStorage.setItem('pdfURL', environment.PDF_URL);
    sessionStorage.setItem('uiVersion', packageJson.version);
    this.utilityProviderService.setupBrand();

    // Resolve Display
    let display = "web";
    if (self != top) {
      display = "native";
    }
    sessionStorage.setItem("display", display);

    this.subscribeIdleWatch(sessionStorage.getItem(USER_LOGIN) === 'Y');

    this.unsubscribeAll(this.idleSubscriptions);

    this.idleSubscriptions['userLoggedIn$'] = this.loginService.userLoggedIn$.subscribe(isLoggedIn => {
      this.subscribeIdleWatch(isLoggedIn)
    });
    this.idleSubscriptions['onIdleStatusChanged$'] = this.userIdle.onIdleStatusChanged().subscribe((data) => console.log('Idle', data));
    this.idleSubscriptions['onTimerStart$'] = this.userIdle.onTimerStart().subscribe(count => {
      if (count) {
        const counter = this.userIdle.getConfigValue().timeout - count
        this.countdown = counter;
        this.showTimeout = true;
      }
    });
    this.idleSubscriptions['onTimeout$'] = this.userIdle.onTimeout().subscribe(() => {
      this.onLogout();
    });
  }

  subscribeIdleWatch(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.userIdle.startWatching();
      this.unauthorizedSubscription = this.utilityProviderService.
        unauthorized$.subscribe(isUnauthorized => {
          if (isUnauthorized) {
            this.onLogout();
          }
        });
    } else {
      this.userIdle.stopWatching();
      if (this.unauthorizedSubscription) {
        this.unauthorizedSubscription.unsubscribe();
      }
    }
  }

  onContinue() {
    this.userIdle.resetTimer();
    this.countdown = 0;
    this.showTimeout = false;
  }

  onLogout() {
    this.utilityProviderService.clearAppStorage();
    this.utilityProviderService.clearCookies();

    this.loginService.userLoggedIn$.next(false);
    this.showTimeout = false;
    this.router.navigate(['/eservice/login'], { relativeTo: this.route });
  }

  unsubscribeAll(subscriptions) {
    Object.keys(subscriptions).forEach(subscription => {
      subscriptions[subscription].unsubscribe();
    });
  }

  handleUrlHashes() {

    if (location.hash) {
      let url = location.pathname + location.hash;
      let rtl = url.replace('/#', '');
      if (rtl.indexOf('?') > 0) {
        location.href = location.href.replace('/#', '');
      } else {
        this.router.navigate([rtl]);
      }

    } else {
      return;
    }
  }

  reloadWindow() {
    window.location.reload();
  }
}
