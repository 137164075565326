import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AiEnrollmentService {

  invitationApiUrl = environment.BASE_URL + "/registration/v2/invitations";
  apiUrl = environment.BASE_URL + "/registration/v2/invitations/user/"

  constructor(private http: HttpClient, private apollo: Apollo) { }

  getInvitationDetails(id): Observable<any> {
    return this.http.get(`${this.invitationApiUrl}?id=${id}`);
  }

  completeEnrollment(enrollmentDetails) {
    return this.http.post(this.apiUrl + 'createAccount', enrollmentDetails);
  }

  aiLinkPolicy(linkPolicyRequest): Observable<any> {
    return this.http.post(this.apiUrl + 'linkPolicy', linkPolicyRequest);
  }

  aiEdocEnrollment(edocEnrollmentyRequest): Observable<any> {
    return this.http.post(this.apiUrl + 'edocEnrollment', edocEnrollmentyRequest);
  }

  abandonRequest(landingPage, id) {
    return this.http.get(this.invitationApiUrl + '/abandon/' + landingPage + '?id=' + id);
  }

}
