import gql from 'graphql-tag';

export const SecurityQuestionListQuery = gql`
  query SecurityQuestionList($username: String) {
    account(username: $username, userType: "idg_user_group") {
      profile {
        questions
      }
    }
  }
`;

export const ManageAlertsPolicyList = gql`
  query Account($username: String!) {
    account(username: $username, userType: "idg_user_group") {
      registeredPolicies {
        policyNumber
        policyInfo {
          lob
          state
          productCode
        }
        inquiry {
          nameInsured {
            email
          }
        }
      }
    }
  }
`;

export const ManageRemainderList = gql`
  query Account($username: String!) {
    account(username: $username, userType: "idg_user_group") {
      profile {
        registeredPolicies {
          policyNumber
          lob
          state
          remaindersFlag
        }
      }
    }
  }
`;

export const ManageAlertsQuery = gql`
  query ManageAlerts($policyNumber: String) {
    manageAlerts(policyNumber: $policyNumber) {
      preferences
      qs
      isPrime
      exactTargetCompanyName
      exactTargetCorporationName
    }
  }
`;
