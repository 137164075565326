import { environment } from '@prcins/environments';

export const ADDRESS_URL = {
  Validate_Address: `${environment.BASE_URL}/common/address/validateAddress`,
  Update_Address: `${environment.BASE_URL}/endorsement/changeaddress`,
  Update_Address_Legacy: `${environment.BASE_URL}/common/forms/changeaddress`,
  Endorse_address : `${environment.BASE_URL}/v2/core`,
  endorse_Url : `${environment.BASE_URL}/v2/`,
  Zip2State: `${environment.BASE_URL}/common/address/zip2state`,
  Print: `${environment.BASE_URL}/common/forms/pdfchangeaddress`,
  current_location_address: `${environment.BASE_URL}/common/address/currentLocation`
};

export const CLAIMS_URL = {
  Claims_Talk: `${environment.BASE_URL}/common/claims/getclaimstalksurl`,
  Claims_Token: `${environment.BASE_URL}/claims/token`
};

export const EDOCS_URL = {
  Signup: `${environment.BASE_URL}/api/policy/edocs`,
  SavePreference: `${environment.BASE_URL}/public/api/extedocs`
};

export const PAYMENT_URL = {
  ValidateCard: `${environment.BASE_URL}/payment/validatecard`,
  Secure: `${environment.BASE_URL}/account/secure`,
  Payment: `${environment.BASE_URL}/payment`,
  Decrypt: `${environment.BASE_URL}/account/decode`,
  TokenLoad: `${environment.BASE_URL}/account/reload/user`,
  ManagePayment: `${environment.BASE_URL}/manage-payment`,
  checkPendingPayments: `${environment.BASE_URL}/payment/checkAnyPendingPayments`
};

export const REGISTRATION_URL = {
  Enrollment: `${
    environment.BASE_URL
  }/notification/qpay/check/enrollment`,
  MobileVerification: `${
    environment.BASE_URL
  }/notification/qpay/send/verification`,
  MobileSecurityCodeVerification: `${
    environment.BASE_URL
  }/notification/qpay/verify`,
  TextAlertDetails: `${
    environment.BASE_URL
  }/notification/textalerts`,
  TextAlertAddUpdate: `${
    environment.BASE_URL
  }/notification/enroll-notifications`,
  TextAlertOTPVerify: `${
    environment.BASE_URL
  }/notification/verify/otp`,
  TextAlertSendOtpVerification: `${
    environment.BASE_URL
  }/notification/send-otp/verification`
};

export const ACCOUNTS_URL = {
  ChangeEmail: `${environment.BASE_URL}/account/changeemail`,
  ChangePassword: `${environment.BASE_URL}/account/changepassword`,
  ChangeSecurityQuestions: `${environment.BASE_URL}/account/changequestions`,
  EmailSupport: `${environment.BASE_URL}/account/emailForm`,
  ScheduledReminder: `${environment.BASE_URL}/account/changereminders`,
  DeletePolicy: `${environment.BASE_URL}/account/deletepolicy`,
  alertPreferences: `${environment.BASE_URL}/public/api/alerts/preferences`
};

export const ADMIN_URL = {
  disableAccount: `${environment.BASE_URL}/account/disableaccount`,
  validateSuperAdmin: `${environment.BASE_URL}/admin/role`

};

export const DOCS_URL = {
  validateDecPage: `${environment.BASE_URL}/docs/validate/declaration`,
  downloadDeclaration: `${environment.PDF_URL}/docs/imageright/declaration`,
  requestMobile: `${environment.PDF_URL}/docs/mobileidcard`,
  downloadMobile: `${environment.PDF_URL}/docs/mobileidcard/pdf`
};

export const POLICY_URL = {
  addPolicy: `${environment.BASE_URL}/account/addpolicy`,
  policyPreferences: `${environment.BASE_URL}/account/communicationPreferences`
};

export const GENERAL_URL = {
  faq: `https://www.plymouthrock.com/resource-center/account-faqs`,
  resource: `https://www.plymouthrock.com/resources`,
  glossary: `https://www.plymouthrock.com/resources/glossary`,
  buyersGuide: `https://www.plymouthrock.com/plymouthrock/esalesPdfs/Buyers_Guide.pdf`,
  billOfRights: `https://www.plymouthrock.com/plymouthrock/esalesPdfs/BOR.pdf`,
  urlFooter: {
    privacy: 'https://www.plymouthrock.com/privacy-policy',
    terms: 'https://www.plymouthrock.com/internet-policy',
    terms_edoc: 'https://www.plymouthrock.com/utility/terms-eDocs-popup',
    fb: `https://www.facebook.com/PlymouthRockAssurance/`,
    twitter: `https://twitter.com/plymouthrock`,
    insta: `https://www.instagram.com/plymouthrockassurance/`,
    blog: `https://www.plymouthrock.com/blog`
  }

};


export const ROADREWARD_URL = {
  DriverDetails: `${
    environment.BASE_URL
  }/common/roadrewards/drivers`,
  };
