import { Component, Input, OnChanges, OnInit, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IPolicy, PolicyBroadcasterService, RightRailService } from '@prcins/utils';
import { IBillingDetails } from '../models/billing-details';
import { PolicyProviderService } from '../services/policy-provider.service';
import { CURRENT_POLICY_LOB, CURRENT_POLICY_PAY_PLAN, CURRENT_POLICY_PRODUCT_CODE, USER_ID, SCHEDULED_PAY_PLANS, CURRENT_POLICY_STATUS } from '@prcins/constants';
import { map } from 'rxjs/operators';

@Component({
  selector: 'prcins-billing-overview',
  templateUrl: './billing-overview.component.html',
  styleUrls: ['./billing-overview.component.css']
})
export class BillingOverviewComponent implements OnChanges, AfterViewInit {

  @Input() policy: IPolicy;
  @Input() noic :any;
  billingDetails$: Observable<IBillingDetails>;
  isCPPAllowed = false;
  lob = sessionStorage.getItem(CURRENT_POLICY_LOB);
  productCode = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE);
  policyStatus$: Observable<any>;
  policyIssuance$: Observable<any>;
  showBalaceDeatilsLink :boolean;
  balanceDetails: any;
  eligibleForTXAlertsEnrollment =false;
  isValidLob = false;
  onSheduledPayPlan: boolean;
  showPaymentSection: boolean = false;
  paymentDetails: any;
  nextPaymentDueDate: any;
  nextPaymentAmntDue: any;
  payPlan: any;


  constructor(private billingProvider: PolicyProviderService,
    private policyBroadcaster: PolicyBroadcasterService,
    private rightRailService: RightRailService) { }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this.billingDetails$ = this.billingProvider.getBillingDetails(
      this.policy.policyNumber
    );
    this.billingProvider.balanceDetails(this.policy.policyNumber).subscribe(data => {
      
      this.balanceDetails = data["data"]["balanceDetails"];
      this.lob = sessionStorage.getItem(CURRENT_POLICY_LOB);
      this.productCode = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE);

      if((this.balanceDetails["currentTermBalance"] && this.balanceDetails["pastTermBalance"]) || (this.balanceDetails["currentTermBalance"] && this.balanceDetails["futureTermBalance"])){
        this.showBalaceDeatilsLink = true;
      }

    });
    this.rightRailService.getQuickLinks(this.policy.policyNumber).subscribe(data => this.isCPPAllowed = data.showPayPlanChange);
    this.billingDetails$.subscribe(billingData => {
      this.policyBroadcaster.broadcastBillingLoaded(billingData);
      this.onSheduledPayPlan = SCHEDULED_PAY_PLANS.includes(billingData.payplan);
      sessionStorage.setItem(CURRENT_POLICY_PAY_PLAN, billingData.payplan);
      this.payPlan = billingData.payplan;
      this.nextPaymentDueDate = billingData.nextPaymentDueDt;
      this.nextPaymentAmntDue = billingData.nextPaymentAmnt;
      this.paymentDetails={
        "amountDue":this.nextPaymentAmntDue,
        "paymentDueDt":this.noic && this.noic.data.cancellationDate? this.noic.data.cancellationDate : this.nextPaymentDueDate,
        "landingPage":true,
        "lastPaymentDate":billingData.lastPaymentDt,
        "pendingCancellation":this.noic && this.noic.data.cancellationDate
      }
      sessionStorage.setItem("paymentDetails", JSON.stringify(this.paymentDetails));
    });
    this.policyStatus$ = this.billingProvider.getPolicyGreetingDetails(this.policy.policyNumber).pipe(
      map(data => {
        sessionStorage.setItem(CURRENT_POLICY_STATUS, data.statusCode);
        return { inactive: data.statusCode === 'Expired' || data.statusCode === 'Cancelled' }
      }));
    this.policyIssuance$ = this.billingProvider.getPolicyInquiry(this.policy.policyNumber).pipe(map(data => {
      let issuedDay = new Date(data.policyDetails.product.issuedDate);
      issuedDay.setDate(issuedDay.getDate() + 1);
      return { isNewPolicy: new Date() <= issuedDay };
    }));

    let inValidStatus = ["cancel","canceled", "cancelled", "nonRenewed","expired"];
    this.billingProvider.getPayloadForBillingAlerts(this.policy.policyNumber).subscribe( (resp: any) => {
        const {
          statusCode
        } = resp.policySearchInfo;
        const payplan = resp.billingDetails.payplan?resp.billingDetails.payplan:'';
        const lobCode = sessionStorage.getItem(CURRENT_POLICY_LOB)?sessionStorage.getItem(CURRENT_POLICY_LOB):'';
        const status = statusCode?statusCode:'';
        const productCode = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE)?sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE):'';
        const validPayPlan = !(payplan === '') && !payplan.includes('PAYROLL') && !payplan.includes('PREMFIN') && !payplan.includes('MORTGAGEE');
        const validPolicyStatus = !(status === '') && inValidStatus.indexOf(status.toLowerCase()) < 0;
        const validProduct = productCode === 'ALN_HO' || productCode === 'ALN_PA' || productCode === 'ALN_MC';
        this.isValidLob = lobCode === 'HO' || lobCode === 'PA' || lobCode === 'MC';
        this.eligibleForTXAlertsEnrollment = validPayPlan && validPolicyStatus && this.isValidLob && validProduct;
    });
  }

  ngAfterViewInit(): void {

  }

  getBillingCategory() {


  }

  showLastPaymentsSection(event){
       this.showPaymentSection=!event;
  }

}
