import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { routingValidatorFn, _ACHAccountValidatorFn, _ACHRoutingValidatorFn } from '../validators/payment-form.validators';

@Injectable({
  providedIn: 'root'
})
export class BankFormProviderService {
  constructor(private fb: FormBuilder) {}

  getForm() {
    return this.fb.group(
      {
        accountType: ['', [Validators.required]],
        accountNumber: ['', [Validators.required, Validators.minLength(4), Validators.pattern(/^-?([0-9]\d*)?$/), _ACHAccountValidatorFn()]],
        maskedAccountNumber: ['', [Validators.required, Validators.minLength(4), _ACHAccountValidatorFn()]],
        reAccountNumber: ['', [Validators.required, Validators.minLength(4)]],
        maskedReAccountNumber: ['', [Validators.required, Validators.minLength(4)]],
        routingNumber: [
          '',
          [Validators.required, Validators.minLength(9), routingValidatorFn(), _ACHRoutingValidatorFn()]
        ],
        maskedRoutingNumber: ['', [Validators.required, _ACHRoutingValidatorFn()]]
      }
    );
  }
}
