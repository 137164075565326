import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[prcinsOnlyNumber]'
})
export class OnlyNumberDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event ;
    if (
      [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      //(e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      //(e.keyCode == 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      // (e.keyCode == 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      ((e.keyCode >= 35 && e.keyCode <= 39) ||
      (e.keyCode >= 48 && e.keyCode <= 57) || 
      (e.keyCode >= 96 && e.keyCode <= 105)) && 
      !e.shiftKey
    ) {
      // let it happen, don't do anything
      return;
    } else{
      e.preventDefault();      
    }
  }
}
