<prcins-es-popup *ngIf="display">
    <ng-container body>
        <div class="text-center">
            <span class="es-h1">Oops, it’s us, not you!</span>
        </div>
        <div class="mt-4 text-sm">
            <span class="justify-center">We're unable to process your policy change right now. Please call <a
                    [href]="'tel:'+csPhone" class="text-es-blue">{{csPhone|telephone}}</a> and
                someone will be happy to assist you.</span>
        </div>
        <div class="flex justify-between mt-4 flex-wrap-reverse items-center">
            <button class="mt-4 es-btn-primary w-full lg:w-40 text-center" (click)="hidePopup()"
                type="button">Close</button>
        </div>
    </ng-container>
</prcins-es-popup>
