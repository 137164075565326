import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'prcins-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit{

  showAppDownloadBlocker = false;
  iosAppDownloadLink = "https://apps.apple.com/us/app/plymouth-rock-assurance/id632820710";
  androidAppDownloadLink = "https://play.google.com/store/apps/details?id=com.prac.eservice";

  constructor() {
  }

  ngOnInit() {
    if(window.location !== window.parent.location) {
      this.showAppDownloadBlocker = true;
    }
  }

  getPlatform() {
    var platform = ["Win32", "Android", "iOS"];

    for (var i = 0; i < platform.length; i++) {

        if (navigator.platform.indexOf(platform[i]) >- 1) {

            return platform[i];
        }
    }
  }


}
