<ng-container *ngIf="isHome; else selectedPolicy">
  <div (click)="togglePolicyDropdown('type1')"
    class="bg-es-twilight-blue text-white cursor-pointer flex items-center justify-between p-4">
    <span class="flex">
      <img class="pr-2" src="assets/svgs/policy-switch-icon.svg" alt="">
      Switch Policy
    </span>
    <img class="" *ngIf="!showPolicies; else policyDropdown" src="assets/svgs/down-icon.svg" alt="">
    <ng-template #policyDropdown>
      <img class="" src="assets/svgs/up-icon.svg" alt="">
    </ng-template>
  </div>
</ng-container>
<ng-template #selectedPolicy>
  <div class="relative flex flex-col items-center">
    <div class="bg-es-twilight-blue w-full h-12"></div>
    <div class="selected-policy bg-white w-11/12 flex justify-center items-center py-3 rounded border"
      (click)="togglePolicyDropdown('type2')">
      <prcins-lob [account]="currentSelectedPolicy">
        <span PA class="flex items-center">
          <img class="mr-2" src="assets/svgs/car-icon.svg" alt="">
        </span>
        <span HO class="flex items-center">
          <img class="mr-2 lob-selector-icon" src="assets/svgs/home-blue-icon.svg" alt="">
        </span>
        <span MC class="flex items-center">
          <img class="mr-2 lob-selector-icon"  src="assets/png/motor-blue.png" alt="" width="50" height="45">
        </span>
        <span PCAT class="flex items-center">
          <img class="mr-2 lob-selector-icon" src="assets/png/blue umbrella icon.png" alt="">
        </span>
        <span CA class="flex items-center">
          <img class="mr-2 lob-selector-icon" src="assets/png/blue truck icon.png" alt="">
        </span>
      </prcins-lob>

      <span class="ml-2 font-bold"> Policy #: {{currentSelectedPolicy?.policyNumber}}</span>
      <div *ngIf="!noSelector"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
      </div>
    </div>
    <div class="w-full h-8"></div>
  </div>
</ng-template>
<ng-container *ngIf="!noSelector">
  <div class="policy-selector bg-es-twilight-blue text-white cursor-pointer" [class.type2]="type2Selector"
    *ngIf="showPolicies">
    <div class="p-2 border-b flex" [class.active_policy]="policy.policyNumber === currentSelectedPolicy.policyNumber"
      *ngFor="let policy of registeredPolicies" (click)="onPolicySelected(policy)">
      <prcins-lob [account]="policy">
        <span PA class="ml-2 flex items-center">
          <img class="mr-4" src="assets/svgs/car-white-icon.svg" alt="">
          Auto
        </span>
        <span MC class="ml-2 flex items-center">
          <img class="mr-2" src="assets/png/motor.png" alt="" width="30" height="30">
          Motorcycle
        </span>
        <span HO class="ml-2 flex items-center">
          <img class="mr-4" src="assets/svgs/home-white-icon.svg" alt="">
          Home
        </span>
        <span PCAT class="ml-2 flex items-center">
          <img class="mr-2 lob-icon" src="assets/png/white umbrella icon.png" alt="">
          Umbrella
        </span>
        <span CA class="ml-2 flex items-center">
          <img class="mr-2 lob-icon" src="assets/png/white truck icon.png" alt="">
          Business Auto
        </span>
      </prcins-lob>
      <span class="ml-2">{{policy.policyNumber}}</span>
    </div>
    <div class="p-2 border-b flex" (click)="linkPolicy()">
      <span class="ml-2 flex">
        <img class="mr-4" src="assets/svgs/plus-icon.svg" alt="">
        Link a Policy
      </span>
    </div>
  </div>
</ng-container>