import { Component, OnInit, Input } from '@angular/core';
import { BRAND, Brand, CURRENT_POLICY, USER_ID } from '@prcins/constants';
import { AccountsProviderService } from '../services/accounts-provider.service';
import { AlertPreferences } from '@prcins/utils';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'prcins-alert-preferences',
  templateUrl: './manage-alert-preferences.component.html',
  styleUrls: ['./manage-alert-preferences.component.css']
})
export class ManageAlertPreferencesComponent implements OnInit {
  policyAlerts: AlertPreferences = { email: null, policyBillingClaimsOptin: 'Y', weatherSafetyOptin: 'Y', newsletterOptin: 'Y' };
  policyNumber: string;
  policyEmail: string;
  errorMessage: string;
  billingAlertOption: boolean;
  weatherSafetyOption: boolean;
  newsLetterOption: boolean;
  isLoading = false;
  noEmailExist = false;
  submitSuccess = false;
  submitError = false;
  fetchPolicyError = false;
  showSpinner = false;
  showSuccessMessage = false;
  showErrorMessage = false;
  showAlertSection = false;
  showTermsAndConditions = false;
  brand = localStorage.getItem(BRAND);
  mainPage = Brand[this.brand].urlEnterpriseWeb;
  constructor(private accountProvider: AccountsProviderService, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    // this.policyNumber = sessionStorage.getItem(CURRENT_POLICY);
    let { userId } = this.route.snapshot.params;
    this.policyAlerts.email = userId;
    this.policyEmail = userId;
    this.billingAlertOption = false;
    this.weatherSafetyOption = false;
    this.newsLetterOption = false;
    this.isLoading = true;

    this.showSuccessMessage = false;
    this.showErrorMessage = false;

    this.accountProvider.getAlertPreferences(this.policyEmail).pipe(take(1)).subscribe(
      (policyAlerts) => {
        if (policyAlerts) {
          this.processPolicyAlertInfo(policyAlerts);
        } else {
          this.fetchPolicyError = true;
          this.isLoading = false;
          this.showTermsAndConditions = false;
          // setTimeout(() => {
          //   this.fetchPolicyError = false;
          // }, 10000);
        }
      },
      error => {
        console.log(error.message);
        this.fetchPolicyError = true;
        this.isLoading = false;
        this.showTermsAndConditions = false;
        // setTimeout(() => {
        //   this.fetchPolicyError = false;
        // }, 10000);
      }
    );

  }

  processPolicyAlertInfo(policyAlerts) {
    if (policyAlerts !== '' && policyAlerts !== undefined && policyAlerts.email !== null) {
      this.policyAlerts = policyAlerts;
      this.billingAlertOption = this.policyAlerts.policyBillingClaimsOptin.indexOf('Y') !== -1 ? true : false;
      this.weatherSafetyOption = this.policyAlerts.weatherSafetyOptin.indexOf('Y') !== -1 ? true : false;
      this.newsLetterOption = this.policyAlerts.newsletterOptin.indexOf('Y') !== -1 ? true : false;
      this.isLoading = false;
      this.showTermsAndConditions = true;
      this.showAlertSection = true;
    } else {
      this.isLoading = false;
      this.showTermsAndConditions = true;
      this.showAlertSection = true;
      // this.noEmailExist = true;
      this.fetchPolicyError = false;
    }

    this.billingAlertOption = this.policyAlerts.policyBillingClaimsOptin.indexOf('Y') !== -1 ? true : false;
    this.weatherSafetyOption = this.policyAlerts.weatherSafetyOptin.indexOf('Y') !== -1 ? true : false;
    this.newsLetterOption = this.policyAlerts.newsletterOptin.indexOf('Y') !== -1 ? true : false;

  }

  alertPreferenceChanged(item) {
    const { key, value } = item;

    if (key === 'billingAlertOptionKey' && value === true) {
      this.policyAlerts.policyBillingClaimsOptin = 'Y';
    } else if (key === 'billingAlertOptionKey' && value === false) {
      this.policyAlerts.policyBillingClaimsOptin = 'N';
    }

    if (key === 'weatherAlertOptionKey' && value === true) {
      this.policyAlerts.weatherSafetyOptin = 'Y';
    } else if (key === 'weatherAlertOptionKey' && value === false) {
      this.policyAlerts.weatherSafetyOptin = 'N';
    }

    if (key === 'newsletterAlertOptionKey' && value === true) {
      this.policyAlerts.newsletterOptin = 'Y';
    } else if (key === 'newsletterAlertOptionKey' && value === false) {
      this.policyAlerts.newsletterOptin = 'N';
    }
  }

  onSaveAlertPreference() {

    const { email, policyBillingClaimsOptin, weatherSafetyOptin, newsletterOptin } = this.policyAlerts;
    //alert(email+'-'+policyBillingClaimsOptin+'-'+weatherSafetyOptin+'-'+newsletterOptin);

    let alertPreferencePayload = {
      "email": email,
      "policyBillingClaimsOptin": policyBillingClaimsOptin,
      "weatherSafetyOptin": weatherSafetyOptin,
      "newsletterOptin": newsletterOptin
    }

    this.accountProvider.saveAlertPreference(alertPreferencePayload).pipe(take(1)).subscribe(
      (response) => {
        this.showSpinner = false;
        if (response.email !== null && response.email !== '' && response.email !== undefined) {
          this.showAlertSection = true;
          this.submitSuccess = true;
          window.scrollTo(0, 0);
          // setTimeout(() => {
          //   this.submitSuccess = false;
          // }, 10000);
        } else {
          this.submitError = true;
          // setTimeout(() => {
          //   this.submitError = false;
          // }, 10000);
        }
      },
      error => {
        this.errorMessage = error.message;
        console.log(error.message);
        this.submitError = true;
        // setTimeout(() => {
        //   this.submitError = false;
        // }, 10000);
      }
    );
  }



}
