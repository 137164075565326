import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@prcins/environments";

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  constructor(private http: HttpClient) { }

  get(flag): Observable<any> {
    return this.http.get(environment.BASE_URL + '/mfa/login-feature-flag/' + flag);
  }
}
