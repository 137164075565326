import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'prcins-road-rewards-banner',
  templateUrl: './road-rewards-banner.component.html',
  styleUrls: ['./road-rewards-banner.component.css']
})
export class RoadRewardsBannerComponent implements OnInit {

  @Output() enrollRoadRewards: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  openRRPage(){
    this.enrollRoadRewards.emit();
  }

}
