<div>
  
    <h1 class="es-h1 mt-4">Confirmation</h1>
    <div class="flex items-center mb-4 p-4 border rounded border-gray-300 bg-es-sky-blue">
      <span class="mr-4">
        <img style="width:26px;" src="assets/png/check-icon.png">
      </span>
      <ng-container *ngIf="paymentId.length>0" > 
        <span>
          <div>Thank you for your payment in the amount of
            <strong>{{paymentAmount | currency:'USD':'symbol-narrow':'1.2-2'}}</strong></div>
          <div class="mt-4">Your confirmation number is <strong>{{paymentId}}</strong></div>
        </span>
      </ng-container>
    </div>
    <div class="flex items-center mb-4 p-4 border rounded border-gray-300 bg-es-sky-blue" *ngIf="savedWallet && !saveWalletError">
      <span class="mr-4">
        <img style="width:26px;" src="assets/png/check-icon.png">
      </span>
      <ng-container> 
        <span>
          <div>Your payment method, <strong>{{savedWallet}}</strong>, has been saved successfullly.</div>
        </span>
      </ng-container>
    </div>
    <prcins-danger *ngIf="saveWalletError">
      Your payment was successful.
      <div>We were unable to save this payment method.</div>
      <!-- <div *ngIf="isEService">We were unable to save this payment method, <a [routerLink]="['../manage']"
        prcins-tealium-tracker="click" prcins-tealium-event="method_payment_not_saved" class='cursor-pointer font-bold text-es-blue-light'>please try again.</a></div> -->
    </prcins-danger>
    <div class="mt-4">
      You will receive an email confirmation of this transaction. If you have questions about your payment, please
      contact Customer Solutions at {{helpPhone}}.
    </div>
    <div class="mt-4">
      Although your account is credited on the date you make your payment, payments submitted after 5:00 pm will not be
      processed until the following business day. For all payments, it may take up to two business days for you to see
      the payment post to your account.
    </div>
    <div *ngIf="showIntermediateRegisterationForm">
      <prcins-quick-pay-registration-header [headerText]="'With an account, you can easily access all of your policy details and make payment easier next time with saved payment methods!'" ></prcins-quick-pay-registration-header>
      <prcins-intermediate-registeration-form [registrationEmailAddress]="registrationEmailAddress" [showBillingAlertEnrollment]="true" (submitted)="onRegistrationSubmitted($event)"></prcins-intermediate-registeration-form>
    </div>
    <div class="mt-4">
      A payment is considered 'received' by Plymouth Rock if and when it is honored by the payor's bank or Credit Card
      Company.
      If the payment is not so honored and a notice of cancellation had previously been issued, then any Rescission or
      Reinstatement Notice that was issued in reliance on the payment will be void and the policy will be cancelled in
      accordance with the
      original cancellation notice.
    </div>
    <div class="flex justify-center pb-8" *ngIf="!showIntermediateRegisterationForm">
      <button class="es-btn-secondary w-full mt-4 sm:w-3/4" type="button" (click)="printPage()">Print</button>
    </div>
    <prcins-cross-sell-promotion-widget *ngIf="showHOCrossSellPromotion" [pageType]="'Payment_Confirmation'"></prcins-cross-sell-promotion-widget>
    <prcins-mobile-registration *ngIf="showMobileRegistration && !showIntermediateRegisterationForm"></prcins-mobile-registration>
  
</div>
