import { TealiumUtagService } from './utag.service';
import { environment } from '@prcins/environments';
import * as i0 from "@angular/core";
import * as i1 from "./utag.service";
export class TealiumTrackerService {
    constructor(uTagService) {
        this.uTagService = uTagService;
        this.uTagService.setConfig({
            account: 'plymouth',
            profile: 'eservice',
            environment: environment.production ? 'prod' : 'qa'
        });
    }
    track(tealium_event, data) {
        this.uTagService.track(tealium_event, data);
    }
    view(data) {
        this.uTagService.track('view', data);
    }
    link(data) {
        this.uTagService.track('link', data);
    }
}
TealiumTrackerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TealiumTrackerService_Factory() { return new TealiumTrackerService(i0.ɵɵinject(i1.TealiumUtagService)); }, token: TealiumTrackerService, providedIn: "root" });
