import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class EservicePromotionBroadcasterService {
    constructor(router, route) {
        this.router = router;
        this.route = route;
        this.showingEservicePromotion = new Subject();
        this.showingEservicePromotion$ = this.showingEservicePromotion.asObservable();
    }
    broadcastPromotion(isShowing) {
        this.showingEservicePromotion.next(isShowing);
    }
}
EservicePromotionBroadcasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EservicePromotionBroadcasterService_Factory() { return new EservicePromotionBroadcasterService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute)); }, token: EservicePromotionBroadcasterService, providedIn: "root" });
