/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../utils/src/lib/danger/danger.component.ngfactory";
import * as i3 from "../../../../utils/src/lib/danger/danger.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../utils/src/lib/spinner/spinner.component.ngfactory";
import * as i6 from "../../../../utils/src/lib/spinner/spinner.component";
import * as i7 from "@angular/router";
import * as i8 from "../../../../utils/src/lib/register-user-form/register-user-form.component.ngfactory";
import * as i9 from "../../../../utils/src/lib/register-user-form/register-user-form.component";
import * as i10 from "./register.component";
import * as i11 from "../../../../utils/src/lib/services/login.service";
import * as i12 from "../../../../utils/src/lib/services/form-validator.service";
import * as i13 from "../../../../utils/src/lib/services/utility-provider.service";
import * as i14 from "../../../../utils/src/lib/services/registration.service";
var styles_RegisterComponent = [i0.styles];
var RenderType_RegisterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterComponent, data: {} });
export { RenderType_RegisterComponent as RenderType_RegisterComponent };
function View_RegisterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_RegisterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Oops! Did you forget you already signed up? If you "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "underline font-bold text-es-blue-light"], ["href", "/eservice/login"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["forgot your password"])), (_l()(), i1.ɵted(-1, null, [", don't sweat it! Or "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "underline font-bold text-es-blue-light"], ["href", "https://www.plymouthrock.com/contact-us"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["give us a call"])), (_l()(), i1.ɵted(-1, null, [". "]))], null, null); }
function View_RegisterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "prcins-danger", [], null, null, null, i2.View_DangerComponent_0, i2.RenderType_DangerComponent)), i1.ɵdid(1, 4243456, null, 0, i3.DangerComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RegisterComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RegisterComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.alreadyRegisterError; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.alreadyRegisterError; _ck(_v, 5, 0, currVal_1); }, null); }
function View_RegisterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-spinner", [], null, null, null, i5.View_SpinnerComponent_0, i5.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i6.SpinnerComponent, [], null, null)], null, null); }
export function View_RegisterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "desktop-only-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "main", [["class", "px-8 pb-8 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create an Account"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["First, let\u2019s find your policy. If you have more than one policy with us and already have a registered account,"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [["class", "ml-2 text-es-blue-light cursor-pointer"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 1), (_l()(), i1.ɵted(-1, null, ["click here"])), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "prcins-register-user-form", [], null, null, null, i8.View_RegisterUserFormComponent_0, i8.RenderType_RegisterUserFormComponent)), i1.ɵdid(15, 114688, null, 0, i9.RegisterUserFormComponent, [], { userForm: [0, "userForm"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterComponent_4)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 8, "div", [["class", "flex flex-wrap-reverse justify-center lg:justify-between items-center px-8 lg:px-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 5, "span", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Already have an account? "])), (_l()(), i1.ɵeld(21, 0, null, null, 3, "a", [["class", "ml-2 text-es-blue-light"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(23, 1), (_l()(), i1.ɵted(-1, null, ["Sign in"])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "es-btn-primary mb-2 lg:w-64"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRegister() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.registerError; _ck(_v, 5, 0, currVal_0); var currVal_3 = _ck(_v, 11, 0, "../../link-policies"); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.registrationForm; _ck(_v, 15, 0, currVal_4); var currVal_5 = _co.showSpinner; _ck(_v, 17, 0, currVal_5); var currVal_8 = _ck(_v, 23, 0, "../../login"); _ck(_v, 22, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 10).target; var currVal_2 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_1, currVal_2); var currVal_6 = i1.ɵnov(_v, 22).target; var currVal_7 = i1.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_6, currVal_7); }); }
export function View_RegisterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-register", [], null, null, null, View_RegisterComponent_0, RenderType_RegisterComponent)), i1.ɵdid(1, 114688, null, 0, i10.RegisterComponent, [i11.LoginService, i7.Router, i7.ActivatedRoute, i12.FormValidatorService, i13.UtilityProviderService, i14.RegistrationService, i11.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterComponentNgFactory = i1.ɵccf("prcins-register", i10.RegisterComponent, View_RegisterComponent_Host_0, {}, {}, []);
export { RegisterComponentNgFactory as RegisterComponentNgFactory };
