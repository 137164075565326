<div prcins-tealium-tracker="load" prcins-tealium-event="payment_review">
  <h1 class="es-h1 mt-4">Review</h1>

  <prcins-danger *ngIf="isReviewError">  
    {{reviewErrorMessage}}
    <div *ngIf="paymentDetails.saveThisCard"> This card will not be saved if you selected to save it for future use.</div>
    <div *ngIf="paymentDetails.saveThisBank"> This bank account will not be saved if you selected to save it for future use.</div>
    <span  *ngIf="reviewErrorMessage !== 'Unable to submit.  This is a duplicate payment.'" prcins-tealium-tracker="load" prcins-tealium-event="payment_failed"></span>
  </prcins-danger>

  <ng-container *ngIf="isReviewLoaded && !isConfirmInProgress; else loading">
    <p class="pb-4 border border-t-0 border-l-0 border-r-0 border-b-1">Please review your payment information. If you
      would like to change it, click the "Edit Info" button below.</p>
    <div class="mt-4 flex flex-col mt-2" *ngIf="paymentDetails?.paymentAmount">
      <span class="font-bold">Payment Amount</span>
      <span>{{paymentDetails?.paymentAmount | currency}}</span>
    </div>
    <ng-container *ngIf="paymentDetails?.paymentOptionSelected==='eft'; else cardContent" prcins-tealium-tracker="load"
      [prcins-tealium-evar]="{ 'account_type': paymentDetails?.eftForm.accountType === 'C' ? 'Checking': 'Savings' }">
      <div class="mt-4 flex flex-col mt-2">
        <span class="font-bold">Payment Method</span>
        <span>Bank Account</span>
      </div>
      <div class="mt-4 flex flex-col mt-2">
        <span class="font-bold">Account Type</span>
        <span *ngIf="!isSavedPaymentMethod">{{paymentDetails?.eftForm.accountType === "C" ? 'Checking Account': 'Savings Account'}}</span>
        <span *ngIf="!!isSavedPaymentMethod">{{paymentDetails?.savedPaymentMethod.transType === "CHECKING" ? 'Checking Account': 'Savings Account'}}</span>
      </div>
      <div class="mt-4 flex flex-col mt-2">
        <span class="font-bold">Account Number</span>
        <span *ngIf="!isSavedPaymentMethod">{{paymentDetails?.eftForm.accountNumber | secureMask:'1111'}}</span>
        <span *ngIf="!!isSavedPaymentMethod">*****{{paymentDetails?.savedPaymentMethod.debitAccount}}</span>
      </div>
      <div class="mt-4 flex flex-col mt-2">
        <span class="font-bold">Routing Number</span>
        <span *ngIf="!isSavedPaymentMethod">{{paymentDetails?.eftForm.routingNumber | secureMask:'1111'}}</span>
        <span *ngIf="!!isSavedPaymentMethod">{{paymentDetails?.savedPaymentMethod.debitRouting | secureMask:'1111'}}</span>
      </div>
    </ng-container>

    <ng-template #cardContent prcins-tealium-tracker="load"
      [prcins-tealium-evar]="{ 'payment_method': 'Credit / Debit Card' }">
      <div class="mt-4 flex flex-col mt-2">
        <span class="font-bold">Payment Method</span>
        <span>Credit / Debit Card</span>
        <ng-container prcins-tealium-tracker="load"
          [prcins-tealium-evar]="{ 'transaction_type': paymentDetails?.cardForm?.cardType }"></ng-container>
      </div>
      <div *ngIf="!isSavedPaymentMethod" class="mt-4 flex flex-col mt-2">
        <span class="font-bold">Card Holder Name</span>
        <span>{{paymentDetails?.cardForm.cardHolderName}}</span>
      </div>
      <div class="mt-4 flex flex-col mt-2">
        <span class="font-bold">Card Number</span>
        <span *ngIf="!isSavedPaymentMethod" >{{(paymentDetails?.cardForm.cardNumber)| secureMask:'1111'}}</span>
        <span *ngIf="!!isSavedPaymentMethod" >*****{{(paymentDetails.savedPaymentMethod.debitAccount)}}</span>
      </div>
      <div class="mt-4 flex flex-col mt-2">
        <span class="font-bold">Expiration Date</span>
        <span *ngIf="!isSavedPaymentMethod" >{{paymentDetails?.cardForm.expirationDate}}</span>
        <span *ngIf="!!isSavedPaymentMethod" >{{paymentDetails.savedPaymentMethod.expirationDate}}</span>
      </div>
    </ng-template>

    <div class="mt-4 flex flex-wrap-reverse justify-between pt-4 border border-t-1 border-l-0 border-r-0 border-b-0">
      <a class="es-link-secondary w-full mt-4 sm:w-40" (click)="cancelPayment()" [routerLink]="['../..']">Cancel</a>
      <a class="es-link-secondary w-full mt-4 sm:w-40 cursor-pointer" (click)="onEditInfo()">Edit Info</a>
      <button class="es-btn-primary w-full mt-4 sm:w-40"
        (click)="onAuthorizePayment(paymentDetails?.paymentOptionSelected)" [hidden]="disableAutorization()">Authorize Payment</button>
    </div>
    <span class="mt-4 flex flex-col mt-2 font-bold text-sm">
    By clicking the "Authorize Payment" button, you are authorizing {{companyName}} to withdraw {{paymentDetails?.paymentAmount | currency}} from your account or charge your credit/debit card.
  </span>
  
  </ng-container>

  <prcins-es-popup *ngIf="showGuardPopup" headerClass="bg-es-blue text-white">
    <div header-left>
      <span>Confirmation</span>
    </div>
    <div body>
      <p>Are you sure you want to cancel this transaction?</p>
      <div class="flex flex-wrap-reverse justify-between">
        <a class="es-link-secondary w-full mt-4 sm:w-40 cursor-pointer" (click)="chooseDeactivate(false)">No</a>
        <button class="es-btn-primary w-full mt-4 sm:w-40" type="button" (click)="chooseDeactivate(true)">Yes</button>
      </div>
    </div>
  </prcins-es-popup>

  <ng-template #loading>
    <prcins-card pt="pt-0">
      <div body>
        <prcins-skeleton   numOfRows="10"></prcins-skeleton>
      </div>
    </prcins-card>
  </ng-template>

</div>