<ng-container *ngIf="!isPreview(); else preview">
    <ng-container *ngIf="!hide">

        <prcins-mobile-selector *ngIf="!isLarge; else large" [registeredPolicies]="allowedPolicies" [isHome]="isHome"
            [noSelector]="noSelector" (policySelected)="onPolicySelected($event)"></prcins-mobile-selector>
        <ng-template #large>
            <prcins-desktop-selector [registeredPolicies]="allowedPolicies" [isHome]="isHome" [noSelector]="noSelector"
                (policySelected)="onPolicySelected($event)">
            </prcins-desktop-selector>
        </ng-template>
    </ng-container>
</ng-container>
<ng-template #preview>
    <div class="flex flex-center bg-red-700 p-4 w-full text-white shadow-lg mb-4 font-bold">
        This policy is not registered in eService. This is only a preview.
    </div>
</ng-template>