<prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="show">
    <div header-left>
        <span>Session Redirect</span>
    </div>
    <div header-right>
        <img class="cursor-pointer" height='15px' width='15px' (click)="close()"
            src="assets/svgs/close-hamburger.svg">
    </div>
    <div body>
        <p>Oops! We seem to be having a problem. Please try again later or contact us for help.</p>
    </div>
</prcins-es-popup>