import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { ACCOUNTS_URL, USER_ID, ADMIN_URL, REGISTRATION_URL } from '@prcins/constants';
import { SecurityQuestionListQuery, ManageAlertsQuery, ManageAlertsPolicyList, ManageRemainderList, UtilityProviderService, AllAssociatedPolicyQuery } from '@prcins/utils';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
import * as i3 from "../../../../utils/src/lib/services/utility-provider.service";
export class AccountsProviderService {
    constructor(http, apollo, utility) {
        this.http = http;
        this.apollo = apollo;
        this.utility = utility;
    }
    getSecurityQuestions(username) {
        return this.apollo
            .watchQuery({
            query: SecurityQuestionListQuery,
            variables: {
                username
            }
        })
            .valueChanges.pipe(map(({ data }) => data.account));
    }
    getAllAssociatedPolicies(username, fetchPolicy = 'cache-first') {
        return this.apollo
            .watchQuery({
            query: AllAssociatedPolicyQuery,
            variables: {
                username: username
            },
            fetchPolicy
        })
            .valueChanges.pipe(map(({ data }) => data.policyAssociations.registeredPolicies));
    }
    getRegisteredPolicies(username, fetchPolicy = 'cache-first') {
        return this.apollo
            .watchQuery({
            query: ManageAlertsPolicyList,
            variables: {
                username: username
            },
            fetchPolicy
        })
            .valueChanges.pipe(map(({ data }) => data.account.registeredPolicies));
    }
    getPolicyReminders(username) {
        return this.apollo
            .watchQuery({
            query: ManageRemainderList,
            variables: {
                username: username
            }
        })
            .valueChanges.pipe(map(({ data }) => data.account.profile.registeredPolicies));
    }
    getManageAlertsConfigs(policyNumber) {
        return this.apollo
            .watchQuery({
            query: ManageAlertsQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data.manageAlerts));
    }
    updateEmail(newEmail, userId) {
        return this.http.post(`${ACCOUNTS_URL.ChangeEmail}/${userId}/idg_user_group`, { newEmail, userId });
    }
    updatePassword(currentPassword, newPassword, userId) {
        return this.http.post(`${ACCOUNTS_URL.ChangePassword}/${userId}/idg_user_group`, { currentPassword, newPassword, userId });
    }
    updateSecurityQuestions(questionsPayload, userId) {
        return this.http.post(`${ACCOUNTS_URL.ChangeSecurityQuestions}/${userId}/idg_user_group`, questionsPayload);
    }
    updateScheduledReminder(policyNumber, remindersFlag) {
        const userType = this.utility.getUserType();
        return this.http.put(`${ACCOUNTS_URL.ScheduledReminder}/${sessionStorage.getItem(USER_ID)}/${userType}/${policyNumber}/${remindersFlag}`, null);
    }
    deletePolicy(policyNumber) {
        const userType = this.utility.getUserType();
        return this.http.delete(`${ACCOUNTS_URL.DeletePolicy}/${sessionStorage.getItem(USER_ID)}/${userType}/${policyNumber}`);
    }
    disableAccount(policy) {
        return this.http.delete(`${ADMIN_URL.disableAccount}/${sessionStorage.getItem(USER_ID)}/idg_user_group/${policy.policyNumber}`);
    }
    getTextAlertDetails(userId) {
        return this.http.get(REGISTRATION_URL.TextAlertDetails + "/" + userId);
    }
    addUpdateTextAlertDetails(payload) {
        return this.http.post(REGISTRATION_URL.TextAlertAddUpdate, payload);
    }
    stepOneRegistration(payload) {
        return this.http.post(REGISTRATION_URL.TextAlertSendOtpVerification, payload);
    }
    stepTwoRegistration(payload) {
        return this.http.post(REGISTRATION_URL.TextAlertOTPVerify, payload);
    }
    getAlertPreferences(policyEmail) {
        return this.http.get(ACCOUNTS_URL.alertPreferences + "/" + policyEmail);
    }
    saveAlertPreference(request) {
        return this.http.post(ACCOUNTS_URL.alertPreferences, request);
    }
}
AccountsProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountsProviderService_Factory() { return new AccountsProviderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo), i0.ɵɵinject(i3.UtilityProviderService)); }, token: AccountsProviderService, providedIn: "root" });
