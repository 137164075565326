<prcins-header></prcins-header>
<main class="flex justify-center w-full">
  <div class="es-container w-full flex justify-between flex-wrap">
    <section class="es-main-container w-full px-4">
      <h1 class="es-h1 mt-4">Disable Account</h1>
      <ng-container *ngIf="isLoading; else data">
        <prcins-card pt="pt-0">
          <div body>
            <prcins-skeleton numOfRows="5"></prcins-skeleton>
          </div>
        </prcins-card>
      </ng-container>
      <ng-template #data>
        <prcins-notification [notification]="notification"></prcins-notification>
        <prcins-card headerClass="bg-es-blue text-white">
          <span header-left>Account Info</span>
          <div body>

            <div class="flex justify-between sm:px-2">
              <span>Name</span><span class="font-bold">{{fullName}}</span>
            </div>
            <div class="flex justify-between sm:px-2">
              <span>Email</span><a class="text-es-blue-light font-bold">{{email}}</a>
            </div>
            <div class="flex justify-between  border-b-2 mt-4 sm:px-2"></div>
            <div class="flex justify-between mt-3 pb-2 border border-t-0 border-r-0 border-l-0"
              [class.border-b-0]="isLast" *ngFor="let policy of registeredPolicies; last as isLast ">
              <span>{{policy.policyNumber}}</span>
              <ng-container [ngSwitch]="policy.policyInfo.lob">
                <span class="flex items-center" *ngSwitchCase="'PA'">

                  Auto Policy
                </span>
                <span class="flex items-center" *ngSwitchCase="'HO'">

                  Home Policy
                </span>
                <span class="flex items-center" *ngSwitchCase="'CA'">
                  <ng-container *ngIf="policy.policyInfo.productCode==='PCAT' || policy.policyInfo.productCode==='ALN_UMB' else businessAutoGroup">

                    Umbrella Policy
                  </ng-container>
                  <ng-template #businessAutoGroup>

                    Business Auto
                  </ng-template>
                </span>
              </ng-container>
            </div>

          </div>
        </prcins-card>
        <div class="flex justify-between mt-4 flex-wrap-reverse">
          <button class="mt-4 es-btn-secondary w-full lg:w-40 text-center" [routerLink]="['../']">Cancel</button>
          <button class="mt-4 es-btn-primary w-full lg:w-40 text-center" (click)="onDisableAccount()"
            type="button">Disable</button>
        </div>
      </ng-template>
    </section>
  </div>
</main>