import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prcins-policy-type-resolver',
  templateUrl: './policy-type-resolver.component.html',
  styleUrls: ['./policy-type-resolver.component.css']
})
export class PolicyTypeResolverComponent {

  @Input()
  lob: string;
  @Input()
  productCode: string;

  constructor() { }
}
