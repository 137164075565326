import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { INotification } from '../models/notification.model';

@Component({
  selector: 'prcins-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.css']
})
export class SkeletonComponent implements OnInit, OnDestroy {

  @Input() numOfRows: number;
  @Input() timeout: number;
  @Input() hideTimeoutMessage: boolean;

  @Output('reload')
  reloadEvent: EventEmitter<any> = new EventEmitter();
  @Output('onTimeout')
  timeoutEvent: EventEmitter<any> = new EventEmitter();

  looper: any[];
  isTimedOut: boolean = false;
  timeoutSubscription: Subscription;
  notification: INotification = { type: 'ERROR' }

  ngOnInit(): void {
    this.numOfRows = Number(this.numOfRows) || 4;
    this.timeout = Number(this.timeout) || 60000;
    this.looper = Array(this.numOfRows);
    const timeout$ = of(null).pipe(
      delay(this.timeout),
      map(data => {
        this.isTimedOut = true;
        this.timeoutEvent.next();
      }));
    this.timeoutSubscription = timeout$.subscribe();
  }

  ngOnDestroy(): void {
    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }
  }

  onReload() {
    this.reloadEvent.emit();
  }
}
