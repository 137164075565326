import { Component } from '@angular/core';
import { BRAND, Brand } from '@prcins/constants';
import { environment } from '@prcins/environments';

@Component({
  selector: 'prcins-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.css']
})
export class DesktopHeaderComponent {
  brand = localStorage.getItem(BRAND);
  contactUs = Brand[this.brand].urlContactUs;
  mainPage = Brand[this.brand].urlEnterpriseWeb;
  efnolURL = environment.EFNOL_URL;
}
