<div>
  <main class="px-0 py-0">
    <prcins-danger *ngIf="registerError">
      <span *ngIf="!alreadyRegisterError">
        {{errorMessage}}
      </span>
      <span *ngIf="alreadyRegisterError">
        Oops! Did you forget you already signed up? If you <a class="underline font-bold text-es-blue-light"
          href='/eservice/login'>forgot your password</a>, don't sweat it! Or <a
          class="underline font-bold text-es-blue-light" href='https://www.plymouthrock.com/contact-us'
          target='_blank'>give us a call</a>.
      </span>
    </prcins-danger>
    <prcins-danger *ngIf="verificationInvalid"><span>
      Oops! We seem to be having a problem. Please try again later or <a class="underline font-bold text-es-blue-light" href='https://www.plymouthrock.com/contact-us'
      target='_blank'>contact us</a> for help.
    </span></prcins-danger>
    <p *ngIf="!verificationFailed && (userPolicyDetailsResp.phoneNumber || userPolicyDetailsResp.emailAddress)">
     {{description}}</p>
    <p *ngIf="verificationFailed">Please choose a new verification method and
      try again.</p>
    <form [formGroup]="verificationOptionsForm">
      <ng-container>
        <!-- Text -->
        <div class="flex mt-4 items-center" *ngIf="userPolicyDetailsResp.phoneNumber && !inputVerificationTextFailed">
          <label class="switch">
            <input type="radio" (click)="changeMode($event)" formControlName="mode" value="TEXT">
            <div class="tick"></div>
          </label>
          <span class="ml-2">Text a code to my cell {{userPolicyDetailsResp.phoneNumber}}</span>
        </div>
        <!-- EMAIL -->
        <div class="flex mt-4 items-center" *ngIf="userPolicyDetailsResp.emailAddress && !inputVerificationEmailFailed">
          <label class="switch">
            <input type="radio" (click)="changeMode($event)" formControlName="mode" value="EMAIL">
            <div class="tick"></div>
          </label>
          <span class="ml-2">Email a code to {{userPolicyDetailsResp.emailAddress}}</span>
        </div>

        <prcins-error-label [control]="verificationOptionsForm.get('mode')" class="w-full lg:w-3/5"
          [controlValue]="verificationOptionsForm.get('mode').value" controlLabel="verificationOptionsForm_mode"
          [controlTouched]="verificationOptionsForm.get('mode').touched">
        </prcins-error-label>
      </ng-container>

      <div *ngIf="verificationOptionsForm.get('mode').value === 'POLICY_NUMBER'">
        <div class="flex items-center justify-between flex-wrap mt-4">
          <label class="mb-2 font-semibold" for="">Enter Policy Number</label>
          <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="token">
        </div>
        <div class="flex items-center justify-between flex-wrap">
          <label class="mb-2 font-semibold" for=""></label>
          <prcins-error-label [control]="verificationOptionsForm.get('token')" class="w-full lg:w-3/5"
            [controlValue]="verificationOptionsForm.get('token').value" controlLabel="userRegisterForm_policyNumber"
            [controlTouched]="verificationOptionsForm.get('token').touched">
          </prcins-error-label>
        </div>
        <div class="flex items-center justify-between flex-wrap">
          <label class="mb-2 font-semibold" for=""></label>
          <i class="w-full lg:w-3/5">Your policy number can be found on your bill or declaration page</i>
        </div>
      </div>
    </form>
    <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
  </main>
</div>

<div class="flex px-8 lg:px-0 mb-4" *ngIf="verificationOptionsForm.get('mode').value === 'TEXT'">
  <label class="flex items-center">
    <strong class="terms"> By continuing, I agree to <a class="text-es-blue hover:underline"
        href="https://www.plymouthrock.com/internet-policy/mobileterms" target="_blank" rel="noopener noreferrer">Terms
        and Conditions</a> for receiving Plymouth Rock text alerts.</strong>
  </label>
</div>

<div class="flex justify-between flex-wrap px-8 lg:px-0" *ngIf="!isMobileApp && !isMobileBrowser; else mobileAppBtns">
  <button class="w-full lg:w-2/5 es-btn-secondary mt-2" (click)="onCancelClicked()" type="button">Cancel</button>
  <button class="w-full lg:w-2/5 es-btn-primary mt-2" (click)="onSubmit()" type="button">Continue</button>
</div>

<ng-template #mobileAppBtns>
  <div class="flex justify-between flex-wrap px-8 lg:px-0">
    <button class="w-full lg:w-2/5 es-btn-primary mt-2" (click)="onSubmit()" type="button">Continue</button>
    <button class="w-full lg:w-2/5 es-btn-secondary mt-2" (click)="onCancelClicked()" type="button">Cancel</button>
  </div>
</ng-template>
