import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DiscardComponent, BankFormProviderService, FormValidatorService } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { USER_ID } from '@prcins/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { Bank } from '../model/wallet';

@Component({
  selector: 'prcins-edit-bank',
  templateUrl: './edit-bank.component.html',
  styleUrls: ['./edit-bank.component.css']
})
export class EditBankComponent extends DiscardComponent implements OnInit {
  eftForm: FormGroup;
  bank: Bank;
  policyNumber: string;
  showSpinner = false;
  showError = false;
  channel: string;
  generalPhone: string;
  agentInfo = {};

  constructor(private bankFormProvider: BankFormProviderService,
    private managePaymentsProviderService: ManagePaymentsProviderService,
    private formValidator: FormValidatorService,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.policyNumber = this.route.snapshot.queryParamMap.get('policyNumber');

    // this.eftForm = this.bankFormProvider.getEditForm();
    this.eftForm = this.bankFormProvider.getForm();
    this.bank = this.managePaymentsProviderService.data;
    const accountType = this.bank.transType === 'CHECKING'? 'C' : this.bank.transType === 'SAVINGS'? 'S' : '';
    this.eftForm.get('accountType').setValue(accountType);
    this.eftForm.get('accountNumber').setValue(this.bank.debitAccount);
    this.eftForm.get('maskedAccountNumber').setValue("****" + this.bank.debitAccount);
    this.eftForm.get('reAccountNumber').setValue(this.bank.debitAccount);
    this.eftForm.get('maskedReAccountNumber').setValue("****" + this.bank.debitAccount);
    this.eftForm.get('routingNumber').setValue(this.bank.debitRouting);
    const debitRouting: string = this.bank.debitRouting + "";
    const maskedRouting = debitRouting.substring(debitRouting.length - 4);
    this.eftForm.get('maskedRoutingNumber').setValue("****" + maskedRouting);
    this.setAgentInfo();
  }

  doEditBank() {
    // form Validation
    this.formValidator.validateAllFormFields(this.eftForm);

    if (this.eftForm.valid  && this.eftForm.get('accountNumber').value === this.eftForm.get('reAccountNumber').value) {
      this.showSpinner = true;
      var editedBank = new Bank();
      editedBank.debitAccount = this.eftForm.get('accountNumber').value;
      editedBank.debitRouting = this.eftForm.get('routingNumber').value;
      editedBank.creditAccount = this.policyNumber;
      editedBank.accountEmail = sessionStorage.getItem(USER_ID);
      editedBank.paymentType = "ACH";
      const accountType = this.eftForm.get('accountType').value;
      if (accountType === 'C') {
        editedBank.transType = "CHECKING";
      } else if (accountType === 'S') {
        editedBank.transType = "SAVINGS";
      }
      editedBank.paymentMethodId = this.bank.paymentMethodId;
      editedBank.accountNickName = this.bank.accountNickName; // TODO - remove after ACI Speedpay fix

      this.managePaymentsProviderService.editWallet(editedBank).subscribe((response) => {
        if (response == null || response.status === 'success' && response.error === "") {
          this.navigateToManagePayments('true');
        } else {
          alert(response.status + ", " + response.errorMessage);
          // TODO - error
        }
      }, (error: HttpErrorResponse) => {
        this.httpErrorResponse();
      });
    }
  }

  navigateToManagePayments(isSuccess: string) {
    sessionStorage.setItem('editWalletSuccess', isSuccess);
    this.router.navigate(['../manage'], {
      relativeTo: this.route
    });
  }

  cancelTransaction() {
    this.navigateToManagePayments('false');
  }

  httpErrorResponse() {
    this.showSpinner = false;
    this.showError = true;
  }

  setAgentInfo() {
    let agentDetails = sessionStorage.agentInfo;
    if (agentDetails) {
      this.agentInfo = JSON.parse(agentDetails);
    }
    this.generalPhone = sessionStorage.getItem('general-phone');
    this.channel = sessionStorage.channel;
  }
}
