<prcins-es-popup headerClass="bg-es-blue text-white">
  <div header-left>
    <span>{{title}}</span>
  </div>
  <div body>
    <ng-content select="[modifiedData]"></ng-content>

    <ng-container *ngIf="!isModeSelected && userPolicyDetailsResp">
      <prcins-mfa-verification-options
        (submitted)="onFormSubmitted($event)"
        (cancelled)="onCancelClicked($event)"
        [description]="description"
        [verificationType]="type"
        [verificationMode]="verificationMode"
        [userPolicyDetailsResp]="userPolicyDetailsResp">
      </prcins-mfa-verification-options>
    </ng-container>

    <ng-container *ngIf="isModeSelected">
      <prcins-mfa-code-verification
        [verificationOptionsForm]="verificationOptionsForm"
        [userPolicyDetailsResp]="userPolicyDetailsResp"
        (submitted)="onTokenSubmitted($event)"
        (backClicked)="onBackClicked($event)">
      </prcins-mfa-code-verification>
    </ng-container>
  </div>
</prcins-es-popup>
