/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./round-radio.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./round-radio.component";
var styles_RoundRadioComponent = [i0.styles];
var RenderType_RoundRadioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RoundRadioComponent, data: {} });
export { RenderType_RoundRadioComponent as RenderType_RoundRadioComponent };
export function View_RoundRadioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "label", [["class", "switch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["type", "radio"]], [[8, "value", 0], [8, "name", 0], [8, "checked", 0], [1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRadioChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "tick"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.radioValue, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.radioName, ""); var currVal_2 = _co.radioChecked; var currVal_3 = ((_co.radioDisabled === "true") ? true : null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_RoundRadioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-round-radio", [], null, null, null, View_RoundRadioComponent_0, RenderType_RoundRadioComponent)), i1.ɵdid(1, 49152, null, 0, i2.RoundRadioComponent, [], null, null)], null, null); }
var RoundRadioComponentNgFactory = i1.ɵccf("prcins-round-radio", i2.RoundRadioComponent, View_RoundRadioComponent_Host_0, { radioName: "radioName", radioValue: "radioValue", radioChecked: "radioChecked", radioDisabled: "radioDisabled" }, { radioChanged: "radioChanged" }, []);
export { RoundRadioComponentNgFactory as RoundRadioComponentNgFactory };
