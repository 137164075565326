/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./square-checkbox.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./square-checkbox.component";
var styles_SquareCheckboxComponent = [i0.styles];
var RenderType_SquareCheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SquareCheckboxComponent, data: {} });
export { RenderType_SquareCheckboxComponent as RenderType_SquareCheckboxComponent };
export function View_SquareCheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "label", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "click"], [null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onSliderChange() !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = ((_co.checkValue = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "filler"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.disabled; var currVal_9 = _co.checkValue; _ck(_v, 4, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "switch ", _co.switchSecondary, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = i1.ɵinlineInterpolate(1, "tick ", _co.tickSecondary, ""); _ck(_v, 8, 0, currVal_10); }); }
export function View_SquareCheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-square-checkbox", [], null, null, null, View_SquareCheckboxComponent_0, RenderType_SquareCheckboxComponent)), i1.ɵdid(1, 114688, null, 0, i3.SquareCheckboxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SquareCheckboxComponentNgFactory = i1.ɵccf("prcins-square-checkbox", i3.SquareCheckboxComponent, View_SquareCheckboxComponent_Host_0, { sliderKey: "sliderKey", sliderValue: "sliderValue", disabled: "disabled", switchSecondary: "switchSecondary", tickSecondary: "tickSecondary" }, { sliderChanged: "sliderChanged" }, []);
export { SquareCheckboxComponentNgFactory as SquareCheckboxComponentNgFactory };
