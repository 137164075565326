/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./road-rewards-banner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../libs/utils/src/lib/card/card.component.ngfactory";
import * as i3 from "../../../../../libs/utils/src/lib/card/card.component";
import * as i4 from "../../../../../libs/utils/src/lib/skeleton/skeleton.component.ngfactory";
import * as i5 from "../../../../../libs/utils/src/lib/skeleton/skeleton.component";
import * as i6 from "./road-rewards-banner.component";
var styles_RoadRewardsBannerComponent = [i0.styles];
var RenderType_RoadRewardsBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RoadRewardsBannerComponent, data: {} });
export { RenderType_RoadRewardsBannerComponent as RenderType_RoadRewardsBannerComponent };
function View_RoadRewardsBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "prcins-card", [["pt", "pt-0"]], null, null, null, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i3.CardComponent, [], { pt: [0, "pt"] }, null), (_l()(), i1.ɵeld(2, 0, null, 2, 2, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "prcins-skeleton", [["numOfRows", "5"]], null, null, null, i4.View_SkeletonComponent_0, i4.RenderType_SkeletonComponent)), i1.ɵdid(4, 245760, null, 0, i5.SkeletonComponent, [], { numOfRows: [0, "numOfRows"] }, null)], function (_ck, _v) { var currVal_0 = "pt-0"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "5"; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_RoadRewardsBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "p-4 lg:px-2 lg:pb-0 mt-8 es-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "hidden lg:flex xl:flex w-1/3 relative mb-0 pb-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "absolute bottom-0"], ["src", "assets/svgs/rr-banner.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "w-full lg:w-2/3 text-justify"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "es-banner-h1 text-center lg:text-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Drive Safe. Get Rewarded."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "pb-2 mr-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enroll in Road Rewards, drive safe and earn everyday rewards like Amazon.com gift cards, Starbucks cards and more. "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "pb-2 flex self-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "es-link-primary mt-0 cursor-pointer w-1/3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openRRPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Enroll Now"])), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_RoadRewardsBannerComponent_1))], null, null); }
export function View_RoadRewardsBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-road-rewards-banner", [], null, null, null, View_RoadRewardsBannerComponent_0, RenderType_RoadRewardsBannerComponent)), i1.ɵdid(1, 114688, null, 0, i6.RoadRewardsBannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoadRewardsBannerComponentNgFactory = i1.ɵccf("prcins-road-rewards-banner", i6.RoadRewardsBannerComponent, View_RoadRewardsBannerComponent_Host_0, {}, { enrollRoadRewards: "enrollRoadRewards" }, []);
export { RoadRewardsBannerComponentNgFactory as RoadRewardsBannerComponentNgFactory };
