import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prcins-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.css']
})
export class PasswordFormComponent implements OnInit {

  @Input() passwordForm;
  @Input() edocPromised: boolean;
  maskPassword = false;
  actionType: string;

  constructor() { }

  ngOnInit() {
    this.actionType = sessionStorage.getItem('passwordActionType');
  }

  hasNumber(str) {
    return /\d/.test(str);
  }

  hasLowerCase(str) {
    return (/[a-z]/.test(str));
  }

}
