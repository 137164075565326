/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./expired-policies.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./expired-policies.component";
import * as i3 from "../../../../utils/src/lib/services/registration.service";
var styles_ExpiredPoliciesComponent = [i0.styles];
var RenderType_ExpiredPoliciesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExpiredPoliciesComponent, data: {} });
export { RenderType_ExpiredPoliciesComponent as RenderType_ExpiredPoliciesComponent };
export function View_ExpiredPoliciesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "desktop-only-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "main", [["class", "px-8 pb-8 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "es-h1 mt-4 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops!"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "font-semibold text-center mt-4 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your policy is not eligible for online services at this time."])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [["class", "text-center px-38"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Questions? Please"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "text-es-blue hover:underline font-semibold cursor-pointer"], ["href", "https://www.plymouthrock.com/contact-us"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Contact Us. "]))], null, null); }
export function View_ExpiredPoliciesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-expired-policies", [], null, null, null, View_ExpiredPoliciesComponent_0, RenderType_ExpiredPoliciesComponent)), i1.ɵdid(1, 114688, null, 0, i2.ExpiredPoliciesComponent, [i3.RegistrationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExpiredPoliciesComponentNgFactory = i1.ɵccf("prcins-expired-policies", i2.ExpiredPoliciesComponent, View_ExpiredPoliciesComponent_Host_0, {}, {}, []);
export { ExpiredPoliciesComponentNgFactory as ExpiredPoliciesComponentNgFactory };
