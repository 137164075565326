<div class="loader-main">
  <div class="spinner p-4 bg-white rounded">
    <div *ngIf="isAddressSpinner" class="header">
     Address Change
    </div>
    <div class="flex justify-center items-center"><img src="assets/png/loading.gif" alt=""></div>
    <img *ngIf="!isAddressSpinner" class="blink-image" src="assets/svgs/brands/{{brand}}/eservice-spinner-logo.png">
    <div *ngIf="isAddressSpinner" class="address-spinner">
      <h1>Please wait.</h1>
      <p>All changes will be lost if you navigate away from this page.</p>
    </div>
  </div>
</div>