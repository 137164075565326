<form [formGroup]="userForm">
  <div class="mt-4 flex items-center flex-wrap">
    <label for="" class="mb-2 w-full lg:w-2/5 font-semibold">Is this policy owner an individual or a company?</label>
    <div class="add-group-checkboxes">
      <input class="checkbox" id="individual" type="radio" formControlName="type" value="INDIVIDUAL"
        (change)="onPolicyTypeSelected()" />
      <label for="individual" style="border-radius: 9999px;">Individual</label>
      <input class="checkbox" id="commercial" type="radio" formControlName="type" value="COMMERCIAL"
        (change)="onPolicyTypeSelected()" />
      <label for="commercial" style="border-radius: 9999px;">Company</label>
    </div>
  </div>
  <div class="flex lg:justify-end">
    <prcins-error-label [control]="userForm.get('type')" [controlValue]="userForm.get('type').value"
      controlLabel="userRegisterForm_type" [controlTouched]="userForm.get('type').touched">
    </prcins-error-label>
  </div>

  <ng-container *ngIf="userForm.get('type').value === 'INDIVIDUAL'">
    <!-- First Name -->
    <ng-container>
      <div class="flex items-center justify-between flex-wrap mt-4">
        <label class="mb-2 font-semibold" for="">First Name</label>
        <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="firstName"
          oninput="this.value = this.value.toUpperCase()">
      </div>
      <div class="flex lg:justify-end">
        <prcins-error-label [control]="userForm.get('firstName')" [controlValue]="userForm.get('firstName').value"
          controlLabel="userRegisterForm_firstName" [controlTouched]="userForm.get('firstName').touched">
        </prcins-error-label>
      </div>
    </ng-container>
    <!-- Last Name -->
    <ng-container>
      <div class="flex items-center justify-between flex-wrap mt-4">
        <label class="mb-2 font-semibold" for="">Last Name</label>
        <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="lastName"
          oninput="this.value = this.value.toUpperCase()">
      </div>
      <div class="flex lg:justify-end">
        <prcins-error-label [control]="userForm.get('lastName')" [controlValue]="userForm.get('lastName').value"
          controlLabel="userRegisterForm_lastName" [controlTouched]="userForm.get('lastName').touched">
        </prcins-error-label>
      </div>
    </ng-container>
    <!-- Date of Birth -->
    <ng-container>
      <div class="flex items-center justify-between flex-wrap mt-4">
        <label class="mb-2 font-semibold" for="">Date of Birth</label>
        <div class="flex flex-wrap w-full lg:w-3/5">
          <input type="tel"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            maxlength="10" appSecureUSDateFormatMask placeholder="MM/DD/YYYY" formControlName="dob"
            class="w-11/12 lg:w-3/5 loginHelptext es-textfield prcins-mask-data">
          <span class="add-form-help-text" style="margin-top: 5px;">
            <prcins-help-text id="primaryUse" title="Date of Birth"
              text="The date of birth should match the first or second named insured on a policy.">
            </prcins-help-text>
          </span>
        </div>
      </div>
      <div class="flex lg:justify-end">
        <prcins-error-label [control]="userForm.get('dob')" [controlValue]="userForm.get('dob').value"
          controlLabel="userRegisterForm_displayDob" [controlTouched]="userForm.get('dob').touched">
        </prcins-error-label>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="userForm.get('type').value === 'COMMERCIAL'">
    <!-- Company Name -->
    <ng-container>
      <div class="flex items-center justify-between flex-wrap mt-4">
        <label class="mb-2 font-semibold" for="">Company Name</label>
        <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="companyName">
      </div>
      <div class="flex lg:justify-end">
        <prcins-error-label [control]="userForm.get('companyName')" [controlValue]="userForm.get('companyName').value"
          controlLabel="userRegisterForm_companyName" [controlTouched]="userForm.get('companyName').touched">
        </prcins-error-label>
      </div>
    </ng-container>
    <!-- Policy Number -->
    <ng-container>
      <div class="flex items-center justify-between flex-wrap mt-4">
        <label class="mb-2 font-semibold" for="">Policy Number</label>
        <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="policyNumber">
      </div>
      <div class="flex lg:justify-end">
        <prcins-error-label [control]="userForm.get('policyNumber')" [controlValue]="userForm.get('policyNumber').value"
          controlLabel="userRegisterForm_policyNumber" [controlTouched]="userForm.get('policyNumber').touched">
        </prcins-error-label>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="userForm.get('type').value">
    <!-- Billing Zip Code -->
    <div class="flex items-center justify-between flex-wrap mt-4">
      <label class="mb-2 font-semibold" for="">Zip Code</label>
      <div class="flex flex-wrap w-full lg:w-3/5">
        <input type="text" maxlength="5" prcinsOnlyNumber trimInput formControlName="zipCode"
          class="w-11/12 lg:w-3/5 es-textfield loginHelptext" placeholder="5 digits only">
        <span class="add-form-help-text" style="margin-top: 5px;">
          <prcins-help-text id="primaryUse" title="Zip Code"
            text="This is usually the 5 digit zip code of your home or company address.">
          </prcins-help-text>
        </span>
      </div>
    </div>
    <div class="flex lg:justify-end">
      <prcins-error-label [control]="userForm.get('zipCode')" [controlValue]="userForm.get('zipCode').value"
        controlLabel="userRegisterForm_zipCode" [controlTouched]="userForm.get('zipCode').touched">
      </prcins-error-label>
    </div>
  </ng-container>
</form>
