import { Directive } from '@angular/core';
import {FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[prcinsPhoneValidation]',
  providers: [
      { provide: NG_VALIDATORS, useExisting: PhonevalidationDirective, multi: true }

    ]
})
export class PhonevalidationDirective implements Validator {

    static validatePhone(control: FormControl): ValidationErrors {

      const value = control.value;
      const valid = null;
      const invalid = {validatePhone: true};

      if (!value || !value.length) {
         return valid;  // it assumes its not a required field
       }
       
      if (value.length===12  && value.match(/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/)) {

        const codes = value.split('-');
        const areaCode = codes[0];
        const exchangeCode = codes[1];
        const subscriberCode = codes[2];
        const lastTwoDigitsOfexchangeCode = exchangeCode.substring(1, 3);
        const allChars = areaCode + exchangeCode + subscriberCode;

        if (areaCode.charAt(0) == '0' || areaCode.charAt(0) == '1' || areaCode.charAt(1) == '9'
        || exchangeCode.charAt(0) == '0' || exchangeCode.charAt(0) == '1' || lastTwoDigitsOfexchangeCode == '11') {
          return invalid;
        } else if (areaCode == '800' && exchangeCode == '555') {
          return invalid;
        } else  if (/^(.)\1*$/.test(allChars)) {
          return invalid;
        } return valid;
      } else {
        return invalid;
       }

 }

    validate(c: FormControl): ValidationErrors | null {

    // Here we call our static validator function
    return PhonevalidationDirective.validatePhone(c);
    }

}
