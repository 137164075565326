<router-outlet></router-outlet>
<prcins-tealium-page-tracker></prcins-tealium-page-tracker>
<prcins-es-popup *ngIf="showTimeout" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Session Timeout</span>
  </div>
  <div body>
    <p>Your session is being timed out due to inactivity.</p>
    <p class="mt-4">Please choose to stay signed in or you will be logged out automatically</p>

    <div class="flex flex-wrap-reverse justify-between">
      <a class="es-link-secondary w-full mt-4 sm:w-40 cursor-pointer" (click)="onLogout()">Logout</a>
      <button class="es-btn-primary w-full mt-4 sm:w-40" type="button" (click)="onContinue()">Stay signed in
        ({{countdown}})</button>
    </div>
  </div>
</prcins-es-popup>



