import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { UtilityProviderService } from '../services/utility-provider.service';

@Component({
  selector: 'prcins-session-redirect-error',
  templateUrl: './session-redirect-error.component.html',
  styleUrls: ['./session-redirect-error.component.css']
})
export class SessionRedirectErrorComponent implements OnInit {

  show: boolean = true;


  constructor(private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private utilityProviderService: UtilityProviderService,

  ) { }

  ngOnInit() {
  }

  onContinue() {
    this.router.navigate(['eservice/login']);
  }

  close() {
    this.onLogout();
    this.show = false;
  }

  onLogout() {
    this.utilityProviderService.clearAppStorage();
    this.utilityProviderService.clearCookies();

    this.loginService.userLoggedIn$.next(false);
    this.router.navigate(['/eservice/login'], { relativeTo: this.route });
  }

}
