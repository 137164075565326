<header class="hidden lg:flex xl:flex p-4 bg-es-blue justify-between items-center border-t-4 border-es-orange">
  <a href="{{mainPage}}" target="_blank">
    <img src="assets/svgs/brands/{{brand}}/header-logo.svg">
  </a>
</header>
<main class="w-full px-4">
  <ng-container *ngIf="!isLoading; else loading">
    <h1 class="es-h1 mt-4">Manage your email preferences</h1>
    <p class="mb-4">Get reminders for payments, policy renewals and other important account updates.</p>

    <prcins-danger *ngIf="submitError">Oops! We seem to be having a problem. Please try again later or contact us for
      help.</prcins-danger>
    <prcins-danger *ngIf="fetchPolicyError">Oops! We seem to be having a problem. Please try again later or contact us
      for help.</prcins-danger>
    <!-- <prcins-danger *ngIf="noEmailExist"> <span>&#9746;</span> No Valid Email Associated with the policy for the user. Please contact Support Team.</prcins-danger> -->

    <div *ngIf="submitSuccess" class="flex items-center mb-4 p-4 border rounded border-gray-300 bg-es-sky-blue">
      <span class="mr-2">
        <img src="assets/png/check-icon.png">
      </span>
      <span>Your Alert preferences have been successfully updated.</span>
    </div>

    <prcins-card pt="pt-0" *ngIf="showAlertSection">
      <div body>
        <div class="flex justify-between items-center mt-4">
          <span class="font-bold" style="margin-top: -1.5rem;">Policy, Billing and Claims
            <br>
            <span class="font-medium">Updates and reminders to make sure you always have the latest information about
              your policy</span>
          </span>
          <span>
            <prcins-round-toggle-switch [sliderValue]="billingAlertOption" [sliderKey]="'billingAlertOptionKey'"
              (sliderChanged)="alertPreferenceChanged($event)">
            </prcins-round-toggle-switch>
          </span>
        </div>

        <div class="flex justify-between items-center mt-4">
          <span class="font-bold">Weather and Safety<br>
            <span class="font-medium">Alerts, tips and updates to help keep you and your family safe.</span>
          </span>
          <span>
            <prcins-round-toggle-switch [sliderValue]="weatherSafetyOption" [sliderKey]="'weatherAlertOptionKey'"
              (sliderChanged)="alertPreferenceChanged($event)">
            </prcins-round-toggle-switch>
          </span>
        </div>

        <div class="flex justify-between items-center mt-4">
          <span class="font-bold">Newsletters and Promotions<br>
            <span class="font-medium">Occasional Plymouth Rock updates and special offers/promotions.</span>
          </span>
          <span>
            <prcins-round-toggle-switch [sliderValue]="newsLetterOption" [sliderKey]="'newsletterAlertOptionKey'"
              (sliderChanged)="alertPreferenceChanged($event)">
            </prcins-round-toggle-switch>
          </span>
        </div>


        <div *ngIf="showTermsAndConditions" class="flex flex-wrap-reverse justify-between">
          <!-- <a class="es-link-secondary w-full mt-4 sm:w-40" [routerLink]="['../..']">Cancel</a> -->
          <button class="es-btn-primary w-full mt-4 sm:w-40" type="button" (click)="onSaveAlertPreference()">Save
            Preferences</button>
        </div>

      </div>
    </prcins-card>


    <p *ngIf="showTermsAndConditions" class="mt-4 text-sm">
      Please allow up to 5 business days for any email preference changes to take effect.
      <br><br>
      Your selections will not affect receipt of service-related, transactional or legal email communications related to
      your policy.
      Changes to Policy and Billing Alerts do not change your electronic documents and electronic billing subscriptions.
      Sign up for and manage electronic documents and electronic billing by going to <a
        href="https://www.plymouthrock.com/" target="_blank" class="text-blue-600">Manage Policy</a> from the home page.
      Learn about our <a href="https://www.plymouthrock.com/privacy-policy" target="_blank"
        class="text-blue-600">privacy policy</a>.
    </p>

  </ng-container>


  <ng-template #loading>
    <prcins-card pt="pt-0">
      <div body>
        <prcins-skeleton numOfRows="10"></prcins-skeleton>
      </div>
    </prcins-card>
  </ng-template>
</main>