import { Component, Input } from '@angular/core';

@Component({
  selector: 'prcins-es-popup',
  templateUrl: './es-popup.component.html',
  styleUrls: ['./es-popup.component.css']
})
export class EsPopupComponent {
  @Input() headerClass;

}

/** Usage
<prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="helpFlags.routingNumber">
  <div header-left>
    <span>Routing Number</span>
  </div>
  <div header-right>
    <img class="cursor-pointer" height='15px' width='15px' (click)="hideHelpText()"
      src="assets/svgs/close-hamburger.svg">
  </div>
  <div body>
    <p>Your bank routing number, account number and check number can be located at the bottom of your check. Do not
      include any spaces when you enter this number.</p>
    <img alt='check' src='assets/png/payments/check.jpg'>
  </div>
</prcins-es-popup>
*/
