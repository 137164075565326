<div *ngIf="invitationDetails">
  <div [ngSwitch]="invitationDetails.landingPage">
    <div *ngSwitchCase="'LOGIN_PAGE'">
      <prcins-login [invitationDetails]="invitationDetails"></prcins-login>
    </div>
    <div *ngSwitchCase="'CREATE_ACCOUNT'">
      <prcins-ai-complete-enrollment [invitationDetails]="invitationDetails"></prcins-ai-complete-enrollment>
    </div>
    <div *ngSwitchCase="'LINK_POLICY'">
      <prcins-ai-link-policy [invitationDetails]="invitationDetails"></prcins-ai-link-policy>
    </div>
    <div *ngSwitchCase="'EDOCS_ENROLLMENT'">
      <prcins-enroll-edocs [invitationDetails]="invitationDetails"></prcins-enroll-edocs>
    </div>
    <div *ngSwitchCase="'ERROR_PAGE'">
      <prcins-expired-policies></prcins-expired-policies>
    </div>
  </div>
</div>
