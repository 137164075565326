<label class="switch {{switchSecondary}}">
  <input type="checkbox" (click)="onSliderChange()" [(ngModel)]="checkValue" [disabled]="disabled">
  <span class="filler"></span>
  <div class="tick {{tickSecondary}}"></div>
</label>

<!-- Usage ----
  <prcins-square-checkbox 
    [sliderValue]="value" 
    [sliderKey]="key" 
    (sliderChanged)="onChanged($event)">
  </prcins-square-checkbox> 
-->