/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edoc-promotion-banner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./edoc-promotion-banner.component";
import * as i4 from "@angular/router";
var styles_EdocPromotionBannerComponent = [i0.styles];
var RenderType_EdocPromotionBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EdocPromotionBannerComponent, data: {} });
export { RenderType_EdocPromotionBannerComponent as RenderType_EdocPromotionBannerComponent };
function View_EdocPromotionBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "flex bg-es-sky-blue"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "flex items-center w-3/12 sm:w-2/12 px-4 py-4 rounded-l"], ["style", "background-color: #ff6319;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/png/eDocs 3-Leaf_icon_2x.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "small-banner flex flex-wrap w-9/12 py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "flex flex-col w-full px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Get your documents faster!"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go paperless with eDocuments and save paper. Sign up and you can receive policy and billing docs by email."])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "flex flex-col w-full items-end px-2 py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["class", "es-btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToEdocs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Enroll now"])), (_l()(), i1.ɵeld(13, 0, null, null, 9, "div", [["class", "large-banner flex w-10/12 py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "flex flex-col w-3/5 px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Get your documents faster!"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go paperless with eDocuments and save paper. Sign up and you can receive policy and billing docs by email."])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "flex flex-col w-2/5 justify-center px-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "a", [["class", "es-link-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToEdocs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Enroll now"]))], null, null); }
export function View_EdocPromotionBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mt-4 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "border rounded border-gray-300"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EdocPromotionBannerComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.productCode !== "PAIP"); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_EdocPromotionBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-edoc-promotion-banner", [], null, null, null, View_EdocPromotionBannerComponent_0, RenderType_EdocPromotionBannerComponent)), i1.ɵdid(1, 114688, null, 0, i3.EdocPromotionBannerComponent, [i4.Router, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EdocPromotionBannerComponentNgFactory = i1.ɵccf("prcins-edoc-promotion-banner", i3.EdocPromotionBannerComponent, View_EdocPromotionBannerComponent_Host_0, {}, {}, []);
export { EdocPromotionBannerComponentNgFactory as EdocPromotionBannerComponentNgFactory };
