import { Component, OnInit, Input } from '@angular/core';
//import { BRAND, Brand, CURRENT_POLICY, USER_ID, TOKEN, POLICY_ZIP_CODE, PAYMENT_SOURCE, ADMIN_QPAY_TOKEN } from '@prcins/constants';
// import { AccountsProviderService } from '../services/accounts-provider.service';
//import { AlertPreferences } from '@prcins/utils';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BRAND, Brand } from '@prcins/constants';
import { Location } from '@angular/common';
//import { PolicyLookupService } from '../../../../quickpay/src/lib/policy-lookup.service'

@Component({
  selector: 'prcins-additional-drivers-rspn',
  templateUrl: './additional-drivers-rspn.component.html',
  styleUrls: ['./additional-drivers-rspn.component.css']
})
export class AdditionalDriversRspnComponent implements OnInit {
  // driverData: any[] = [];
  driverData: any;
  brand = localStorage.getItem(BRAND);
  mainPage = Brand[this.brand].urlEnterpriseWeb;
  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private location: Location) {
    this.driverData = this.location.getState();
  }

  ngOnInit() {
   // console.log( this.driverData);

  }

}
