<prcins-es-popup *ngIf="showPopup">
    <ng-container body>
        <div class="flex justify-center mt-4">
            <img src="assets/png/orange-tree.png" alt="tree" class="img-tree">
        </div>
        <div class="text-center">
            <span class="es-h1">We'll keep the paper,you <br>save a tree!</span>
        </div>

        <ng-container *ngIf="notify$ | async as notification">
            <prcins-notification *ngIf="notification" [notification]="notification">
            </prcins-notification>
        </ng-container>
        <ng-container body
            *ngIf="!showSpinner && (!edocsEnrollmentNotification ||edocsEnrollmentNotification?.type === 'ERROR')">
            <div class="mt-4 text-sm">
                <span class="flex justify-center">Yes please!</span>
                <span class="block mt-4">Email my docs instead of sending piles of paper. I have read and agree to the
                    <a href="https://www.plymouthrock.com/utility/terms-eDocs-popup" target="_blank"
                        class="text-blue-600">terms &
                        conditions.</a> and I <a href="https://www.plymouthrock.com/internet-policy/consent-to-conduct"
                        target="_blank" class="text-blue-600">Consent to Conduct Business Electronically.</a></span>
            </div>
            <div class="mt-8 flex flex-wrap-reverse justify-center lg:justify-between items-center px-8 lg:px-0">
                <button type="button" class="es-btn-secondary lg:w-64" (click)='hidePopup()'>No,Thanks</button>
                <button type="button" class="es-btn-primary mb-2 lg:w-64" (click)='enrollEdocs()'>Sign Me Up!</button>
            </div>
        </ng-container>
        <ng-container body *ngIf="showSpinner">
            <prcins-skeleton numOfRows="5"></prcins-skeleton>
        </ng-container>
        <prcins-terms-conditions></prcins-terms-conditions>
    </ng-container>
</prcins-es-popup>