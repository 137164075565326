<div class="desktop-only-container">
  <main class="px-8 pb-8 pt-4">
    <h1 class="es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0">Complete Your
      {{invitationDetails.invitedPolicyLob }} Policy Enrollment</h1>
    <prcins-danger *ngIf="registrationError">
      <span>
        {{errorMessage}}
      </span>
    </prcins-danger>
    <prcins-password-form [passwordForm]="eDocEnrollmentLandingForm" [edocPromised]="showEdocPromisedDisclaimer"></prcins-password-form>
    <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
  </main>
</div>

<div class="desktop-only-disclaimer-container">
  <div class="flex px-8 lg:px-0 mb-4">
    <label class="flex">
      <strong class="terms"> By continuing, I have read and agree to the <a class="text-es-blue hover:underline"
          href="https://www.plymouthrock.com/utility/internet-policy-popup" target="_blank"
          rel="noopener noreferrer">Terms and
          Conditions</a> and I <a href="https://www.plymouthrock.com/internet-policy/consent-to-conduct" target="_blank"
          class="text-blue-600">Consent to Conduct Business Electronically</a>.</strong>
    </label>
  </div>
</div>


<div class="flex justify-end px-8 lg:px-0">
  <button class="es-btn-primary mb-2 lg:w-64" (click)="onSubmit()" type="button">Continue</button>
</div>

<prcins-es-popup *ngIf="alreadyRegisteredAccountPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Email Address</span>
  </div>
  <div body>
    <p>We found a registered account associated with that email
      address, please select one of the following:</p>
    <div class="flex mt-4 items-center">
      <prcins-round-radio radioName="registeredAccount" radioValue="EXISTING"
        (radioChanged)="registeredAccount($event)">
      </prcins-round-radio>
      <span class="ml-2">Login to my existing account</span>
    </div>
    <div class="flex mt-4 items-center">
      <prcins-round-radio radioName="registeredAccount" radioValue="NEW_POLICY"
        (radioChanged)="registeredAccount($event)">
      </prcins-round-radio>
      <span class="ml-2">Add a new policy to my existing
        account</span>
    </div>
    <div class="add-btns">
	<button class="add-btn add-btn-primary" type="button" (click)="alreadyRegisteredAccount()">Continue</button>
      <button class="add-btn add-btn-cancel" type="button"
        (click)="alreadyRegisteredAccountPopup = false">Cancel</button>      
    </div>
  </div>
</prcins-es-popup>
