/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./payment.component";
var styles_PaymentComponent = [];
var RenderType_PaymentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaymentComponent, data: {} });
export { RenderType_PaymentComponent as RenderType_PaymentComponent };
export function View_PaymentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "main", [["class", "w-full px-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_PaymentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "prcins-payment", [], null, null, null, View_PaymentComponent_0, RenderType_PaymentComponent)), i0.ɵdid(1, 49152, null, 0, i2.PaymentComponent, [], null, null)], null, null); }
var PaymentComponentNgFactory = i0.ɵccf("prcins-payment", i2.PaymentComponent, View_PaymentComponent_Host_0, {}, {}, []);
export { PaymentComponentNgFactory as PaymentComponentNgFactory };
