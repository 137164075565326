import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { DesktopHeaderComponent } from './header/desktop-header/desktop-header.component';
import { MobileHeaderComponent } from './header/mobile-header/mobile-header.component';
import { RegisterComponent } from './register/register.component';
import { ForgotEmailComponent } from './forgot-email/forgot-email.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotAnswerComponent } from './forgot-answer/forgot-answer.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetupComponent } from './setup/setup.component';
import { UtilsModule } from "@prcins/utils";
import { EnrollEdocsComponent } from './enroll-edocs/enroll-edocs.component';
import { IntermediateRegisterationFormComponent } from './intermediate-registeration-form/intermediate-registeration-form.component'; 

import { FeatureComponent } from './new-feature/feature.component'

//import { ScriptTagComponent } from 'libs/utils/src/lib/scripttag-component/scriptTag.component';

import { LazyElementsModule } from '@angular-extensions/elements';
import { VerificationComponent } from './verification/verification.component';
import { VerificationOptionsComponent } from './verification/verification-options/verification-options.component';
import { CodeVerificationComponent } from './verification/code-verification/code-verification.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { LinkPoliciesComponent } from './link-policies/link-policies.component';
import { InvitationLandingComponent } from './invitation-landing/invitation-landing.component';
import { AiLinkPolicyComponent } from './ai-link-policy/ai-link-policy.component';
import { AiCompleteEnrollmentComponent } from './ai-complete-enrollment/ai-complete-enrollment.component';
import { ExpiredPoliciesComponent } from './expired-policies/expired-policies.component';
import { DiscardChangesGuard, VerificationAuthorizeGuard } from '@prcins/utils';

const routes = [
  {
    path: 'login',
    component: MainComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      }
    ]
  },
  {
    path: 'admin/login',
    component: MainComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      }
    ]
  },
  {
    path: 'register',
    component: MainComponent,

    children: [
      {
        path: '',
        data: { 'tealium': { 'subSection': 'Registration' } },
        component: RegisterComponent
      }
    ]
  },
  {
    path: 'forgot-email',
    component: MainComponent,
    children: [
      {
        path: '',
        data: { 'tealium': { 'subSection': 'Forgot Email' } },
        component: ForgotEmailComponent
      }
    ]
  },
  {
    path: 'forgot-password',
    component: MainComponent,
    children: [
      {
        path: '',
        data: { 'tealium': { 'subSection': 'Forgot Password' } },
        component: ForgotPasswordComponent,
        canActivate: [VerificationAuthorizeGuard],
        canDeactivate: [DiscardChangesGuard],
      }
    ]
  },
  // {
  //   path: 'forgot-answer',
  //   component: MainComponent,
  //   children: [
  //     {
  //       path: '',
  //       data: { 'tealium': { 'subSection': 'Forgot Answer' } },
  //       component: ForgotAnswerComponent
  //     }
  //   ]
  // },
  // {
  //   path: 'reset-password',
  //   component: MainComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: ResetPasswordComponent,
  //     }
  //   ]
  // },
  // {
  //   path: 'setup',
  //   component: MainComponent,
  //   children: [
  //     {
  //       path: '',
  //       data: { 'tealium': { 'subSection': 'Registration Details' } },
  //       component: SetupComponent
  //     }
  //   ]
  // },
  {
    path: 'edoc/signup/:userId/:policyNumber',
    component: MainComponent,
    children: [
      {
        path: '',
        data: { 'tealium': { 'subSection': 'eDoc:Sign Up' } },
        component: EnrollEdocsComponent
      }
    ]
  },
  {
    path: 'verification',
    component: MainComponent,
    children: [
      {
        path: '',
        data: { 'tealium': { 'subSection': 'Verification' } },
        component: VerificationComponent,
        canActivate: [VerificationAuthorizeGuard]
      }
    ]
  },
  {
    path: 'create-password',
    component: MainComponent,
    children: [
      {
        path: '',
        data: { 'tealium': { 'subSection': 'Create Password' } },
        component: CreatePasswordComponent,
        canActivate: [VerificationAuthorizeGuard],
        canDeactivate: [DiscardChangesGuard],
      }
    ]
  },
  {
    path: 'link-policies',
    component: MainComponent,
    children: [
      {
        path: '',
        data: { 'tealium': { 'subSection': 'Link Policies' } },
        component: LoginComponent
      }
    ]
  },
  {
    path: 'link-your-home-and-auto',
    component: MainComponent,
    children: [
      {
        path: '',
        data: { 'tealium': { 'subSection': 'Link Home And Auto Policy' } },
        component: AiLinkPolicyComponent
      }
    ]
  },
  {
    path: 'invitation',
    component: MainComponent,
    children: [
      {
        path: '',
        data: { 'tealium': { 'subSection': 'Invitation' } },
        component: InvitationLandingComponent
      }
    ]
  }
  , { path: 'registration', redirectTo: 'register', pathMatch: 'full' }
  , { path: '', redirectTo: 'login', pathMatch: 'full' }
  , { path: 'admin', redirectTo: 'admin/login', pathMatch: 'full' }
  , { path: 'regUser.do', redirectTo: 'register', pathMatch: 'full' }
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, UtilsModule, LazyElementsModule, RouterModule.forChild(routes)],
  declarations: [
    MainComponent,
    LoginComponent,
    HeaderComponent,
    DesktopHeaderComponent,
    MobileHeaderComponent,
    RegisterComponent,
    ForgotEmailComponent,
    ForgotPasswordComponent,
    ForgotAnswerComponent,
    ResetPasswordComponent,
    SetupComponent,
    EnrollEdocsComponent,
    FeatureComponent,
    VerificationComponent,
    VerificationOptionsComponent,
    CodeVerificationComponent,
    CreatePasswordComponent,
    LinkPoliciesComponent,
    AiCompleteEnrollmentComponent,
    AiLinkPolicyComponent,
    InvitationLandingComponent,
    AiLinkPolicyComponent,
    AiCompleteEnrollmentComponent,
    ExpiredPoliciesComponent,
    IntermediateRegisterationFormComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [EnrollEdocsComponent, LoginComponent, IntermediateRegisterationFormComponent]
})
export class LoginModule { }
