import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DiscardComponent } from '@prcins/utils';
import { RegistrationService, UtilityProviderService, FormValidatorService } from '@prcins/utils';
import { ClaimsBasicService } from "libs/claims/src/lib/claims-basic.service";
import * as _ from 'lodash';
import { USER_ID, USER_LOGIN, TOKEN } from '@prcins/constants';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'prcins-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends DiscardComponent implements OnInit {
  showSpinner = false;
  resetPasswordForm = this.utilityProvider.getResetPasswordForm();
  registrationError = false;
  errorMessage: string;
  verificationId: string;
  accountLockError = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formValidator: FormValidatorService,
    private utilityProvider: UtilityProviderService,
    private claimsBasicService: ClaimsBasicService,
    private registrationProvider: RegistrationService
  ) {
    super();
  }

  ngOnInit() {
    this.verificationId = sessionStorage.getItem('verificationId');
    if (this.verificationId) {
      this.updateSafeToNavigate(false);
    }
    if (history.state.email) {
      this.resetPasswordForm.controls['userName'].setValue(history.state.email);
    } else {
      this.registrationProvider.getUserDetails(this.verificationId).subscribe((data) => {
        if (data) {
          this.resetPasswordForm.controls['userName'].setValue(data.emailAddress);
        }
      }, (error: HttpErrorResponse) => {
        this.registrationProvider.invalidateEndorsementSession();
        this.router.navigate(['../login'], {
          relativeTo: this.route,
      });
      });
    }
  }

  onContinue() {
    if (this.resetPasswordForm.valid) {
      this.registrationError = false;
      this.accountLockError = false;
      this.showSpinner = true;
      let formValue = this.resetPasswordForm.value;
      formValue.verificationId = this.verificationId;
      delete formValue.confirmPassword;

      // const payload = {
      //   "policyNumber": "",
      //   "agreementType": "GENERALTC",
      //   "agreementType2": "ELECTRONICCONSENT",
      //   "source": "ESERVICE",
      //   "email": sessionStorage.getItem(USER_ID)||''
      // }
      // this.claimsBasicService.eDiscloserAudit(payload).subscribe();

      this.registrationProvider
        .changePassword(formValue)
        .subscribe((resp: any) => {
          if (resp.messageCode === 'PASSWORD_UPDATED') {

            sessionStorage.setItem(USER_LOGIN, 'Y');
            sessionStorage.setItem(TOKEN, resp.token);
            sessionStorage.setItem('randomNumber', Math.random().toString());
            this.registrationProvider.invalidateEndorsementSession();
            this.updateSafeToNavigate(true);
            this.showSpinner = false;
            const routePath = ['eservice/home'];
            this.router.navigate(routePath);
          } else if (resp.messageCode === 'UNAVAILABLE_FOR_15MIN') {
            this.accountLockError = true;
          } else {
            this.registrationError = true;
            this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
          }
          this.showSpinner = false;
        }, (error: HttpErrorResponse) => {
          this.showSpinner = false;
          this.registrationError = true;
          this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
        });
    } else {
      this.formValidator.validateAllFormFields(this.resetPasswordForm);
    }
  }

  cancelTransaction() {
    this.registrationProvider.invalidateEndorsementSession();
    // this.chooseDeactivate(true);
    this.updateSafeToNavigate(true);
    this.router.navigate(['../login'], {
      relativeTo: this.route,
    });
}
}
