<ng-container [ngSwitch]="lob">
  <ng-container *ngSwitchCase="'PA'">Personal Automobile</ng-container>
  <ng-container *ngSwitchCase="'HO'">Homeowner/Dwelling</ng-container>
  <ng-container *ngSwitchCase="'CA'">
    <ng-container *ngIf="productCode==='ALN_UMB' || productCode==='ALN_UMB'; else businessAutoGroup">
      Personal Umbrella
    </ng-container>
    <ng-template #businessAutoGroup>
      Business Automobile
    </ng-template>
  </ng-container>
</ng-container>