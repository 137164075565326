import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  FormValidatorService,
  RegistrationService,
  AiEnrollmentService
} from '@prcins/utils';
import { USER_ID, USER_LOGIN, TOKEN } from '@prcins/constants';
import { ClaimsBasicService } from "libs/claims/src/lib/claims-basic.service";
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'prcins-ai-link-policy',
  templateUrl: './ai-link-policy.component.html',
  styleUrls: ['./ai-link-policy.component.css']
})
export class AiLinkPolicyComponent implements OnInit {

  @Input() invitationDetails;
  linkError = false;
  errorMessage: string;
  error: string;
  showSpinner = false;
  maskPassword = false;
  aiLinkPolicyForm: FormGroup = this.fb.group({
    enrollEDOC: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });
  verificationId: string;
  routerEventSubscription: Subscription;
  showEdocPromisedDisclaimer = false;
  constructor(private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private formValidator: FormValidatorService,
    private claimsBasicService: ClaimsBasicService,
    private registrationProvider: RegistrationService,
    private aiEnrollmentService: AiEnrollmentService) { }

  ngOnInit() {
    this.verificationId = sessionStorage.getItem('verificationId');

    if (this.invitationDetails && !this.invitationDetails.edocEnrollmentAllowed) {
      this.aiLinkPolicyForm.removeControl('enrollEDOC');
    }

	  if (this.invitationDetails && this.invitationDetails.edocEnrollmentAllowed && this.invitationDetails.defaultEdoc && typeof this.invitationDetails.defaultEdoc === 'string') {
        if (this.invitationDetails.defaultEdoc=== 'PROMISE') {
          this.showEdocPromisedDisclaimer = true;
        }
        this.aiLinkPolicyForm.patchValue({
          enrollEDOC: (this.invitationDetails.defaultEdoc=== 'PROMISE'? 'Y' : 'N')
        });
      }
      
    this.routerEventSubscription = this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        if (sessionStorage.getItem('verificationId')) {
          this.aiEnrollmentService.abandonRequest(this.invitationDetails.landingPage, this.verificationId).subscribe((resp) => {
            this.registrationProvider.invalidateEndorsementSession();
          });
        }
      }
    });
  }

  onSubmit() {
    if (this.aiLinkPolicyForm.valid) {
      this.showSpinner = true;
      this.linkError = false;
      this.error = null;
      let formValue = this.aiLinkPolicyForm.value;

      const payload = {
        "policyNumber": "",
        "agreementType": "GENERALTC",
        "agreementType2": "ELECTRONICCONSENT",
        "source": "ESERVICE",
        "email": this.invitationDetails.emailAddress.toLowerCase()
      }
      this.claimsBasicService.eDiscloserAudit(payload).subscribe();

      var request: any = {
        userName: this.invitationDetails.emailAddress.toLowerCase(),
        verificationId: this.verificationId,
        password: this.aiLinkPolicyForm.value.password
      };

      if (this.invitationDetails && this.invitationDetails.edocEnrollmentAllowed) {
        if (formValue.enrollEDOC === 'Y') {
          request.enrollEDOC = true;
        } else {
          request.enrollEDOC = false;
        }
      }

      this.aiEnrollmentService
        .aiLinkPolicy(request)
        .subscribe((result: any) => {
          this.showSpinner = false;
          if (result.messageCode === 'ADD_POLICY_SUCCESS') {

            // START: LOGIN AFTER SUCCESSFULL LINKING
            result.userId = request.userName;
            sessionStorage.setItem(USER_LOGIN, 'Y');
            sessionStorage.setItem(USER_ID, request.userName);
            sessionStorage.setItem(TOKEN, result.token);
            sessionStorage.setItem('randomNumber', Math.random().toString());

            this.registrationProvider.invalidateEndorsementSession();
            this.showSpinner = false;
            const routePath = ['eservice/home'];
            this.router.navigate(routePath);
            // END: LOGIN AFTER SUCCESSFULL LINKING

          } else {
            // handle error response codes
            this.showSpinner = false;
            this.linkError = true;
            this.errorMessage =
              "Oops! We can't seem to find you. Please try that again.";
          }

        }, error => {
          this.linkError = true;
          this.errorMessage =
            'Sorry unable to link policy now. Please try again or contact us.';
          this.showSpinner = false;
        });
    } else {
      this.formValidator.validateAllFormFields(this.aiLinkPolicyForm);
    }
  }

  goToRegister() {
    this.registrationProvider.userRegistrationForm$.next(null);
    sessionStorage.setItem('passwordActionType', 'Create');
    this.router.navigate(['eservice/register']);
  }

  forgotPassword() {
    this.error = null;
    this.aiEnrollmentService.abandonRequest(this.invitationDetails.landingPage, this.verificationId).subscribe((resp) => {
      this.routerEventSubscription.unsubscribe();
      this.registrationProvider.invalidateEndorsementSession();
      if (this.invitationDetails.emailAddress) {
        sessionStorage.setItem('userId', this.invitationDetails.emailAddress);
        let data = {
          origin: 'LINK_POLICY',
          userName: this.invitationDetails.emailAddress
        }
        this.registrationProvider.forgotPassword(data).subscribe((resp: any) => {
          if (resp.messageCode === undefined || resp.messageCode === '') {
            sessionStorage.setItem('verificationId', resp.id);
            sessionStorage.setItem('passwordActionType', 'linkPolicies');
            this.registrationProvider.verifyUserRegisteredOrNot$.next(resp);
            this.showSpinner = false;
            this.router.navigate(['eservice/verification']);
          } else {
            this.error = 'Please provide your valid email to reset the password.';
          }
        }, (error: HttpErrorResponse) => {
          this.showSpinner = false;
        });
      } else {
        this.error = 'Please provide your valid email to reset the password.';
      }
    }, (error: HttpErrorResponse) => {
      this.showSpinner = false;
    });
  }

}
