import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class QpayDocumentsGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const allowedRoute = 'policy-documents';
    const currentRoute = state.url.split('/').pop();

    if (currentRoute === allowedRoute) {
      return true;
    } else {
      this.router.navigate(['/qpay/documents/policy-documents']);
      return false;
    }
  }
}
