import { Component, OnInit, Input } from '@angular/core';
import { BRAND, USER_LOGIN, Brand, CURRENT_POLICY, USER_ID, TOKEN, POLICY_ZIP_CODE, PAYMENT_SOURCE, ADMIN_QPAY_TOKEN } from '@prcins/constants';
// import { AccountsProviderService } from '../services/accounts-provider.service';
import { AlertPreferences } from '@prcins/utils';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PolicyLookupService } from '../../../../../libs/quickpay/src/lib/policy-lookup.service'

@Component({
  selector: 'prcins-additional-drivers-info',
  templateUrl: './additional-drivers-info.component.html',
  styleUrls: ['./additional-drivers-info.component.css']
})
export class AdditionalDriversInfoComponent implements OnInit {
  // form: FormGroup;
  showSpinner = false;
  brand = localStorage.getItem(BRAND);
  mainPage = Brand[this.brand].urlEnterpriseWeb;
  policyLookupForm : any;
  isLoading = false;
  authError = false;
  authenticateSubscription;
  public queryParamPolicyNumber:string;
  
  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder, 
    private accessProvider: PolicyLookupService) {
      this.route.queryParams.subscribe(params => {
        this.queryParamPolicyNumber = params['policyNumber'] ? params['policyNumber']: "";
      });
     }
    
  ngOnInit() {
    this.policyLookupForm  = this.fb.group({
      policyNumber: ['', [Validators.required,Validators.pattern(/(^[a-zA-Z]{3}[0-9a-zA-Z]{11}$)|(^[0-9]{7,9}$)/)]],
      zipCode: ['', [Validators.required,Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]]
    });
    this.policyLookupForm.get('policyNumber').setValue(this.queryParamPolicyNumber);
    // this.route.queryParams.subscribe(params => {
    //  let isQPayAdmin = params[ADMIN_QPAY_TOKEN] === "QPtrue";
    //   sessionStorage.setItem(ADMIN_QPAY_TOKEN, String(isQPayAdmin));
    // }); 
  }
  
  onPolicyLookup() {
    this.policyLookupForm.get('zipCode').markAsTouched();
    this.policyLookupForm.get('policyNumber').markAsTouched();
    this.authError = false;
    
    if (this.policyLookupForm.valid) {
      this.isLoading = true;
      const { policyNumber, zipCode } = this.policyLookupForm.value;
      let payload = {
        "policyNumber": policyNumber,
        "agreementType": "GENERALTC",
        "agreementType2": "ELECTRONICCONSENT",
        "source": "ADDITIONALDRIVER"
      }
      this.accessProvider.eDiscloserAudit(payload).subscribe(data => console.log(data));
      this.authenticateSubscription = this.accessProvider
        .policyLookup(this.policyLookupForm.value)
        .subscribe(
          (result: any) => {
            const { messageCode, token } = result;
            if (!messageCode && token) {
              sessionStorage.setItem(CURRENT_POLICY, policyNumber);
              sessionStorage.setItem(POLICY_ZIP_CODE, zipCode);
              sessionStorage.setItem(TOKEN, result.token);
              sessionStorage.setItem(USER_LOGIN, 'Y');
              this.router.navigate(["./review"], {
                relativeTo: this.route
              });
            } else {
              // this.isLoginSuccess =  false;
              this.isLoading = false;
              this.authError = true;
              // this.pageErrors["authError"] = this.config["ALL_authError"];
            }
          },
          _ => {
            this.isLoading = false;
          }
        );
    }
  }

}
