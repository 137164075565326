import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { IPaymentPolicy } from './models/payment.model';
import { LOGIN_URL } from './constants/urls.constants';
import { PAYMENT_URL } from '@prcins/constants';

@Injectable({
  providedIn: 'root'
})
export class PolicyLookupService {
  constructor(private http: HttpClient) {}

  policyLookup(policy: IPaymentPolicy) {
    if (this.isQPayOpen(policy.policyNumber)) {
      return this.authenticate(policy);
    }
    return of({});
  }

  authenticate(payload: IPaymentPolicy) {
    console.log("payload", payload)
    return this.http.post(LOGIN_URL.Authenticate, payload).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  eDiscloserAudit(payload: any) {
    console.log("payload", payload)
    return this.http.post(LOGIN_URL.AuditUrl, payload).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  
  isQPayOpen(policyNumber: string): boolean {
    const inActivePrefixes: string[] = [
      'PRU',
      'HPU',
      'MAA',
      'FFA',
      'TLA',
      'MWU'
    ];
    const prefix = policyNumber.substring(0, 3);
    const result = inActivePrefixes.indexOf(prefix) > -1 ? false : true;
    return result;
  }

  public loadUserFromToken(token) {
    return this.http.post(`${PAYMENT_URL.TokenLoad}`, {token});
  }
}
