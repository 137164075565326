/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-bank.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../utils/src/lib/danger/danger.component.ngfactory";
import * as i3 from "../../../../utils/src/lib/danger/danger.component";
import * as i4 from "../../../../utils/src/lib/es-popup/es-popup.component.ngfactory";
import * as i5 from "../../../../utils/src/lib/es-popup/es-popup.component";
import * as i6 from "../../../../utils/src/lib/spinner/spinner.component.ngfactory";
import * as i7 from "../../../../utils/src/lib/spinner/spinner.component";
import * as i8 from "@angular/common";
import * as i9 from "../../../../utils/src/lib/bank-form/bank-form.component.ngfactory";
import * as i10 from "../../../../utils/src/lib/bank-form/bank-form.component";
import * as i11 from "@angular/router";
import * as i12 from "./edit-bank.component";
import * as i13 from "../../../../utils/src/lib/services/bank-form-provider.service";
import * as i14 from "../services/manage-payments-provider.service";
import * as i15 from "../../../../utils/src/lib/services/form-validator.service";
var styles_EditBankComponent = [i0.styles];
var RenderType_EditBankComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditBankComponent, data: {} });
export { RenderType_EditBankComponent as RenderType_EditBankComponent };
function View_EditBankComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "prcins-danger", [], null, null, null, i2.View_DangerComponent_0, i2.RenderType_DangerComponent)), i1.ɵdid(1, 4243456, null, 0, i3.DangerComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" Oops! There is a problem with the bank account you entered. "]))], null, null); }
function View_EditBankComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "prcins-es-popup", [["headerClass", "bg-es-blue text-white"]], null, null, null, i4.View_EsPopupComponent_0, i4.RenderType_EsPopupComponent)), i1.ɵdid(1, 49152, null, 0, i5.EsPopupComponent, [], { headerClass: [0, "headerClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["header-left", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirmation"])), (_l()(), i1.ɵeld(5, 0, null, 2, 7, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "text-center text-lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to cancel this transaction?"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "flex flex-wrap-reverse justify-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "es-link-secondary  w-half-2 mt-8  cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showGuardPopup = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "es-btn-primary w-half-2 mt-8 cursor-pointer"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelTransaction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"]))], function (_ck, _v) { var currVal_0 = "bg-es-blue text-white"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EditBankComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-spinner", [], null, null, null, i6.View_SpinnerComponent_0, i6.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i7.SpinnerComponent, [], null, null)], null, null); }
export function View_EditBankComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "es-h1 mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit Bank Account"])), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditBankComponent_1)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "font-whitney-semibold"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " ****", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "prcins-bank-form", [], null, null, null, i9.View_BankFormComponent_0, i9.RenderType_BankFormComponent)), i1.ɵdid(9, 114688, null, 0, i10.BankFormComponent, [i11.ActivatedRoute], { eftForm: [0, "eftForm"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "es-btn-primary w-full mt-4"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doEditBank() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["class", "es-link-secondary w-full mt-4 cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showGuardPopup = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditBankComponent_2)), i1.ɵdid(16, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditBankComponent_3)), i1.ɵdid(18, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showError; _ck(_v, 5, 0, currVal_0); var currVal_3 = _co.eftForm; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.showGuardPopup; _ck(_v, 16, 0, currVal_4); var currVal_5 = _co.showSpinner; _ck(_v, 18, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.bank.accountType; var currVal_2 = _co.bank.debitAccount; _ck(_v, 7, 0, currVal_1, currVal_2); }); }
export function View_EditBankComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-edit-bank", [], null, null, null, View_EditBankComponent_0, RenderType_EditBankComponent)), i1.ɵdid(1, 114688, null, 0, i12.EditBankComponent, [i13.BankFormProviderService, i14.ManagePaymentsProviderService, i15.FormValidatorService, i11.Router, i11.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditBankComponentNgFactory = i1.ɵccf("prcins-edit-bank", i12.EditBankComponent, View_EditBankComponent_Host_0, {}, {}, []);
export { EditBankComponentNgFactory as EditBankComponentNgFactory };
