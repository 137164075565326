import { OnInit, Input } from '@angular/core';
import {
  tap,
  debounceTime,
  filter,
  distinctUntilChanged
} from 'rxjs/operators';
import { Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

export class CardBaseForm implements OnInit {
  @Input() cardForm;
  cardType: string;

  cvvLength = 4;
  cardNumberError = false;

  invalidExpirationMonth = false;
  expirationMonthError = false;
  expirationYearError = false;

  isChangePayPlan = false;

  months = [
    {
      value: '01',
      display: 'January(01)'
    },
    {
      value: '02',
      display: 'February(02)'
    },
    {
      value: '03',
      display: 'March(03)'
    },
    {
      value: '04',
      display: 'April(04)'
    },
    {
      value: '05',
      display: 'May(05)'
    },
    {
      value: '06',
      display: 'June(06)'
    },
    {
      value: '07',
      display: 'July(07)'
    },
    {
      value: '08',
      display: 'August(08)'
    },
    {
      value: '09',
      display: 'September(09)'
    },
    {
      value: '10',
      display: 'October(10)'
    },
    {
      value: '11',
      display: 'November(11)'
    },
    {
      value: '12',
      display: 'December(12)'
    }
  ];

  years = (() => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i <= 10; i += 1) {
      years.push(currentYear + i);
    }
    return years;
  })();

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    
    if(sessionStorage.getItem('qPayEmail')) {
      this.cardForm.patchValue({'cardHolderEmailId': sessionStorage.getItem('qPayEmail')})
    }

    const routePath = this.route.snapshot.routeConfig.path;
    if (routePath === 'change-plan') {
      this.isChangePayPlan = true;
      this.cardForm.addControl('plan_terms', new FormControl(false, [Validators.requiredTrue]));
    }

    if (this.cardForm.get("maskedCardNumber")) {
      this.cardForm.get("maskedCardNumber").valueChanges.subscribe(val => {
        if (val && !val.includes('*')) {
          this.cardForm.get('cardNumber').setValue(val);
          this.cardForm.get('cardNumber').markAsTouched();
          setTimeout(() => {
            if (!this.cardForm.get('cardNumber').valid) {
              this.cardNumberError = true;
            } else {
              this.cardNumberError = false;
              this.setCvvLength();
            }
          }, 0);
        }
      });
    }

    if (this.cardForm.get("maskedSecurityCode")) {
      this.cardForm.get("maskedSecurityCode").valueChanges.subscribe(val => {
        if (val && !val.includes('*')) {
          this.cardForm.get('securityCode').setValue(val);
          this.cardForm.get('securityCode').markAsTouched();
        }
      });
    }

    this.cardNumberChanges();
  }

  onTermsChanged(item) {
    this.cardForm.get('plan_terms').setValue(item.value);
  }

  setCvvLength() {
    if (this.cardForm.get('securityCode')) {
      this.cvvLength = this.cardType === 'American Express' ? 4 : 3;
      this.cardForm.get('securityCode').clearValidators();
      this.cardForm.get('maskedSecurityCode').clearValidators();
      this.cardForm.get('securityCode').setValidators([Validators.required, Validators.minLength(this.cvvLength), Validators.maxLength(this.cvvLength)]);
      this.cardForm.get('maskedSecurityCode').setValidators([Validators.required, Validators.minLength(this.cvvLength), Validators.maxLength(this.cvvLength)]);
      this.cardForm.get('securityCode').updateValueAndValidity();
      this.cardForm.get('maskedSecurityCode').updateValueAndValidity();
    }
  }

  cardNumberChanges() {
    let cardNumberControl = this.cardForm.get('cardNumber');
    let cardTypeControl = this.cardForm.get('cardType');
    if (cardNumberControl && cardNumberControl.value && cardNumberControl.value.length >= 15) {
      this.setCvvLength();
    } else if (cardTypeControl && cardTypeControl.value) {
      this.cardType = cardTypeControl.value;
      this.setCvvLength();
    }
    if (cardNumberControl) {
      cardNumberControl
        .valueChanges.pipe(
          tap((val: string) => {
            if (val.length === 0) {
              this.cardType = '';
            }
          }),
          debounceTime(10),
          filter((val: string) => val.length > 4),
          distinctUntilChanged()
        )
        .subscribe(val => {
          this.cardType = this.getCardType(val);
          this.cardForm.get('cardType').setValue(this.cardType);
          if (val.length >= 15) {
            this.setCvvLength();
          }
        });
    }
  }

  getCardType(cardNumber) {
    const cardType = /^5[1-5]/.test(cardNumber)
      ? 'Master Card'
      : /^4/.test(cardNumber)
        ? 'Visa'
        : /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/.test(
          cardNumber
        )
          ? 'Discover'
          : /^(?:3[47][0-9]{13})$/.test(cardNumber)
            ? 'American Express'
            : '';
    return cardType;
  }

  onExpiryMonthChanged() {
    this.checkMonthValidity();
  }

  onExpiryYearChanged() {
    this.checkMonthValidity();
  }

  checkMonthValidity() {
    const expirationMonth = this.cardForm.get('expirationMonth');
    const expirationYear = this.cardForm.get('expirationYear').value;
    this.expirationMonthError = false;
    this.expirationYearError = false;
    this.invalidExpirationMonth = false;
    if (expirationMonth.value === '%%' || expirationYear === '%%') {
      if (expirationMonth.value === '%%') {
        this.expirationMonthError = true;
      }
      if (expirationYear === '%%') {
        this.expirationYearError = true;
      }
      return;
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    if (
      Number(expirationYear) === currentYear &&
      expirationMonth.value < currentMonth
    ) {
      this.invalidExpirationMonth = true;
      expirationMonth.setErrors({ invalid: true });
    } else {
      this.invalidExpirationMonth = false;
      expirationMonth.setErrors();
    }
    if (expirationYear !== '%%' && !this.invalidExpirationMonth) {
      this.cardForm
        .get('expirationDate')
        .setValue(`${expirationMonth.value}/${expirationYear}`);
    } else {
      this.cardForm.get('expirationDate').setValue('');
    }
  }
}
