import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trimInput]'
})
export class TrimInputDirective {

  constructor(private el: ElementRef, private control : NgControl) { }

  @HostListener('blur')
  onBlur(): void {
    const trimvalue = (<HTMLInputElement>this.el.nativeElement).value.trim();
    this.control.control.setValue(trimvalue);
  }
}
