import { Component, Input, OnChanges } from '@angular/core';
import { PolicyProviderService } from '../services/policy-provider.service';
import { IPolicyGreeting, IPolicy, UtilityProviderService } from '@prcins/utils';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'prcins-policy-highlight',
  templateUrl: './policy-highlight.component.html',
  styleUrls: ['./policy-highlight.component.css']
})
export class PolicyHighlightComponent implements OnChanges {
  @Input() policy: IPolicy;
  @Input() noic: any;

  policyGreeting$: Observable<IPolicyGreeting>;

  constructor(private policyProvider: PolicyProviderService, private router: Router,
    private route: ActivatedRoute, private utilityProvider: UtilityProviderService) { }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this.policyGreeting$ = this.policyProvider.getPolicyGreetingDetails(
      this.policy.policyNumber
    );
  }

  onNeedToSelected(type) {

    if (type === 'proof') {
      this.router.navigate(['../documents/idcards'], { relativeTo: this.route });
    } else if (type === 'vehicles') {
      this.router.navigate(['../policy/vehicles'], { relativeTo: this.route });
    } else if (type === 'drivers') {
      this.router.navigate(['../policy/drivers'], { relativeTo: this.route });
    } else if (type === 'coverage') {
      this.router.navigate(['../documents/policy-declaration'], { relativeTo: this.route });
    } else if (type === 'paypplan') {
      this.router.navigate(['../billing/change-plan'], { relativeTo: this.route });
    } else if (type === 'address') {
      this.router.navigate(['../address'], { relativeTo: this.route });
    } else if (type === 'docs') {
      this.router.navigate(['../documents/policy-documents'], { relativeTo: this.route });
    } else if (type === 'claim') {
      this.openGeneralClaim();
    }

  }

  openGeneralClaim() {
    this.utilityProvider.getClaimsToken().subscribe(data => {
      window.open(data, "_blank");
    });
  }

  showABTestRegular(policy: IPolicy, greeting: IPolicyGreeting): boolean {
    if (policy.policyInfo.lob === 'PA') {
        return false;
    } else {
      return true;
    }
  }


}
