<div class="w-full mb-8">

  <ng-container *ngIf="policySelected$ | async as policy">
    <prcins-incomplete-status *ngIf="policy.policyInfo.statusCode === 'Incomplete'; else active">
    </prcins-incomplete-status>
    <ng-template class="px-4" #active>
      <ng-container *ngIf="covidMessageData && covidMessageData.status=='SUCCESS'">
        <div class="mb-8 mt-4 border rounded relative covod19-notification">
          <div class="flex items-center p-2 pr-2">
            <span class="covid-notification"><strong>i</strong></span><span><strong>A Covid-19 Relief Credit of
                ${{covidMessageData.premium}} will be applied to your policy in
                {{covidMessageData.creditMonth}}</strong></span>
          </div>
          <div class="flex items-center p-4 pr-4 pt-0">
            <span>You will see this credit listed as a payment when reviewing your <a class="text-es-blue-light"
                [routerLink]="['../billing/history']">Payment History</a>. This will also be listed as a Covid-19 Relief
              Credit on your next billing statement.</span>
          </div>
        </div>
      </ng-container>

      <div *ngIf="(showRoadRewards && isPolicyNotCancelledOrExpired) && ( validRRLetsGo || validRRdisplay)"
        class="popup-fixed-container">
        <div name="popupRewards" class="popup-container">
          <div class="popup-item-container mt-20 sm:mt-24 md:mt-20">
            <div>
              <div>
                <ng-container *ngIf="validRRLetsGo ; else roadRewardsEnroll">
                  <div class="flex flex-col justify-center items-center popup-roadrewards"
                    style="background-color: white;border-radius: 1.1rem;">
                    <img src="assets/svgs/roadrewards-popup-complete-enrollement.svg" alt="">
                  </div>
                  <div class="flex justify-between flex-wrap-reverse" style="position: relative; top:-70px;">
                    <button class="es-btn-primary mt-0 m-auto w-56 font-bold" (click)="openRRPage()">LET'S GO</button>
                  </div>
                  <div class="flex justify-between flex-wrap-reverse"
                    style="position: relative; top:-580px;right:-247px;">
                    <a class='cursor-pointer font-bold' (click)="cancelRoadReawds()">X</a>
                  </div>

                </ng-container>
                <ng-template #roadRewardsEnroll>
                  <ng-container>
                    <div class="flex flex-col justify-center items-center popup-roadrewards"
                      style="background-color: white;border-radius: 1.1rem;">
                      <img src="assets/svgs/roadrewards-popup.svg" alt="">
                    </div>
                    <div class="flex justify-between flex-wrap-reverse" style="position: relative; top:-120px;">
                      <button class="es-btn-secondary mt-2 m-auto w-56"
                        style="background-color: white;color: #0092d2;font-weight: bold;"
                        (click)="cancelRoadReawds()">NO
                        THANKS</button>
                      <button class="es-btn-primary mt-0 m-auto w-56" (click)="openRoadRewads()">ENROLL NOW</button>
                    </div>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <ng-container *ngIf="policy.policyInfo.statusCode !='Canceled'">
          <ng-container *ngIf="pastDueNotification$ | async as notification">
            <prcins-notification *ngIf="notification" [notification]="notification">
              Did you forget about us? Your {{notification.data.productDesciption}} Policy payment of
              {{notification.data.amount | currency : 'USD'}} was due on
              {{notification.data.dueDate | date : 'MMM d, y'}}
            </prcins-notification>
          </ng-container>
          <ng-container *ngIf="eDocNotification$ | async as notification">
            <prcins-notification *ngIf="showEdocsNotification" [notification]="notification">
              Your {{notification.data.productDesciption}} Policy includes an eDocuments
              {{notification.data.discountType}}.
              In order
              to keep this <span *ngIf="notification.data.discountType == 'discount'; else others">savings,</span>
              <ng-template #others>
                {{notification.data.discountType}},
              </ng-template>
              you must enroll in eDocs. It's easy! <a class='cursor-pointer font-bold text-es-blue-light'
                (click)="togglePopup()">Enroll now.</a>
              <!-- href='../eservice/home/documents/manage-edocs' -->
            </prcins-notification>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="noicNotification$ | async as notification">
          <prcins-notification *ngIf="notification" [notification]="notification">
            Your {{notification.data.productDesciption}} Policy will be cancelled for non-payment on
            {{notification.data.cancellationDate | date : 'MMM d, y'}} if payment in the amount of
            {{notification.data.amount | currency : 'USD'}} is not received before {{notification.data.cancellationDate
            | date : 'MMM d, y'}}
          </prcins-notification>
        </ng-container>
        <prcins-notification *ngIf="notificationModel" [notification]="notificationModel">
        </prcins-notification>


      </div>

      <prcins-edoc-promotion-banner *ngIf="showEdocsPromotion"></prcins-edoc-promotion-banner>
      <prcins-policy-highlight [policy]="policy" [noic]="noicNotification$ | async"></prcins-policy-highlight>

      <div *ngIf="agentInfoLoaded$ | async">
        <div *ngIf="isHO3Policy && isAlligned" class="bg-gray-200 py-2">
          <div class="flex justify-center p-2 pl-4 font-whitney-semibold">Peace of mind for a few extra dollars.</div>
          <div class="flex justify-center mb-4 pl-4">

            <div *ngFor="let lqLink of hoLandingQuickLinks"
              class="flex flex-col justify-center items-center border border-2 rounded-lg bg-white cursor-pointer mr-4 w-32">
              <span class="mt-2 py-2 w-20 h-16"
                style="text-align : center">{{landingQuickLinksConfig[lqLink].label}}</span>
              <div class="border-b-2 px-4"><img src="{{landingQuickLinksConfig[lqLink].icon}}" alt=""></div>
              <div class="flex items-center justify-center mt-2">

                <a *ngIf="lqLink !== 'additionalProtection' && channel === 'DIRECT'"
                  class="text-es-orange mb-2 cursor-pointer" target="_blank"
                  (click)="showRedirectPopup(landingQuickLinksConfig[lqLink].link)">Details</a>
                <a *ngIf="lqLink !== 'additionalProtection' && channel !== 'DIRECT'"
                  class="text-es-orange mb-2 cursor-pointer" target="_blank"
                  (click)="showAgentLetUsHelpYouPopUp = true">Details</a>
                <a *ngIf="lqLink === 'additionalProtection'" class="text-es-orange mb-2 cursor-pointer"
                  [routerLink]="landingQuickLinksConfig[lqLink].link">Details</a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <prcins-billing-overview [policy]="policy" [noic]="noicNotification$ | async"></prcins-billing-overview>
      <prcins-cross-sell-promotion-widget *ngIf="showHOCrossSellPromotion"
        [pageType]="'Eservice_Homepage'"></prcins-cross-sell-promotion-widget>
      <!-- <prcins-premium-entry [policy]="policy"></prcins-premium-entry> -->
      <ng-container *ngIf="roadRewardsLoaded$ | async; else roadRewardsEnroll">
        <prcins-road-rewards-banner *ngIf="(roadRewardsDriverLoaded$ | async) && isRoadRewardsEligible() && isPolicyNotCancelledOrExpired && validRRdisplay"
          (enrollRoadRewards)="openRoadRewads()"></prcins-road-rewards-banner>
      </ng-container>
      <ng-template #roadRewardsEnroll>
        <prcins-card pt="pt-0">
          <div body>
            <prcins-skeleton numOfRows="5"></prcins-skeleton>
          </div>
        </prcins-card>
      </ng-template>
      <prcins-open-claims [policy]="policy"></prcins-open-claims>

      <ng-container prcins-tealium-tracker="load" [prcins-tealium-evar]="{'policy_number':policy.policyNumber,
      'statusCode':policy.policyInfo.state,
      'lob':policy.policyInfo.lob,
      'channel':policy.policyInfo.channel}"></ng-container>
    </ng-template>
  </ng-container>
</div>

<prcins-edocs-popup [showIf]="showEdocsPopup" (discard)="togglePopup()"></prcins-edocs-popup>
<prcins-es-popup *ngIf="showAgentLetUsHelpYouPopUp" headerClass="bg-white text-white">

  <div body class="items-center justify-between">
    <div class="flex justify-center items-center text-es-orange text-center text-xl font-semibold">Let us help you.
    </div>
    <p *ngIf="channel === 'DIRECT'" class="mt-4">
      We would like to assist you with this change, please contact us for assistance.</p>
    <p *ngIf="channel !== 'DIRECT'" class="mt-4">
      Your agent would like to assist you with this change. Please contact {{agentInfo.agencyName}} for assistance.
    </p>

    <div class="mt-4">
      <a *ngIf="channel === 'DIRECT'" href="tel:{{generalPhone}}">
        <button class="add-btn add-btn-primary">{{generalPhone}}</button>
      </a>
      <a *ngIf="channel !== 'DIRECT'" href="tel:{{agentInfo.agencyPhone}}">
        <button class="add-btn add-btn-primary cursor-pointer">{{agentInfo.agencyPhone}}</button>
      </a>
      <a class="es-link-secondary w-full mt-4 cursor-pointer" (click)="closeShowAgentLetUsHelpYouPopUp()">Close</a>
    </div>
  </div>
</prcins-es-popup>

<prcins-es-popup *ngIf="showRedirectPopupFlag" headerClass="bg-white text-white">

  <div body class="items-center justify-between">
    <div class="flex justify-center items-center text-es-orange text-center text-xl font-semibold">You're being
      redirected to a partner website.</div>
    <p class="mt-4">
      Our trusted partner will help you get the best coverage, visit there site to learn more.</p>

    <div class="mt-4">
      <a href="{{redirectQuickLink}}">
        <button class="add-btn add-btn-primary cursor-pointer">Continue</button>
      </a>
      <a class="es-link-secondary w-full mt-4 cursor-pointer" (click)="closeRedirectPopup()">Go back</a>
    </div>
  </div>
</prcins-es-popup>