import { Component, OnInit } from '@angular/core';
import { PolicyProviderService } from '../services/policy-provider.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { INotification, LoginService } from '@prcins/utils';

@Component({
  selector: 'prcins-admin-delete-account',
  templateUrl: './admin-delete-account.component.html',
  styleUrls: ['./admin-delete-account.component.css']
})
export class AdminDeleteAccountComponent implements OnInit {

  registeredPolicies: any[];
  email: string;
  fullName: string = '';
  notification: INotification;
  isLoading: boolean = false;

  constructor(private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService) { }

  ngOnInit() {
    this.isLoading = true;
    this.adminService.adminActivityError.subscribe(data => this.notification = data);
    const email = this.route.snapshot.queryParams.email;
    this.loginService.getAllAssociatedPolicies(email).subscribe(registeredPolicies => {
      this.registeredPolicies = registeredPolicies;
      if (this.registeredPolicies.length) {
        const { policyInfo: accountInfo } = this.registeredPolicies[0];
        this.fullName = accountInfo.firstName + ' ' + accountInfo.lastName;
      } else {
        this.loginService.getActiveAccount(email).subscribe(accountInfo => {
          this.fullName = accountInfo.firstName + ' ' + accountInfo.lastName;
        });
      }
      this.email = email;
      this.isLoading = false;
    }, () => { this.isLoading = false; });
  }


  onDisableAccount() {
    this.adminService.disableAccount(this.email, this.registeredPolicies.length ? this.registeredPolicies[0].policyNumber : '').subscribe(data => {
      if (data.messageCode && data.messageCode === "DISABLE_ACCOUNT_SUCCESS") {
        this.router.navigate(['..'], { relativeTo: this.route }).then(data => {
          console.log('Route Callback');
          this.adminService.adminActivityError.next({ type: 'INFO', message: 'Account Disabled' });
        });
      } else {
        this.adminService.adminActivityError.next({ type: 'ERROR', message: 'Disable Account Failed' });
      }
    });
  }

}
