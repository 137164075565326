import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import gql from 'graphql-tag';
import {
  PaymentDetailsQuery,
  PolicyReviewQuery,
  MobileRegistrationQuery,
  MobileRegisteredPolicyQuery,
  BillingEftQuery
} from '@prcins/utils';
import {
  PAYMENT_URL,
  QPAY_PAYMENT_DETAILS,
  REGISTRATION_URL
} from '@prcins/constants';
import { LOGIN_URL } from '@prcins/constants';

@Injectable({
  providedIn: 'root'
})
export class PaymentProviderService {
  private APP_NAME = 'qpay';
  private _ACHValidationFailureCount = 0;
  constructor(private http: HttpClient, private apollo: Apollo) {}

  showRegistrationWidget(payload) {
    return this.http.post(REGISTRATION_URL.Enrollment, payload);
  }

  stepOneRegistration(payload) {
    return this.http.post(REGISTRATION_URL.MobileVerification, payload);
  }

  stepTwoRegistration(payload) {
    return this.http.post(
      REGISTRATION_URL.MobileSecurityCodeVerification,
      payload
    );
  }

  eDiscloserAudit(payload: any) {
    return this.http.post(LOGIN_URL.AuditUrl, payload).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getPayloadForRegistration(policyNumber) {
    return this.apollo
      .watchQuery({
        query: MobileRegistrationQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }) => data));
  }

  getPaymentDetails(policyNumber: string): Observable<any> {
    return this.apollo
      .watchQuery({
        query: PaymentDetailsQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }) => data));
  }

  encryptPaymentDetails(paymentDetails: any, policyNumber: string): any {
    return this.http.post(PAYMENT_URL.Secure, {
      ...paymentDetails,
      policyNumber
    });
  }

  validateCard(cardValidationPayload: any, underwriterCode: string) {
    const validateUrl = `${PAYMENT_URL.ValidateCard}/${
      cardValidationPayload.policyNumber
    }/${underwriterCode}/${this.APP_NAME}`;
    return this.http.post(validateUrl, cardValidationPayload);
  }

  setPaymentDetails(paymentDetails: any) {
    if (paymentDetails.paymentOptionSelected === 'cc') {
      paymentDetails.cardForm['__typename'] = 'CardDetails';
      paymentDetails.eftForm = {
        __typename: 'AccountDetails',
        accountEmailId: '',
        accountHolderName: '',
        accountType: '%%',
        accountNumber: '',
        reAccountNumber: '',
        routingNumber: '',
        maskedAccountNumber: '',
        maskedReAccountNumber: '',
        maskedRoutingNumber: ''
      };
    } else {
      paymentDetails.eftForm['__typename'] = 'AccountDetails';
      paymentDetails.cardForm = {
        __typename: 'CardDetails',
        cardHolderName: '',
        cardHolderEmailId: '',
        cardNumber: '',
        securityCode: '',
        transactionType: '%%',
        maskedCardNumber: '',
        expirationDate: ''
      };
    }
    this.apollo.getClient().writeData({
      data: {
        paymentDetails: {
          ...paymentDetails,
          __typename: 'PaymentDetails'
        }
      }
    });
  }

  public getPaymentDetailsFromSession(policyNumber: string): Observable<any> {
    const pd = sessionStorage.getItem(QPAY_PAYMENT_DETAILS);
    if (pd && policyNumber) {
      return this.http.post(PAYMENT_URL.Decrypt, {
        pd,
        policyNumber
      });
    }
    return of(null);
  }

  getPolicyPaymentDetails() {
    try {
      return this.apollo.getClient().readQuery({
        query: gql`
          {
            paymentDetails {
              paymentAmount
              paymentOptionSelected
              cardForm {
                cardHolderName
                cardHolderEmailId
                transactionType
                cardNumber
                maskedCardNumber
                securityCode
                expirationDate
              }
              eftForm {
                accountEmailId
                accountHolderName
                accountType
                accountNumber
                reAccountNumber
                routingNumber
                maskedAccountNumber
                maskedReAccountNumber
                maskedRoutingNumber
                etfSavedPaymentMethod
              }
            }
          }
        `
      });
    } catch (ex) {
      return {};
    }
  }

  getPolicyReviewDetails(policyNumber: string): Observable<any> {
    return this.apollo
      .watchQuery({
        query: PolicyReviewQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }: any) => data));
  }

  getMobileRegisteredPolicies(username?: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: MobileRegisteredPolicyQuery,
        variables: {
          username: username
        },
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map(({ data }: any) => data.account.registeredPolicies)
      );
  }

  public makePayment(type, payload: {}): Observable<any> {
    const url = `${PAYMENT_URL.Payment}/${type}`;
    return this.http.post(url, payload);
  }

  getPendingPaymentsDetails(policyNumber): Observable<any> {
    return this.http.get(PAYMENT_URL.checkPendingPayments+ '/' +policyNumber);
  }

  getEFTDetails(policyNumber, payPlan): Observable<any> {
    return this.apollo
      .watchQuery({
        query: BillingEftQuery,
        variables: {
          policyNumber,
          payPlan
        },
        fetchPolicy: "no-cache"
      })
      .valueChanges.pipe(map(({ data }: any) => data.billingEftData));
  }

  resetACHFailureCount(){
    this._ACHValidationFailureCount=0;
  }

  incrementACHFailureCount(){
    this._ACHValidationFailureCount++;
  }

  getACHFailureCount(): number{
    return this._ACHValidationFailureCount;
  }

}
