import { Component, Input, OnChanges } from '@angular/core';
import { errorLabels } from './error.constants';

@Component({
  selector: 'prcins-error-label',
  templateUrl: './error-label.component.html',
  styleUrls: ['./error-label.component.css']
})
export class ErrorLabelComponent implements OnChanges {
  @Input() control;
  @Input() controlLabel;
  @Input() controlValue;
  @Input() controlTouched;

  errorMessage;
  errorField;

  ngOnChanges(): void {
    if (!this.errorField) {
      this.errorField = errorLabels[this.controlLabel];
    }
    this.errorMessage = '';
    if (this.controlTouched && !this.control.valid && this.errorField) {
      Object.keys(this.control.errors).forEach(error => {
        if (this.errorField[error]) {
          this.errorMessage += this.errorField[error] + ' ';
        }        
      });
    }
  }
}

/*** Usage
 * <prcins-error-label [control]="paymentForm.get('paymentAmount')"
 * [controlValue]="paymentForm.get('paymentAmount').value" controlLabel="paymentForm_paymentAmount"
 * [controlTouched]="paymentForm.get('paymentAmount').touched">
 * </prcins-error-label>
**/
