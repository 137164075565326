/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./policy-selector.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./mobile-selector/mobile-selector.component.ngfactory";
import * as i3 from "./mobile-selector/mobile-selector.component";
import * as i4 from "@angular/router";
import * as i5 from "./desktop-selector/desktop-selector.component.ngfactory";
import * as i6 from "./desktop-selector/desktop-selector.component";
import * as i7 from "@angular/common";
import * as i8 from "./policy-selector.component";
import * as i9 from "../../../../../libs/utils/src/lib/services/policy-broadcaster.service";
var styles_PolicySelectorComponent = [i0.styles];
var RenderType_PolicySelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PolicySelectorComponent, data: {} });
export { RenderType_PolicySelectorComponent as RenderType_PolicySelectorComponent };
function View_PolicySelectorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-mobile-selector", [], null, [[null, "policySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("policySelected" === en)) {
        var pd_0 = (_co.onPolicySelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MobileSelectorComponent_0, i2.RenderType_MobileSelectorComponent)), i1.ɵdid(1, 573440, null, 0, i3.MobileSelectorComponent, [i4.Router, i4.ActivatedRoute], { registeredPolicies: [0, "registeredPolicies"], isHome: [1, "isHome"], noSelector: [2, "noSelector"] }, { policySelected: "policySelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allowedPolicies; var currVal_1 = _co.isHome; var currVal_2 = _co.noSelector; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_PolicySelectorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-desktop-selector", [], null, [[null, "policySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("policySelected" === en)) {
        var pd_0 = (_co.onPolicySelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DesktopSelectorComponent_0, i5.RenderType_DesktopSelectorComponent)), i1.ɵdid(1, 573440, null, 0, i6.DesktopSelectorComponent, [i4.Router, i4.ActivatedRoute], { registeredPolicies: [0, "registeredPolicies"], isHome: [1, "isHome"], noSelector: [2, "noSelector"] }, { policySelected: "policySelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allowedPolicies; var currVal_1 = _co.isHome; var currVal_2 = _co.noSelector; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_PolicySelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicySelectorComponent_3)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["large", 2]], null, 0, null, View_PolicySelectorComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLarge; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PolicySelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicySelectorComponent_2)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hide; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PolicySelectorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "flex flex-center bg-red-700 p-4 w-full text-white shadow-lg mb-4 font-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This policy is not registered in eService. This is only a preview. "]))], null, null); }
export function View_PolicySelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicySelectorComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["preview", 2]], null, 0, null, View_PolicySelectorComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isPreview(); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_PolicySelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-policy-selector", [], null, null, null, View_PolicySelectorComponent_0, RenderType_PolicySelectorComponent)), i1.ɵdid(1, 770048, null, 0, i8.PolicySelectorComponent, [i9.PolicyBroadcasterService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PolicySelectorComponentNgFactory = i1.ɵccf("prcins-policy-selector", i8.PolicySelectorComponent, View_PolicySelectorComponent_Host_0, { registeredPolicies: "registeredPolicies" }, {}, []);
export { PolicySelectorComponentNgFactory as PolicySelectorComponentNgFactory };
