/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./desktop-header/desktop-header.component.ngfactory";
import * as i3 from "./desktop-header/desktop-header.component";
import * as i4 from "../../../../../libs/utils/src/lib/services/navigation-provider.service";
import * as i5 from "@angular/common";
import * as i6 from "./mobile-header/mobile-header.component.ngfactory";
import * as i7 from "./mobile-header/mobile-header.component";
import * as i8 from "./header.component";
import * as i9 from "@angular/router";
import * as i10 from "apollo-angular";
import * as i11 from "../../../../../libs/utils/src/lib/services/policy-broadcaster.service";
import * as i12 from "../../../../../libs/utils/src/lib/services/login.service";
import * as i13 from "../../../../../libs/utils/src/lib/services/utility-provider.service";
import * as i14 from "../../../../../libs/utils/src/lib/services/right-rail.service";
import * as i15 from "ngx-cookie-service";
import * as i16 from "../services/policy-provider.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "prcins-desktop-header", [], null, [[null, "logOut"], [null, "softLogOut"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logOut" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } if (("softLogOut" === en)) {
        var pd_1 = (_co.onSoftLogout() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DesktopHeaderComponent_0, i2.RenderType_DesktopHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DesktopHeaderComponent, [i4.NavigationProviderService], { menus: [0, "menus"], activeMenus: [1, "activeMenus"], selectedHeader: [2, "selectedHeader"] }, { logOut: "logOut", softLogOut: "softLogOut" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "prcins-mobile-header", [], null, [[null, "logOut"], [null, "softLogOut"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logOut" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } if (("softLogOut" === en)) {
        var pd_1 = (_co.onSoftLogout() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_MobileHeaderComponent_0, i6.RenderType_MobileHeaderComponent)), i1.ɵdid(4, 114688, null, 0, i7.MobileHeaderComponent, [i4.NavigationProviderService], { menus: [0, "menus"], activeMenus: [1, "activeMenus"], selectedHeader: [2, "selectedHeader"] }, { logOut: "logOut", softLogOut: "softLogOut" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.menus)); var currVal_1 = _co.activeMenus; var currVal_2 = _co.selectedHeader; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.menus)); var currVal_4 = _co.activeMenus; var currVal_5 = _co.selectedHeader; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i8.HeaderComponent, [i9.Router, i9.ActivatedRoute, i10.Apollo, i11.PolicyBroadcasterService, i12.LoginService, i13.UtilityProviderService, i14.RightRailService, i15.CookieService, i16.PolicyProviderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("prcins-header", i8.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
