import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPolicy, IRightRail, PolicyBroadcasterService, UtilityProviderService, INotification, EdocsBroadcasterService, RightRailService } from '@prcins/utils';
import { Router, NavigationStart, RouterState, NavigationEnd } from '@angular/router';
import {
  GENERAL_URL,
  CURRENT_POLICY,
  USER_FNAME,
  USER_LNAME,
  USER_ID,
  CURRENT_POLICY_LOB,
  CURRENT_POLICY_PRODUCT_CODE,
  CURRENT_POLICY_STATUS,
  CURRENT_POLICY_CHANNEL
} from '@prcins/constants';
import { LoginService } from '@prcins/utils';
import { Observable, Subscription } from 'rxjs';
import { NotificationService } from '../notification.service';
import { environment } from '@prcins/environments';

@Component({
  selector: 'prcins-right-rails',
  templateUrl: './right-rails.component.html',
  styleUrls: ['./right-rails.component.css']
})
export class RightRailsComponent implements OnInit, OnDestroy {
  rightRailsLoading = false;
  eDocsLoading: boolean = false;
  rightRail: IRightRail;
  edocsResponse: boolean = false;
  hidePolicyLevelItems = false;
  showEft = false;
  showEdocs = false;
  faq = GENERAL_URL.faq;
  hideRightRails = false;
  showEdocsPopup = false;
  showSpinner = false;
  edocsEnrollmentNotification: INotification;
  isHome = false;
  homeEvidence$: Observable<boolean>;
  isPolicyLevel = false;
  routeSubscription: Subscription
  validRRdisplay = false;
  policyLoaded$: Observable<any>;
  isPolicyIncomplete = false;
  showAgentInfo$: Observable<boolean>;
  agentInfoLoaded$: Observable<boolean>;
  showEserviceTxtMessage: boolean = false;
  showQuickLinks = true;
  isDirectOrCaptive = false;
  policy = null;
  productCode;

  constructor(
    private policyBroadcaster: PolicyBroadcasterService,
    private rightRailService: RightRailService,
    private notificationService: NotificationService,
    private router: Router,
    private loginProvider: LoginService,
    private utilityProvider: UtilityProviderService,
    private eb: EdocsBroadcasterService

  ) {
    this.agentInfoLoaded$ = this.rightRailService.agentInfoLoaded$;
  }

  ngOnInit() {
    this.productCode = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE);
    console.log(sessionStorage.getItem(CURRENT_POLICY_LOB));
    this.policyBroadcaster.policySelected$.subscribe((val: IPolicy) => {

      this.policy = val;

      if (val && val.policyInfo.lob === 'PA' && val.policyInfo.statusCode === 'Active') {
        this.showQuickLinks = false;
      }

      this.isPolicyIncomplete = sessionStorage.getItem(CURRENT_POLICY_STATUS) === 'Incomplete';

      if (this.isPolicyIncomplete) {
        return;
      }
      this.routeSubscription = this.router.events.subscribe((routerEvent: any) => {
        if (routerEvent instanceof NavigationStart) {
          this.checkPolicyLevel(routerEvent.url);
          this.checkRouter(routerEvent.url);
        } else if (routerEvent instanceof NavigationEnd) {
          if (this.edocsResponse) {
            this.showEdocs = this.edocsResponse;
          }
          this.showEftOrEdoc(this.showEft, this.showEdocs, routerEvent.url);
        }
      });

      this.isHome = sessionStorage.getItem(CURRENT_POLICY_LOB) === 'HO';



      // this.router.routerState.snapshot.url;
      // let currentUrl = this.router.url;
      this.checkPolicyLevel(this.router.url);
      this.rightRailsLoading = true;

      this.rightRailService.agentInfoLoaded$.next(false);

      this.rightRailService.getQuickLinks(val.policyNumber).subscribe(data => {
        console.log(data);
        if (data) {
          this.rightRail = data;
          sessionStorage.setItem("agentInfo", JSON.stringify(this.rightRail.agentInfo));
          this.rightRailService.agentInfoLoaded$.next(true);
          this.rightRailsLoading = false;
          sessionStorage.setItem("general-phone", this.rightRail.generalPhone);
          sessionStorage.setItem("claimsPhone", this.rightRail.claimsPhone);
          if (this.rightRail) {
            this.showEft = !this.isHome && this.rightRail.showEft;
            this.showEserviceTxtMessage = this.rightRail.showEServiceTxtMsg;
          }
          if (this.edocsResponse) {
            this.showEdocs = this.edocsResponse;
          }
          this.showEftOrEdoc(this.showEft, this.showEdocs, this.router.url);
        }
      });
      this.eDocsLoading = true;
      this.rightRailService.getEdocs(val.policyNumber).subscribe(eDocs => {
        // currentUrl = this.router.url;
        this.eDocsLoading = false;
        this.edocsResponse = eDocs;
        // this.showEdocs = this.edocsResponse &&
        //   (currentUrl.indexOf('/documents/manage-edocs') === -1 &&
        //     currentUrl.indexOf('/home/account/') === -1);
        this.showEftOrEdoc(this.showEft, this.edocsResponse, this.router.url);
      });



      //this.showRoadRewards =  sessionStorage.getItem("showedRoadRewards") == "Y" ? false : true;
      this.policyLoaded$ = this.policyBroadcaster.policyLoaded$;

      this.showAgentInfo$ = this.rightRailService.showAgentInfo$;



      this.policyLoaded$.subscribe(response => {

        this.isDirectOrCaptive = sessionStorage.getItem(CURRENT_POLICY_CHANNEL) === 'DIRECT' || sessionStorage.getItem(CURRENT_POLICY_CHANNEL) === 'CAPTIVE';

        if (sessionStorage.getItem(CURRENT_POLICY_LOB) === 'PA' && sessionStorage.getItem("channel") == 'IA' && sessionStorage.getItem("invalidRRDisplay") == "N") {
          this.validRRdisplay = true;
        } else {
          this.validRRdisplay = false;
        }

      });

    });





    // this.router.events.subscribe((routerEvent: any) => {
    //   if (routerEvent instanceof NavigationStart) {
    //     this.checkPolicyLevel(routerEvent.url);
    //     this.checkRouter(routerEvent.url);
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  checkPolicyLevel(url: string) {
    const userId = sessionStorage.getItem(USER_ID);
    if (userId) {
      this.loginProvider.getRegisteredPolicies(userId).subscribe(data => {
        const isAccountRoute = url.indexOf('/home/account/') !== -1
        this.isPolicyLevel = (isAccountRoute && data.length === 1) || !isAccountRoute;
      });
    }
  }

  checkRouter(url: string) {
    this.hideRightRails = false;
    if (
      url.indexOf('/documents/manage-edocs') !== -1 ||
      url.indexOf('/home/account/') !== -1
    ) {
      this.showEdocs = false;
    } else if (url.indexOf('/home/link-a-policy') !== -1) {
      this.hideRightRails = true;
    } else {
      this.showEdocs = this.edocsResponse;
    }
  }

  manageEdocs(): void {
    this.router.navigate(['eservice/home/documents/manage-edocs']);
  }

  openGeneralClaim() {
    this.utilityProvider.getClaimsToken().subscribe(data => {
      window.open(data, "_blank");
    });
  }

  enrollEdocs() {
    this.showSpinner = true;
    const email = sessionStorage.getItem(USER_ID);
    const policyNumber = sessionStorage.getItem(CURRENT_POLICY);

    this.loginProvider
      .eDiscloserAudit({
        policyNumber,
        agreementType: 'EDOCENROLL',
        agreementType2: 'ELECTRONICCONSENT',
        source: 'ESERVICE',
        email
      })
      .subscribe();

    this.utilityProvider.signupForEdocs(policyNumber, email).subscribe(
      (resp: any) => {
        if (!resp.errorMsg && resp.messageCode === 'edocs.update.success') {
          let userId = sessionStorage.getItem(USER_ID);
          const accounts = this.eb.getEdocs(userId);
          this.eb.updateEdocs(userId, accounts);
          this.eb.updateShowEdocs(policyNumber, false);
          // this.togglePopup();
          this.showSpinner = false;
          this.eb.notify$.next({ type: 'INFO', message: 'Congratulations! You have successfully changed your eDoc preferences.' });
        } else if (resp.errorMsg === 'zip.mismatch') {
          this.showSpinner = false;
          this.notificationService.notify$.next({ type: 'ERROR', message: 'We are unable to find an account for the information you provided. Please make sure the information is correct. If you still cannot sign up, please contact us.' });
        } else if (resp.errorMsg === 'alreadySigned') {
          this.showSpinner = false;
          this.eb.updateShowEdocs(policyNumber, false);
          // this.togglePopup();
          this.eb.notify$.next({ type: 'ERROR', message: 'You have already signed up for eDocuments!' });
        } else {
          this.showSpinner = false;
          this.notificationService.notify$.next({ type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' });
        }

      },
      err => {
        this.notificationService.notify$.next({ type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' });
        this.showSpinner = false;
      }
    );
  }

  hideRightRail() {
    this.hideRightRails = true;
  }


  openRoadRewads() {
    let zip = sessionStorage.getItem("RoadRewardsZip");
    let policyNumber = sessionStorage.getItem("currentPolicy").toUpperCase();
    window.open(environment.RR_URL + "/#/?utm_source=eservice&utm_medium=banner&utm_campaign=rr_enrollment&policyNumber=" + policyNumber + "&zipCode=" + zip, "_blank");
  }

  private showEftOrEdoc(eft, edoc, url) {
    if (eft) {
      this.showEft = true;
      if (url.indexOf('/home/documents/') !== -1 && edoc) {
        this.showEdocs = true;
      } else {
        this.showEdocs = false;
      }
    } else if (edoc) {
      this.showEdocs = true;
    } else {
      this.showEft = false;
      this.showEdocs = false;
    }
    this.policyNoEdoc();
  }

  private policyNoEdoc() {
    if (sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE) === 'PCAT') {
      this.showEdocs = false;
      return;
    }
    const policyNumber = sessionStorage.getItem(CURRENT_POLICY);
    const business = ['PAC', 'PRC', 'PGC', 'CSC'];
    for (const item of business) {
      if (policyNumber.indexOf(item) !== -1) {
        this.showEdocs = false;
        break;
      }
    }
  }

  isTextCustomerEligible() {
    //return sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE) === 'ALN_PA' || sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE) === 'ALN_HO'
    //return sessionStorage.getItem(CURRENT_POLICY_LOB) === 'PA' || sessionStorage.getItem(CURRENT_POLICY_LOB) === 'HO'
  }
}
