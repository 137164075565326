import { Component, Input, Output, EventEmitter  } from '@angular/core';
import { TogglerComponent } from '../toggler/toggler.component';

@Component({
  selector: 'prcins-square-checkbox',
  templateUrl: './square-checkbox.component.html',
  styleUrls: ['./square-checkbox.component.css']
})
export class SquareCheckboxComponent extends TogglerComponent {
    @Input() switchSecondary;
    @Input() tickSecondary;
  /*  @Input() checkBoxName;
    @Input() checkBoxValue;
    @Input() checkBoxChecked;
    @Input() checkBoxDisabled;
    @Output() checkBoxChanged = new EventEmitter<{ key: string; value: string }>();
  
    onCheckBoxChange() {
      this.checkBoxChanged.next({ key: this.checkBoxName, value: this.checkBoxValue });
    }*/
  }

  /* -- Usage ----
  <prcins-square-checkbox 
    [sliderValue]="value" 
    [sliderKey]="key" 
    (sliderChanged)="onChanged($event)">
  </prcins-square-checkbox> 
-- */