<div *ngIf="showPendingPayments && (!!currentPayPlan && !currentPayPlan.includes('EFT'))" class="px-8 py-4 border mb-8 rounded bg-es-sky-blue">
    <div class="flex">
        <span class="mr-2 mt-2">
            <img class="{{iconWidth}}" src="assets/{{icon}}">
          </span>
        <span *ngIf="showSinglePymntMsg && (pageType == 'Payment History' || pageType == 'Future Installments') ; else confirmationNumMsg">
            Your payment of <b>{{paymentDetails[0].PAYMENT_AMOUNT | currency : 'USD'}}</b> was received on <b>{{paymentDetails[0].PAYMENT_PROCESSED_DT | date:'MM/dd/yyyy'}}</b>. 
            It will take up to two business days to process.
        </span>
        <span *ngIf="showSinglePymntMsg && !pageType" #confirmationNumMsg>Thanks for your payment in the amount of <b>{{paymentDetails[0].PAYMENT_AMOUNT | currency : 'USD'}}</b> received on <b>{{paymentDetails[0].PAYMENT_PROCESSED_DT | date:'MM/dd/yyyy'}}</b>. 
            Your confirmation number is <b>{{paymentDetails[0].PAYMENT_CONFIRMATION_NUMBER}}</b>. 
           <!-- <br> It’ll take us up to two business days to update your billing details. Please check back for updates -->
        </span>
        <span *ngIf="showMultiplePymntsMsg && (pageType == 'Payment History' || pageType == 'Future Installments') ; else belowMsg " class="mt-1 mr-8">We have received <b>{{paymentDetails.length}} </b> payments 
            on your account totaling <b>{{ totalPaymentAmt | currency : 'USD'}}</b>. It will take upto two business days to process.
       </span>
        <span *ngIf="showMultiplePymntsMsg && !pageType" #belowMsg class="mt-1 {{paymentMsgClass}}">We have received <b>{{paymentDetails.length}} </b> payments 
               on your account totaling <b>{{ totalPaymentAmt | currency : 'USD'}}</b>.  
        </span>
    </div>
    <div *ngIf="!pageType" class="border-b-2 mt-4"></div>
    <p *ngIf="!pageType" class="mt-4 font-bold"> An update to your amount due is pending as it can take up to two business days for your recent payment to process.
    </p>
    <div *ngIf="currentPaymentDetails && currentPaymentDetails.landingPage && currentPaymentDetails.amountDue > 0">
    <div class="flex justify-between mt-4">
        <span >Amount Due</span>
        <span>{{ currentPaymentDetails.amountDue | currency : 'USD'}}</span>
    </div>
    <div class="flex justify-between mt-1">
        <span>Due Date</span>
        <span>{{currentPaymentDetails.paymentDueDt | date : 'EEEE MM/dd/yyyy'}}</span>
    </div>
    <div class="pt-8 flex justify-center items-center ">
    <a [routerLink]="['../payment/entry']" class="text-es-blue-light mb-2 cursor-pointer underline">Make an Additional Payment</a>
    </div>
</div>
</div>
 <!-- EFT Enrolled Policy Message -->

<div *ngIf="showEFTSection">
   <div *ngIf="!showPendingCancellationSection"  class="px-8 py-4 border mb-8 rounded bg-es-sky-blue">
    <div class="flex justify-center items-center ">
    <span class="font-bold text-es-orange">
        You are enrolled in automated payments
    </span>
    </div>
    <div class="flex mt-4">
        <span *ngIf="showEFTMessageTwo">
            An automated payment of <b>{{currentPaymentDetails.amountDue | currency : 'USD'}}</b> will be submitted on <b>{{currentPaymentDetails.paymentDueDt | date : 'MM/dd/yyyy'}}</b>, using account ending in {{eftAccountNum}}.
        </span>
        <span *ngIf="showEFTMessageOne">
            An automated payment of <b>{{currentPaymentDetails.amountDue | currency : 'USD'}}</b> was submitted on {{currentPaymentDetails.paymentDueDt | date : 'MM/dd/yyyy'}}, using account ending in {{eftAccountNum}}.
        </span>
    </div>
    <div class="border-b-2 mt-4"></div>
    <div>
    <div *ngIf="pageType  !== 'Make a Payment'" class="pt-8 flex justify-center items-center ">
    <a [routerLink]="['../payment/entry']" class="text-es-blue-light mb-2 cursor-pointer underline">Make an Additional Payment</a>
    </div>
    <div class="flex justify-between mt-4">
        <span >
            Automatic payments may take up to 3 business days to appear on your bank statement and policy.
        </span>
    </div>
  </div> 
 </div>
 <div *ngIf="showPendingCancellationSection && pageType  !== 'Make a Payment'" class="px-8 py-4 border mb-8 rounded border-es-pale-orange bg-es-pale-orange-light">
    <div class="flex mt-4">
        <span class="mr-2 mt-2">
            <img class="w-16" src="assets/svgs/help.svg">
          </span>
        <span>
           <b> You must make a payment to avoid an interruption in coverage.</b> Your automatic payments have been paused and will resume once you satisfy the current minimum due.
        </span>
    </div>
    <div class="border-b-2 border-es-pale-orange mt-4"></div>
    <div class="flex justify-center items-center mt-4">
        <span class="font-bold"> Payment Amount</span>
    </div>
    <div class="flex justify-center items-center">
        <span class="text-es-orange"> {{currentPaymentDetails.amountDue | currency : 'USD'}}</span>
    </div>
    <div class="flex justify-center items-center mt-4">
        <span class="font-bold">Due Date</span>
    </div>
    <div class="flex justify-center items-center">
        <span>{{currentPaymentDetails.paymentDueDt | date : 'MM/dd/yyyy' }}</span>
    </div>
 </div>
</div>

<!-- Mortgagee Bill border-es-pale-orange bg-es-pale-orange-light px-8 py-4 border mb-8 rounded bg-es-sky-blue-->
<div *ngIf="showMortgageeSection && !pageType" class="{{divBackground}}">
    <div class="flex mt-2 font-bold">
        <span *ngIf="showMortMsgOne">
            We have billed your mortgage company for any premiums due.
        </span>
    </div>
    <div *ngIf="showMortMsgTwo" class="flex mt-2">
        <span class="mr-2 mt-2">
            <img class="w-16" src="assets/svgs/help.svg">
          </span>
          <span >
            We have billed your mortgage company for any premiums due however we have not received payment yet.
        </span>
    </div>
    <div *ngIf="currentPaymentDetails.amountDue > 0">
        <div class="flex justify-between mt-4">
            <span >Amount Due</span>
            <span>{{ currentPaymentDetails.amountDue | currency : 'USD'}}</span>
        </div>
        <div class="flex justify-between mt-1">
            <span>Due Date</span>
            <span>{{currentPaymentDetails.paymentDueDt | date : 'EEEE MM/dd/yyyy'}}</span>
        </div>
    </div>
    <div *ngIf="showMortMsgTwo" class="pt-8 flex justify-center items-center ">
        <a (click)="showHelpMsg=true" class="text-es-blue-light mb-2 cursor-pointer underline">What should I do?</a>
    </div>
    <div *ngIf="showHelpMsg">
        <div class="flex mt-4">
            <span *ngIf="agentName">
                Contact your mortgage company to check on the status of payment as soon as possible. You can also contact {{agentName}} for help.
            </span>
            <span *ngIf="!agentName">
                Contact your mortgage company to check on the status of payment as soon as possible. You can also contact us for help.
            </span>
        </div>
    </div>
</div>
