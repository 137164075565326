import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AiEnrollmentService } from '@prcins/utils';


@Component({
  selector: 'prcins-invitation-landing',
  templateUrl: './invitation-landing.component.html',
  styleUrls: ['./invitation-landing.component.css']
})
export class InvitationLandingComponent implements OnInit {

  id: string;
  invitationDetails: any;

  constructor(private route: ActivatedRoute,
    private aiEnrollmentService: AiEnrollmentService,
    private router: Router
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.queryParams.id;
    this.aiEnrollmentService.getInvitationDetails(this.id).subscribe(invitationDetails => {
      if (invitationDetails && invitationDetails.landingPage) {
        this.invitationDetails = invitationDetails;
        sessionStorage.setItem('verificationId', invitationDetails.id);
      } else {
        this.router.navigate(['eservice/login']);
      }
    }, (error: HttpErrorResponse) => {
      this.router.navigate(['eservice/login']);
    });
  }


}
