/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cross-sell-promotion-widget.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./cross-sell-promotion-widget.component";
var styles_CrossSellPromotionWidgetComponent = [i0.styles];
var RenderType_CrossSellPromotionWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CrossSellPromotionWidgetComponent, data: {} });
export { RenderType_CrossSellPromotionWidgetComponent as RenderType_CrossSellPromotionWidgetComponent };
export function View_CrossSellPromotionWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "hoxsell-desktop py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["style", "width:100%"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/png/ho_xsell_ads_NoPTmatch_desktop.png"], ["style", "width:100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "hoxsell-mobile py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["style", "width:100%"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "assets/png/ho_xsell_ads_NoPTmatch_mobile.png"], ["style", "width:100%"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.crossPromotionURL, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.crossPromotionURL, ""); _ck(_v, 4, 0, currVal_1); }); }
export function View_CrossSellPromotionWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-cross-sell-promotion-widget", [], null, null, null, View_CrossSellPromotionWidgetComponent_0, RenderType_CrossSellPromotionWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i2.CrossSellPromotionWidgetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CrossSellPromotionWidgetComponentNgFactory = i1.ɵccf("prcins-cross-sell-promotion-widget", i2.CrossSellPromotionWidgetComponent, View_CrossSellPromotionWidgetComponent_Host_0, { pageType: "pageType" }, {}, []);
export { CrossSellPromotionWidgetComponentNgFactory as CrossSellPromotionWidgetComponentNgFactory };
