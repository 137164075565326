import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { USER_ID, CURRENT_POLICY } from '@prcins/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { INotification } from '../models/notification.model';
import { LoginService } from '../services/login.service';
import { UtilityProviderService } from '../services/utility-provider.service';
import { EdocsBroadcasterService } from '../services/edocs-broadcaster.service';

@Component({
  selector: 'prcins-edocs-popup',
  templateUrl: './edocs-popup.component.html',
  styleUrls: ['./edocs-popup.component.css']
})
export class EdocsPopupComponent implements OnInit {

  @Input('showIf')
  showPopup: boolean = false;

  @Input()
  redirectTo: string = null;

  @Output()
  discard = new EventEmitter();

  showSpinner: boolean = false;
  notify$: Subject<INotification>;


  constructor(private loginProvider: LoginService,
    private utilityProvider: UtilityProviderService,
    private eb: EdocsBroadcasterService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  enrollEdocs() {
    this.showSpinner = true;
    const email = sessionStorage.getItem(USER_ID);
    const policyNumber = sessionStorage.getItem(CURRENT_POLICY);

    this.loginProvider
      .eDiscloserAudit({
        policyNumber,
        agreementType: 'EDOCENROLL',
        agreementType2: 'ELECTRONICCONSENT',
        source: 'ESERVICE',
        email
      })
      .subscribe();

    this.utilityProvider.signupForEdocs(policyNumber, email).subscribe(
      (resp: any) => {
        if (!resp.errorMsg && resp.messageCode === 'edocs.update.success') {
          this.eb.updateShowEdocs(policyNumber, false);
          this.hidePopup();
          this.showSpinner = false;
          if (this.redirectTo != null) {
            this.router.navigate([this.redirectTo], { relativeTo: this.route });
          }
          this.eb.notify$.next({ type: 'INFO', message: 'Congratulations! You have successfully changed your eDoc preferences.' });
        } else if (resp.errorMsg === 'zip.mismatch') {
          this.showSpinner = false;
          this.notify$.next({ type: 'ERROR', message: 'We are unable to find an account for the information you provided. Please make sure the information is correct. If you still cannot sign up, please contact us.' });
        } else if (resp.errorMsg === 'alreadySigned') {
          this.showSpinner = false;
          this.eb.updateShowEdocs(policyNumber, false);
          this.hidePopup();
          this.eb.notify$.next({ type: 'ERROR', message: 'You have already signed up for eDocuments!' });
        } else {
          this.showSpinner = false;
          this.notify$.next({ type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' });
        }

      },
      err => {
        this.notify$.next({ type: 'ERROR', message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.' });
        this.showSpinner = false;
      }
    );
  }

  hidePopup() {
    this.discard.next();
    if (this.redirectTo != null) {
      this.router.navigate([this.redirectTo], { relativeTo: this.route });
    }
  }

}
