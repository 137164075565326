import { Component, OnInit } from '@angular/core';
import { LoginService } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { FormValidatorService, UtilityProviderService, RegistrationService } from '@prcins/utils';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'prcins-forgot-email',
  templateUrl: './forgot-email.component.html',
  styleUrls: ['./forgot-email.component.css']
})
export class ForgotEmailComponent implements OnInit {
  showSpinner = false;
  isPolicyFound = true;
  errorMessage: string;

  forgotEmailForm = this.utilityProvider.getRegistrationUserForm();

  constructor(
    private loginProvider: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private formValidator: FormValidatorService,
    private utilityProvider: UtilityProviderService,
    private registrationProvider: RegistrationService
  ) { }

  ngOnInit() {
    sessionStorage.removeItem('verificationId');
    this.registrationProvider.userRegistrationFormObservable.subscribe((resp) => {
      if (_.size(resp) > 0) {
        if (resp.dob) {
          resp.dob = moment(resp.dob).format('MM/DD/YYYY');
        }
        this.forgotEmailForm.patchValue(resp);
      }
    });
  }

  onForgotEmail() {
    if (this.forgotEmailForm.valid) {
      this.showSpinner = true;
      const userPolicy = this.forgotEmailForm.value;
      if (userPolicy.dob) {
        userPolicy.dob = moment(userPolicy.dob).format('YYYY-MM-DD');
      }
      this.registrationProvider
        .forgotEmail(userPolicy)
        .subscribe((resp: any) => {
          if (resp.messageCode === undefined || resp.messageCode === '') {
            if (resp.mfaVerificationRequired) {
              sessionStorage.setItem('verificationId', resp.id);
              this.registrationProvider.userRegistrationForm$.next(userPolicy);
              this.registrationProvider.verifyUserRegisteredOrNot$.next(resp);
              this.router.navigate(['../../verification'], {
                relativeTo: this.route,
              });
            } else {
              this.router.navigate(['../login'], {
                relativeTo: this.route,
                state: {
                  email: resp.emailAddress
                }
              });
            }
          } else {
            this.isPolicyFound = false;
            this.errorMessage = "Oops! We can't seem to find you. Please try that again.";
          }
          this.showSpinner = false;
        }, (error: HttpErrorResponse) => {
          this.showSpinner = false;
        });
    } else {
      this.formValidator.validateAllFormFields(this.forgotEmailForm);
    }
  }
}
