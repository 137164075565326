import { HttpClient } from "@angular/common/http";
import { environment } from "@prcins/environments";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MfaService {
    constructor(http) {
        this.http = http;
    }
    getVerification(userId, type) {
        return this.http.post(environment.BASE_URL + '/account/' + userId + '/verifyuser', {
            type
        });
    }
    saveChanges(verificationId, token, type, action) {
        return this.http.post(`${environment.BASE_URL}/mfa/saveAction?type=${type}`, {
            verificationId,
            token,
            action
        });
    }
    login(verificationId, token, type, action, captchaToken) {
        const headers = { 'headers': { 'userid': action.userId, 'tkn': captchaToken } };
        return this.http.post(`${environment.AUTH_URL}/mfa/login?type=${type}`, {
            verificationId,
            token,
            action
        }, headers);
    }
}
MfaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MfaService_Factory() { return new MfaService(i0.ɵɵinject(i1.HttpClient)); }, token: MfaService, providedIn: "root" });
