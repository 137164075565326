/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./mobile-header.component";
var styles_MobileHeaderComponent = [i0.styles];
var RenderType_MobileHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileHeaderComponent, data: {} });
export { RenderType_MobileHeaderComponent as RenderType_MobileHeaderComponent };
function View_MobileHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "assets/svgs/hamburger.svg"]], null, null, null, null, null))], null, null); }
function View_MobileHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "assets/svgs/close-hamburger.svg"]], null, null, null, null, null))], null, null); }
function View_MobileHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "bg-es-blue text-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "es-mobile-header-menu justify-start items-center"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["class", "mr-2"], ["src", "assets/svgs/logout.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "a", [["class", "es-mobile-header-menu justify-start items-center"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["alt", ""], ["class", "mr-2"], ["src", "assets/svgs/payment-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quick Pay"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "es-mobile-header-menu justify-start items-center"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["alt", ""], ["class", "mr-2"], ["src", "assets/svgs/claims-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Report a Claim"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "a", [["class", "es-mobile-header-menu justify-start items-center border-b"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["alt", ""], ["class", "mr-2"], ["src", "assets/svgs/mailing-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact Us"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "/eservice/login"); _ck(_v, 2, 0, currVal_2); var currVal_5 = _ck(_v, 8, 0, "/qpay/login"); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 7).target; var currVal_4 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.efnolURL, ""); _ck(_v, 11, 0, currVal_6); var currVal_7 = i1.ɵinlineInterpolate(1, "", _co.contactUs, ""); _ck(_v, 14, 0, currVal_7); }); }
export function View_MobileHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "lg:hidden xl:hidden w-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "header", [["class", "sm:flex md:flex p-4 bg-es-blue flex justify-between items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleNavMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeaderComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.showNavigationPanel; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.showNavigationPanel; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.showNavigationPanel; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.mainPage, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "assets/svgs/brands/", _co.brand, "/mobile-logo.svg"); _ck(_v, 3, 0, currVal_1); }); }
export function View_MobileHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-mobile-header", [], null, null, null, View_MobileHeaderComponent_0, RenderType_MobileHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i4.MobileHeaderComponent, [], null, null)], null, null); }
var MobileHeaderComponentNgFactory = i1.ɵccf("prcins-mobile-header", i4.MobileHeaderComponent, View_MobileHeaderComponent_Host_0, {}, {}, []);
export { MobileHeaderComponentNgFactory as MobileHeaderComponentNgFactory };
