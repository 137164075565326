import { Component, Input, OnChanges } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { PolicyProviderService } from '../services/policy-provider.service';
import { IClaims, IPolicy } from '@prcins/utils';
import { ClaimsSearchService } from '../services/claims-search.service';
import { DocumentService } from 'libs/documents/src/lib/services/documents.service';


@Component({
  selector: 'prcins-open-claims',
  templateUrl: './open-claims.component.html',
  styleUrls: ['./open-claims.component.css']
})
export class OpenClaimsComponent implements OnChanges {

  @Input() policy: IPolicy;
  policySelected$: Observable<IPolicy>;
  claimlist: IClaims[];
  openClaims: IClaims[] = [];
  claimsListEmty = false;
  showOpenClaims = false;
  showClaimDocuments = false;
  isLoading = false;

  constructor(private policyProvider: PolicyProviderService,
    private claimsService: ClaimsSearchService,
    private documentService: DocumentService) { }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {

    console.log(this.policy);

    this.openClaims = [];
    this.claimsListEmty = false;
    this.showOpenClaims = false;
    const claimsObservable = this.claimsService.claimsSearchQuery(this.policy.policyNumber);
    const claimsDocObservable = this.documentService.getDocuments(this.policy.policyNumber, 'CLAIMS');
    this.isLoading = true;
    zip(claimsObservable, claimsDocObservable).subscribe(data => {
      this.isLoading = false;
      let [claims, claimDocuments] = data;
      this.openClaims = [];
      this.claimlist = claims['data'].claimsSearch.sort((x, y) => {
        return y.claimNumber - x.claimNumber;
      }).sort((a, b) => {
        return this.getTime(b.dateOfLoss) - this.getTime(a.dateOfLoss);
      });

      this.claimlist.forEach(obj => {
        if (obj.status == "Open") {
          this.openClaims.push(obj)
        }
      });
      if (this.claimlist.length == 0 || this.openClaims.length == 0) {
        this.claimsListEmty = true;
        this.showOpenClaims = true;
      }
      if (this.openClaims.length > 0) this.showOpenClaims = true;
      console.log(this.openClaims);

      if (claimDocuments.length < 0) {
        this.showClaimDocuments = true;
      }
    }, () => { this.isLoading = false; });
  }

  // openGeneralClaim(url,policy,user) {

  // 	userService.tealiumTrack({"event_name": "report_claim"});
  // 	var ua = navigator.userAgent.toLowerCase();
  // 	var isAndroid = ua.indexOf("android") > -1;

  // 	var result = policyService.getUserClaimToken(policy,user.split(" ")[0],user.split(" ")[1]);
  // 	blockUI.start();
  // 	result.success(function (data, status, headers, config) {
  // 		 console.log(data);
  // 		 url = url+"?t="+data.access_token;

  // 	if ("native" == $rootScope.display && !isAndroid) {
  // 		window.open(url);
  // 		self.opener.location.reload();
  // 		return false;
  // 	} else {
  // 		var ref = window.open(url);
  // 		if (!ref) {
  // 			$log.debug("pop-up window has been blocked");
  // 		}
  // 	}

  // 		 blockUI.stop();
  // 	}).error(function (data, status, headers, config) {
  // 		blockUI.stop();
  // 		uiHelper.openErrorModal();
  //   });
  // }

  private getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }

}
