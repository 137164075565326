 
<div *ngIf="policyGreeting$ | async as policyGreetings; else loading">

 <div class="need-to-dropdown-abtest">
    <prcins-card *ngIf="showABTestRegular(policy,policyGreetings)" headerClass="bg-es-blue text-white" >
      <ng-container header-left >
        <ng-container [ngSwitch]="policy.policyInfo.lob">
          <img class="mr-4" src="assets/svgs/car-white-icon.svg" alt="" *ngSwitchCase="'PA'">
          <img class="mr-4" src="assets/svgs/home-white-icon.svg" alt="" *ngSwitchCase="'HO'">
          <img class="mr-4" src="assets/png/motor.png" width="50" height="50" alt="" *ngSwitchCase="'MC'">
          <ng-container *ngSwitchCase="'CA'">
            <ng-container *ngIf="policy.policyInfo.productCode==='PCAT' || policy.policyInfo.productCode==='ALN_UMB'; else businessAutoGroup">
              <img class="mr-2 lob-icon" src="assets/png/white umbrella icon.png" alt="">
            </ng-container>
            <ng-template #businessAutoGroup>
              <img class="mr-2 lob-icon" src="assets/png/white truck icon.png" alt="">
            </ng-template>
          </ng-container>
        </ng-container>

        <span class="ml-2">Policy #:<span class="ml-2">{{policy.policyNumber}}</span></span>
      </ng-container>
       <a header-right class="hidden sm:block" [routerLink]="['../documents/policy-declaration']">View Coverage
        Details</a> 
       
      <div body>
        <ng-container>
          <div>Policy Period:<span class="ml-2 font-bold">{{policyGreetings.effectiveDate | date : 'MM/dd/yyyy'}} -
              {{policyGreetings.expirationDate | date : 'MM/dd/yyyy'}}</span></div>
          <div>Policy Status:<span class="ml-2 font-bold">{{policyGreetings.statusCode}}</span></div>
        </ng-container>
      </div>
    </prcins-card>
  
    <prcins-card *ngIf="!showABTestRegular(policy,policyGreetings)"  headerClass="bg-es-blue text-white ab-test" >
      <ng-container header-left >
        <ng-container [ngSwitch]="policy.policyInfo.lob">
          <img class="mr-4" src="assets/svgs/car-white-icon.svg"  alt="" *ngSwitchCase="'PA'" style="height:25px">
          <img class="mr-4" src="assets/svgs/home-white-icon.svg" alt="" *ngSwitchCase="'HO'">
          <img class="mr-4" src="assets/png/motor.png" width="50" height="50" alt="" *ngSwitchCase="'MC'">
          <ng-container *ngSwitchCase="'CA'">
            <ng-container *ngIf="policy.policyInfo.productCode==='PCAT' || policy.policyInfo.productCode==='ALN_UMB'; else businessAutoGroup">
              <img class="mr-2 lob-icon" src="assets/png/white umbrella icon.png" alt="">
            </ng-container>
            <ng-template #businessAutoGroup>
              <img class="mr-2 lob-icon" src="assets/png/white truck icon.png" alt="">
            </ng-template>
          </ng-container>
        </ng-container>
    
        <span class="ml-2">Policy #:<span class="ml-2">{{policy.policyNumber}}</span></span>
      </ng-container>
      <!-- <a header-right class="hidden sm:block" [routerLink]="['../documents/policy-declaration']">View Coverage
        Details</a> -->
        <ng-container header-right >
       <div class="custom-ng-select">
        <ng-select  [clearable]=false [searchable]="false" placeholder="I need to...." (change)="onNeedToSelected($event)" >
          
          <ng-option value="proof">Get Proof Of Insurance</ng-option>
          <ng-option value="vehicles">View/Edit Vehicles</ng-option>
          <ng-option value="drivers">View/Edit Drivers</ng-option>
          <ng-option value="coverage">View/Edit Coverage</ng-option>
          <ng-option value="paypplan">Update My Pay Plan</ng-option>
          <ng-option value="address">Update My Address</ng-option>
          <ng-option value="docs">View Policy Documents</ng-option>
          <ng-option value="claim">Report a Claim</ng-option>
        </ng-select>
       </div>
      </ng-container> 
      <div body>
        <ng-container>
          <div>Policy Period:<span class="ml-2 font-bold">{{policyGreetings.effectiveDate | date : 'MM/dd/yyyy'}} -
              {{policyGreetings.expirationDate | date : 'MM/dd/yyyy'}}</span></div>
          <div>Policy Status:<span class="ml-2 font-bold">{{policyGreetings.statusCode}}</span></div>
        </ng-container>
      </div>
    </prcins-card>
  
    
   <div *ngIf="showABTestRegular(policy,policyGreetings)" class="quicklinks-section">
    <ng-container>
      <div class="border rounded border-gray-300 mt-8" *ngIf="policyGreetings && policyGreetings.lob === 'PA'">
        <div class="flex justify-center flex-wrap pt-4 pb-4 quicklinks">
          <div class="w-1/3 flex flex-col justify-center items-center cursor-pointer drivers-quicklink" [routerLink]="['../policy/drivers']">
            <img src="assets/svgs/anti-driver.svg" alt="">
            <span class="font-bold text-es-blue-light">Drivers</span>
          </div>
  
          <div class="w-1/3 flex flex-col justify-center items-center cursor-pointer vehicles-quicklink" [routerLink]="['../policy/vehicles']">
            <img src="assets/svgs/vehicles-small.svg" alt="" style="height: 50px;">
            <span class="font-bold text-es-blue-light"  style="margin-top: -10px;">Vehicles</span>
          </div>
  
          <div class="w-1/3 flex flex-col justify-center items-center cursor-pointer coverages-quicklink" [routerLink]="['../documents/policy-declaration']">
            <img src="assets/svgs/coverage-small.svg" alt="" style="height: 50px;width: 50px;">
            <span class="font-bold text-es-blue-light" style="margin-top: -10px;">Coverages</span>
          </div>
        </div>
      </div>      
    </ng-container>
  </div>
  </div>


</div>






<ng-template #loading>
  <prcins-card pt="pt-0">
    <div body>
      <prcins-skeleton   numOfRows="2"></prcins-skeleton>
    </div>
  </prcins-card>
</ng-template>