<main class="px-8 pb-8 pt-4">
  <h1 class="es-h1 mt-4 pb-8 border border-t-0 border-r-0 border-l-0">Link a Policy</h1>
  <prcins-danger *ngIf="linkError">{{errorMessage}}</prcins-danger>
  <p>Wouldn't it be great if you could see all your policy info in one place? We think so too, that's why you can
    register your additional policies here.</p>
  <prcins-user-form [userForm]="linkPolicyForm"></prcins-user-form>
  <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
  <div class="flex flex-wrap-reverse mt-8 justify-between">
    <a class="w-full lg:w-2/5 es-link-secondary" routerLink="../">Cancel</a>
    <button class="w-full lg:w-2/5 es-btn-primary mb-2 lg:mb-0" (click)="linkPolicy()" type="button">Continue</button>
  </div>
</main>

<prcins-terms-conditions></prcins-terms-conditions>