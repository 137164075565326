import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RegistrationService } from '@prcins/utils';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'prcins-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {

  isModeSelected = false;
  userPolicyDetailsResp: any;
  verificationOptionsForm: any;
  verificationMode = [];

  constructor(private registrationProvider: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit() {
        let verificationId = sessionStorage.getItem('verificationId');
        this.registrationProvider.getUserDetails(verificationId).subscribe((data) => {
          if (data) {
        if (data.verified) {
          this.registrationProvider.invalidateEndorsementSession();
          this.router.navigate(['../login'], {
            relativeTo: this.route,
          });
        } else {
            this.userPolicyDetailsResp = data;
          }
      }
    }, (error: HttpErrorResponse) => {
      this.registrationProvider.invalidateEndorsementSession();
      this.router.navigate(['../login'], {
        relativeTo: this.route,
    });
    });
  }

  onFormSubmitted(value) {
    this.isModeSelected = true;
    this.verificationOptionsForm = value;
  }

  onBackClicked(value) {
    this.isModeSelected = false;
    if (value) {
      this.verificationMode.push(value);
    }
  }

}
