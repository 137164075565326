export const USER_ID = "userId";
export const ADMIN_USER_ID = "adminUserId";
export const FORGET_USER_ID = "forgetUserId";
export const USER_LOGIN = "userLogin";
export const USER_TYPE = "userType";
export const USER_FNAME = "userfirstName";
export const USER_LNAME = "userLastName";
export const AUTH = "authFlag";
export const REGISTRATION = "registration";
export const CURRENT_POLICY = "currentPolicy";
export const TOKEN = "token";
export const POLICY_ZIP_CODE = "zipCode";
export const CURRENT_POLICY_COMPANY_CODE = "companyCode";
export const CURRENT_POLICY_LOB = "lob";
export const CURRENT_POLICY_STATUS = "policyStatus"
export const CURRENT_POLICY_PRODUCT_CODE = "productCode";
export const CURRENT_POLICY_CHANNEL = "channel";
export const CURRENT_POLICY_STATE = "state";
export const CURRENT_POLICY_PAY_PLAN = "payplan";
export const QPAY_PAYMENT_DETAILS = "pd";
export const QPAY_REGISTRATION_EMAIL = "qpayRegistrationEmailPrefill";
export const PAYMENT_SOURCE = "paymentSource";
export const EMPLOYEE_QPAY_PAYMENT_SOURCE = 'empqpay';
export const PAYMENT_AMOUNT = "paymentAmount";
export const PAYMENT_ID = "paymentId";
export const CURRENT_POLICY_PRIME = "prime";
export const ADMIN = "admin";
export const BRAND = "brand";
export const BILLING_ENDORSEMENT_PAYMENT_DETAILS = "endorsementPd";
export const BASE_URL = "baseURL";
export const WALLET_APP_NAME = "eservice";
export const SAVED_WALLET = "savedWallet";
export const SAVE_WALLET_ERROR = "saveWalletError";
export const ADMIN_QPAY_TOKEN = "aQPT";
export const SHOW_RR_SUB_MENU = "showRRSubMenu";
export const GUEST_USER_ID = "guestUserId";