import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import * as i0 from "@angular/core";
export class ScrollToInvalidService {
    scrollToFirstInvalidControl(elementRef) {
        const firstInvalidControl = elementRef.nativeElement.querySelector(".ng-invalid");
        if (!firstInvalidControl) {
            return;
        }
        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: "smooth"
        });
        fromEvent(window, "scroll")
            .pipe(debounceTime(100), take(1))
            .subscribe(() => firstInvalidControl.focus());
    }
    getTopOffset(controlEl) {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }
}
ScrollToInvalidService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScrollToInvalidService_Factory() { return new ScrollToInvalidService(); }, token: ScrollToInvalidService, providedIn: "root" });
