export const SECURITY_QUESTIONS = [
  'Select',
  'Where did I meet my significant other?',
  'What was my favorite game as a child?',
  'What was my first teachers name?',
  'Where did I go for my honeymoon?',
  "What was my first pet's name?",
  'What city were you born in?',
  'What is your favorite sports team?',
  'What is your all-time favorite food?',
  "What is your mother's maiden name?"
];
