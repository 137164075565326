import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

export const accountMatchValidator = (): ValidatorFn => {
  return (group: FormGroup): null => {
    const accNumber = group.get('accountNumber');
    const rAccNumber = group.get('reAccountNumber');
    if (accNumber.value && rAccNumber.value) {
      if (rAccNumber.value !== accNumber.value) {
        rAccNumber.setErrors({ accountNotMatching: true });
      }
    }
    return null;
  };
};

export const routingValidatorFn = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.touched && control.value.length === 9) {
      const [d1, d2, d3, d4, d5, d6, d7, d8, d9] = [...control.value.split('')];
      const sum =
        3 * Number(d1) +
        3 * Number(d4) +
        3 * Number(d7) +
        7 * Number(d2) +
        7 * Number(d5) +
        7 * Number(d8) +
        Number(d3) +
        Number(d6) +
        Number(d9);

      return sum === 0 || sum % 10 !== 0
        ? { invalidRoutingNumber: true }
        : null;
    }
    return null;
  };
};

export const cardNumberValidatorFn = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.touched) {
      const modelValue = control.value.toString().replace(/\s+/g, '');
      let len = modelValue.length;
      if (len === 15) {
        return !/^(?:3[47][0-9]{13})$/.test(modelValue)
          ? { invalidCardNumber: true }
          : null;
      }
      if (len === 16) {
        if (/^(?:3[47][0-9]{13})$/.test(modelValue)) {
          return { invalidCardNumber: true };
        }
        let mul = 0;
        const prodArr = [
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
        ];
        let sum = 0;
        while (len--) {
          sum += prodArr[mul][parseInt(modelValue.charAt(len), 10)];
          mul ^= 1;
        }
        return sum % 10 === 0 && sum > 0 ? null : { invalidCardNumber: true };
      }
    }
    return null;
  };
};

export const expiryDateValidatorFn = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.touched && control.value.length === 6) {
      const expirationDate = new Date(
        control.value.substr(0, 2) + '/01/' + control.value.substr(2, 4)
      );
      const currentDate = new Date();
      currentDate.setDate(0);
      return expirationDate >= currentDate ? null : { invalidExpiryDate: true };
    }
    return null;
  };
};

export const paymentDetailsValidatorFn = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const paymentMethod = control.get('paymentOptionSelected').value;

    if (paymentMethod === 'eft') {
      if (control.get('eftForm').disabled &&
        (control.get('eftSavedPaymentMethod').enabled && (!control.get('eftSavedPaymentMethod').value || !(control.get('eftSavedPaymentMethod').value.length > 0)))) {
        control.get('eftSavedPaymentMethod').setErrors({ required: true });
        return { required: true };
      }
    } else if (paymentMethod === 'cc') {
      if (control.get('cardForm').disabled &&
        (control.get('ccSavedPaymentMethod').enabled && (!control.get('ccSavedPaymentMethod').value || !(control.get('ccSavedPaymentMethod').value.length > 0)))) {
        control.get('ccSavedPaymentMethod').setErrors({ required: true });
        return { required: true };
      }
    }
    return null;
  }
};

export const _ACHRoutingValidatorFn = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const _ACHValidationError = history.state.error as {
      code: string,
      message: string
    };
    if (_ACHValidationError) {
      if (_ACHValidationError.code === '4533003') { return { achRoutingAccountPair: true }; }
      else if (_ACHValidationError.code === '4533005') { return { achRouting: true }; }
    }
    return null;
  }
};

export const _ACHAccountValidatorFn = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const _ACHValidationError = history.state.error as {
      code: string,
      message: string
    };
    if (_ACHValidationError) {
      if (_ACHValidationError.code === '4533003') { return { achRoutingAccountPair: true }; }
      else if (_ACHValidationError.code === '4533006') { return { achAccount: true }; }
    }
    return null;
  }
};
