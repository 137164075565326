export const Brand = {
  PL: {
    Privacy: 'http://pilgrimins.com/privacy',
    companyName: 'Pilgrim Assurance',
    urlContactUs: 'http://www.pilgrimins.com/about-us/contact-us',
    urlTermsConditions: 'http://www.pilgrimins.com/terms',
    urlEnterpriseWeb: 'http://www.pilgrimins.com/'  
  },
  PR: {
    Privacy: 'http://plymouthrock.com/privacy',
    companyName: 'Plymouth Rock Assurance Corporation',
    urlContactUs: 'https://www.plymouthrock.com/contact-us',
    urlTermsConditions: 'https://www.plymouthrock.com/terms',
    urlEnterpriseWeb: 'http://www.plymouthrock.com/' 
  }
};
