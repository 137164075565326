<ng-container *ngIf="!isLoading; else loading">
  <ng-container *ngIf="showOpenClaims">
    <prcins-card headerClass="bg-es-blue text-white" *ngIf="!claimsListEmty">

      <span header-left>Open Claim(s)</span>

      <div body *ngFor="let claims of openClaims | slice:0; let i = index;">

        <div class="flex justify-between mt-4 sm:px-2">
          <span class="font-bold">Claim #:{{claims.claimNumber}}</span><span *ngIf="claims.status === 'Open'"
            class="text-es-status-green font-bold">In Progress</span>
            <span *ngIf="claims.status != 'Open'"
            class="text-es-status-green font-bold">{{claims.status}}</span>
        </div>
        <div class="flex justify-between sm:px-2">
          <span>{{claims.dateOfLoss | date: 'MM/dd/yyyy'}}</span><span></span>
        </div>

        <div class="flex justify-between sm:px-2">
          <span></span>
          <span>
            <a class="mt-8 w-32 sm:w-48 text-center text-blue-700" [routerLink]="['../claims',claims.claimNumber]">View
              Details</a>
            <a *ngIf="showClaimDocuments" class="mt-8 ml-8 w-32 sm:w-48 text-center text-blue-700"
              [routerLink]="['../documents/policy-documents']" [queryParams]="{docType:'CLAIMS'}">Documents</a>
          </span>
        </div>

        <div [ngClass]="{'border-b-2': i < openClaims.length-1}" class="flex justify-between mt-4 sm:px-2"></div>

      </div>

    </prcins-card>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div class="mt-8">
    <prcins-card pt="pt-0">
      <div body>
        <prcins-skeleton  numOfRows="6"></prcins-skeleton>
      </div>
    </prcins-card>
  </div>

</ng-template>