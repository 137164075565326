import { Component, OnInit, Input, OnChanges, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'prcins-help-text',
  templateUrl: './help-text.component.html',
  styleUrls: ['./help-text.component.css']
})
export class HelpTextComponent implements OnInit {

  targetId: string;
  showPopUp = false;
  @Input() title: string;
  @Input() text: string;
  @Input() id: string;
  constructor(private elRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.targetId = this.id + 'Modal';

  }
  showPopUpModal(id: string) {
    let el = this.elRef.nativeElement.querySelector('#' + id);
    this.renderer.setStyle(el, 'display', 'block');
  }
  closePopUpModal(id: string) {
    let el = this.elRef.nativeElement.querySelector('#' + id);
    this.renderer.setStyle(el, 'display', 'none');
  }

}
