/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-banner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./message-banner.component";
import * as i4 from "apollo-angular";
import * as i5 from "@angular/platform-browser";
var styles_MessageBannerComponent = [i0.styles];
var RenderType_MessageBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageBannerComponent, data: {} });
export { RenderType_MessageBannerComponent as RenderType_MessageBannerComponent };
function View_MessageBannerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sanitizer.bypassSecurityTrustHtml(_v.context.$implicit.richText); _ck(_v, 1, 0, currVal_0); }); }
function View_MessageBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "banner  text-black  shadow-lg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageBannerComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MessageBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageBannerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MessageBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-message-banner", [], null, null, null, View_MessageBannerComponent_0, RenderType_MessageBannerComponent)), i1.ɵdid(1, 114688, null, 0, i3.MessageBannerComponent, [i4.Apollo, i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageBannerComponentNgFactory = i1.ɵccf("prcins-message-banner", i3.MessageBannerComponent, View_MessageBannerComponent_Host_0, {}, {}, []);
export { MessageBannerComponentNgFactory as MessageBannerComponentNgFactory };
