import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { DeviceDetectorService } from 'ngx-device-detector';
/**
 * Facade for the underlying logger implementation
 */
@Injectable({ providedIn: "root" })
export class Logger {

    constructor(private logger:NGXLogger, private deviceService: DeviceDetectorService) {}

    debug(message: any, ...additional: any[]): void {
        this.logger.debug(message, additional);
    }

    info(message: any, ...additional: any[]): void {
        this.logger.info(message, additional);
    }

    warn(message: any, ...additional: any[]): void {
        this.logger.warn(message, additional);
    }

    error(message: any, ...additional: any[]): void {
        message = this.getDeviceInfo() + ' ' +message;
        this.logger.error(message, additional);
    }

   private getDeviceInfo(): string {
    let message= '';
    const deviceInfo = this.deviceService.getDeviceInfo();
    
    if(deviceInfo) {
        message='Device: '+deviceInfo.device +', OS: '+deviceInfo.os +', OS_Version: ' +deviceInfo.os_version+', Browser: '+deviceInfo.browser +', Browser_version: '+deviceInfo.browser_version;
    }
    return message;

    }

}