/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../libs/utils/src/lib/es-popup/es-popup.component.ngfactory";
import * as i3 from "../../../../libs/utils/src/lib/es-popup/es-popup.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../libs/utils/src/lib/tealium/page-tracker.directive";
import * as i6 from "../../../../libs/utils/src/lib/services/tealium/tealium-tracker.service";
import * as i7 from "@angular/common";
import * as i8 from "./app.component";
import * as i9 from "angular-user-idle";
import * as i10 from "apollo-angular";
import * as i11 from "../../../../libs/utils/src/lib/services/login.service";
import * as i12 from "../../../../libs/utils/src/lib/services/utility-provider.service";
import * as i13 from "@angular/common/http";
import * as i14 from "./services/session.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "prcins-es-popup", [["headerClass", "bg-es-blue text-white"]], null, null, null, i2.View_EsPopupComponent_0, i2.RenderType_EsPopupComponent)), i1.ɵdid(1, 49152, null, 0, i3.EsPopupComponent, [], { headerClass: [0, "headerClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["header-left", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Session Timeout"])), (_l()(), i1.ɵeld(5, 0, null, 2, 9, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your session is being timed out due to inactivity."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please choose to stay signed in or you will be logged out automatically"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "flex flex-wrap-reverse justify-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["class", "es-link-secondary w-full mt-4 sm:w-40 cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "es-btn-primary w-full mt-4 sm:w-40"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onContinue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["Stay signed in (", ")"]))], function (_ck, _v) { var currVal_0 = "bg-es-blue text-white"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.countdown; _ck(_v, 14, 0, currVal_1); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "prcins-tealium-page-tracker", [], null, null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i5.PageTrackerDirective, [i4.ActivatedRoute, i4.Router, i6.TealiumTrackerService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); var currVal_0 = _co.showTimeout; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "prcins-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i8.AppComponent, [i9.UserIdleService, i4.Router, i4.ActivatedRoute, i10.Apollo, i11.LoginService, i12.UtilityProviderService, i1.ViewContainerRef, i13.HttpClient, i14.SessionService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("prcins-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
